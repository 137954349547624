import React, {Suspense, useEffect} from "react";
import {BrowserRouter, Navigate, Route, Router, Routes, useSearchParams} from "react-router-dom";
import "./common.scss";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/autoplay";
import "swiper/css/effect-fade";
import "swiper/css/pagination";
import 'swiper/css/effect-coverflow';

import {ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {BRAND_ACCOUNT_ID, BRAND_ID, CUSTOMER_ID} from "./constants/enums";
import {useDispatch, useSelector} from "react-redux";
import {
    fetchBrandAccounts,
    fetchCustomerProfile,
    fetchMagicCode,
    setAuthentication,
    setAuthState,
    setBrandId,
    setIsPro,
    setUpdatedBrand,
    setUserId,
    updateCustomerArchPro
} from "./redux/app/appAction";
import {AUTHENTICATED, AUTHENTICATING, NOT_AUTHENTICATED, ISPRO} from "./constants/authEnums";
import HeaderV2 from "./components/Header/HeaderV2";
import {
    businessAccessURL,
    businessConsoleURL,
    businessFormFiveURL,
    changeRoleURL,
    comingsoonURL,
    consoleUrl,
    contactUsURL,
    datatypesURL,
    faqsURL,
    DRconsoleURL,
    editProfile,
    homepageURL,
    loginURL,
    privacypolicyURL,
    regiserOTPURL,
    registrationFormURL,
    resetPassURL,
    studentAccesssURL,
    studentsConsoleURL,
    teamAccesssURL,
    teamMemberChangeRoleURL,
    teamMemberConsoleURL,
    termsandconditionURL,

    BlogsListingURL,
    BlogsInnerURL, congratulationsPageUrl, personalAccountRegistrationUrl, enthusiastAccesssURL,
} from "./components/helpers/constant-words";
import RegistrationForm from "./pages/RegistrationForm/RegistrationForm";
import HomepageV2 from "./pages/Homepage/Homepage-v2";
import Comingsoon from "./pages/Comingsoon/Comingsoon";
import Login from "./pages/Login/Login";
import ResetPassword from "./pages/ResetPassword/ResetPassword";
import ContactUs from "./pages/ContactUs/ContactUs";
import Faqs from "./pages/Faqs/Faqs";
import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy";
import Termsandcondition from "./pages/Terms&Condition/Terms&Condition";
import NotFound from "./pages/NotFound";
import DataTypes from "./pages/DataTypes/DataTypes";
import DEConsole from "./pages/DEConsole/DEConsole";

import BusinessConsole from "./pages/BusinessConsole/BusinessConsole";
import TeamMemberConsole from "./pages/TeamMemberConsole/TeamMemberConsole";
import ChangeRole from "./pages/ChangeRole/ChangeRole";
import TeamMemberChangeRole from "./pages/TeamMemberChangeRole/TeamMemberChangeRole";
import TeamAccessForm from "./pages/TeamMember/TeamAccess/TeamAccessForm";
import StudentsAccess from "./pages/StudentsForm/StudentAccess/StudentsAccess";
import BusinessAccess from "./pages/BusinessForm/BusinessAccess/BusinessAccess";
import FormFive from "./pages/FormFive/Form/FormFive";
import RegisterOTP from "./pages/RegistrationForm/RegisterOTP/RegisterOTP";
import EditProfile from "./pages/EditProfile/EditProfile";
import MagicLogin from "./pages/MagicLogin";
import BlogsListing from "./pages/BlogsListing/BlogsListing";
import BlogsInner from "./pages/BlogsInner/BlogsInner";
import {getBrandId, getCustomer} from "./redux/app/appSelectors";
import FullPageLoader from "./pages/FullPageLoader";
import Congratulations from "./pages/Congratulations/Congratulations";
import _ from "lodash";
import AccountCategory from "./pages/RegistrationForm/AccountCategory/AccountCategory";
import EnthusiastAccess from "./pages/EnthusiastForm/EnthusiastAccess/EnthusiastAccess";
import UserConsole from "./pages/UserConsole/UserConsole";
import AOS from "aos";
import "aos/dist/aos.css";

function App() {

    const dispatch = useDispatch();
    useEffect(() => {
        AOS.init({
          duration: 1500,
          // once: true,
        });
      }, []);

    useEffect(() => {
        let customerId = window.localStorage.getItem(CUSTOMER_ID);

        if (customerId) {
            dispatch(setAuthentication(true));
            dispatch(setAuthState(AUTHENTICATED));
            dispatch(fetchCustomerProfile(customerId));
            dispatch(setBrandId(customerId))
            //dispatch(setIsPro(true))

        } else {
            dispatch(setAuthState(NOT_AUTHENTICATED));
        }
    }, [])

    // useEffect(() => {
    //     let ispro = window.localStorage.getItem(ISPRO);
    //     if(ispro){
    //         dispatch(setIsPro(true))
    //     }
    // },[])
    const authState = useSelector((state) => state?.app?.authState);


    const NotAuthenticatedRoutes = () => {
        return (
            <Routes>
                <Route path={businessFormFiveURL} element={<FormFive/>}/>
                <Route path={registrationFormURL} element={<AccountCategory />}/>
                <Route path={personalAccountRegistrationUrl} element={<RegistrationForm />}/>
                <Route path={homepageURL} element={<HomepageV2/>}/>

                <Route path={comingsoonURL} element={<Comingsoon/>}/>
                <Route path={loginURL} element={<Login/>}/>
                <Route path={resetPassURL} element={<ResetPassword/>}/>
                <Route path={contactUsURL} element={<ContactUs/>}/>
                <Route path={faqsURL} element={<Faqs/>}/>
                <Route path={privacypolicyURL} element={<PrivacyPolicy/>}/>
                <Route path={BlogsListingURL} element={<BlogsListing />} />
                <Route path={BlogsInnerURL} element={<BlogsInner />} />
                <Route path={termsandconditionURL} element={<Termsandcondition/>}/>
                <Route path={regiserOTPURL} element={<RegisterOTP/>}/>
                <Route path={congratulationsPageUrl} element={<Congratulations />}/>
                <Route exact path="/404" element={<NotFound/>}/>
                <Route path="t/:code" element={<MagicLogin />}/>
                <Route path="*" element={<Navigate to={loginURL} />}/>
            </Routes>
        );
    }

    const AuthenticatedRoutes = () => {
        return (
            <Routes>
                <Route path={registrationFormURL} element={<AccountCategory />}/>
                <Route path={businessFormFiveURL} element={<FormFive/>}/>
                <Route path={personalAccountRegistrationUrl} element={<RegistrationForm />}/>
                <Route path={regiserOTPURL} element={<RegisterOTP/>}/>
                {/* <Route path={datatypesURL} element={<DataTypes />} /> */}
                {/* <Route path={consoleUrl} element={<UserConsole/>}/> */}
                <Route path={congratulationsPageUrl} element={<Congratulations />}/>
                <Route path={homepageURL} element={<HomepageV2/>}/>
                <Route path={comingsoonURL} element={<Comingsoon/>}/>
                <Route path={datatypesURL} element={<DataTypes/>}/>
                <Route path={DRconsoleURL} element={<DEConsole/>}/>
                <Route path={consoleUrl} element={<UserConsole/>}/>
                <Route path={businessConsoleURL} element={<BusinessConsole/>}/>
                <Route path={teamMemberConsoleURL} element={<TeamMemberConsole/>}/>
                <Route path={changeRoleURL} element={<ChangeRole/>}/>
                <Route
                    path={teamMemberChangeRoleURL}
                    element={<TeamMemberChangeRole/>}
                />
                <Route path={teamAccesssURL} element={<TeamAccessForm/>}/>
                <Route path={studentAccesssURL} element={<StudentsAccess/>}/>
                <Route path={businessAccessURL} element={<BusinessAccess/>}/>
                {/* <Route path={consoleURL} element={<Console />} /> */}
                <Route path={enthusiastAccesssURL} element={<EnthusiastAccess/>}/>
                <Route path={businessConsoleURL} element={<BusinessConsole/>}/>
                <Route path={teamMemberConsoleURL} element={<TeamMemberConsole/>}/>
                <Route path={BlogsListingURL} element={<BlogsListing />} />
                <Route path={BlogsInnerURL} element={<BlogsInner />} />


                <Route
                    path={teamMemberChangeRoleURL}
                    element={<TeamMemberChangeRole/>}
                />
                <Route path={editProfile} element={<EditProfile/>}/>
                <Route path={loginURL} element={<Login/>}/>
                <Route path={resetPassURL} element={<ResetPassword/>}/>
                <Route path={contactUsURL} element={<ContactUs/>}/>
                <Route path={faqsURL} element={<Faqs/>}/>
                <Route path={privacypolicyURL} element={<PrivacyPolicy/>}/>
                <Route path={termsandconditionURL} element={<Termsandcondition/>}/>
                <Route exact path="/404" element={<NotFound/>}/>
                <Route path="t/:code" element={<MagicLogin />}/>
                <Route path="*" element={<Navigate to="/404"/>}/>
                
            </Routes>
        );
    }

    const getRoutes = () => {
        switch (authState) {
            case AUTHENTICATED:
                return <AuthenticatedRoutes/>;
            case NOT_AUTHENTICATED:
                return <NotAuthenticatedRoutes/>;
            case AUTHENTICATING:
                return <FullPageLoader/>;
            default:
                return <FullPageLoader />;
        }
    };

    return (
        <>
            <ToastContainer/>
            <BrowserRouter>
                <HeaderV2/>
                <Suspense fallback={<FullPageLoader />}>
                    {getRoutes()}
                </Suspense>
               
            </BrowserRouter>
        </>
    );
}

export default App;

// git push origin beta
