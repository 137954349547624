import style from "./countrycode.module.scss";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { countries } from "../../db/dataTypesData";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { useWindowSize } from "react-use";
import { useSelector } from "react-redux";
import {getCreateAccount} from "../../redux/app/appSelectors"

const darkTheme = createTheme({
  typography: {
    fontFamily: "Poppins",
  },
  palette: {
    mode: "dark",
  },
});
const lighttheme = createTheme({
  typography: {
    fontFamily: "Poppins",
  },
  palette: {
    mode: "light",
  },
});

const CountryCodeDropdown = ({ textLabel, id, handleChangeUser, keyName }) => {
  const { width } = useWindowSize();
  const lightTheme =   useSelector((state) => getCreateAccount(state));
  return (
    <>
    {lightTheme === false ? (
      <ThemeProvider theme={lighttheme}>
        <CssBaseline />
        <Autocomplete
          defaultValue={{code:"IN", phone: "91"}}
          className={`${style.autocomplete_dropdown} ${style.autocomplete_dropdown_lt}`}
          id={id}
          options={countries}
          disableClearable
          componentsProps={{
            paper: {
              sx: {
                width: 500,
                borderRadius: width > 768 ? "10px" : "10px",
              },
            },
          }}
          autoHighlight
          getOptionLabel={(option) => `${option.code} + ${option.phone}`}
          onChange={(e,newValue) => handleChangeUser(keyName ? keyName : "country_code",newValue.phone)}
          sx={{
            color: "#111",
            "& fieldset": {
              borderRadius: width > 768 ? "10px" : "10px",
              border: "1px solid #707070",
              color: "#111",
            },
            "& .MuiOutlinedInput-root": {
              borderRadius: width > 768 ? "10px" : "10px",
            },
            "& label": {
              lineHeight: width > 768 ? "2em" : "1.5em",
              color: "#111",
            },
            "& label.Mui-focused": {
              color: "#111",
            },
            "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
              borderRadius: width > 768 ? "10px" : "10px",
              border: "1px solid #707070",
            },
            "& .MuiOutlinedInput-root .Mui-focused .MuiOutlinedInput-notchedOutline":
              {
                border: "1px solid #707070",
              },
            "&.Mui-focused .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
              {
                borderColor: "#111",
              },
            "& .MuiSvgIcon-root ": {
              fill: "#f77b00 !important",
            },
          }}
          renderOption={(props, option) => (
            <Box
              className={style.option_list}
              component="li"
              {...props}
              sx={{
                minWidth: 450,
                zIndex: 9,
                overflow: "visible",
              }}
            >
              <span>{option.code}</span>
              <span>{option.label}</span>
              <span>+{option.phone}</span>
            </Box>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              label={textLabel}
              inputProps={{
                style: {
                  fontSize: width > 768 ? "1.25em" : "1.25em",
                  // height: "2em",
                },
                ...params.inputProps,
                // autoComplete: "new-password", // disable autocomplete and autofill
              }}
            />
          )}
        />
      </ThemeProvider>
    ) : (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <Autocomplete
        defaultValue={{code:"IN", phone: "91"}}
        className={style.autocomplete_dropdown}
        id="country-select-demo"
        options={countries}
        disableClearable
        componentsProps={{
          paper: {
            sx: {
              width: 500,
              borderRadius: width > 768 ? "10px" : "10px",
            },
          },
        }}
        autoHighlight
        getOptionLabel={(option) =>   `${option.code} + ${option.phone}`}
        //value={user}
        onChange={(e,newValue) => handleChangeUser(keyName ? keyName : "country_code",newValue.phone)}
        sx={{
          color:"#fff",
          "& fieldset": {
            borderRadius: width > 768 ? "10px" : "10px",
            border: "1px solid $color-707070",
          },
          "& .MuiOutlinedInput-root": {
            borderRadius: width > 768 ? "10px" : "10px",
            border: "1px solid $color-707070",
          },
          "& label": {
            lineHeight: width > 768 ? "2em" : "1.5em",
          },
          "& label.Mui-focused": {
            color: "#fff",
          },
          "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderRadius: width > 768 ? "10px" : "10px",
            border: "1px solid $color-707070",
          },
          "& .MuiOutlinedInput-root .Mui-focused .MuiOutlinedInput-notchedOutline":
            {
              border: "1px solid $color-707070",
            },
          "&.Mui-focused .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
            {
              borderColor: "#fff",
            },
          "& .MuiSvgIcon-root ": {
            fill: "#f77b00 !important",
          },
        }}
        renderOption={(props, option) => (
          <Box
            className={style.option_list}
            component="li"
            {...props}
            sx={{
              minWidth: 450,
              zIndex: 9,
              overflow: "visible",
            }}
          >
            <span>{option.code}</span>
            <span>{option.label}</span>
            <span>+{option.phone}</span>
          </Box>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            label={textLabel}

            inputProps={{
              style: {
                fontSize: width > 768 ? "1.25em" : "1.25em",
                // height: "2em",
              },
              ...params.inputProps,
              // autoComplete: "new-password", // disable autocomplete and autofill
            }}
          />
        )}
      />
    </ThemeProvider>
      )}
      </>
  );
};

export default CountryCodeDropdown;


