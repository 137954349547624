import {isValidPhoneNumber} from 'libphonenumber-js'
import {countries} from "../db/dataTypesData";
import _ from 'lodash'

export function validatePhoneNumber(contact_number, country_code) {
    let data = {};

    let country = countries.filter(country => country.phone === country_code);

    data.isValid = isValidPhoneNumber(contact_number, !_.isEmpty(country) ? country[0]?.code : 'IN');
    data.message = `Please enter a valid number of ${country[0]?.label}`

    return data;
}
