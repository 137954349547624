import React, { useEffect , useState} from "react";
import style from "../Form/formfive.module.scss";
import LogoutText from "../../../components/LogoutText/LogoutText";
import FullWidthTextField from "../../../components/TextField/FullWidthTextField";
import { rightarrowblack, rightarrowwhite } from "../../../images";
import { updateCustomerArchPro ,updateCustomerArchProV2,setCustomerData} from "../../../redux/app/appAction";
import {ErrorsMsg, SuccessMsg} from "../../../util/Message";
import {toast} from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { getBrandId, getBrand } from "../../../redux/app/appSelectors";


const FIStep01 = ({
  nextStep,
  currentStep,
  totalSteps,
  progressStatus,
  previousStep,
  handleUserExtraData,
  userExtraData,
  goToStep
}) => {
  const dispatch = useDispatch();
  const brandId = useSelector((state) => getBrandId(state)) ;
  const brandData = useSelector((state) => getBrand(state));
  const [user, setUser] = useState({
    brand_name: "",

 });
 const [errors, setErrors] = useState({});

  useEffect(() => {
    if(brandData?.brand_name){
    setUser({ brand_name: brandData?.brand_name})
    }
  },[brandData])

 const handleChangeUser = (keyName, keyValue) => {
   let update = {...user};
   if(keyValue){
    setErrors({})
   }
   update[keyName] = keyValue;
   update.brand_current_flow = currentStep;
   setUser(update);
 }
 const handleValidation = (user) => {
   let isValid = true;
   let errors = {};

   if (!user?.brand_name) {
       isValid = false;
       errors["brand_name"] = "Brand name cannot be blank";
   }

   return {
       isValid,
       errors
   }
 };
 const handleUpdateContactDetail = () => {
  let check = handleValidation(user);
    
  if(check.isValid && brandId && user){
    updateCustomerArchProV2(brandId, user).then(res => {
      if(res?.status === 'Success' && res?.data?.brand) {
        dispatch(setCustomerData(res?.data?.brand));
        nextStep();
        window.scrollTo(0, 0);
      } else {
        toast(ErrorsMsg(res?.data?.message),{
          className:"mail_toast",
          position: toast.POSITION.TOP_CENTER,
          hideProgressBar: true,
          closeButton: false,
        })
      }
    });

    setErrors({})
  }else {
    setErrors(check?.errors);
    }

 }
 const renderError = (keyName) => {
   return errors && errors[keyName] ? <p className="error" style={{margin: "10px 0", }}>{errors[keyName]}</p> : null;
 };



  return (
    <>
      <div className={style.text_container}>
      <p className={`${style.head_text1}`}>About The Business</p>
    
        <h1 className={style.title}>
          {/* Be Found By Clients,{" "}
          <span className={style.coloured_text}>led by AI!</span> */}
          Register your Brand
        </h1>
        <p className={style.description}></p>
      </div>
      <div className={`${style.steps} ${style.fastep01}`}>
        <div className={style.led_ai_row}>
          <div className={style.field_wrapper}>
            <FullWidthTextField label="Brand Name*" value={user?.brand_name}
                onChange={(e) => handleChangeUser("brand_name", e.target.value)}/>

          </div>
        
          {renderError("brand_name") }
        </div>
      </div>

      <div className={style.next_logout}>
        <div className={style.cta_wrapper}>
          <div
            className={style.next_button}
            onClick={handleUpdateContactDetail}
          >
            <div className={style.text}>Next</div>
            <img
              src={rightarrowblack}
              alt="icon"
              className={style.icon}
              loading="lazy"
            />
          </div>
          <div
            className={style.back_button}
            onClick={() => {
              goToStep(2);
              setErrors({})
              window.scrollTo(0, 0);
            }}
          >
            Back
          </div>
        </div>
        <LogoutText userData={user}/>
      </div>
    </>
  );
};

export default FIStep01;
