import React, {useEffect, useState} from "react";
import "./edit-profile.scss";
import {useWindowSize} from "react-use";
import FullWidthTextField from "../../components/TextField/FullWidthTextField";
import TextFieldWithIcon from "../../components/TextFieldWithIcon/TextFieldWithIcon";
import {checkiconWhite, editiconorange, rightarrowwhite} from "../../images";
import CountryCodeDropdown from "../../components/CountryCodeDropdown/CountryCodeDropdown";
import {toast} from "react-toastify";
import OtpInput from "react-otp-input";
import {useDispatch, useSelector} from "react-redux";
import {onEditProfileViaOtp, updateCustomerProfile} from "../../redux/app/appAction";
import {getDisplayNameAsPerLabel} from "../../constants/appEnums";
import {getCustomer, getUserId} from "../../redux/app/appSelectors";
import _ from "lodash";
import {ErrorsMsg} from "../../util/Message";
import callApi from "../../util/apiCaller";
import {validatePhoneNumber} from "../../util/phoneValidator";
import PasswordInput from "../../components/PasswordInput/PasswordInput";
import BlinkingDots from "../../Animations/BlinkingDots/BlinkingDots";
import FooterV2 from "../../components/FooterV2/FooterV2";

const profileData = {
  color: "#FF4A68",
};

export const MsgWithVarText = ({text}) => (
    <div className="otp_box">
      <img
          width={39}
          height={39}
          src={checkiconWhite}
          className="otp_resend_icon"
          loading="lazy"
          alt="icon"
      />
      <p className="title">{text ? text : 'Success'}</p>
    </div>
);

const EditProfile = () => {
  const { width } = useWindowSize();
  let password = "12345678"
  const [otpBox, setOtpBox] = useState(false);
  const [otpVal, setOtpVal] = useState({
    "local_otp": "",
    "contact_email": ""
  });
  const [resend, setResend] = useState(false);
  const [time, setTime] = useState(0);
  const [phoneotpBox, setPhoneOtpBox] = useState(false);
  const [whatsappverify, setWhatsappVerify] = useState(false);
  const [whatsappotpBox, setWhatsappOtpBox] = useState(false);
  const [errors, setErrors] = useState({});
  const customer = useSelector((state) => getCustomer(state));
  const userId = useSelector((state) => getUserId(state));
  const dispatch = useDispatch();
  const [activeInput, setActiveInput] = useState([])

  const [user, setUser] = useState({

    contact_name: "",
    contact_email: "",
    password: "",
    country_code: "91",
    contact_phone: "",
    whatsapp_no: "",
    whatsapp_country_code: "91",
    new_password: "",
    current_password: "",
    confirm_new_password: "",
});

  useEffect(() => {
    if(!_.isEmpty(customer)) {
      setUser(prev => ({...prev, ...customer}))
    }
  }, [customer]);

const handleChangeUser = (keyName, keyValue) => {
  let update = {...user};
  setErrors((prev) => ({...prev, [keyName] : ""}))
  update[keyName] = keyValue;
  setUser(update);
}

const handleCloseAllExtraItems = () => {
  setOtpBox(false);
  setWhatsappOtpBox(false);
  setPhoneOtpBox(false);
  setActiveInput(prev => ([...prev.filter(item => item !== 'contact_email' || item !== 'contact_phone' || item !== 'whatsapp_no')]));
}

const handleotpChange = (keyName, keyValue) => {
  let update = {...otpVal};
  setErrors((prev) => ({...prev, [keyName] : ""}))
  update[keyName] = keyValue;
  if(user?.contact_email){
  update.contact_email = user?.contact_email;
  }else{
    update.contact_email = customer?.contact_email;
  }
  setOtpVal(update);
};

const handleValidation = (user) => {
  let isValid = true;
  let errors = {};

  if (!user?.contact_name) {
      isValid = false;
      errors["contact_name"] = "Contact name cannot be blank";
  }

  if(user.contact_name === customer.contact_name) {
    isValid = false;
    errors["contact_name"] = "Contact name cannot be same";
  }

  return {
      isValid,
      errors
  }
};

const handleEmailValidation = (user) => {
  let isValid = true;
  let errors = {};

  if (!user?.contact_email) {
      isValid = false;
      errors["contact_email"] = "Contact email cannot be blank";
  }

  if(user.contact_email === customer.contact_email) {
    isValid = false;
    errors["contact_email"] = "New email cannot be same";
  }

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (!emailRegex.test(user.contact_email)) {
    isValid = false;
    errors["contact_email"] = "Invalid email format, format - abc@gmail.com";
  }

  return {
      isValid,
      errors
  }
};

const generateCustomerOtp = async (customer) => {
  try {
    const res = await callApi(`customer/request-validation-otp`, "post", {customer: customer});
    //console.log(res);
    if (res && res?.status === 'Success') {
      toast(<MsgWithVarText text={'OTP Shared'} />, {
        className: "otp_toast",
        position: toast.POSITION.TOP_CENTER,
        hideProgressBar: true,
        closeButton: false,
      });
    } else {
      toast(ErrorsMsg(res?.data?.message ? res?.data?.message : "An Error occurred"),{
        className: "mail_toast",
        position: toast.POSITION.TOP_CENTER,
        hideProgressBar: true,
        closeButton: false,
      })
    }
    return res
  } catch (err) {
    // dispatch(SET_IS_LOADING(false));
  }

}

const handleEmailValidate = () => {
  let update = {}
  update.contact_email = user?.contact_email;
  let check = handleEmailValidation(update);

    if( check.isValid && user && userId){
      generateCustomerOtp({_id: customer._id, ...update}).then(res => {
        if(res.status === 'Success') {
          setErrors({})
          setOtpBox(true);
        }
      })
    }else{
      setErrors(check.errors);
    }
}

  const otpValidation = (value) => {
    let isValid = true;
    let errors = {};
    if (_.isEmpty(value?.local_otp)) {
      isValid = false;
      errors["local_otp"] = "otp cannot be blank";
    }
    if(value?.local_otp?.length !== 6) {
      isValid = false;
      errors["local_otp"] = "otp should be 6 digits";
    }

    return {
      isValid,
      errors
    }
  }

  const verifyOtp = async (creds, user) => {
    let res = await callApi(`customer/auth/validate-otp-v4`, "post", {customer: {...user, ...creds}})
    if (res && res?.status === 'Success') {
      dispatch(updateCustomerProfile(user._id, user));
    } else {
      toast(ErrorsMsg("Please fill your correct otp "),{
        className: "mail_toast",
        position: toast.POSITION.TOP_CENTER,
        hideProgressBar: true,
        closeButton: false,
        autoClose: 1000,
      })
    }

    return res
  }

  const verifyEmailHandle = (user) => {
  let check = otpValidation(otpVal)

  if(check.isValid) {
    verifyOtp(otpVal, { contact_email: user?.contact_email,_id: customer._id}).then(res => {
      if(res.status === 'Success') {
        setActiveInput(prev => prev.filter(item => item !== 'contact_email'));
        setOtpBox(false);
        setOtpVal("");
      } else {
        setUser(customer);
      }
    });
  } else {
    setErrors(check.errors)
  }
}

const handlePhoneValidation = (user) => {
  let isValid = true;
  let validation = validatePhoneNumber(user.contact_phone, user.country_code);
  let errors = {};
    if(! user?.contact_phone){
      isValid = false;
      errors["contact_phone"] = "contact phone cannot be blank";
    }
    if(user?.contact_phone === customer?.contact_phone) {
      isValid = false;
      errors["contact_phone"] = "new contact cannot be same";
    }
    if(!validation.isValid) {
      isValid = false;
      errors["contact_phone"] = validation.message;
    }
    if (!user?.country_code ) {
        isValid = false;
        errors["country_code"] = "country code cannot be blank";
    }
    return {
      isValid,
      errors
  }
}
const handlePhoneValidate = () => {
  let update = {contact_phone: user?.contact_phone, country_code:user?.country_code}

  let check = handlePhoneValidation(update);

  if(check.isValid && user && userId){
    generateCustomerOtp({_id: customer._id, ...update}).then(res => {
      if(res.status === 'Success') {
        setErrors({})
        setPhoneOtpBox(true);
      }
    })
    setErrors({})
  }else{
    setErrors(check.errors);
  }
}
const verifyPhoneHandle = () => {
  let check = otpValidation(otpVal)

  if(check.isValid) {
    verifyOtp(otpVal, {contact_phone: user?.contact_phone, country_code: user?.country_code, _id: customer._id}).then(res => {
      if(res.status === 'Success') {
        setActiveInput(prev => prev.filter(item => item !== 'contact_phone'));
        setPhoneOtpBox(false);
        setOtpVal("");
      }
    });
  } else {
    setErrors(check.errors)
  }
}

const handleWhatsappValidation = (user) => {
  let isValid = true;
  let errors = {};
  let validation = validatePhoneNumber(user.whatsapp_no, user.country_code);
    if(! user?.whatsapp_no){
      isValid = false;
      errors["whatsapp_no"] = "Whatsapp phone cannot be blank";
    }
  if(user?.whatsapp_no === customer?.whatsapp_no) {
    isValid = false;
    errors["whatsapp_no"] = "new whatsapp cannot be same";
  }
  if(!validation.isValid) {
    isValid = false;
    errors["whatsapp_no"] = validation.message;
  }
    if (!user?.country_code ) {
        isValid = false;
        errors["country_code"] = "country code cannot be blank";
    }
    return {
      isValid,
      errors
  }
}
const handleWhatsappValidate = () => {
  let update = {whatsapp_no: user?.whatsapp_no, country_code:user?.whatsapp_country_code}

  let check = handleWhatsappValidation(update);

  if(check.isValid && user && userId){
    generateCustomerOtp({_id: customer._id, ...update}).then(res => {
      if(res.status === 'Success') {
        setErrors({})
        setWhatsappOtpBox(true);
      }
    })
    setErrors({})
  }else{
    setErrors(check.errors);
  }
}
const verifyWhatsappHandle = () => {
  let check = otpValidation(otpVal)
  let update = {
    whatsapp_no: user?.whatsapp_no,
    country_code: user.country_code,
    contact_email: user?.contact_email,
    _id: customer._id
  }
  if(check.isValid) {
    verifyOtp(otpVal, update).then(res => {
      if(res.status === 'Success') {
        setActiveInput(prev => prev.filter(item => item !== 'whatsapp_no'));
        setWhatsappOtpBox(false);
        setOtpVal("");
      }
    });
  } else {
    setErrors(check.errors)
  }
}
const handlePasswordValidation = (user) =>{
  let isValid = true;
  let errors = {};
    if(!user?.oldPassword){
      isValid = false;
      errors["current_password"] = "Password cannot be blank";
    }
    if (!user?.newPassword ) {
        isValid = false;
        errors["new_password"] = "Password cannot be blank";
    }
  if (user?.newPassword.length < 8 ) {
    isValid = false;
    errors["new_password"] = "Password should be atleast 8 characters";
  }
  if (user?.newPassword === user?.oldPassword ) {
    isValid = false;
    errors["new_password"] = "New password cannot be same as old password";
  }
    if(!user?.confirm_new_password){
      isValid = false;
      errors["confirm_new_password"] = "confirm Password cannot be blank"
    }
     if (user?.newPassword !== user?.confirm_new_password) {
        isValid = false;
        errors["confirm_new_password"] = "password is not matching";
    }
    return {
      isValid,
      errors
  }

}

const handleChangePassword = async (customer,id) => {
  const res = await callApi(`customer/${id}/change-password`, "post", customer);
  //console.log(res);
  if (res && res?.status === 'Success') {
    // if (navigate) {
    //     navigate(regiserOTPURL);
    // }
    const {customer} = res?.data;
    setActiveInput(prev => prev.filter(item => item !== 'password'));
    setErrors(prev => ({...prev, oldPassword: '', newPassword: ''}));
    setUser(prev => ({
      ...prev,
      current_password: "",
      new_password: "",
      confirm_new_password: "",
    }))
    toast(<MsgWithVarText text={'Password updated'} />, {
      className: "mail_toast",
      position: toast.POSITION.TOP_CENTER,
      hideProgressBar: true,
      closeButton: false,
    })
  } else {

    toast(ErrorsMsg("Please enter correct password"),{
      className: "mail_toast",
      position: toast.POSITION.TOP_CENTER,
      hideProgressBar: true,
      closeButton: false,
    })

  }
}

const handlePasswordReset = () => {
  let update = {}
  update.oldPassword = user?.current_password;
  update.newPassword = user?.new_password;
  update.confirm_new_password = user?.confirm_new_password;
  let check = handlePasswordValidation(update);

  if (check.isValid && userId) {
  handleChangePassword(update,userId);
  setErrors({})
  }else {
    setErrors(check.errors);
  }
}

const handleSubmit = () => {
let update = {}
  update.contact_name = user?.contact_name;
    let check = handleValidation(update);

    if (check.isValid) {
      setActiveInput(prev => prev.filter(item => item !== 'contact_name'));
      dispatch(updateCustomerProfile(userId, update));
      setErrors({})
    } else {
    setErrors(check.errors);
    }
}

const renderError = (keyName) => {
   return errors && errors[keyName] ? <p style={{position: 'relative', marginBottom: '10px',marginTop:'-10px', left: '0'}} className="error">{errors[keyName]}</p> : null;
};


  // const handleotpChange = (otp) => {
  //   setOtpVal(otp);
  //   setPhoneOtpVal(otp);
  //   setWhatsappOtpVal(otp);
  // };

  const Msg = () => (
    <div className="otp_box">
      <img
        width={39}
        height={39}
        src={checkiconWhite}
        className="otp_resend_icon"
        loading="lazy"
        alt="icon"
      />
      <p className="title">OTP reshared</p>
    </div>
  );

  const resendHandler = (customer) => {
    if (resend === false) {
      setResend(true);
      resetTimer();
      
      generateCustomerOtp(customer).then(res => {
        if(res.status === "Success") {
          setOtpVal("");
          setErrors({})
        }
      })
    } else {
      setResend(false);
    }
  };
  useEffect(() => {
    const resetButton = setTimeout(() => {
        setResend(false);
    }, 1000);
    return () => clearTimeout(resetButton);
}, [resend]);

useEffect(() => {
    let timerInterval = null;

    // Decrease time by 1 second every second
    if (time > 0) {
        timerInterval = setInterval(() => {
            setTime(prevTime => prevTime - 1);
        }, 1000);
    }

    // Clear interval and reset timer when time reaches 0
    if (time === 0) {
        clearInterval(timerInterval);
    }

    return () => clearInterval(timerInterval);
}, [time])

const resetTimer = () => {
    setTime(60);
};
  const whatsappResendHandler = () => {
    if (resend === false) {
      dispatch(onEditProfileViaOtp({contact_email: user?.contact_email, country_code:"91", whatsapp_no:user?.whatsapp_no },""))
      setResend(true);
      toast(<Msg />, {
        className: "otp_toast",
        position: toast.POSITION.TOP_CENTER,
        hideProgressBar: true,
        closeButton: false,
      });
    } else {
      setResend(false);
    }
  };
  useEffect(() => {
    const resetButton = setTimeout(() => {
      setResend(false);
    }, 3000);
    return () => clearTimeout(resetButton);
  }, [resend]);

  return (
    <>
     <BlinkingDots />
      <main className="edit_container">
        <section className="edit_sec1">
          <div className="my_container">
            <div className="row">
              <div className="col-md-3 d-none d-md-block d-lg-block">
                <p className="title">My Account</p>
                <ul className="account_list">
                  <li className="list-item">Edit Your Profile</li>
                </ul>
              </div>
              <div className="col-md-9">
                <div className="role_wrap">
                  <p className="role_title">YOUR ROLE</p>
                  <p className="user_role" style={{ color: profileData.color }}>
                    {getDisplayNameAsPerLabel(customer?.user_type)}
                  </p>
                </div>
                <div className="profile_form_container">
                  <div className={`row ${width < 600 && "gx-0"}`}>
                    <div className={`col-lg-6 ${width > 992 && "ps-0"}`}>
                      <div className="field_wrapper padding_right">
                      <TextFieldWithIcon
                          onFocus={() => setActiveInput(prev => ([...prev, 'contact_name']))}
                          label="Your full name?*"
                          icon={editiconorange}
                          value={user?.contact_name}
                          onChange={(e) => handleChangeUser("contact_name", e.target.value)}
                        />
                        {renderError("contact_name")}
                        {activeInput.indexOf('contact_name') !== -1 && (
                          <>
                        <div className="update_wrap">
                          <div className="btn_wrapper">
                            <button className="form_btn" onClick={handleSubmit}>
                              Update Name
                              <img
                                  src={rightarrowwhite}
                                  alt="icon"
                                  className="icon"
                                  loading="lazy"
                              />
                            </button>
                            <button className="back_button"
                                    onClick={() => {
                                      setActiveInput(prev => prev.filter(item => item !== 'contact_name'));
                                      setUser(prev => ({...prev, contact_name: customer.contact_name}))
                                      setErrors(prev => ({...prev, contact_name: ''}))
                                    }}>Cancel
                            </button>
                          </div>
                      </div>
                        </>
                        )}
                      </div>
                    </div>
                    <div className={`col-lg-6 ${width > 992 && "pe-0"}`}>
                      <div className="field_wrapper padding_left">
                        <TextFieldWithIcon
                            onFocus={() => setActiveInput(prev => ([...prev, 'contact_email']))}
                          label="E-mail*"
                          icon={editiconorange}
                          value={user?.contact_email}
                          onChange={(e) => handleChangeUser("contact_email", e.target.value)}
                        />
                        {renderError("contact_email")}
                        {activeInput.indexOf('contact_email') !== -1 && !otpBox && (
                          <div style={{marginTop: '32px'}}>
                            <p className="pass_notice">
                              Please enter your new Email ID. <br />
                              An OTP verification will be sent to your new
                              email.
                            </p>
                            <div className="mobile_center">
                              <div className="btn_wrapper">
                                <button
                                  className="form_btn"
                                  onClick={handleEmailValidate}
                                >
                                  Verify with OTP
                                  <img
                                    src={rightarrowwhite}
                                    alt="icon"
                                    className="icon"
                                    loading="lazy"
                                  />
                                </button>
                                <button className="back_button" onClick={() => {
                                  setActiveInput(prev => prev.filter(item => item !== 'contact_email'));
                                  setUser(prev => ({...prev, contact_email: customer.contact_email}))
                                  setErrors(prev => ({...prev, contact_email: ''}))
                                }}>Cancel</button>
                              </div>
                            </div>
                          </div>
                        )}
                        {otpBox === true && (
                          <>
                            <div className="otp_wrapper">
                              <div className="otp_title">Enter OTP</div>
                              <div className="otp_box_wrapper">
                                <OtpInput
                                  value={otpVal?.local_otp}
                                  onChange={(e) => handleotpChange("local_otp", e)}
                                  numInputs={6}
                                  containerStyle="otp_input_wrapper"
                                  inputStyle="otp_input"
                                  isInputNum={true}
                                  focusStyle="otp_focused"
                                  shouldAutoFocus
                                />
                                 {renderError('local_otp')}
                              </div>
                             
                              <div className="mobile_center">
                                <div className="btn_wrapper">
                                  <button
                                    className="form_btn"
                                    onClick={() => verifyEmailHandle(user)}
                                  >
                                    Verify
                                    <img
                                      src={rightarrowwhite}
                                      alt="icon"
                                      className="icon"
                                      loading="lazy"
                                    />
                                  </button>
                                  {time !== 0 ?
                                        <div className="back_button">
                                            Resend otp in {time} sec
                                        </div>
                                        :
                                        <button
                                        className="back_button"
                                        onClick={() => resendHandler({contact_email: user?.contact_email, _id: customer._id})}
                                      >
                                        {resend === false
                                          ? "Resend OTP"
                                          : "Resending OTP"}
                                      </button>
                                    }
                               
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                    <div className={`col-lg-6 ${width > 992 && "ps-0"}`}>
                      <div className="field_wrapper padding_right">
                      {activeInput.indexOf('password') === -1 && <TextFieldWithIcon
                            inputProps={
                              { readOnly: true, }
                            }
                            onFocus={() => setActiveInput(prev => ([...prev, 'password']))}
                            type={'password'}
                          label="Password*"
                          icon={editiconorange}
                          value={password}
                          //onChange={(e) => handleChangeUser("password", e.target.value)}
                        /> }

                        {/* {passwordReset === false && ( */}
                          {/* <p
                            className= "error"


                          > */}
                            {/* {renderError("password")} */}
                            {/* {error === false */}
                            {activeInput.indexOf('password') === -1 &&  <p className="pass_notice">
                              your password should be at least 8 characters </p>}
                              {/* : "error message here"} */}
                          {/* </p> */}
                        {/* )} */}
                        {activeInput.indexOf('password') !== -1 && (
                          <>
                            <div className="reset_fields">
                              <PasswordInput
                              label="Enter current password*"
                              value={user?.current_password}
                              id={"enter-current-password"}
                              keyName={"current_password"}
                              handleChangeUser={handleChangeUser}/>
                          {renderError("current_password")}
                            </div>
                            <div className="reset_fields">
                              <PasswordInput
                              label="Enter new password*"
                              value={user?.new_password}
                              id={"enter-new-password"}
                              keyName={"new_password"}
                              handleChangeUser={handleChangeUser}/>

                                {renderError("new_password")}

                            </div>
                            <div className="reset_fields">
                              <PasswordInput
                              label="Confirm new password*"
                              value={user?.confirm_new_password}
                              id={"confirm-new-password"}
                              keyName={"confirm_new_password"}
                              handleChangeUser={handleChangeUser} />

                                {renderError("confirm_new_password") }


                            </div>
                            <div className="mobile_center">
                              <div className="btn_wrapper">
                                <button
                                  className="submit_button"
                                  onClick={handlePasswordReset}
                                >
                                  Submit
                                </button>
                                <button
                                  className="back_button"
                                  onClick={() => {
                                    setActiveInput(prev => prev.filter(item => item !== 'password'));
                                    setErrors(prev => ({...prev, oldPassword: '', newPassword: ''}));
                                    setUser(prev => ({
                                      ...prev,
                                          current_password: "",
                                      new_password: "",
                                      confirm_new_password: "",
                                    }))
                                  }}
                                >
                                  Cancel
                                </button>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                    <div className={`col-lg-6 ${width > 992 && "pe-0"}`}>
                      <div className="field_wrapper padding_left">
                        <div
                          className="row"
                        >
                           <div className={`col-12 col-sm-4 col-md-4 ps-0 ${width <= 575 && "pe-0"}`}>
                            <div className="field_wrapper_1">
                              <CountryCodeDropdown textLabel="Code*" handleChangeUser={handleChangeUser} />
                              {renderError("country_code")}
                            </div>
                          </div>
                          <div
                            className={`col-12 col-sm-8 col-md-8 ${
                              width > 992 ? "pe-0" : "ps-0"
                            } ${width <= 575 && "pe-0 mobile_stack"}`}
                          >
                            <div className="field_wrapper_1">
                              <TextFieldWithIcon
                                  onFocus={() => setActiveInput(prev => ([...prev, 'contact_phone']))}
                                label="Phone*"
                                type="number"
                                icon={editiconorange}
                                value={user?.contact_phone}
                                onChange={(e) => handleChangeUser("contact_phone", e.target.value)}
                              />
                              {renderError("contact_phone")}
                            </div>
                          </div>
                        </div>
                        {/*{activeInput.indexOf('contact_phone') !== -1 && phoneotpBox === false && (*/}
                        {/*  <div className="checkbox_wrapper">*/}
                        {/*    <label className="checkbox_label">*/}
                        {/*      <input type="checkbox" className="check_box" onChange={() => handleChangeUser("whatsapp_no", user?.contact_phone)} />*/}
                        {/*      My Whatsapp number is my contact number*/}
                        {/*    </label>*/}
                        {/*  </div>*/}
                        {/*)}*/}

                        {activeInput.indexOf('contact_phone') !== -1 && !phoneotpBox && (
                          <>
                            <p className="pass_notice my-4">
                              Please enter your new 10 digit mobile number.
                              <br />
                              An OTP verification will be sent to your new
                              number.
                            </p>

                            <div className="mobile_center">
                              <div className="btn_wrapper">
                                <button
                                  className="form_btn"
                                  onClick={handlePhoneValidate}
                                >
                                  Verify with OTP
                                  <img
                                    src={rightarrowwhite}
                                    alt="icon"
                                    className="icon"
                                    loading="lazy"
                                  />
                                </button>
                                <button className="back_button"  onClick={() => {
                                  setActiveInput(prev => prev.filter(item => item !== 'contact_phone'));
                                  setUser(prev => ({...prev, contact_phone: customer.contact_phone}))
                                  setErrors(prev => ({...prev, contact_phone: ''}))
                                }
                          }>Cancel</button>
                              </div>
                            </div>
                          </>
                        )}

                        {phoneotpBox === true && (
                          <>
                            <div className="otp_wrapper">
                              <div className="otp_title">Enter OTP</div>
                              <div className="otp_box_wrapper">
                                <OtpInput
                                  value={otpVal?.local_otp}
                                  onChange={(e) => handleotpChange("local_otp", e)}
                                  numInputs={6}
                                  containerStyle="otp_input_wrapper"
                                  inputStyle="otp_input"
                                  isInputNum={true}
                                  focusStyle="otp_focused"
                                  shouldAutoFocus
                                />
                                {renderError('local_otp')}
                              </div>
                              <div className="mobile_center">
                                <div className="btn_wrapper">
                                  <button
                                    className="form_btn"
                                    onClick={verifyPhoneHandle}
                                  >
                                    Verify
                                    <img
                                      src={rightarrowwhite}
                                      alt="icon"
                                      className="icon"
                                      loading="lazy"
                                    />
                                  </button>
                                  {time !== 0 ?
                                        <div className="back_button">
                                            Resend otp in {time} sec
                                        </div>
                                        :
                                        <button
                                        className="back_button"
                                        onClick={() => resendHandler({contact_phone: user?.contact_phone, country_code: user?.country_code, _id: customer._id})}
                                      >
                                        {resend === false
                                          ? "Resend OTP"
                                          : "Resending OTP"}
                                      </button>
                                    }
                               
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                    <div className={`col-lg-6 ${width > 992 && "ps-0"}`}>
                      <div className="field_wrapper padding_right">
                        <div
                            className="row"
                        >
                          <div className="col-4 col-sm-4 col-md-4 ps-0">
                            <div className="field_wrapper_1">
                              <CountryCodeDropdown textLabel="Code*" handleChangeUser={handleChangeUser} />
                              {renderError("whatsapp_country_code")}
                            </div>
                          </div>
                          <div className="col-8 col-sm-8 col-md-8  pe-0">
                            <div className="field_wrapper_1">
                              <TextFieldWithIcon
                                  onFocus={() => setActiveInput(prev => ([...prev, 'whatsapp_no']))}
                                  label="WhatsApp number*"
                                  icon={editiconorange}
                                  value={user?.whatsapp_no}
                                  onChange={(e) => handleChangeUser("whatsapp_no", e.target.value)}
                              />
                              {renderError("whatsapp_no")}
                            </div>
                          </div>
                        </div>

                        {/*{whatsappverify === false && whatsappotpBox === false && (*/}
                        {/*  <div className="checkbox_wrapper">*/}
                        {/*    <label className="checkbox_label">*/}
                        {/*      <input type="checkbox" className="check_box" onChange={() => handleChangeUser("contact_phone",user?.whatsapp_no)} />*/}
                        {/*      Same as phone number*/}
                        {/*    </label>*/}
                        {/*  </div>*/}
                        {/*)}*/}

                        {activeInput.indexOf('whatsapp_no') !== -1 && !whatsappotpBox && (
                          <>
                            <p className="pass_notice my-4">
                              Please enter your new 10 digit WhatsApp number.
                              <br />
                              An OTP verification will be sent to your new
                              WhatsApp number.
                            </p>

                            <div className="mobile_center">
                              <div className="btn_wrapper">
                                <button
                                  className="form_btn"
                                  onClick={handleWhatsappValidate}
                                >
                                  Verify with OTP
                                  <img
                                    src={rightarrowwhite}
                                    alt="icon"
                                    className="icon"
                                    loading="lazy"
                                  />
                                </button>
                                <button className="back_button"  onClick={() => {
                                  setActiveInput(prev => prev.filter(item => item !== 'whatsapp_no'));
                                  setUser(prev => ({...prev, whatsapp_no: customer.whatsapp_no}))
                                  setErrors(prev => ({...prev, whatsapp_no: ''}))
                                }}>Cancel</button>
                              </div>
                            </div>
                          </>
                        )}
                        {whatsappotpBox === true && (
                          <>
                            <div className="otp_wrapper">
                              <div className="otp_title">Enter OTP</div>
                              <div className="otp_box_wrapper">
                                <OtpInput
                                  value={otpVal?.local_otp}
                                  onChange={(e) => handleotpChange("local_otp", e)}
                                  numInputs={6}
                                  containerStyle="otp_input_wrapper"
                                  inputStyle="otp_input"
                                  isInputNum={true}
                                  focusStyle="otp_focused"
                                  shouldAutoFocus
                                />
                                {renderError('local_otp')}
                              </div>
                              <div className="mobile_center">
                                <div className="btn_wrapper">
                                  <button
                                    className="form_btn"
                                    onClick={verifyWhatsappHandle}
                                  >
                                    Verify
                                    <img
                                      src={rightarrowwhite}
                                      alt="icon"
                                      className="icon"
                                      loading="lazy"
                                    />
                                  </button>
                                  {time !== 0 ?
                                        <div className="back_button">
                                            Resend otp in {time} sec
                                        </div>
                                        :
                                        <button
                                    className="back_button"
                                    onClick={() => resendHandler({whatsapp_no: user?.whatsapp_no, country_code: user?.whatsapp_country_code, _id: customer?._id})}
                                  >
                                    {resend === false
                                      ? "Resend OTP"
                                      : "Resending OTP"}
                                  </button>
                                    }
                               
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </section>
        <FooterV2 />
      </main>
    </>
  );
};
export default EditProfile;
