import React, { useEffect, useState } from "react";
import style from "../Form/formfive.module.scss";
import LogoutText from "../../../components/LogoutText/LogoutText";
import { formupload, rightarrowblack, rightarrowwhite } from "../../../images";
import callApi from "../../../util/apiCaller";
import axios from "axios";
//import { callUploadApi } from "../../../util/apiCaller";
import { updateCustomerArchPro } from "../../../redux/app/appAction";
import { useDispatch, useSelector } from "react-redux";
import { getBrandId, getBrand } from "../../../redux/app/appSelectors";
import { PDF_IMAGE_FILE_COMPANY_PROFILE_SIZE } from "../../../constants/enums";
import _ from "lodash";
import {ErrorsMsg, SuccessMsg} from "../../../util/Message";
import {toast} from "react-toastify";
import { setBrand, setBrandId,updateCustomerArchProV2,setCustomerData} from "../../../redux/app/appAction";

const API_URL = process.env.REACT_APP_API_URL || `https://apidev.archinza.com`;

const FIStep04 = ({
  nextStep,
  previousStep,
  currentStep,
  totalSteps,
  progressStatus,
  handleUserExtraData, userExtraData
}) => {
  const dispatch = useDispatch();
  const brandId = useSelector((state) => getBrandId(state)) ;
  const brandData = useSelector((state) => getBrand(state))
  const [errors, setErrors] = useState({});
  const [profileData, setProfileData] = useState({})
  const [profileImgName, setProfileImgName] = useState("")
  const [upload, setUpload] = useState(false);
  const [file, setFile] = useState(null);
  const [error] = useState(false);

  useEffect(() => {
    if(brandData?.company_profile_name )
    setProfileImgName(brandData?.company_profile_name)
  },[brandData])

  const uploadCompanyProfile = async(file) => {
    //console.log("file", file);
    let data = new FormData();
    data.append('file', file);

    let config = {
        method: 'post',
        url: `${API_URL}/media/upload`,
        data : data
    };

    let result = await axios.request(config);
    //console.log(result);
    if (result?.data?.data?.link) {
       if(brandId){
       
        setProfileData({company_profile: result?.data?.data?.link,
          company_profile_name: file?.name,
          brand_current_flow : currentStep})
        updateCustomerArchProV2(brandId,
          {company_profile: result?.data?.data?.link,
            company_profile_name: file?.name,
          brand_current_flow : currentStep}).then(res => {
          if(res?.status === 'Success' && res?.data?.brand) {
            dispatch(setCustomerData(res?.data?.brand));
            nextStep();
            window.scrollTo(0, 0);
            setFile(null)
          } else {
            toast(ErrorsMsg(res?.data?.message),{
              className:"mail_toast",
              position: toast.POSITION.TOP_CENTER,
              hideProgressBar: true,
              closeButton: false,
            })
          }
        });
        setErrors({})

       }
    }
  }

  const handleValidation = (file) => {
    // if(file){
    //   setShowError(false);
    // }
    let isValid = true;
    let errors = {};
    if (!file) {
        isValid = false;
        errors["file"] = "please select company Profile";
    }

      return {
        isValid,
        errors
    }
  }
  const handleApi = (file) => {
    //let check = handleValidation(file);
    if(file?.size/(1024*1024) > PDF_IMAGE_FILE_COMPANY_PROFILE_SIZE){
      toast(ErrorsMsg(`File size should be < ${PDF_IMAGE_FILE_COMPANY_PROFILE_SIZE} mb`),{
        className: "mail_toast",
        position: toast.POSITION.TOP_CENTER,
        hideProgressBar: true,
        closeButton: false,
      });
    }else{
      if( file === null){
        nextStep();
        window.scrollTo(0, 0);
      }else{
          if( file){
            uploadCompanyProfile(file)
      
          }else {
            // setErrors(check?.errors);
          }
      }
    }
  }
  const renderError = (keyName) => {
    return errors && errors[keyName] ? <p className="error" style={{marginBottom: "30px"}}>{errors[keyName]}</p> : null;
  };



  return (
    <>
      <div className={style.text_container}>
      <p className={`${style.head_text1}`}> Business Connect Data</p>
        <h1 className={style.title}>Upload Company Profile</h1>
        <p className={style.description}></p>
      </div>
      <div className={`${style.steps} ${style.fastep12}`}>
        <div className={style.fileupload_wrapper}>
          <div className={style.fileupload}>
          <input
            className={style.input_box}
            type="file"
            id="uploadFile7i18"
            hidden
            name="company_logo"
            onChange={(e) => {
              const allowedTypes = ["application/pdf", "image/png", "image/jpeg", "image/jpg"];
              const file = e.target.files[0];

              if (file) {
                const fileType = file.type;
                const fileExtension = file.name.split(".").pop().toLowerCase();

                if (allowedTypes.includes(fileType) || (fileExtension === "pdf" || fileExtension === "png" || fileExtension === "jpg" || fileExtension === "jpeg")) {
                  setFile(file);
                  setErrors({});
                  setProfileImgName("")
                } else {
                  toast(ErrorsMsg("Invalid file type. Please select a PDF, PNG, JPG, or JPEG file."),{
                    className: "mail_toast",
                    position: toast.POSITION.TOP_CENTER,
                    hideProgressBar: true,
                    closeButton: false,
                  });
                }
              }
            }}
            />
            <label
              htmlFor="uploadFile7i18"
              className={style.upload_label}
              onClick={() => setUpload(true)}
            >
              <div className={style.img_wrapper}>
                <img
                  width={57}
                  height={40}
                  src={formupload}
                  alt="upload"
                  className={style.upload_icon}
                  loading="lazy"
                />
              </div>
              <div className={style.cta_wrapper}>
                <div className={style.next_button}>
                  <div className={style.text}>Upload file</div>
                  <img
                    src={rightarrowwhite}
                    alt="icon"
                    className={style.icon}
                    loading="lazy"
                  />
                </div>
              </div>
              <div
                className={style.filename}
                style={{ display:"block" }}
                >
                  {file?.name}
                  {profileImgName}
              </div>
              <div className={style.notice}>
                Please select single file
                Maximum file size is {PDF_IMAGE_FILE_COMPANY_PROFILE_SIZE}MB. PDF, JPEG, PNG allowed
              </div>
            </label>
          </div>
        </div>
      </div>

      <div className={style.next_logout}>
  
        {renderError("file")}
        <div className={style.cta_wrapper}>
          <div
            className={style.next_button}
            onClick={() => handleApi(file)}
          >
            <div className={style.text}>Next</div>
            <img
              src={rightarrowblack}
              alt="icon"
              className={style.icon}
              loading="lazy"
            />
          </div>
          <div
            className={style.back_button}
            onClick={() => {
              setFile(null)
              previousStep(8);
              window.scrollTo(0, 0);
            }}
          >
            Back
          </div>
        </div>
        <LogoutText  userData={profileData} />
      </div>
    </>
  );
};

export default FIStep04;
