import React, { useEffect, useState } from "react";
import style from "../Form/formfive.module.scss";
import LogoutText from "../../../components/LogoutText/LogoutText";
import RadioButton from "../../../components/RadioButton/RadioButton";
import { rightarrowblack, rightarrowwhite } from "../../../images";
import callApi from "../../../util/apiCaller";
import { updateCustomerArchPro } from "../../../redux/app/appAction";
import { useDispatch, useSelector } from "react-redux";
import { getBrandId, getBrand } from "../../../redux/app/appSelectors";
import {ErrorsMsg, SuccessMsg} from "../../../util/Message";
import {toast} from "react-toastify";
import { setBrand, setBrandId,updateCustomerArchProV2,setCustomerData} from "../../../redux/app/appAction";
import RadioButtonLightTheme from "../../../components/RadioButtonLightTheme/RadioButtonLightTheme";

//const formALocationArr = ["Anywhere", "In my country", "In my city"];

const FAStep06 = ({ nextStep, previousStep, currentStep, totalSteps, progressStatus,handleUserExtraData, userExtraData }) => {
  const dispatch = useDispatch();
  const brandId = useSelector((state) => getBrandId(state)) ;
  const brandData = useSelector((state) => getBrand(state))
  const [errors, setErrors] = useState({});
  //const [showError, setShowError] = useState(true)
  const [code, setCode] = useState([])
  const [projectLocation, setProjectLocation ] = useState()

  useEffect(() => {
    setProjectLocation({"location_preference": brandData?.location_preference})
  },[brandData])

  const handleChangeUser = ( keyName,keyValue) => {
    if(keyValue){
      setErrors({})
    }
    let update ={...projectLocation};
    update["location_preference"] = keyValue;
    update.brand_current_flow = currentStep;
    setProjectLocation(update);

  }
  const handleValidation = (user) => {
    let isValid = true;
    let errors = {};
    if (!user?.location_preference) {
        isValid = false;
        errors["location_preference"] = "Please select location preference";
    }

      return {
        isValid,
        errors
    }
  }
  const handleUpdateApi = (brandId,projectLocation) => {
    let check = handleValidation(projectLocation);
   
    if(check.isValid && brandId && projectLocation){
      updateCustomerArchProV2(brandId, projectLocation).then(res => {
        if(res?.status === 'Success' && res?.data?.brand) {
          dispatch(setCustomerData(res?.data?.brand));
          nextStep();
          window.scrollTo(0, 0);
        } else {
          toast(ErrorsMsg(res?.data?.message),{
            className:"mail_toast",
            position: toast.POSITION.TOP_CENTER,
            hideProgressBar: true,
            closeButton: false,
          })
        }
      });
      setErrors({})
    }else {
      setErrors(check?.errors);
      }
  }
  const renderError = (keyName) => {
    return errors && errors[keyName] ? <p className="error">{errors[keyName]}</p> : null;
  };

  useEffect(() => {

    fetchContentCode("project-location-preference")
   },[])

  async function fetchContentCode(code) {

    try {
      const res = await callApi(`content/code/${code}`, "get");
      if (res && res?.status === 'Success') {
        const { params } = res?.data?.content;
        // let param = params?.map((option) => option.label)
        setCode(params);
      } else {
      }
    } catch (err) {

    }
  }

  const formALocationList = code?.map((option,i) => (
    <React.Fragment key={option.value}>
      <RadioButtonLightTheme label={option.label} labelId={`FAd4${option.label+i}`} value={option.value} checked={option?.value === projectLocation?.location_preference} handleChangeUser={handleChangeUser}/>
    </React.Fragment>
  ));

  return (
    <>
      <div className={style.text_container}>
      <p className={`${style.head_text1}`}>
      Project Where Is Your Product | Material Service The Best Fit</p>
        <h1 className={style.title}>Project Location Preference</h1>
        <p className={style.description}></p>
      </div>
      <div className={`${style.steps} ${style.step08}`}>
        <ul className={style.steps_ul}>{formALocationList}</ul>
        <div style={{display:"flex",justifyContent:"center",alignItems:"center"}}>{renderError("location_preference")}</div>
      </div>

      <div className={style.next_logout}>
      
        <div className={style.cta_wrapper}>
          <div
            className={style.next_button}
            onClick={() => handleUpdateApi(brandId,projectLocation)}
          >
            <div className={style.text}>Next</div>
            <img
              src={rightarrowblack}
              alt="icon"
              className={style.icon}
              loading="lazy"
            />
          </div>
          <div
            className={style.back_button}
            onClick={() => {
              previousStep(7);
              window.scrollTo(0, 0);
            }}
          >
            Back
          </div>
        </div>
        <LogoutText  userData={projectLocation} />
      </div>
    </>
  );
};

export default FAStep06;
