import TextField from "@mui/material/TextField";
import style from "./fulltextfieldlighttheme.module.scss";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { useWindowSize } from "react-use";

const darkTheme = createTheme({
  typography: {
    fontFamily: "Poppins",
  },
  palette: {
    mode: "dark",
  },
});

const FullWidthTextFieldLightTheme = ({ key, ...rest }) => {
  const { width } = useWindowSize();
  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <TextField
        key={key}
        outline={false}
        className={style.fullwidth_input}
        fullWidth
        inputProps={{
          style: {
            fontSize: width > 768 ? "1.25em" : "1.25em",
            color: "#707070",
            WebkitBoxShadow: "0 0 0 1000px white inset",
            webkitTextFillColor: "#111",
            borderRadius: width > 768 ? "10px" : "10px",
          },
        }}
        sx={{
          "& fieldset": {
            borderRadius: width > 768 ? "10px" : "10px",
            border: "1px solid #707070",
          },

          // input label when focused
          "& label": {
            lineHeight: width > 768 ? "2em" : "1.5em",
            color: "#707070",
          },
          "& label.Mui-focused": {
            color: "#707070",
            borderColor: "#707070",
          },
          // focused color for input with variant='standard'
          "& .MuiInput-underline:after": {
            borderBottomColor: "#707070",
          },
          // focused color for input with variant='filled'
          "& .MuiFilledInput-underline:after": {
            borderBottomColor: "#707070",
          },
          // focused color for input with variant='outlined'
          "& .MuiOutlinedInput-root": {
            "&.Mui-focused fieldset": {
              border: "1px solid #707070",
              borderColor: "#707070",
            },
            "&:hover fieldset": {
              border: "1px solid #707070",
            },
          },
        }}
        {...rest}
      />
    </ThemeProvider>
  );
};

export default FullWidthTextFieldLightTheme;
