import React, {useEffect} from "react";
import {useParams} from "react-router-dom";
import {useDispatch} from "react-redux";
import {fetchMagicCode} from "../redux/app/appAction";
import { useNavigate } from "react-router-dom";
const HeaderV2 = (props) => {

    const dispatch = useDispatch();
    let navigate = useNavigate()

    let params = useParams();

    useEffect(() => {
        let magic_token = params.code;
        if (magic_token) {
            dispatch(fetchMagicCode(magic_token,navigate));
        }
    }, [params]);

    return (
        <>
        </>
    );
};

export default HeaderV2;


