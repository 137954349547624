import React from "react";
import "./contactus.scss";
import BreadCrumb from "../../components/Breadcrumb/Breadcrumb";
import GlowCta from "../../components/GlowCta/GlowCta";
import { addLocation, contactBanner,rightarrowwhite } from "../../images";
import HeaderV2 from "../../components/Header/HeaderV2";
import { consoleUrl, registrationFormURL } from "../../components/helpers/constant-words";
import {getCustomer, getISPro} from "../../redux/app/appSelectors";
import { useSelector } from "react-redux";
import FooterV2 from "../../components/FooterV2/FooterV2";
import { Link } from "react-router-dom";

const ContactUs = () => {
  // const [headerHeight, setHeaderHeight] = useState(0);
  // const getHeightValue = (data) => {
  //   setHeaderHeight(data);
  // };
  const customer = useSelector((state) => getCustomer(state));
  const getLoggedInState = (customer) => {
    return !!(customer && customer._id);
  }
  return (
    <>
      {/* <HeaderV2 
    //  getHeight={getHeightValue} 
     /> */}
      <main className="contact_main">
        <img
          src={contactBanner}
          alt="background"
          className="contact_background"
        />
        <section className="contact_sec1">
          <div className="my_container">
            <div className="Breadcrumb_container">
              <BreadCrumb
                link="/contact-us"
                text="Contact Us"
                title="Contact us"
                linkDisabled
                socialIcon
                facebookLink="https://www.facebook.com/people/Archinza/100091559990889/?mibextid=LQQJ4d"
                instaLink="https://www.instagram.com/accounts/suspended/?next=https%3A%2F%2Finstagram.com%2Farchin.za%3Figshid%3DMzRlODBiNWFlZA%26__coig_ufac%3D1"
                linkedInLink="https://www.linkedin.com/uas/login?session_redirect=https%3A%2F%2Fwww.linkedin.com%2Fcompany%2F92807055%2Fadmin%2Ffeed%2Fposts%2F"
              />
            </div>
            <div className="cta_wrapper">
         { getLoggedInState(customer) === false ?      
              <Link className="common_cta" onClick={() => {window.scrollTo(0,0)}} to={consoleUrl}>
                <div className="text">Get Early Access</div>
                <img
                  src={rightarrowwhite}
                  alt="icon"
                  className="icon"
                  loading="lazy"
                />
              </Link>
         : 
         
         <Link className="common_cta" onClick={() => {window.scrollTo(0,0)}} to={consoleUrl}>
         <div className="text">Get Early Access</div>
         <img
           src={rightarrowwhite}
           alt="icon"
           className="icon"
           loading="lazy"
         />
       </Link>
         }
            </div>
          </div>
        </section>

        <section className="contact_sec2">
          <div className="my_container">
            <div className="contact_row">
              <a href="mailto:hello@archinza.com" className="contact_col">
                <div className="contact_details_wrapper">
                  <h3 className="heading">Have a question?</h3>
                  <div className="desc">hello@archinza.com</div>
                </div>
              </a>
              <a href="mailto:reach@archinza.com" className="contact_col">
                <div className="contact_details_wrapper">
                  <h3 className="heading">Want to market?</h3>
                  <div className="desc">reach@archinza.com</div>
                </div>
              </a>
              <a href="mailto:careers@archinza.com" className="contact_col">
                <div className="contact_details_wrapper">
                  <h3 className="heading">For careers</h3>
                  <div className="desc">careers@archinza.com</div>
                </div>
              </a>
            </div>
          </div>
        </section>
      </main>
      <FooterV2 bgColor="#000000" />
    </>
  );
};

export default ContactUs;
