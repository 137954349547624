import React from "react";
import "./faqs.scss";
import { faqsBanner } from "../../images";
import BreadCrumb from "../../components/Breadcrumb/Breadcrumb";
import GlowCta from "../../components/GlowCta/GlowCta";
import Accordion from "react-bootstrap/Accordion";
import { FaqsData } from "../../components/Data/faqsData";
import { homeFaqData } from "../../components/Data/homeData";
import { FaqAccordion } from "../../components/FaqAccordion/FaqAccordion";
import { consoleUrl, registrationFormURL } from "../../components/helpers/constant-words";
import {getCustomer, getISPro} from "../../redux/app/appSelectors";
import { useSelector } from "react-redux";
import FooterV2 from "../../components/FooterV2/FooterV2";


const Faqs = () => {
  // const faqsList = FaqsData.map((item, i) => (
  //   <Accordion.Item eventKey={i + ""} key={i}>
  //     <Accordion.Header>{item.title}</Accordion.Header>
  //     <Accordion.Body>
  //       <div className="accord_desc">{item.desc}</div>
  //     </Accordion.Body>
  //   </Accordion.Item>
  // ));
  const customer = useSelector((state) => getCustomer(state));
  const getLoggedInState = (customer) => {
    return !!(customer && customer._id);
  }
  return (
    <>
      <main className="faq_main">
        <img src={faqsBanner} alt="background" className="faq_background" />

        <section className="faq_sec1">
          <div className="my_container">
            <div className="Breadcrumb_container">
              <BreadCrumb
                link="/faqs"
                linkDisabled
                text="FAQs"
                title="Frequently asked questions"
              />
            </div>
            <div className="cta_container">
            { getLoggedInState(customer) === false ?   <GlowCta onClick={() => {window.scrollTo(0,0)}}
         link={registrationFormURL} text="Get Early Access" /> 
         : 
         <GlowCta onClick={() => {window.scrollTo(0,0)}} link={consoleUrl} text="Get Early Access" />
         }
            </div>
          </div>
        </section>

        <section className="faq_sec2">
          <div className="my_container">
            <div className="accordion_wrapper">
              {/* <Accordion defaultActiveKey="0">
                {faqsList}
              </Accordion> */}

              <FaqAccordion
          firstActive={true}
          // borderLeft={false}
          items={homeFaqData}
        />
            </div>
          </div>
        </section>
      </main>
      <FooterV2 bgColor="#000000" />
    </>
  );
};

export default Faqs;

