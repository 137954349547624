import React, { useEffect, useState } from "react";
import style from "../Form/formfive.module.scss";
import LogoutText from "../../../components/LogoutText/LogoutText";
import {
  formBehance,
  formLinkedin,
  formfb,
  forminsta,
  rightarrowblack,
  rightarrowwhite,
  websiteicon,
} from "../../../images";
import FullWidthTextField from "../../../components/TextField/FullWidthTextField";
import TextFieldWithIcon from "../../../components/TextFieldWithIcon/TextFieldWithIcon";
//import callApi from "../../../util/apiCaller";
import { updateCustomerArchPro } from "../../../redux/app/appAction";
import { useDispatch, useSelector } from "react-redux";
import { getBrandId, getBrand } from "../../../redux/app/appSelectors";
import { isValidUrl } from "../../../util/linkValidator";
import {ErrorsMsg, SuccessMsg} from "../../../util/Message";
import {toast} from "react-toastify";
import { setBrand, setBrandId,updateCustomerArchProV2,setCustomerData} from "../../../redux/app/appAction";

const FAStep12 = ({
  nextStep,
  previousStep,
  currentStep,
  totalSteps,
  progressStatus,
  handleUserExtraData, userExtraData
}) => {
  const dispatch = useDispatch();
  const brandId = useSelector((state) => getBrandId(state)) ;
  const brandData = useSelector((state) => getBrand(state))
  const [errors, setErrors] = useState({});
  const [socialNetwork, setSocialNetwork ] = useState({
    "linkedin_link": "",
    "instagram_link": "",
    "facebook_link": "",
    "website_link": "",
    // "behance_link": "",
    // "other_link": "",
    "gstin_number": ""
  })

  useEffect(() => {
    setSocialNetwork({
      "linkedin_link": brandData?.linkedin_link ,
      "instagram_link": brandData?.instagram_link,
      "facebook_link": brandData?.facebook_link,
      "website_link": brandData?.website_link,
      // "behance_link": "",
      // "other_link": "",
      "gstin_number": brandData?.gstin_number
    })
  },[brandData])

  const handleUserDetail = ( keyName,keyValue) => {


    setErrors(prev => ({...prev, [keyName]: ""}))
    let update ={...socialNetwork};
    update[keyName] = keyValue;
    update.brand_current_flow = currentStep;
    setSocialNetwork(update);

  }
  const handleValidation = (user) => {
    let isValid = true;
    let errors = {};

    if (!user?.instagram_link) {
        isValid = false;
        errors["instagram_link"] = "Instagram link cannot be blank";
    }else {

      if(!isValidUrl(user?.instagram_link)){
        isValid = false;
        errors["instagram_link"] = "Invalid url format";
      }
    }
    if (!user?.linkedin_link) {
      isValid = false;
      errors["linkedin_link"] = "Linkedin link cannot be blank";
   }else {

    if(!isValidUrl(user?.linkedin_link)){
      isValid = false;
      errors["linkedin_link"] = "Invalid url format";
    }
  }
    if (!user?.website_link) {
      isValid = false;
      errors["website_link"] = "Website link cannot be blank";
    }else {

      if(!isValidUrl(user?.website_link)){
        isValid = false;
        errors["website_link"] = "Invalid url format";
      }
    }
    // if (!user?.behance_link) {
    //   isValid = false;
    //   errors["behance_link"] = "behance link cannot be blank";
    // }
    // if (!user?.other_link) {
    //   isValid = false;
    //   errors["other_link"] = "other link cannot be blank";
    // }
    if (!user?.facebook_link) {
      isValid = false;
      errors["facebook_link"] = "Facebook link cannot be blank";
    }else {

      if(!isValidUrl(user?.facebook_link)){
        isValid = false;
        errors["facebook_link"] = "Invalid url format";
      }
    }
    if (!user?.gstin_number) {
      isValid = false;
      errors["gstin_number"] = "GST number cannot be blank";
    }else{
      //let reg = new RegExp('^(?=.*[A-Za-z])(?=.*\d).+$')
      let gstReg = /^\d{2}[A-Z]{5}\d{4}[A-Z]{1}\d[Z]{1}[A-Z\d]{1}$/

      if(!gstReg.test(user?.gstin_number)){
        isValid = false;
        errors["gstin_number"]= "GST number should have correct format & of 15 character ";
      }
    }

      return {
        isValid,
        errors
    }
  }
  const handleUpdateApi = (brandId, socialNetwork) => {
    let check = handleValidation(socialNetwork);
   
    if(check.isValid && brandId){
      updateCustomerArchProV2(brandId, socialNetwork).then(res => {
        if(res?.status === 'Success' && res?.data?.brand) {
          dispatch(setCustomerData(res?.data?.brand));
          nextStep();
          window.scrollTo(0, 0);
        } else {
          toast(ErrorsMsg(res?.data?.message),{
            className:"mail_toast",
            position: toast.POSITION.TOP_CENTER,
            hideProgressBar: true,
            closeButton: false,
          })
        }
      });
      setErrors({})
    }else {
      setErrors(check?.errors);
      }
  }
  const renderError = (keyName) => {
    return errors && errors[keyName] ? <p className="error" style={{margin: "10px 0", }}>{errors[keyName]}</p> : null;
  };

  return (
    <>
      <div className={style.text_container}>
      <p className={`${style.head_text1}`}>Firm Connect Data</p>
        <h1 className={style.title}>
          Be found by clients,{" "}
          <span className={style.coloured_text}>led by AI!</span>
        </h1>
        <p className={style.description}>Fill One</p>
      </div>
      <div className={`${style.steps} ${style.reduceSpace}`}>
        <div className={`row ${style.owner_row}`}>
          <div className="col-md-6">
            <div className={style.field_wrapper}>
              <TextFieldWithIcon label="Website" icon={websiteicon} value={socialNetwork?.website_link} onChange={(e) => handleUserDetail("website_link" ,e.target.value)} />
              {renderError("website_link")}
            </div>
          </div>
          <div className="col-md-6">
            <div className={style.field_wrapper}>
              <TextFieldWithIcon label="Instagram" icon={forminsta} value={socialNetwork?.instagram_link} onChange={(e) => handleUserDetail("instagram_link" ,e.target.value)}/>
              {renderError("instagram_link")}
            </div>
          </div>
          <div className="col-md-6">
            <div className={style.field_wrapper}>
              <TextFieldWithIcon label="Facebook" icon={formfb} value={socialNetwork?.facebook_link} onChange={(e) => handleUserDetail( "facebook_link",e.target.value)} />
              {renderError("facebook_link")}
            </div>
          </div>
          <div className="col-md-6">
            <div className={style.field_wrapper}>
              <TextFieldWithIcon label="Linkedin" icon={formLinkedin} value={socialNetwork?.linkedin_link} onChange={(e) => handleUserDetail("linkedin_link" ,e.target.value)}  />
              {renderError("linkedin_link")}
            </div>
          </div>
          {/* <div className="col-md-6">
            <div className={style.field_wrapper}>
              <TextFieldWithIcon label="Behance" icon={formBehance} value={socialNetwork?.behance_link} onChange={(e) => handleUserDetail("behance_link" ,e.target.value)}/>
              {renderError("behance_link")}
            </div>
          </div>
          <div className="col-md-6">
            <div className={style.field_wrapper}>
              <FullWidthTextField label="Other" value={socialNetwork?.other_link} onChange={(e) => handleUserDetail("other_link" ,e.target.value)} />
              {renderError("other_link")}
            </div>
          </div> */}
          <div className="col-md-6">
            <div className={style.field_wrapper}>
            <FullWidthTextField label="GST Number" value={socialNetwork?.gstin_number} onChange={(e) => handleUserDetail("gstin_number" ,e.target.value)}/>
            {renderError("gstin_number")}
            </div>
          </div>
        </div>
      </div>

      <div className={style.next_logout}>
        <div className={style.cta_wrapper}>
          <div
            className={style.next_button}
            onClick={() => handleUpdateApi(brandId,socialNetwork)}
          >
            <div className={style.text}>Next</div>
            <img
              src={rightarrowblack}
              alt="icon"
              className={style.icon}
              loading="lazy"
            />
          </div>
          <div
            className={style.back_button}
            onClick={() => {
              previousStep(8);
              setErrors({})
              window.scrollTo(0, 0);
            }}
          >
            Back
          </div>
        </div>
        <LogoutText  userData={socialNetwork}/>
      </div>
    </>
  );
};

export default FAStep12;
