import React, { useEffect, useState } from "react";
import "./profilecard.scss";
import {
  consoleCall,
  consoleEdit,
  consoleEmail,
  consoleLocation,
  contactBg,
  editicon,
} from "../../images";
import { Link } from "react-router-dom";
import { changeRoleURL, editProfile } from "../helpers/constant-words";
import { getDisplayNameAsPerLabel } from "../../constants/appEnums";

const ProfileCard = ({
  name,
  ctaTextColor,
  code,
  title,
  contactNo,
  emailId,
  address,
  city,
  country
}) => {
  const [colorr, setColorr] = useState("")
  useEffect(() => {
  if("student" === title){
    setColorr("#ff4a68") 
  
  }else if("business" === title){
    setColorr("#ed008c") 
    
  }else if ("team-member" === title){
    setColorr("#12cc50") 
    
  }else if ("design-enthusiast" === title){
    setColorr("#014fe0")
  }
},[title])
  return (
    <div className="profile_card">
      <img
        width={1063}
        height={288}
        src={contactBg}
        alt="background"
        className="profile-bg"
      />
      <div className="row profile-row">
        <div className="col-sm-6 col-md-6 detail_col" >
          <p className="greeting">Hey there,</p>
          <h1 className="name">{name}</h1>
          <button className="console_btn" style={{ color: colorr }}>
          {getDisplayNameAsPerLabel(title)}
            {/* <Link to={changeRoleURL} className="edit_link">
              <img src={editicon} alt="Edit" className="edit_img" />
            </Link> */}
          </button>
          <Link to={changeRoleURL} className="change_link">
            Change your Role
          </Link>
        </div>
        <div className="col-sm-6 col-md-6 detail_col">
          <div className="detail_wrapper">
            <div className="details">
              <img src={consoleCall} alt="" className="contact_img" />
              <div className="contact">
                {code}{" - "}{contactNo}
              </div>
            </div>
            <div className="details">
              <img src={consoleEmail} alt="" className="contact_img" />
              <div className="contact">
                {emailId}
              </div>
            </div>
            <div className="details">
              <img src={consoleLocation} alt="" className="contact_img" />

              <p className="contact">{city}{" "}{country}</p>

              <p className="contact">{address}</p>

            </div>
            <Link to={editProfile} className="edit_wrapper">
              <img src={consoleEdit} alt="Edit" className="edit_img" />
              <p className="edit">Edit</p>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileCard;

