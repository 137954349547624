import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation, useSearchParams } from "react-router-dom";
import "./headerv2.scss";
import "./headerv3.scss";
import { useWindowSize } from "react-use";
import {
    blacklogo,
    dropdownarrow,
    fbHeaderB,
    facebook,
    fbHeader,
    insta,
    instaHeader,
    instaHeaderB,
    linkedIn,
    linkedinHeader,
    linkedinHeaderB,
    loginicon,
    prologoblack,
    rightarrowwhite,
    rightarrowblack,
    whitelogo,
    prologo
} from "../../images";
import {
    consoleUrl,
    contactUsURL,
    BlogsListingURL,
    editProfile,
    facebookURL,
    homepageURL,
    instagramURL,
    linkedinURL,
    loginURL,
    businessFormFiveURL,
    registrationFormURL,
    personalAccountRegistrationUrl
} from "../helpers/constant-words";
import HeaderNavItemV2 from "./HeaderNavItemV2";
import GlowCta from "../GlowCta/GlowCta";
import { useDispatch, useSelector } from "react-redux";
import { getBrandText, getCreateAccount, getCustomer, getISPro, getPersonalText } from "../../redux/app/appSelectors";
import { getUserProfileAccessRoute, showIfUserProfileIsComplete } from "../../constants/appEnums";
import { fetchMagicCode, logoutCustomer, setCreateAccount } from "../../redux/app/appAction";
import MobileHeader from "./MobileHeader";
import { setPersonalText, setBrandText } from "../../redux/app/appAction";
import { createAction } from "@reduxjs/toolkit";

const body = document.querySelector("body");



const usersArr = [
    {
        categoryId: 0,
        category: "student",
        background: "#FF4A68",
        users: [
            {
                name: "Habib",
                age: "",
            },
        ],
    },
    {
        categoryId: 1,
        category: "team-member",
        background: "#12CC50",
        users: [
            {
                name: "Talha",
                age: "",
            },
        ],
    },
    {
        categoryId: 2,
        category: "design-enthusiast",
        background: "#014FE0",
        users: [
            {
                name: "Ayushi",
                age: "",
            },
        ],
    },
    {
        categoryId: 3,
        category: "business-firm-owner",
        background: "#CC9921",
        users: [
            {
                name: "Elon",
                age: "",
            },
        ],
    },
];

const mobNavLinksArr = [
    {
        id: 1,
        type: "text",
        mainTitle: "KNOW",
        mainLink: homepageURL,
    },
    {
        id: 2,
        type: "text",
        mainTitle: "CONNECT",
        mainLink: contactUsURL,
        // typeDropdown: "dropdown",
    },
    {
        id: 3,
        type: "text",
        mainTitle: "LOGIN",
        mainLink: homepageURL,
        typeLogin: "Login",
    },
];

const HeaderV2 = (props) => {

    const dispatch = useDispatch();
    const ispro = useSelector((state) => getISPro(state))
    let [searchParams, setSearchParams] = useSearchParams();
    const location = useLocation();

    useEffect(() => {
        let magic_token = searchParams.get("mgk");
        if (magic_token) {
            dispatch(fetchMagicCode(magic_token));
        }
    }, [searchParams]);
    const [loading, setLoading] = useState(true);
    const customer = useSelector((state) => getCustomer(state));
    const createAccount = useSelector((state) => getCreateAccount(state));
    const [navlinkIndex, setNavlinkIndex] = useState(null);
    const { width } = useWindowSize();
    const { pathname: locationPathname } = useLocation();
    const [navActiveIndex, setNavActiveIndex] = useState(null);
    const [isMenuActive, setIsMenuActive] = useState(false);
    const [isBg, setIsbg] = useState(false);
    const [userState, setUserState] = useState(0);
    const [loggedIn, setLoggedIn] = useState(false);
    const [userCategory, setUserCategory] = useState(1);
    const [dropdownActive, setDropdownActive] = useState(false);
    const [isConsole, setIsConsole] = useState(false);
    const navLinksArr = [
        {
            id: 1,
            type: "img",
            img: whitelogo,
            mainLink: "/",
            class: "logo_img",
        },
        {
            id: 2,
            type: "text",
            mainTitle: "CONSOLE",
            mainLink: homepageURL,
            linkVariant: "console",
            class: "hide_console",
        },
        {
            id: 2,
            type: "text",
            mainTitle: "KNOW",
            mainLink: homepageURL,
        },
        {
            id: 3,
            type: "text",
            mainTitle: "CONNECT",
            mainLink: contactUsURL,
        },
        {
            id: 4,
            type: "text",
            mainTitle: "BLOG",
            mainLink: BlogsListingURL,
        },
        {
            id: 5,
            type: "social",
            links: [
                { img: createAccount === false ? fbHeaderB : fbHeader, url: facebookURL },
            { img: createAccount === false ?  instaHeaderB : instaHeader, url: instagramURL },
            {
              img: createAccount === false ?  linkedinHeaderB : linkedinHeader,
              url: linkedinURL,
            },
            ],
        },
        // {
        //   id: 5,
        //   type: "search",
        //   img: headerSearch,
        //   // mainLink: "/",
        //   class: "search_icon",
        // },
        {
            id: 6,
            type: "form",
            userStates: [
                {
                    title: "Get Early Access",
                    slug: registrationFormURL,
                },
                {
                    title: "Create Business Account",
                    slug: businessFormFiveURL,
                },
                //   {
                //     title: "Continue Pro Access",
                //     slug: homepageURL,
                //   },
                //   {
                //     title: "Continue the Quiz",
                //     slug: homepageURL,
                //   },
            ],
        },
    ];
    // const [height, setHeight] = useState(0);
    const ref = useRef(null);

    // useEffect(() => {
    //   setHeight(ref.current.clientHeight);
    //   props.getHeight(height);
    // });
    const [colorr, setColorr] = useState("")
    useEffect(() => {
        if ("student" === customer?.user_type) {
            setColorr("#ff4a68")

        } else if ("business" === customer?.user_type) {
            setColorr("#ed008c")

        } else if ("team-member" === customer?.user_type) {
            setColorr("#12CC50")

        } else if ("design-enthusiast" === customer?.user_type) {
            setColorr("#014fe0")
        }
    }, [customer])

    function userStateHandler(e) {
        if (userState !== navLinksArr[3].links.length - 1) {
            setUserState(userState + 1);
        } else {
            setUserState(0);
        }
    }
    const brandText = useSelector((state) => getBrandText(state))
    const personalText = useSelector((state) => getPersonalText(state))

    function userStatusHandler(e) {
        if (userCategory !== usersArr.length - 1) {
            setUserCategory(userCategory + 1);
        } else {
            setUserCategory(0);
        }
    }

    // useEffect(() => {
    //     body.style.overflow = "auto";
    //     if (locationPathname === "/") {
    //         setIsbg(false);
    //     } else {
    //         setIsbg(true);
    //     }
    //     setIsMenuActive(false);
    // }, [locationPathname]);

    // useEffect(() => {
    //     window.addEventListener("scroll", handleScroll);

    //     return () => {
    //         window.removeEventListener("scroll", () => handleScroll);
    //     };
    // });

    // const handleScroll = () => {
    //     if (window.location.pathname === "/")
    //         if (window.pageYOffset > 60) {
    //             setIsbg(true);
    //         } else {
    //             setIsbg(false);
    //         }
    // };

    const scrollHandler = () => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
    };

    const handleHamburgerClick = () => {
        setIsMenuActive(!isMenuActive);
        if (!isMenuActive) {
            // Disable scroll
            body.style.overflow = "hidden";
        } else {
            // Enable scroll
            body.style.overflow = "auto";
        }
    };

    const handleNavIndex = (i) => {
        if (navActiveIndex === i) {
            setNavActiveIndex(null);
        } else {
            setNavActiveIndex(i);
        }
    };
    const getLoggedInState = (customer) => {
        return !!(customer && customer._id);
    }
    const navlinkList = navLinksArr.map((nav, i) => (
        // if(getLoggedInState(customer) === false ){ 
        <li key={navLinksArr[i].id}>
            {nav.type === "img" && (
                <div
                    onClick={() => setNavlinkIndex(i)}
                    className={`logo_wrapper ${navlinkIndex === i ? "" : ""} `}
                >
                    <a href={homepageURL}>
                        <img
                            width={292}
                            className={nav.class}
                            src={`${ispro ? createAccount === false? prologoblack
                                :prologo :createAccount === false? blacklogo: whitelogo}`}
                            alt="atlas img"
                            onClick={scrollHandler}
                            loading="eager"
                        />
                    </a>
                </div>
            )}
            {nav.type === "text" && (
                <div
                    onClick={() => setNavlinkIndex(i)}
                    className={`nav_tab ${navlinkIndex === i ? "active" : ""} `}
                >
                    {nav.linktype === "external" ? (
                        <a
                            className={nav.class}
                            href={nav.mainLink}
                            target="_blank"
                            rel="noreferrer"
                        >
                            {nav.mainTitle}
                        </a>
                    ) : (
                        // <Link className={nav.class} to={nav.mainLink}>
                        <Link
                            className={`${nav.linkVariant === "console" && isConsole === true
                                    ? ""
                                    : nav.class
                                }`}
                            to={nav.mainLink}
                        >
                            {nav.mainTitle}
                        </Link>
                    )}
                </div>
            )}

            {isConsole === true
                ? nav.type === "search" && (
                    <div className="nav_tab">
                        <img src={nav.img} alt="search" className={nav.class} />
                    </div>
                )
                : null}

            {nav.type === "social" && (
                <div
                    onClick={() => setNavlinkIndex(i)}
                    className={`nav_tab social_links ${navlinkIndex === i ? "active" : ""
                        } `}
                >
                    {nav.links.map((item) => (
                        <a
                            className={nav.class}
                            href={item.url}
                            target="_blank"
                            rel="noreferrer"
                            key={item.url}
                        >
                            <img
                                width={30}
                                height={30}
                                src={item.img}
                                alt={item.url}
                                className="social_img"
                            />
                        </a>
                    ))}
                </div>
            )}

            {isConsole === false
                ? nav.type === "form" && (
                    <div
                        //   onClick={userStateHandler}
                        className={`nav_tab bg_cta ${navlinkIndex === i ? "active" : ""
                            } `}
                    >
                        {getLoggedInState(customer) === false ? (<div
                            className="multi_text"

                        // style={{ pointerEvents: "none" }}
                        >
                            {personalText === false ?
                                <Link to={nav.userStates[0].slug} className="title">{brandText === false ? nav.userStates[0].title : null}</Link>
                                : null}
                            {personalText === true ? <Link className="title" to={nav.userStates[1].slug} onClick={() => {
                                dispatch(setBrandText(true))
                                dispatch(setPersonalText(false))
                            }}> {"Create Business Account"}</Link> : null}
                            {brandText === true ? <Link className="title" to={personalAccountRegistrationUrl} onClick={() => {
                                dispatch(setPersonalText(true))
                                dispatch(setBrandText(false))
                            }}> {"Create Personal Account"}</Link> : null}

                            <div>
                                <img
                                    width={28.48}
                                    src={createAccount === false ? rightarrowblack : rightarrowwhite}
                                    alt="right arrow"
                                    className="right_arrow"
                                />
                                {/* <img
                width={15.5}
                src={blackright}
                alt="right arrow"
                className="right_arrow"
              /> */}
                            </div>
                        </div>
                        )
                            :
                            createAccount === true ? <Link
                                className="multi_text"
                                to={nav.userStates[1].slug}
                                style={{ padding: "0.99em 1.6em" }}
                            >
                                <div className="title">{nav.userStates[1].title}</div>
                                <div>
                                    <img
                                        width={28.48}
                                        src={rightarrowwhite}
                                        alt="right arrow"
                                        className="right_arrow"
                                    />
                                    {/* <img
                width={15.5}
                src={blackright}
                alt="right arrow"
                className="right_arrow"
              /> */}
                                </div>
                            </Link> : null}
                    </div>
                )
                : null}
        </li>
        // }else{

        // }
    ));

    useEffect(() => {
        if (
            location.pathname === "/students-console" ||
            location.pathname === "/team-member-console" ||
            location.pathname === "/business-console"
        ) {
            setIsConsole(true);
        } else {
            setIsConsole(false);
        }
    }, [location.pathname]);

    useEffect(() => {
        if (location.pathname === businessFormFiveURL) {
            dispatch(setCreateAccount(false))
        } else {
            dispatch(setCreateAccount(true))
        }
        setLoading(false);
    }, [location.pathname]);

    const logOut = () => {
        dispatch(logoutCustomer());
    }

    // console.log(customer, "customer", getLoggedInState(customer));

    return (
        <>
            {loading && (
                <div className="loadergifdiv">
                    <img src={blacklogo} alt="loader" className="img-fluid" />
                </div>
            )}
              <div className={createAccount === false ? "desktop_blankv3" : "desktop_blank"}></div>
            <header
                ref={ref}
                className={`${createAccount === false ? "headerv3_sec" : "headerv2_sec"} ${isMenuActive ? "menuactive" : ""}`}
            // data-aos={window.location.pathname === "/" && "fade-down"}
            >
                {width > 1080 ? (
                    <>
                        {/* <div className="desktop_blank"></div> */}
                        <div className="my_container">
                            <div className="navlinks">
                                <ul>
                                    {navlinkList}
                                    <li>
                                        {getLoggedInState(customer) === true && (
                                            <div
                                                className="nav_tab user_tab"
                                                onClick={userStatusHandler}
                                                onMouseOver={() => setDropdownActive(true)}
                                                onMouseOut={() => setDropdownActive(false)}
                                            >
                                                <Link className="user" to={() => false}>
                                                    {/* <div className="title">
                                                        {customer?.contact_name}

                                                    </div> */}
                                                    <div
                                                        className="initial_circle"
                                                        style={{
                                                            background: colorr,
                                                        }}
                                                    >
                                                        <div className="name">
                                                            {customer?.contact_name}
                                                            {/*{usersArr[userCategory].users[0].name}*/}
                                                        </div>
                                                    </div>
                                                    <div className="dropdown_arrow_wrap">
                                                        <img
                                                            width={9}
                                                            src={dropdownarrow}
                                                            alt="dropdown arrow"
                                                            className="dropdown_arrow"
                                                        />
                                                    </div>
                                                </Link>
                                            </div>
                                        )}
                                        {getLoggedInState(customer) === false && (
                                            <div
                                                className="nav_tab login_tab"
                                            // onClick={() => setLoggedIn(true)}
                                            >
                                                <Link className="login" to={loginURL}>
                                                    <div className="title">Login</div>
                                                    <div>
                                                        <img
                                                            width={30}
                                                            height={30}
                                                            src={loginicon}
                                                            alt="login icon"
                                                            className="login_icon"
                                                        />
                                                    </div>
                                                </Link>
                                            </div>
                                        )}
                                        {dropdownActive === true && (
                                            <div
                                                className="dropdown_box"
                                                onMouseOver={() => setDropdownActive(true)}
                                                onMouseOut={() => setDropdownActive(false)}
                                            >
                                                <Link className="accounts" to={consoleUrl} onClick={() => { dispatch(setCreateAccount(true)) }}>
                                                    <div className="title">My Console</div>
                                                </Link>
                                                <Link className="accounts" to={editProfile} onClick={() => { dispatch(setCreateAccount(true)) }}>
                                                    <div className="title">My Accounts</div>
                                                </Link>
                                                <Link className="accounts" to={() => false} onClick={logOut}>
                                                    <div className="title">Logout</div>
                                                    {/*<div>*/}
                                                    {/*  <img*/}
                                                    {/*    width={30}*/}
                                                    {/*    height={30}*/}
                                                    {/*    src={loginicon}*/}
                                                    {/*    alt="login icon"*/}
                                                    {/*    className="login_icon"*/}
                                                    {/*  />*/}
                                                    {/*</div>*/}
                                                </Link>
                                            </div>
                                        )}
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </>
                ) : (
                    <MobileHeader
                        colorr={colorr}
                        isBg={ispro}
                        customer={customer}
                        logOut={logOut}
                        handleHamburgerClick={handleHamburgerClick}
                        handleNavIndex={handleNavIndex}
                        navActiveIndex={navActiveIndex}
                        isMenuActive={isMenuActive}
                        createAccount={createAccount}
                        getLoggedInState={getLoggedInState}
                        userStatusHandler={userStatusHandler}
                    />
                )}
            </header>
        </>
    );
};

export default HeaderV2;

