import {
    SET_AUTH_STATE,
    SET_BRAND,
    SET_BRAND_COUNT,
    SET_BRAND_ID,
    SET_CITY_LIST,
    SET_CODE,
    SET_ERROR,
    SET_IS_AUTHENTICATION,
    SET_USER_ID,
    SET_USER_TYPE,
    SET_BRAND_ACCOUNTS,
    SET_CREATE_ACCOUNT,
    SET_PRO_LOGO,
    SET_BRAND_TEXT,
    SET_PERSONAL_TEXT
} from "./appActionType";
import callApi from "../../util/apiCaller";
import {BRAND_ID, CUSTOMER_ID, BRAND_ACCOUNT_ID,FORM_FIVE_FLOW} from "../../constants/enums";
import {APP_TOKEN, STUDENT_STEP_FLOW,TEAM_STEP_FLOW,BUSINESS_STEP_FLOW,ENTHUSIAST_STEP_FLOW} from "../../constants/appEnums";
import {
    businessAccessURL,
    congratulationsPageUrl,
    consoleUrl,
    loginURL,
    regiserOTPURL,
    registrationFormURL, 
    studentAccesssURL, 
    teamAccesssURL,
    enthusiastAccesssURL
} from "../../components/helpers/constant-words";
//import {errorMsgs, successMsg} from "../../util/toast";
import {toast} from "react-toastify";
import RegisterOTP from "../../pages/RegistrationForm/RegisterOTP/RegisterOTP";

import _ from "lodash";
import {SuccessMsg, ErrorsMsg } from "../../util/Message";
import React from "react";
import {AUTHENTICATED} from "../../constants/authEnums";


export const setAuthState = (authState) => ({
    type: SET_AUTH_STATE,
    authState,
});

export const setAuthentication = (state) => ({
    type: SET_IS_AUTHENTICATION,
    state,
});

export const setUserId = (id) => ({
    type: SET_USER_ID,
    id,
});

export const setUser = (user) => ({
    type: SET_USER_TYPE,
    user: user,
});
export const setCode = (code) => ({
    type: SET_CODE,
    code: code
})
export const setError = (error) => ({
    type: SET_ERROR,
    error: error,
})
export const setBrandAccount = (brandAccounts) => ({
    type: SET_BRAND_ACCOUNTS,
    brandAccounts : brandAccounts
})
export const setBrandId = (brandId) => ({
    type: SET_BRAND_ID,
    brandId: brandId,
})
export const setBrand = (brand) => ({
    type: SET_BRAND,
    brand: brand
})
export const setBrandCount = (brandCount) => ({
    type: SET_BRAND_COUNT,
    brandCount: brandCount
})
export const setCityList = (cityList) => ({
    type: SET_CITY_LIST,
    cityList: cityList
})
export const setIsPro = (isPro) => ({
    type: SET_PRO_LOGO,
    isPro: isPro,
})
export const setCreateAccount = (isCreateAccount) => ({
    type: SET_CREATE_ACCOUNT,
    isCreateAccount: isCreateAccount,
})
export const setBrandText = (brandText) => ({
    type: SET_BRAND_TEXT,
    brandText: brandText,
})
export const setPersonalText = (personalText) => ({
    type: SET_PERSONAL_TEXT,
    personalText: personalText,
})


export function fetchMagicCode(code) {
    return async dispatch => {
        try {
            const res = await callApi(`customer/auth/magic-login/${code}`, "post", {});

            if (res && res?.status === 'Success') {
                const {customer, token} = res?.data;
                window.localStorage.setItem(CUSTOMER_ID, customer?._id);
                window.localStorage.setItem(APP_TOKEN, token);
                dispatch(setAuthentication(true));
                dispatch(setAuthState(AUTHENTICATED));
               // navigate(consoleUrl)
               window.location = consoleUrl;
            } else {
                toast(ErrorsMsg("Something went wrong"),{
                    className: "mail_toast",
                    position: toast.POSITION.TOP_CENTER,
                    hideProgressBar: true,
                    closeButton: false,
                  });
            }
        } catch (err) {
            // dispatch(SET_IS_LOADING(false));
        }
    }
}

export function fetchCustomerProfileAfterRegistration(customer_id, navigate) {
    return async dispatch => {
        try {
            const res = await callApi(`customer/${customer_id}/v2`, "get");

            if (res && res?.status === 'Success') {
                const {customer} = res?.data;
                dispatch(setUser(customer));
                dispatch(setUserId(customer?._id));
                navigate(congratulationsPageUrl);
            } else {
                toast(ErrorsMsg("Something went wrong"),{
                    className: "mail_toast",
                    position: toast.POSITION.TOP_CENTER,
                    hideProgressBar: true,
                    closeButton: false,
                });
            }

        } catch (err) {
            // dispatch(SET_IS_LOADING(false));
        }
    }
}

const handleOpenProAccessForm = (customer) => {
    let newLink = ""
    if (customer && customer.user_type) {
        switch (customer.user_type) {
            case 'student':
                newLink = studentAccesssURL
                break;
            case 'team-member':
                newLink = teamAccesssURL
                break;
            case 'business':
                newLink = businessAccessURL
                break;
            case 'design-enthusiast':
                newLink = enthusiastAccesssURL
                break;
        }
    }
    return newLink
}

export function fetchCustomerProfile(customer_id, navigate) {
    return async dispatch => {
        try {
            const res = await callApi(`customer/${customer_id}/v2`, "get");
            if (res && res?.status === 'Success') {
                const {customer} = res?.data;
                dispatch(setUser(customer));
                dispatch(setBrand(customer))
                dispatch(setAuthState(AUTHENTICATED));
                dispatch(setUserId(customer._id));
                window.localStorage.setItem(FORM_FIVE_FLOW,customer?.brand_current_flow)
                if(customer?.current_flow){
                    window.localStorage.setItem(BUSINESS_STEP_FLOW,customer?.current_flow) 
                    window.localStorage.setItem(STUDENT_STEP_FLOW,customer?.current_flow) 
                    window.localStorage.setItem(TEAM_STEP_FLOW,customer?.current_flow) 
                    window.localStorage.setItem(ENTHUSIAST_STEP_FLOW,customer?.current_flow) 
                }
                if(customer?.current_flow !== 0 && customer?.profile_completion !== 100) {
                    navigate(handleOpenProAccessForm(customer))
                }
                
                
            } else {
                toast(ErrorsMsg("Something went wrong"),{
                    className: "mail_toast",
                    position: toast.POSITION.TOP_CENTER,
                    hideProgressBar: true,
                    closeButton: false,
                  });
                window.localStorage.clear();
            }

        } catch (err) {
            // dispatch(SET_IS_LOADING(false));
        }
    }
}
export const setUrl = (navigate) => {
   return dispatch => {

    dispatch(setAuthentication(true));
    dispatch(setAuthState(AUTHENTICATED));
    navigate(consoleUrl)
    //window.location = consoleUrl;
   
   }
}

export function editCustomerProfile(customer_id, customer) {
    return async dispatch => {
        try {
            const res = await callApi(`customer/${customer_id}/profile/update`, "post", {
                customer: customer
            });

            if (res && res?.status === 'Success') {
                toast(ErrorsMsg("Updated"),{
                    className: "mail_toast",
                    position: toast.POSITION.TOP_CENTER,
                    hideProgressBar: true,
                    closeButton: false,
                });
            } else {
                toast(ErrorsMsg("Something went wrong"),{
                    className: "mail_toast",
                    position: toast.POSITION.TOP_CENTER,
                    hideProgressBar: true,
                    closeButton: false,
                  });
            }
        } catch (err) {
            // dispatch(SET_IS_LOADING(false));

        }
    }
}

export function onEditProfileViaOtp(customer){
    return async dispatch => {
        try {
            const res = await callApi(`customer/auth/login-otp`, "post", {customer});
         
            if (res && res?.status === 'Success') {

            } else {
              toast(ErrorsMsg(res?.data?.message),{
                    className: "mail_toast",
                    position: toast.POSITION.TOP_CENTER,
                    hideProgressBar: true,
                    closeButton: false,
                  })
            }
        } catch (err) {
            // dispatch(SET_IS_LOADING(false));
        }
    }
}
export function onEditProfileUsingOTP(creds){
    return async dispatch => {
        try {
            const res = await callApi(`customer/auth/validate-otp`, "post", {customer: creds});
          
            if (res && res?.status === 'Success') {
                const {customer, token} = res?.data;
                if (token) {
                    window.localStorage.setItem(APP_TOKEN, token);
                }
                if (customer && customer._id) {
                    window.localStorage.setItem(CUSTOMER_ID, customer?._id);
                    dispatch(fetchCustomerProfile(customer?._id));
                }


            } else {

                toast(ErrorsMsg("Please fill your correct otp "),{
                    className: "mail_toast",
                    position: toast.POSITION.TOP_CENTER,
                    hideProgressBar: true,
                    closeButton: false,
                  })
            }
        } catch (err) {
            // dispatch(SET_IS_LOADING(false));
        }
    }
}

export function signupCustomer(customer, navigate) {
    return async dispatch => {
        try {
            const res = await callApi(`customer/auth/signup`, "post", {customer});

            if (res && res?.status === 'Success') {
                const {customer} = res?.data;
                if (customer) {
                    dispatch(setUser({ contact_email: customer?.contact_email, contact_phone: customer?.contact_phone, country_code: customer?.country_code }));
                }
                dispatch(loginCustomerViaOTP(customer, navigate));
            } else {
                toast(ErrorsMsg(res.data.message),{
                    className: "mail_toast",
                    position: toast.POSITION.TOP_CENTER,
                    hideProgressBar: true,
                    closeButton: false,
                  })

            }
        } catch (err) {


        }
    }
}

export function loginCustomerViaOTP(customer, navigate) {
    return async dispatch => {
        try {
            const res = await callApi(`customer/auth/login-otp`, "post", {customer});
          
            if (res && res?.status === 'Success') {
                toast(SuccessMsg('OTP Shared'), {
                    className: "otp_toast",
                    position: toast.POSITION.TOP_CENTER,
                    hideProgressBar: true,
                    closeButton: false,
                });
                if(!res?.data?.is_customer_found){
                    toast(ErrorsMsg("Customer not found"),{
                        className: "mail_toast",
                        position: toast.POSITION.TOP_CENTER,
                        hideProgressBar: true,
                        closeButton: false,
                      })
                }
                if(navigate){
                    navigate(regiserOTPURL)
                }
            } else {
                toast(ErrorsMsg(res.data.message),{
                    className: "mail_toast",
                    position: toast.POSITION.TOP_CENTER,
                    hideProgressBar: true,
                    closeButton: false,
                  })

            }
        } catch (err) {
            // dispatch(SET_IS_LOADING(false));
        }
    }
}

export const loginCustomerViaMobile = async (customer) => {
    const res = await callApi(`customer/auth/login-otp`, "post", {customer});

    return res;
}
export function onChangePassword(customer,id) {
    return async dispatch => {
        try {
            const res = await callApi(`customer/${id}/change-password`, "post", customer);
          
            if (res && res?.status === 'Success') {
                // if (navigate) {
                //     navigate(regiserOTPURL);
                // }
                const {customer} = res?.data;

            } else {

                toast(ErrorsMsg("Please enter correct password"),{
                    className: "mail_toast",
                    position: toast.POSITION.TOP_CENTER,
                    hideProgressBar: true,
                    closeButton: false,
                  })

            }
        } catch (err) {

         
        }
    }
}

export async function OnForgetPassword(customer) {

            const res = await callApi(`customer/auth/forgot-password`, "post", {customer});
         
          return res;
}

export function OnReSetPassword(customer, token) {
    return async dispatch => {
        try {
            const res = await callApi(`customer/auth/reset-password/${token}`, "post", {customer});
           
            if (res && res?.status === 'Success') {

                const {customer} = res?.data;

            } else {

                toast(ErrorsMsg("Please fill your correct email Id"),{
                    className: "mail_toast",
                    position: toast.POSITION.TOP_CENTER,
                    hideProgressBar: true,
                    closeButton: false,
                  })

            }
        } catch (err) {

            // dispatch(SET_IS_LOADING(false));
        }
    }
}

export function verifyCustomerOTP(creds, navigate) {
    return async dispatch => {
        try {
            const res = await callApi(`customer/auth/validate-otp-v2`, "post", {customer: creds});
          
            if (res && res?.status === 'Success') {
                const {customer, token} = res?.data;
                if (token) {
                    window.localStorage.setItem(APP_TOKEN, token);
                    window.localStorage.setItem(CUSTOMER_ID, customer?._id);
                    dispatch(setAuthState(AUTHENTICATED));
                }
                if (customer && customer._id) {
                    dispatch(fetchCustomerProfileAfterRegistration(customer?._id, navigate));
                }
            } else {

                toast(ErrorsMsg("Please fill your correct otp "),{
                    className: "mail_toast",
                    position: toast.POSITION.TOP_CENTER,
                    hideProgressBar: true,
                    closeButton: false,
                  })

            }
        } catch (err) {
            // dispatch(SET_IS_LOADING(false));
        }
    }
}

export function verifyCustomerOTPV2(creds, navigate) {
    return async dispatch => {
        try {
            const res = await callApi(`customer/auth/validate-otp-v2`, "post", {customer: creds});
           
            if (res && res?.status === 'Success') {
                const {data} = res;
                if (data?.token) {
                    window.localStorage.setItem(APP_TOKEN, data?.token);
                }
                // if (customer && customer._id) {
                //     window.localStorage.setItem(CUSTOMER_ID, customer?._id);
                //     dispatch(fetchCustomerProfile(customer?._id));
                // }
                    navigate(5);
                    dispatch(setBrandAccount(data))

            //  window.location = consoleUrl;

            } else {

                toast(ErrorsMsg("Please fill your correct otp "),{
                    className: "mail_toast",
                    position: toast.POSITION.TOP_CENTER,
                    hideProgressBar: true,
                    closeButton: false,
                  })

            }
        } catch (err) {
            // dispatch(SET_IS_LOADING(false));
        }
    }
}

export function loginViaPassword(creds, navigate) {
    return async dispatch => {
        try {

            const res = await callApi(`customer/auth/login`, "post", {customer: creds});
            //console.log(res);
            if (res && res?.status === 'Success') {

                const {customer, token} = res?.data;

                window.localStorage.setItem(CUSTOMER_ID, (customer?._id));
                window.localStorage.setItem(APP_TOKEN, token);
                dispatch(fetchCustomerProfile(customer?._id));
                window.location = consoleUrl;
            } else {

                toast(ErrorsMsg("Please enter correct email or password"),{
                    className: "mail_toast",
                    position: toast.POSITION.TOP_CENTER,
                    hideProgressBar: true,
                    closeButton: false,
                  })

            }
        } catch (err) {
            // dispatch(SET_IS_LOADING(false));
        }
    }
}

export function updateCustomerRole(customer_id, updateCustomer) {
    return async dispatch => {
        try {
            const res = await callApi(`customer/${customer_id}/profile/update-role`, "post", {
                customer: updateCustomer
            });
          
            if (res && res?.status === 'Success') {
                const {customer} = res?.data;
                dispatch(setUser(customer));
                if(customer?.contact_name){
                    toast(SuccessMsg("Role Changed"),{
                        className: "mail_toast",
                        position: toast.POSITION.TOP_CENTER,
                        hideProgressBar: true,
                        closeButton: false,
                    })
                }


            } else {

                toast(ErrorsMsg("Something went wrong"),{
                    className: "mail_toast",
                    position: toast.POSITION.TOP_CENTER,
                    hideProgressBar: true,
                    closeButton: false,
                })


            }
        } catch (err) {
            // dispatch(SET_IS_LOADING(false));

        }
    }
}

export function updateCustomerProfile(customer_id, updateCustomer) {
    return async dispatch => {
        try {
            const res = await callApi(`customer/${customer_id}/profile/update`, "post", {
                customer: updateCustomer
            });
          
            if (res && res?.status === 'Success') {
                const {customer} = res?.data;
                dispatch(setUser(customer));
                if(customer?.contact_name){
                    toast(SuccessMsg("Updated"),{
                        className: "mail_toast",
                        position: toast.POSITION.TOP_CENTER,
                        hideProgressBar: true,
                        closeButton: false,
                    })
                }


            } else {

                toast(ErrorsMsg("Something went wrong"),{
                    className: "mail_toast",
                    position: toast.POSITION.TOP_CENTER,
                    hideProgressBar: true,
                    closeButton: false,
                  })


            }
        } catch (err) {
            // dispatch(SET_IS_LOADING(false));

        }
    }
}

export function fetchContentCode(code) {
    return async dispatch => {
        try {
            const res = await callApi(`content/code/${code}`, "get");
           
            if (res && res?.status === 'Success') {
                const {content} = res?.data;
                dispatch(setCode(content));
                // window.localStorage.setItem(APP_TOKEN, token);
                // window.location = "/";
            } else {
            }
        } catch (err) {
            // dispatch(SET_IS_LOADING(false));
        }
    }
}

export function setCustomerData(brand,token) {
   
    return async dispatch => {
        try {
            //dispatch(setAuthentication(true));
            //dispatch(setAuthState(AUTHENTICATED));
                dispatch(setUser(brand));
                dispatch(setBrand(brand));
               
                
        } catch (err) {
            // dispatch(SET_IS_LOADING(false));

        }
    }
}

export const addBrandV2 = async (brands, code) =>  {
    try {
        const res = await callApi("brand/new-v2", "post", {
            brand: brands,
            code: code
        });
        const {token,brand} = res?.data
        if(token && brand){
            window.localStorage.setItem(APP_TOKEN,token)
            window.localStorage.setItem(CUSTOMER_ID, brand?._id);
        }
       
        return res
    } catch (err) {
        // dispatch(SET_IS_LOADING(false));

    }
}

export const sendDataForBrandValidation = async (brand) => {
    return await callApi("brand/new-validate", "post", {
        brand: brand
    });
}

export const updateCustomerArchProV2 = async (brand_id, customer) => {
       try {
            const res = await callApi(`customer/${brand_id}/archinza-pro/update`, "post", {
                brand: customer
            });
            const {brand} = res?.data
            if(brand?.brand_current_flow){
            window.localStorage.setItem(FORM_FIVE_FLOW,brand?.brand_current_flow)
            }
            return res;
        } catch (err) {
           // dispatch(SET_IS_LOADING(false));

        }
}

export function updateCustomerArchPro(brand_id, customer) {
    return async dispatch => {
        try {
            const res = await callApi(`customer/${brand_id}/archinza-pro/update`, "post", {
                brand: customer
            });
          
            if (res && res?.status === 'Success') {
                const {brand} = res?.data;
                // dispatch(setUpdatedBrand(brand));
                // window.localStorage.setItem(APP_TOKEN, token);
                // window.location = "/";
            } else {

                toast(ErrorsMsg("Something went wrong"),{
                    className: "mail_toast",
                    position: toast.POSITION.TOP_CENTER,
                    hideProgressBar: true,
                    closeButton: false,
                  })

            }
        } catch (err) {
            // dispatch(SET_IS_LOADING(false));

        }
    }
}

export function addBrand(brands) {
    return async dispatch => {
        try {
            const res = await callApi("brand/new", "post", {
                brand: brands
            });
         
            if (res && res?.status === 'Success') {
                const {brand} = res?.data;

                window.localStorage.setItem(BRAND_ID, (brand?._id));
                dispatch(updateCustomerArchPro(brand?._id,brands));

                // window.localStorage.setItem(APP_TOKEN, token);
                // window.location = "/";
            } else {
            }
        } catch (err) {
            // dispatch(SET_IS_LOADING(false));

        }
    }
}

export function newsletterUpdate(customer) {
    return async dispatch => {
        try {
            const res = await callApi("newsletter/new", "post", {
                newsletter: customer
            });
            
            if (res && res?.status === 'Success') {
                const {newsletter} = res?.data;

                toast(SuccessMsg("Your emailId has been registered successfully"),{
                    className: "mail_toast",
                    position: toast.POSITION.TOP_CENTER,
                    hideProgressBar: true,
                    closeButton: false,
                  })
                // window.localStorage.setItem(APP_TOKEN, token);
                // window.location = "/";
            } else {

                toast(ErrorsMsg("Please give your correct email Id"),{
                    className: "mail_toast",
                    position: toast.POSITION.TOP_CENTER,
                    hideProgressBar: true,
                    closeButton: false,
                  })

            }
        } catch (err) {
            // dispatch(SET_IS_LOADING(false));

        }
    }
}

export function addDocument(document,navigate) {
  return async dispatch => {
    try {
      const res = await callApi("document/new", "post", {
        document : document
      })
      if (res && res?.status === 'Success') {
        const { document } = res?.data;
        navigate(consoleUrl)
        // window.localStorage.setItem(BRAND_ID, JSON.stringify(brand?._id));

        // window.localStorage.setItem(APP_TOKEN, token);
        // window.location = "/";
      } else {
      }
    } catch (err) {
      // dispatch(SET_IS_LOADING(false));

    }
  }
}

export function onAddProjects(project,navigate) {
    return async dispatch => {
      try {
        const res = await callApi("project/new", "post", {
          project : project
        });
        
        if (res && res?.status === 'Success') {
          const { project } = res?.data;

          // window.localStorage.setItem(BRAND_ID, JSON.stringify(brand?._id));
            navigate(consoleUrl)
          // window.localStorage.setItem(APP_TOKEN, token);
          // window.location = "/";
        } else {
        }
      } catch (err) {
        // dispatch(SET_IS_LOADING(false));

      }
    }
  }

// export function fetchBrandList(customer_id) {
//   return async dispatch => {
//     try {
//       const res = await callApi("brand/list", "post", {
//         "pageNum": 1,
//         "pageSize": 20,
//         filters : {customer_id: customer_id}
//       });
//       console.log(res);
//       if (res && res?.status === 'Success') {
//         const { brandList, brandCount } = res;
//         for(let item of brandList){
        
//         }
//         dispatch(setBrand(brandList));
//         dispatch(setBrandCount(brandCount));
//         // window.localStorage.setItem(APP_TOKEN, token);
//         // window.location = "/";
//       } else {
//       }
//     } catch (err) {
//       // dispatch(SET_IS_LOADING(false));

//     }
//   }
// }


export function logoutCustomer () {
    return dispatch => {
       
        window.localStorage.removeItem(BRAND_ID);
        
        window.localStorage.removeItem(STUDENT_STEP_FLOW)
        window.localStorage.removeItem(TEAM_STEP_FLOW)
        window.localStorage.removeItem(BUSINESS_STEP_FLOW)
        window.localStorage.removeItem(ENTHUSIAST_STEP_FLOW)
        window.localStorage.removeItem(FORM_FIVE_FLOW)
        window.localStorage.removeItem(CUSTOMER_ID);
        window.localStorage.removeItem(APP_TOKEN);
       
        window.location = loginURL;
    }
}
