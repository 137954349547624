import React, { useEffect, useState } from "react";
import style from "../TeamAccess/teammember.module.scss";
import CheckboxButton from "../../../components/CheckboxButton/CheckboxButton";
import LogoutText from "../../../components/LogoutText/LogoutText";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { Input } from "@mui/material";
import { plusicon } from "../../../images";
import { useDispatch, useSelector } from "react-redux";
import { updateCustomerProfile } from "../../../redux/app/appAction";
import { getUserId, getCustomer } from "../../../redux/app/appSelectors"
import callApi from "../../../util/apiCaller";
import { rightarrowwhite } from "../../../images";
import FullWidthTextField from "../../../components/TextField/FullWidthTextField";
import { useNavigate } from "react-router-dom";
import { consoleUrl } from "../../../components/helpers/constant-words";
import _ from "lodash";
import { toast } from "react-toastify";
import { MsgWithVarText } from "../../EditProfile/EditProfile";
import AutoCompleteField from "../../../components/AutoCompleteField/AutoCompleteField";

const darkTheme = createTheme({
  palette: {
    mode: "dark",
  },
});

// const concernsArr = [
//   "Jobs",
//   "Searching products materials for projects",
//   "Education & Knowledge on Trends | Materials",
//   "Upskill & Learn New Software",
//   "Knowledge | Confidence to Start my own firm",
// ];

const SAStep05 = ({
  previousStep,
  nextStep,
  currentStep,
  totalSteps,
  progressStatus,
  handleUserExtraData,
  userExtraData
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userId = useSelector((state) => getUserId(state));
  const customer = useSelector((state) => getCustomer(state))
  const [unmetNeeds, setUnmetNeeds] = useState({})
  const [addMore, setAddMore] = useState("")
  const [updatedRequirement, setUpdatedRequirement] = useState([])
  const [errors, setErrors] = useState({});
  const [code, setCode] = useState([]);

  useEffect(() => {
    if (customer?.requirements) {
      setUnmetNeeds({
        requirements: customer?.requirements
      })
    }
  }, [customer])

  useEffect(() => {

    fetchContentCode("team-member-unmet-needs")
  }, [])
  async function fetchContentCode(code) {

    try {
      const res = await callApi(`content/code/${code}`, "get");
      console.log(res);
      if (res && res?.status === 'Success') {
        const { params } = res?.data?.content;
        setUpdatedRequirement([...params]);
        setCode(params.map(item => item.label));
      } else {
      }
    } catch (err) {

    }

  }

  useEffect(() => {
    const handleKeyPress = (e) => {
      if (e.key === 'Enter' && addMore !== "") {
        //handleUserDetail("requirements", addMore);
        if (unmetNeeds?.requirements?.indexOf(addMore) === -1) {
          handleUserDetail("requirements", addMore);
          // let arr = [...updatedRequirement]
          // arr?.push({label:addMore,value:addMore})
          // setUpdatedRequirement([...arr])
        } else {
          toast(<MsgWithVarText text={'Tag already present'} />, {
            className: "mail_toast",
            position: toast.POSITION.TOP_CENTER,
            hideProgressBar: true,
            closeButton: false,
          })
        }
        setAddMore("");
      }
    };

    window.addEventListener('keypress', handleKeyPress);

    return () => {
      window.removeEventListener('keypress', handleKeyPress);
    };
  }, [addMore]);

  const handleUserDetail = (keyName, keyValue) => {
    if (keyValue) {
      let prevIndex = unmetNeeds?.requirements?.indexOf(keyValue);

      if (prevIndex !== -1) {
        toast(<MsgWithVarText text={'Tag already present'} />, {
          className: "mail_toast",
          position: toast.POSITION.TOP_CENTER,
          hideProgressBar: true,
          closeButton: false,
        })
      } else {
        let update = { ...unmetNeeds };
        update['requirements'] = [...unmetNeeds?.requirements];

        if (keyValue === "All of the Above") {
          let arr = [...unmetNeeds?.requirements, ...code.slice(0, code.length - 1)]
          let set = new Set(arr)
          let arr1 = Array.from(set)
          update['requirements'] = [...arr1]
        } else {
          update['requirements']?.push(keyValue);
        }
        setUnmetNeeds(update);
        setErrors({});
      }
    }

  }

  const handleValidation = (user) => {
    let isValid = true;
    let errors = {};
    if (user?.requirements.length === 0) {
      isValid = false;
      errors["requirements"] = "Please select atleast one unmet needs";
    }

    return {
      isValid,
      errors
    }
  }

  const handleUpdateApi = () => {
    let check = handleValidation(unmetNeeds);
    let update = { ...unmetNeeds }
    update.current_flow = currentStep;
    if (check.isValid && userId) {
      dispatch(updateCustomerProfile(userId, update))
      //handleUserExtraData(unmetNeeds);
      nextStep(6);
      setErrors({})
      window.scrollTo(0, 0);
      navigate(consoleUrl)
    } else {
      setErrors(check.errors);
    }

  }
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const renderError = (keyName) => {
    return errors && errors[keyName] ? <p className="error" style={{ marginBottom: "20px" }}>{errors[keyName]}</p> : null;
  };

  const handleRemoveTag = (keyName, keyValue) => {
    if (keyValue) {
      let prevIndex = unmetNeeds?.requirements?.indexOf(keyValue);

      if (prevIndex !== -1) {
        let update = {
          ...unmetNeeds,
          ['requirements']: unmetNeeds?.requirements?.filter(
            (_, index) => index !== prevIndex
          ),
        };
        setUnmetNeeds(update);
      } else {
      }
    }
  }

  const concernList = updatedRequirement?.map((option) => (
    unmetNeeds?.requirements?.indexOf(option.value) === -1 ?
      <React.Fragment key={option.value}>
        <CheckboxButton isChecked={false} label={option.label} labelId={option.value} handleUserDetail={handleUserDetail} keyName={"requirements"} />
      </React.Fragment> : null
  ));
  const addedList = !_.isEmpty(unmetNeeds?.requirements) ? unmetNeeds?.requirements?.map((option) => (
    <React.Fragment key={option}>
      <CheckboxButton isChecked={true} label={option} labelId={option} handleUserDetail={handleUserDetail} keyName={"requirements"} />
    </React.Fragment>
  )) : null

  useEffect(() => {
    progressStatus((currentStep / totalSteps) * 100);
  }, [currentStep, progressStatus, totalSteps]);

  const handleAddMore = () => {
    if (addMore) {
      if (unmetNeeds?.requirements?.indexOf(addMore) === -1) {
        handleUserDetail("requirements", addMore)

      } else {
        toast(<MsgWithVarText text={'Tag already present'} />, {
          className: "mail_toast",
          position: toast.POSITION.TOP_CENTER,
          hideProgressBar: true,
          closeButton: false,
        })
      }
      setAddMore("");
    }
  }

  return (
    <>
      <div className={style.text_container}>
        <h1 className={style.title}>
          Tell us about your Largest Concerns | Unmet Needs ?
        </h1>
        <p className={style.description}>Choose as many</p>
      </div>
      <div className={`${style.steps} ${style.step01}`}>
        <div className={`${style.steps} ${style.step01}`}>
          <div className={style.field_wrapper}>
            {code && <AutoCompleteField
              freeSolo={false}
              popupIcon={""}
              textLabel="Unmet needs/Concerns"
              data={code}
              //course={user?.student_course}
              handleUserDetail={handleUserDetail}

            />}
          </div>
          <div className={'my-1 text-center'}>
            {renderError("requirements")}
          </div>
        </div>
        {/*<ul className={style.step02_ul}>{addedList}{concernList}</ul>*/}
        <div className={`${style.add_more_wrap} ${style.add_more_v2}`}>
          <div className={style.steps_ul}>
            <FullWidthTextField label="Add Others" key={"Student Add Others"} onChange={(e) => setAddMore(e.target.value)} value={addMore} />
            <img
              src={plusicon}
              alt="icon"
              className={style.icon}
              loading="lazy"
              onClick={handleAddMore}
            />

            {/*<FullWidthTextField label="Add Others" value={addOthers} onChange={} />*/}
            {/* <img
              src={plusicon}
              alt="icon"
              className={style.icon}
              loading="lazy"
              onClick={handleAddOthers}
            /> */}

          </div>
        </div>
        <div className={'my-4'}>
          {/* <div className={`${style.reduceSpace }`}> */}
          {

            !_.isEmpty(unmetNeeds?.requirements) ?
              unmetNeeds?.requirements?.map((key, i) => (
                <React.Fragment key={`{key}+${i}io`}>
                  <CheckboxButton isChecked={true} label={key} labelId={`newstud${key + i}`} handleUserDetail={handleRemoveTag} keyName={"requirements"} />
                </React.Fragment>
              )) :
              null

          }
        </div>


      </div>

      <div className={style.next_logout}>
        <div className={style.cta_wrapper}>
          <div
            className={style.next_button}
            onClick={handleUpdateApi}
          >
            <div className={style.text}>Submit</div>
            {/*<img*/}
            {/*  src={rightarrowwhite}*/}
            {/*  alt="icon"*/}
            {/*  className={style.icon}*/}
            {/*  loading="lazy"*/}
            {/*/>*/}
          </div>
          <div
            className={style.back_button}
            onClick={() => {
              previousStep(2);
              setErrors({})
              window.scrollTo(0, 0);
            }}
          >
            Back
          </div>
        </div>
        {/* <LogoutText userExtraData={unmetNeeds}/> */}
      </div>
    </>
  );
};

export default SAStep05;
