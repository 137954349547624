import React, { useEffect, useState } from "react";
import style from "../Form/formfive.module.scss";
import LogoutText from "../../../components/LogoutText/LogoutText";
import RadioButton from "../../../components/RadioButton/RadioButton";
import RadioButtonLightTheme from "../../../components/RadioButtonLightTheme/RadioButtonLightTheme";
import { rightarrowblack, rightarrowwhite } from "../../../images";
import callApi from "../../../util/apiCaller";
import { updateCustomerArchPro } from "../../../redux/app/appAction";
import { useDispatch, useSelector } from "react-redux";
import { getBrand, getBrandId } from "../../../redux/app/appSelectors";
import {ErrorsMsg, SuccessMsg} from "../../../util/Message";
import {toast} from "react-toastify";
import { setBrand, setBrandId,updateCustomerArchProV2,setCustomerData} from "../../../redux/app/appAction";

// const formATeamStrengthArr = [
//   "Upto 10",
//   "10-20",
//   "20-35",
//   "35-50",
//   "50-100",
//   "100+",
// ];

const FAStep03 = ({
  nextStep,
  previousStep,
  currentStep,
  totalSteps,
  progressStatus,
  handleUserExtraData, userExtraData
}) => {
  const dispatch = useDispatch();
  const brandId = useSelector((state) => getBrandId(state)) ;
  const brandData = useSelector((state) => getBrand(state))
  const [errors, setErrors] = useState({});
 // const [showError, setShowError] = useState(true)
  const [code, setCode] = useState([])
  const [team, setTeam ] = useState()

  useEffect(() => {
    setTeam({"team_strength":  brandData?.team_strength })
  },[brandData])

  const handleChangeUser = ( keyName,keyValue) => {
    if(keyValue){
      setErrors({})
    }
    let update ={...team};
    update["team_strength"] = keyValue;
    update.brand_current_flow = currentStep;
    setTeam(update);

  }
  const handleValidation = (user) => {
    let isValid = true;
    let errors = {};
    if (!user?.team_strength) {
        isValid = false;
        errors["team_strength"] = "Please select team strength";
    }

      return {
        isValid,
        errors
    }
  }
  const handleUpdateApi = (brandId,team) => {
    let check = handleValidation(team);
    
    if(check.isValid && brandId && team){
      updateCustomerArchProV2(brandId, team).then(res => {
        if(res?.status === 'Success' && res?.data?.brand) {
          dispatch(setCustomerData(res?.data?.brand));
          nextStep();
          window.scrollTo(0, 0);
        } else {
          toast(ErrorsMsg(res?.data?.message),{
            className:"mail_toast",
            position: toast.POSITION.TOP_CENTER,
            hideProgressBar: true,
            closeButton: false,
          })
        }
      });

      setErrors({})
    }else {
      setErrors(check?.errors);
      }
  }
  const renderError = (keyName) => {
    return errors && errors[keyName] ? <p className="error" >{errors[keyName]}</p> : null;
  };

  useEffect(() => {

    fetchContentCode("team-strength")
   },[])

  async function fetchContentCode(code) {

    try {
      const res = await callApi(`content/code/${code}`, "get");
      if (res && res?.status === 'Success') {
        const { params } = res?.data?.content;
        //let param = params?.map((option) => option.label)
        setCode([...params]);
      } else {
      }
    } catch (err) {

    }
  }

  const formATeamStrengthList = code?.map((option,i) => (
    <React.Fragment key={`${option.value}er${i}`}>
      <RadioButtonLightTheme extraSpace={true} label={option.label} labelId={`FAtc2${option.label+i}`} value={option.value} checked={option?.value === team?.team_strength} handleChangeUser={handleChangeUser} />
    </React.Fragment>
  ));

  return (
    <>
      <div className={style.text_container}>
      <p className={`${style.head_text1}`}>About The Business</p>
        <h1 className={style.title}>Number of employees </h1>
        <p className={style.description}></p>
      </div>
      <div className={`${style.steps} ${style.step08}`}>
        <ul className={style.steps_ul}>{formATeamStrengthList}</ul>
        <div style={{display:"flex",justifyContent:"center",alignItems:"center"}}> { renderError("team_strength") }</div>
      </div>

      <div className={style.next_logout}>
      
        <div className={style.cta_wrapper}>
          <div
            className={style.next_button}
            onClick={() => handleUpdateApi(brandId,team)}
          >
            <div className={style.text}>Next</div>
            <img
              src={rightarrowblack}
              alt="icon"
              className={style.icon}
              loading="lazy"
            />
          </div>
          <div
            className={style.back_button}
            onClick={() => {
              previousStep(7);
              setErrors({});
              window.scrollTo(0, 0);
            }}
          >
            Back
          </div>
        </div>
        <LogoutText  userData={team}/>
      </div>
    </>
  );
};

export default FAStep03;
