import registrationFormContent from "../../constants/content/registrationForm.json";
import { NOT_INITIALIZED } from "../../constants/authEnums";
import {
  FETCHING_STATUS,
  SET_AUTH_STATE,
  SET_IS_AUTHENTICATION,
  SET_USER_TYPE,
  SET_USER_ID,
  SET_CODE,
  SET_ERROR,
  SET_BRAND,
  SET_BRAND_ID,
  SET_BRAND_COUNT,
  SET_CITY_LIST,
  SET_BRAND_ACCOUNTS,
  SET_CREATE_ACCOUNT,
  SET_PRO_LOGO,
  SET_BRAND_TEXT,
  SET_PERSONAL_TEXT
} from "./appActionType";

const initialState = {
  registrationFormContent: registrationFormContent,
  authState: NOT_INITIALIZED,
  isFetching: false,
  isLoggingIn: false,
  isAuthenticated: false,
  userType: "",
  activeUserId: "",
  user: {},
  code: {},
  error : "",
  brand : {},
  brandId : "",
  brandCount : 0,
  brandAccounts: [],
  cityList: {},
  isPro: false,
  isCreateAccount: true,
  brandText: false,
  personalText: false
};

const AppReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCHING_STATUS:
      return {
        ...state,
        isFetching: action.isFetching,
      };

    case SET_AUTH_STATE:
      return {
        ...state,
        authState: action.authState,
      };

    case SET_IS_AUTHENTICATION:
      return {
        ...state,
        isAuthenticated: action.state,
      };

    case SET_USER_ID:
      return {
        ...state,
        activeUserId: action.id,
      };

    case SET_USER_TYPE:
      return {
        ...state,
        user: action.user,
      };
    case SET_CODE:
      return {
        ...state,
        code: action.code,
      };
    case SET_ERROR:
      return {
        ...state,
        error: action.error,
      };
    case SET_BRAND:
      return {
        ...state,
        brand: action.brand,
      }
    case SET_BRAND_ID:
      return {
        ...state,
        brandId: action.brandId
      }
      case SET_BRAND_COUNT:
        return {
          ...state,
          brandCount: action.brandCount
        }
        case SET_BRAND_ACCOUNTS:
        return {
          ...state,
          brandAccounts: action.brandAccounts
        }
        case SET_PRO_LOGO:
          return {
            ...state,
            isPro: action.isPro
          }
        case SET_CREATE_ACCOUNT:
          return {
            ...state,
            isCreateAccount: action.isCreateAccount
          }
        case SET_BRAND_TEXT:
          return {
            ...state,
            brandText: action.brandText
          }
        case SET_PERSONAL_TEXT:
            return {
              ...state,
              personalText: action.personalText
            }
    default:
      return state;
  }
};

export default AppReducer;
