import React, { useEffect, useState } from "react";
import "./userconsole.scss";
import { studentData } from "../../components/Data/studentData";
import {consoleBackground_1, consoleArrow, consoleDot, rightarrowwhite } from "../../images";
import ProfileCard from "../../components/ProfileCard/ProfileCard";
import ConsoleNoticeCard from "../../components/ConsoleNoticeCard/ConsoleNoticeCard";
import {USER_TYPE_BUSINESS, 
  USER_TYPE_STUDENT, 
  USER_TYPE_TEAM_MEMBER, 
  showBusinessAccess, 
  showIfUserProfileIsComplete,
  getUserProfileAccessRoute, 
  USER_TYPE_DESIGN_ENTHUSIAST
  } from "../../constants/appEnums";
  import {
    businessAccessURL,
    businessFormFiveURL,
    studentAccesssURL,
    teamAccesssURL,
    enthusiastAccesssURL
} from "../../components/helpers/constant-words";
import { Swiper, SwiperSlide } from "swiper/react";
import consoleDataJSON from "../../constants/consoleData.json"
import { Autoplay, Pagination } from "swiper/modules";
import { Link , useNavigate} from "react-router-dom";
import ReachCard from "../../components/ReachCard/ReachCard";
import { useWindowSize } from "react-use";
import FooterV2 from "../../components/FooterV2/FooterV2";
import {useDispatch, useSelector} from "react-redux";
import {getBrandId, getCustomer} from "../../redux/app/appSelectors";
import { setIsPro, setCreateAccount, fetchCustomerProfile } from "../../redux/app/appAction";
import BlinkingDots from "../../Animations/BlinkingDots/BlinkingDots";

const UserConsole = () => {
  const { width } = useWindowSize();
  const [timer, setTimer] = useState(0);
  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const deadline = "July, 01, 2024 00:00:00";

  const getTime = () => {
    const time = Date.parse(deadline) - Date.now();

    setDays(Math.floor(time / (1000 * 60 * 60 * 24)));
    setHours(Math.floor((time / (1000 * 60 * 60)) % 24));
    setMinutes(Math.floor((time / 1000 / 60) % 60));
    setSeconds(Math.floor((time / 1000) % 60));
  };

  const navigate = useNavigate()
  const dispatch = useDispatch();
  const brandId = useSelector((state) => getBrandId(state))
  const customer = useSelector(state => getCustomer(state));
  const [colorr, setColorr] = useState("")
  useEffect(() => {
  if("student" === customer?.user_type){
    setColorr("#ff4a68") 
  
  }else if("business" === customer?.user_type){
    setColorr("#ed008c") 
    
  }else if ("team-member" === customer?.user_type){
    setColorr("#12CC50") 
    
  }else if ("design-enthusiast" === customer?.user_type){
    setColorr("#014fe0")
  }
},[customer?.user_type])

useEffect(() => {
  if(brandId){
    dispatch(fetchCustomerProfile(brandId,""))
  }
},[brandId])

  useEffect(() => {
      if(customer?.profile_completion  !== 0){
        dispatch(setIsPro(true))
      }
  },[customer])
  
  // useEffect(() => {
  //   if(brandId){
  //     dispatch(fetchCustomerProfile(brandId,""))
  //     }
  // },[brandId])

  // const handleUserAccess = (customer) => {
  //     if (customer?.user_type === USER_TYPE_STUDENT) {
  //         navigate(studentAccesssURL)

  //     } else if (customer?.user_type === USER_TYPE_TEAM_MEMBER) {
  //         navigate(teamAccesssURL)

  //     } else if (customer?.user_type === USER_TYPE_DESIGN_ENTHUSIAST){

  //     } else if (customer?.user_type === USER_TYPE_BUSINESS){
  //         navigate(businessAccessURL)
  //     }
  // }
 
  const getUserProfileAccessRoute = (customer) => {
    //debugger;
    let url = "";
    switch (customer.user_type) {
        case  USER_TYPE_BUSINESS:
        
          dispatch(setIsPro(true))
            url = businessAccessURL;
            break;
        case  USER_TYPE_TEAM_MEMBER:
          dispatch(setIsPro(true))
            url = teamAccesssURL;
            break;

        case USER_TYPE_STUDENT:
          dispatch(setIsPro(true))
            url = studentAccesssURL;
            break;
        case USER_TYPE_DESIGN_ENTHUSIAST:
          dispatch(setIsPro(true))
            url = enthusiastAccesssURL;
            break;
        default:
    }
    navigate(url);
};

  // const handleBusinessForm = () => {
  //   //debugger;
  //     //navigate(businessFormFiveURL)
  //     if(brandId){
     
  //     }
  //     dispatch(setCreateAccount(false))
      
      
  // }
 

  useEffect(() => {
    const interval = setInterval(() => getTime(deadline), 1000);

    return () => clearInterval(interval);
  }, []);
  return (
    <>
     <BlinkingDots />
      <main className="main_container">
        <section className="studentconsole_sec1">
        
            <div>
              <div className="my_container">
                {/* <img
                  src={consoleBackground_1}
                  alt="background"
                  className="console_background"
                />
                <img src={consoleDot} alt="dot" className="console_dot1" /> */}
                {/* <img src={consoleDot} alt="dot" className="console_dot2" /> */}

                <div className="row console_row" >
                  <div className="col-lg-6 console_col">
                    <ProfileCard
                     name={customer?.contact_name}
                     code={customer?.country_code}
                     title={customer?.user_type}
                     contactNo={customer?.contact_phone}
                     emailId={customer?.contact_email}
                     city={customer?.city}
                     country={customer?.country}
                    />
                  </div>
                  <div className="col-lg-6 console_col">
                    <Swiper
                      spaceBetween={20}
                      pagination={true}
                      speed={500}
                      modules={[Pagination, Autoplay]}
                      autoplay={{
                        delay: 3000,
                        disableOnInteraction: false,
                      }}
                    >
                      <SwiperSlide>
                        <ConsoleNoticeCard
                         title={consoleDataJSON.USER_CONSOLE_HEADING}
                         notice={consoleDataJSON.USER_CONSOLE_DESCRIPTION}
                        />
                      </SwiperSlide>
                      <SwiperSlide>
                        <ConsoleNoticeCard
                          title={consoleDataJSON.USER_CONSOLE_HEADING}
                          notice={consoleDataJSON.USER_CONSOLE_DESCRIPTION}
                        />
                      </SwiperSlide>
                      <SwiperSlide>
                        <ConsoleNoticeCard
                         title={consoleDataJSON.USER_CONSOLE_HEADING}
                         notice={consoleDataJSON.USER_CONSOLE_DESCRIPTION}
                        />
                      </SwiperSlide>
                      <SwiperSlide>
                        <ConsoleNoticeCard
                         title={consoleDataJSON.USER_CONSOLE_HEADING}
                         notice={consoleDataJSON.USER_CONSOLE_DESCRIPTION}
                        />
                      </SwiperSlide>
                    </Swiper>
                  </div>
                </div>
              </div>
            </div>
        
        </section>

        {/* <section className="console_reach_sec">
          <div className="my_container">
            <div className="row">
              <div className="col-lg-8 p-0">
                <ReachCard
                  ctaText="Onboard Your Business | Service"
                  noticeText="You will be required to upload product catalogues, company
              profile, logo"
                />
              </div>
              <div className="col-lg-4"></div>
            </div>
          </div>
        </section> */}

   <section
          className="studentconsole_sec2"
          // style={{ display: "none" }}
        >
          <img src={consoleDot} alt="dot" className="console_dot2" />
          <div className="my_container">
            <div className="row intro_row">
            <div className="row intro_row">
              <div className="sub_content_wrapper col-lg-7">
                <h2 className="heading">
                  Welcome to the Archinza Design Network!
                </h2>
                <div className="intro_wrapper">
                  <p className="desc">For The Design & Build Community</p>
                </div>
              </div>
              <div className="sub_content_wrapper col-lg-5">
              <div className="button_container">
              <Link 
                      //onClick={handleBusinessForm} 
                      to={businessFormFiveURL} 
                      className="cta_wrapper brand_cta" 
                      style={{boxShadow: `0px 0px 20px 0px ${colorr}`,border:`1px solid ${colorr}`}}
                    >
                      <p className="cta_text">
                        Create Business Account
                        {width <= 600 && (
                          <img
                            src={rightarrowwhite}
                            alt="arrow"
                            className="arrow_img"
                          />
                        )}
                      </p>
                      {width > 600 && (
                        <img
                          src={rightarrowwhite}
                          alt="arrow"
                          className="arrow_img"
                        />
                      )}
                    </Link>
              </div>
              </div>
            </div>
              <div className="row intro_col">
              {customer?.profile_completion === 0 ? <div className="sub_content_wrapper  col-lg-7">
                  <h2 className="subheading">Congratulations!</h2>
                  <p className="subdesc">
                    <span className="orange_text">
                      You are eligible for free early access to{" "}
                    </span>{" "}
                    Archinza PRO!
                  </p>
                </div> :null}
                {customer?.profile_completion !== 100 && customer?.profile_completion !== 0 ? <div className="sub_content_wrapper  col-lg-7">
              <h2 className="subheading comingsoon_heading">
                    Complete Soon!
                  </h2>
                  <p className="subdesc">
                    <span className="orange_text">
                      Complete claiming your Free Access to{" "}
                    </span>
                    <span className="bold_text">Archinza PRO!</span>
                  </p>
                </div> : null}
                {customer?.profile_completion  === 100 ?   <div className="sub_content_wrapper  col-lg-7">
                  <h2 className="subheading comingsoon_heading">
                    Coming Soon!
                  </h2>
                  <p className="subdesc">
                    <span className="orange_text">
                      Stay Tuned! We’ll notify you when we are live with{" "}
                    </span>
                    <span className="bold_text">Archinza PRO!</span>
                  </p>
                </div> : null}

                <div className="col-lg-5 intro_col">
                  <div className="button_container">
                    {/* <Link to={() => false} className="cta_wrapper">
                      <span className="cta_text">
                        Complete Claiming Free Access To ARCHINZA PRO Now
                      </span>
                      <img
                        src={consoleArrow}
                        alt="arrow"
                        className="arrow_img"
                      />
                    </Link> */}
                    {/* <Link 
                      //onClick={handleBusinessForm} 
                      to={businessFormFiveURL} 
                      className="cta_wrapper brand_cta" 
                      style={{boxShadow: `0px 0px 20px 0px ${colorr}`,border:`1px solid ${colorr}`}}
                    >
                      <p className="cta_text">
                        Create Business Account
                        {width <= 600 && (
                          <img
                            src={rightarrowwhite}
                            alt="arrow"
                            className="arrow_img"
                          />
                        )}
                      </p>
                      {width > 600 && (
                        <img
                          src={rightarrowwhite}
                          alt="arrow"
                          className="arrow_img"
                        />
                      )}
                    </Link> */}
                    {customer?.profile_completion  === 0 ? 
                      <div
                      style={{cursor:"pointer"}}
                        className="cta_wrapper"
                         onClick={() => getUserProfileAccessRoute(customer)}
                      >
                        <p className="cta_text">
                          Claim Access Now
                          {width < 600 && (
                            <img
                              src={rightarrowwhite}
                              alt="arrow"
                              className="arrow_img"
                            />
                          )}
                        </p>
                        {width > 600 && (
                          <img
                            src={rightarrowwhite}
                            alt="arrow"
                            className="arrow_img"
                          />
                        )}
                      </div> : null}
                    {customer?.profile_completion !== 100 && customer?.profile_completion !== 0 ?
                      <div
                        style={{cursor:"pointer"}}
                        className="cta_wrapper"
                        onClick={() => getUserProfileAccessRoute(customer)}
                      >
                        <p className="cta_text">
                          Complete Now
                          {width < 600 && (
                            <img
                              src={rightarrowwhite}
                              alt="arrow"
                              className="arrow_img"
                            />
                          )}
                        </p>
                        {width > 600 && (
                          <img
                            src={rightarrowwhite}
                            alt="arrow"
                            className="arrow_img"
                          />
                        )}
                      </div>: null
                    }
                    {customer?.profile_completion  === 100 ?  <div className="clock_wrapper">
                        <div className="clock_content">
                          <p className="clock_number">
                            {days >= 0 ? (days < 10 ? "0" + days : days) : "00"}
                          </p>
                          <p className="clock_text">Days</p>
                        </div>
                        <div className="clock_dots">:</div>
                        <div className="clock_content">
                          <p className="clock_number">
                            {hours >= 0
                              ? hours < 10
                                ? "0" + hours
                                : hours
                              : "00"}
                          </p>
                          <p className="clock_text">Hours</p>
                        </div>
                        <div className="clock_dots">:</div>
                        <div className="clock_content">
                          <p className="clock_number">
                            {minutes >= 0
                              ? minutes < 10
                                ? "0" + minutes
                                : minutes
                              : "00"}
                          </p>
                          <p className="clock_text">Minutes</p>
                        </div>
                        <div className="clock_dots">:</div>
                        <div className="clock_content">
                          <p className="clock_number">
                            {seconds >= 0
                              ? seconds < 10
                                ? "0" + seconds
                                : seconds
                              : "00"}
                          </p>
                          <p className="clock_text">Seconds</p>
                        </div>
                      </div>
                    : null}
                  </div>
                  <div className="next_button_container">
                    {/* <Link to={() => false} className="cta_wrapper">
                      <img
                        src={consoleArrow}
                        alt="arrow"
                        className="arrow_img"
                      />
                      <p className="cta_text">start</p>
                    </Link>
                    <button className="remind_btn">Remind me later</button> */}
                    {/* <ConsolePopup /> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="mobile_notice">
              {studentData.map((item, i) => (
                <Swiper
                  spaceBetween={20}
                  pagination={true}
                  speed={500}
                  modules={[Pagination, Autoplay]}
                  autoplay={{
                    delay: 3000,
                    disableOnInteraction: false,
                  }}
                >
                  <SwiperSlide>
                    <ConsoleNoticeCard
                      title={item.heading}
                      notice={item.desc}
                    />
                  </SwiperSlide>
                  <SwiperSlide>
                    <ConsoleNoticeCard
                      title={item.heading}
                      notice={item.desc}
                    />
                  </SwiperSlide>
                  <SwiperSlide>
                    <ConsoleNoticeCard
                      title={item.heading}
                      notice={item.desc}
                    />
                  </SwiperSlide>
                  <SwiperSlide>
                    <ConsoleNoticeCard
                      title={item.heading}
                      notice={item.desc}
                    />
                  </SwiperSlide>
                </Swiper>
              ))}
            </div>
          </div>
        </section>
                  {/* : 
                  <section
          className="studentconsole_sec2"
          // style={{ display: "none" }}
        >
          <img src={consoleDot} alt="dot" className="console_dot2" />
          <div className="my_container">
            <div className="row intro_row">
              <div className="col-lg-12 intro_col">
                <h2 className="heading">
                  Welcome to the Archinza Design Network!
                </h2>
                <div className="intro_wrapper">
                  <p className="desc">For The Design & Build Community</p>
                </div>
              </div>
              </div>
              <div className="mobile_notice">
              {studentData.map((item, i) => (
                <Swiper
                  spaceBetween={20}
                  pagination={true}
                  speed={500}
                  modules={[Pagination, Autoplay]}
                  autoplay={{
                    delay: 3000,
                    disableOnInteraction: false,
                  }}
                >
                  <SwiperSlide>
                    <ConsoleNoticeCard
                      title={item.heading}
                      notice={item.desc}
                    />
                  </SwiperSlide>
                  <SwiperSlide>
                    <ConsoleNoticeCard
                      title={item.heading}
                      notice={item.desc}
                    />
                  </SwiperSlide>
                  <SwiperSlide>
                    <ConsoleNoticeCard
                      title={item.heading}
                      notice={item.desc}
                    />
                  </SwiperSlide>
                  <SwiperSlide>
                    <ConsoleNoticeCard
                      title={item.heading}
                      notice={item.desc}
                    />
                  </SwiperSlide>
                </Swiper>
              ))}
            </div>
              </div>
            </section> */}
           <FooterV2 />        
      </main>
     
    </>
  );
};

export default UserConsole;
