export const FETCHING_STATUS = "FETCHING_STATUS";

export const SET_IS_AUTHENTICATION = "SET_IS_AUTHENTICATION";
export const SET_AUTH_STATE = "SET_AUTH_STATE";

export const SET_USER_ID = "SET_USER_ID";
export const SET_USER_TYPE = "SET_USER_TYPE";
export const SET_CODE = "SET_CODE";
export const SET_ERROR = "SET_ERROR";

export const SET_BRAND = "SET_BRAND";
export const SET_BRAND_ID = "SET_BRAND_ID";
export const SET_BRAND_COUNT = "SET_BRAND_COUNT";
export const SET_CITY_LIST = "SET_CITY_LIST";

export const SET_BRAND_ACCOUNTS = "SET_BRAND_ACCOUNTS";
export const SET_PRO_LOGO = "SET_PRO_LOGO";
export const SET_CREATE_ACCOUNT = "SET_CREATE_ACCOUNT";
export const SET_BRAND_TEXT = "SET_BRAND_TEXT";
export const SET_PERSONAL_TEXT = "SET_PERSONAL_TEXT";
