import React from "react";
import "./header.scss";
import { logo, mbLogo } from "../../images";
import { useWindowSize } from "react-use";
import { Link } from "react-router-dom";
import { homepageURL } from "../helpers/constant-words";

const Header = () => {
  const { width } = useWindowSize();
  return (
    <>
      <section className="header_sec1">
        <Link to={homepageURL}>
          <img
            src={width > 767 ? logo : mbLogo}
            alt="logo_img"
            className="header_logo"
          />
        </Link>
      </section>
    </>
  );
};

export default Header;


