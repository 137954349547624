import React, {useEffect, useState} from "react";

import "./changerole.scss";
import {consoleDot, regBackground, rightarrowwhite} from "../../images";
import RadioButton from "../../components/RadioButton/RadioButton";
import file from "../../constants/changeRole.json";
import BlinkingDots from "../../Animations/BlinkingDots/BlinkingDots";
//import { updateCustomerProfile } from "../../redux/app/appAction";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
//import { getCustomer } from "../../redux/app/appSelectors";
import {DIFFERENT_USER_TYPES} from "../../constants/appEnums";
import {
  businessAccessURL,
  consoleUrl,
  studentAccesssURL,
  teamAccesssURL
} from "../../components/helpers/constant-words";
import {fetchCustomerProfile, updateCustomerProfile, updateCustomerRole} from "../../redux/app/appAction";
import {getCustomer, getUserId} from "../../redux/app/appSelectors"
import { toast } from "react-toastify";
import { ErrorsMsg } from "../../util/Message";
import FooterV2 from "../../components/FooterV2/FooterV2";

const ChangeRole = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const customer = useSelector((state) => getCustomer(state))
    const userId = useSelector((state) => getUserId(state));
    const [student, setStudent] = useState(false)
    const [teamMember, setTeamMember] = useState(false)
    const [business, setBusiness] = useState(false)
    const [designEnthusiast, setDesignEnthusiast] = useState(false)

    const [user, setUser] = useState({
        user_type: ""
    })
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    useEffect(() => {
        if(customer?.user_type === DIFFERENT_USER_TYPES[0].label){
            setBusiness(true)
        }else if(customer?.user_type === DIFFERENT_USER_TYPES[1].label){
            setStudent(true)
        }else if(customer?.user_type === DIFFERENT_USER_TYPES[2].label){
            setTeamMember(true)
        }else if(customer?.user_type === DIFFERENT_USER_TYPES[3].label){
            setDesignEnthusiast(true)
        }
    },[customer])

    const handleChangeUser = (keyName, keyValue) => {
        let update = {...user}
        if(keyValue === DIFFERENT_USER_TYPES[0].label ){
            setBusiness(true)
            setStudent(false)
            setTeamMember(false)
            setDesignEnthusiast(false)
            update[keyName] = String(keyValue.toLowerCase());
        }else if(keyValue === DIFFERENT_USER_TYPES[1].label ){
            setBusiness(false)
            setStudent(true)
            setTeamMember(false)
            setDesignEnthusiast(false)
            update[keyName] = String(keyValue.toLowerCase());
        }else if(keyValue === DIFFERENT_USER_TYPES[2].label){
            setBusiness(false)
            setStudent(false)
            setTeamMember(true)
            setDesignEnthusiast(false)
            update[keyName] = String(keyValue.toLowerCase());
        }else if(keyValue === DIFFERENT_USER_TYPES[3].label){
            setBusiness(false)
            setStudent(false)
            setTeamMember(false)
            setDesignEnthusiast(true)
            update[keyName] = String(keyValue.toLowerCase());
        }

        setUser(update);
    }

    const handleUserAccess = () => {
        let update ={}
        if(userId){
            // if(customer?.user_type === DIFFERENT_USER_TYPES[0].label){

            //     update.user_type = DIFFERENT_USER_TYPES[0].label
            // dispatch(updateCustomerRole(userId, update))

            // navigate(consoleUrl)
            // }else{
                if(user?.user_type === ""){
                    //update.user_type = customer?.user_type
                    toast(ErrorsMsg("Please select new role"),{
                        className: "mail_toast",
                        position: toast.POSITION.TOP_CENTER,
                        hideProgressBar: true,
                        closeButton: false,
                      })
                    // dispatch(updateCustomerProfile(userId, update))
                    // navigate(consoleUrl)
                }else{
                    dispatch(updateCustomerRole(userId, user))
                    navigate(consoleUrl)
                }

           // }
        }
    }

    const goBack = ()=> {
        navigate(-1);
    }

    return (
        <>
            {/* <section className="change_sec1">
                <div className="my_container">
                    <img
                        src={regBackground}
                        alt="background"
                        className="console_background"
                    />
                    <img src={consoleDot} alt="dot" className="console_dot1"/>
                    <img src={consoleDot} alt="dot" className="console_dot2"/>
                </div>
            </section> */}
             <BlinkingDots />
      <section className="change_sec1"></section>
            <section className="change_sec2">
                <div className="my_container">
                    <div className="text-container">
                        <h1 className="title">{file.change_role_title}</h1>
                        <p className="desc">{file.change_role_desc}</p>
                        <h2 className="sub_heading">
                            {file.sub_heading}
                        </h2>
                        <p className="choose_text">{file.choose_text}</p>
                    </div>
                    <ul className="radio_container">
                        <RadioButton

                            label={DIFFERENT_USER_TYPES[0].display_name}
                            labelId={`bus${DIFFERENT_USER_TYPES[0].label}`}
                            value={DIFFERENT_USER_TYPES[0].label}
                            isPro={true}
                            checked={business}
                            handleChangeUser={handleChangeUser}
                        />
                        {/* const ref = useRef(null); */}

                        <RadioButton label={DIFFERENT_USER_TYPES[1].display_name}
                                     labelId={`stu${DIFFERENT_USER_TYPES[1].label}`} isPro={true}
                                     value={DIFFERENT_USER_TYPES[1].label}
                                     checked={student}
                                     handleChangeUser={handleChangeUser}/>
                        <RadioButton
                            label={DIFFERENT_USER_TYPES[2].display_name}
                            labelId={`team${DIFFERENT_USER_TYPES[2].label}`}
                            value={DIFFERENT_USER_TYPES[2].label}
                            checked={teamMember}
                            isPro={true}
                            handleChangeUser={handleChangeUser}
                        />
                        <RadioButton
                            label={DIFFERENT_USER_TYPES[3].display_name}
                            labelId={`des${DIFFERENT_USER_TYPES[3].label}`}
                            value={DIFFERENT_USER_TYPES[3].label}
                            checked={designEnthusiast}
                            isPro={true}
                            handleChangeUser={handleChangeUser}
                        />
                    </ul>
                    <div className="next_logout">
                        <div className="cta_wrapper">
                            <div className="next_button" onClick={handleUserAccess}>
                                <div className="text" >{file.change_role}</div>
                                <img
                                    src={rightarrowwhite}
                                    alt="icon"
                                    className="icon"
                                    loading="lazy"
                                />
                            </div>
                            <div onClick={() => goBack()} className="back_button">{file.back_btn}</div>
                        </div>
                    </div>
                </div>
               
            </section>
            <FooterV2 />
        </>
    );
};

export default ChangeRole;
