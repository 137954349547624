import React from "react";
import { useState } from "react";
import {Link, NavLink} from "react-router-dom";
import { hamburgerLinkArrow, prologo } from "../../images";
import "./headerv2.scss";
import {useWindowSize} from "react-use";
import {facebook, 
    fbHeader,
    blacklogo,
    fbHeaderB,
    instaHeaderB, 
    insta, 
    instaHeader,
    linkedinHeaderB, 
    linkedIn, 
    linkedinHeader,
    prologoblack, 
    rightarrowblack,
    loginicon, 
    whitelogo,
} from "../../images";
import {
    consoleUrl,
    BlogsListingURL,
    contactUsURL,
    facebookURL,
    homepageURL,
    instagramURL,
    linkedinURL,
    loginURL,
    registrationFormURL,
    editProfile,
} from "../helpers/constant-words";
import HeaderNavItemV2 from "./HeaderNavItemV2";
import GlowCta from "../GlowCta/GlowCta";
import {getUserProfileAccessRoute, showIfUserProfileIsComplete} from "../../constants/appEnums";
import { logoutCustomer } from "../../redux/app/appAction";

const navLinksArr = [
    {
        id: 1,
        type: "img",
        img: whitelogo,
        mainLink: "/",
        class: "logo_img",
    },
    {
        id: 2,
        type: "text",
        mainTitle: "CONSOLE",
        mainLink: homepageURL,
        linkVariant: "console",
        class: "hide_console",
    },
    {
        id: 2,
        type: "text",
        mainTitle: "KNOW",
        mainLink: homepageURL,
    },
    {
        id: 3,
        type: "text",
        mainTitle: "CONNECT",
        mainLink: contactUsURL,
    },
    {
        id: 4,
        type: "social",
        links: [
            {img: fbHeader, url: facebookURL},
            {img: instaHeader, url: instagramURL},
            {img: linkedinHeader, url: linkedinURL},
        ],
    },
    // {
    //   id: 5,
    //   type: "search",
    //   img: headerSearch,
    //   // mainLink: "/",
    //   class: "search_icon",
    // },
    {
        id: 6,
        type: "form",
        userStates: [
            {
                title: "Get Early Access",
                slug: registrationFormURL,
            },
            //   {
            //     title: "Apply Now",
            //     slug: homepageURL,
            //   },
            //   {
            //     title: "Continue Pro Access",
            //     slug: homepageURL,
            //   },
            //   {
            //     title: "Continue the Quiz",
            //     slug: homepageURL,
            //   },
        ],
    },
];

const usersArr = [
    {
        categoryId: 0,
        category: "student",
        background: "#FF4A68",
        users: [
            {
                name: "Habib",
                age: "",
            },
        ],
    },
    {
        categoryId: 1,
        category: "team-member",
        background: "#12CC50",
        users: [
            {
                name: "Talha",
                age: "",
            },
        ],
    },
    {
        categoryId: 2,
        category: "design-enthusiast",
        background: "#014FE0",
        users: [
            {
                name: "Ayushi",
                age: "",
            },
        ],
    },
    {
        categoryId: 3,
        category: "business-firm-owner",
        background: "#CC9921",
        users: [
            {
                name: "Elon",
                age: "",
            },
        ],
    },
];

const mobNavLinksArr = [
    {
        id: 1,
        type: "text",
        mainTitle: "KNOW",
        mainLink: homepageURL,
    },
    {
        id: 2,
        type: "text",
        mainTitle: "CONNECT",
        mainLink: contactUsURL,
        // typeDropdown: "dropdown",
    },
    {
        id: 3,
        type: "text",
        mainTitle: "BLOG",
        mainLink:  BlogsListingURL,
        // typeDropdown: "dropdown",
    },
    {
        id: 4,
        type: "text",
        mainTitle: "LOGIN",
        mainLink: loginURL,
        typeLogin: "Login",
    },
];

const MobileHeader = ({
                          isBg,
                          colorr,
                          getLoggedInState,
                          userStatusHandler,
                          customer,
                          createAccount,
                          logOut,
                          isMenuActive,
                          handleHamburgerClick,
                          handleNavIndex,
                          navActiveIndex
                      }) => {
    const {width} = useWindowSize();
    const [dropdownActive, setDropdownActive] = useState(false);
    // const logOut = () => {
    //     dispatch(logoutCustomer());
    // }

    console.log("MobileHeader", customer, getLoggedInState(customer));

    return (
        <header className="header">
            <div className="my_container">
                <div className="login_container">
                    <div className="logo_wrapper">
                        <Link className="logo_link" to="/">
                            <img
                                src={`${isBg ? createAccount === false? prologoblack
                                    :prologo :createAccount === false? blacklogo: whitelogo}`}
                                alt="atlas logo"
                                className="atlas_logo"
                            />
                        </Link>
                    </div>
                    <div>
                        {getLoggedInState(customer) === true && (
                            <div
                                className="nav_tab user_tab"
                                onClick={userStatusHandler}
                                onMouseOver={() => setDropdownActive(true)}
                                onMouseOut={() => setDropdownActive(false)}
                            >
                                <Link className="user" to={() => false}>
                                    {/* <div className="title">
                            {usersArr[userCategory].users[0].name}
                          </div> */}
                                    <div
                                        className="initial_circle"
                                        style={{
                                            background: colorr,
                                        }}
                                    >
                                        <div className="name">
                                            {/* {usersArr[userCategory].users[0].name} */}
                                            {customer?.contact_name?.charAt(0)}
                                        </div>
                                    </div>
                                    {/* <div className="dropdown_arrow_wrap">
                            <img
                              width={9}
                              src={dropdownarrow}
                              alt="dropdown arrow"
                              className="dropdown_arrow"
                            />
                          </div> */}
                                </Link>
                                {dropdownActive === true && (
                          <div
                            className="dropdown_box"
                            onMouseOver={() => setDropdownActive(true)}
                            onMouseOut={() => setDropdownActive(false)}
                          >
                             <Link className="accounts" to={consoleUrl}>
                                                    <div className="title">My Console</div>
                                                </Link>
                            <Link className="accounts" to={editProfile}>
                              <div className="title">My Accounts</div>
                            </Link>
                            <Link className="accounts" to={() => false} onClick={logOut}>
                                                    <div className="title">Logout</div>
                              {/* <div>
                                <img
                                  width={30}
                                  height={30}
                                  src={loginicon}
                                  alt="login icon"
                                  className="login_icon"
                                />
                              </div> */}
                            </Link>
                          </div>
                        )}
                            </div>
                        )}
                        {getLoggedInState(customer) === false ? (
                                <div
                                    className="nav_tab login_tab"
                                >
                                    <Link className="login" to={loginURL}>
                                        {/* <div className="title">Login</div> */}
                                        <div>
                                            <img
                                                width={30}
                                                height={30}
                                                src={loginicon}
                                                alt="login icon"
                                                className="login_icon"
                                            />
                                        </div>
                                    </Link>
                                </div>
                            )
                            :
                            (
                                null
                            )
                            // (
                            //     <Link className="accounts" to={() => false} onClick={logOut}>
                            //         <div className="title">Logout</div>
                            //     </Link>
                            // )
                            }
                    </div>
                </div>
            </div>
            <nav className="nav_links_wrapper">
                <div
                    className={`nav_line2_wrapper_flex_wrapper ${
                        isMenuActive ? "active" : ""
                    }`}
                >
                    <div className="blank"></div>
                    <div className="logo_wrapper">
                        <Link className="logo_link" to="/">
                            <img
                                src={`${isBg ? prologo : whitelogo}`}
                                alt="atlas logo"
                                className="atlas_logo"
                            />
                        </Link>
                    </div>
                    <ul className="nav_line2_wrapper_flex">
                        {mobNavLinksArr.map((navData, i) => {
                            if(getLoggedInState(customer) === true && navData?.mainTitle === "LOGIN"){
                                return    <li className="nav_item"><NavLink
                                className={({ isActive }) =>
                                  isActive ? "nav_link active" : "nav_link"
                                }
                                onClick={logoutCustomer()}
                              >
                                {"Logout"}
                               {/* { navData.typeLogin !== "Login" ? ( */}
                                 <img
                                  src={hamburgerLinkArrow}
                                  alt="arrow"
                                  loading="lazy"
                                  className="active_arrow"
                                />
                        
                               </NavLink>
                                
                            </li>
                        


                            }else{
                               return <HeaderNavItemV2
                                navData={navData}
                                key={parseInt(navData.id)}
                                getLoggedInState={getLoggedInState}
                                customer={customer}
                                handleHamburgerClick={handleHamburgerClick}
                                arrIndex={i}
                                handleNavIndex={handleNavIndex}
                                navActiveIndex={navActiveIndex}
                            />
                            }
                         
                      })}
                        {getLoggedInState(customer) === true ?(
                        
                            <div className="cta_wrapper" onClick={handleHamburgerClick}>
                                
                                    <GlowCta
                                        link={consoleUrl}
                                        text="Console"
                                        
                                    />
                                    </div>
                                  
                                   ) : (<div className="cta_wrapper" onClick={handleHamburgerClick}>
                                    <GlowCta
                                        link={registrationFormURL}
                                        text="Get Early Access"
                                        //onClick={handleHamburgerClick}
                                    />
                              </div>
                                )
                        }
                        <li>
                            {width <= 1023 && (
                                <div className="nav_social_media_icons">
                                    <p className="social_title">FOLLOW US ON:</p>
                                    <a
                                        href="https://www.facebook.com/profile.php?id=100091559990889&mibextid=LQQJ4d"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <img
                                            src={facebook}
                                            alt="facebook"
                                            className="nav_social_icons"
                                        />
                                    </a>
                                    <a
                                        href="https://instagram.com/archin.za?igshid=MzRlODBiNWFlZA"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <img
                                            src={insta}
                                            alt="instagram"
                                            className="nav_social_icons"
                                        />
                                    </a>
                                    <a
                                        href="https://www.linkedin.com/company/92807055/admin/feed/posts/"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <img
                                            src={linkedIn}
                                            alt="linkedIn"
                                            className="nav_social_icons"
                                        />
                                    </a>
                                </div>
                            )}
                        </li>
                    </ul>
                </div>
            </nav>

            {/* mobile UI start */}
            <div
                className={`hamburger_lines ${
                    isMenuActive ? "active" : "" 
                }`}
                onClick={handleHamburgerClick}
            >
                <div className="line line1"></div>
                <div className="line line2"></div>
                <div className="line line3"></div>
            </div>
        </header>
    );
};

export default MobileHeader;


