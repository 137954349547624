import React, { useEffect, useState } from "react";
import style from "../EnthusiastAccess/enthusiast.module.scss";
import LogoutText from "../../../components/LogoutText/LogoutText";
import { ThemeProvider, createTheme, keyframes } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { Input } from "@mui/material";
import { plusicon, rightarrowwhite } from "../../../images";
import RadioButton from "../../../components/RadioButton/RadioButton";
import { useDispatch, useSelector } from "react-redux";
import { updateCustomerProfile } from "../../../redux/app/appAction";
import { getUserId, getCustomer } from "../../../redux/app/appSelectors";
import callApi from "../../../util/apiCaller";

// const yearsArr = [
//   "2023",
//   "2024",
//   "2025",
//   "2026",
//   "2027",
//   "2028",
//   "2029",
//   "2030",
//   "2031",
// ];

const EnthuStep02 = ({
  previousStep,
  nextStep,
  currentStep,
  totalSteps,
  progressStatus,
  handleUserExtraData,
  userExtraData
}) => {
  const dispatch = useDispatch();
  const userId = useSelector((state) => getUserId(state)) ;
  const customer = useSelector((state) => getCustomer(state));
  const [year, setYear] = useState("")
  const [firstName, setFirstName] = useState("")
  const [code ,setCode] = useState({})
  const [showError, setShowError] = useState(true)
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if(customer?.contact_name){
      if(customer?.year_of_graduation){
        setYear({year_of_graduation: customer?.year_of_graduation})
      }
    let str = customer?.contact_name
    let str1 = ""
    for(let i=0; i<str.length; i++){
      if(str[i] === " "){
        break;
      }else{
        str1 += str[i];
      }
    }
setFirstName(str1)
    }
  },[customer])

  useEffect(() => {
  
    fetchContentCode("student-graduate-year")
   },[])
   async function fetchContentCode(code) {
      
    try {
      const res = await callApi(`content/code/${code}`, "get");
      console.log(res);
      if (res && res?.status === 'Success') {
        const { content } = res?.data;
        setCode(content);
      } else {
      }
    } catch (err) {

    }
 
  } 
  
  const handleChangeUser = (keyName,keyValue) => {
      let update = {...year};
      if(keyValue){
        setErrors({})
      }
      update["year_of_graduation"] = (keyValue)
      update.current_flow = currentStep;
      setYear(update);
      //handleUserExtraData()
  }

  const handleValidation = (user) => {
    let isValid = true;
    let errors = {};
    if (!user?.year_of_graduation) {
        isValid = false;
        errors["year_of_graduation"] = "Please select graduation year";
    }

      return {
        isValid,
        errors
    }
  }

  const handleUpdateApi = () => {
   
    let check = handleValidation(year);
    let update= {...year}
    update.current_flow = currentStep;
    if(check.isValid && userId){
      dispatch(updateCustomerProfile(userId, update))
      //handleUserExtraData(year);
      nextStep(3);
      setErrors({})
      window.scrollTo(0, 0);
    }else {
      setErrors(check.errors);
    }
   
   }
   const renderError = (keyName) => {
    return errors && errors[keyName] ? <p className="error">{errors[keyName]}</p> : null;
  };

  const yearsList = code?.params?.map((option) => (
    <React.Fragment key={`w${option.label}`}>
      <RadioButton extraSpace={true} yearBox={true} label={option.label} value={option.value} labelId={`q2${option.label}`} checked={(option?.label === year?.year_of_graduation) ? true : false} handleChangeUser={handleChangeUser} />
    </React.Fragment>
  ));

  useEffect(() => {
    progressStatus((currentStep / totalSteps) * 100);
  }, [currentStep, progressStatus, totalSteps]);

  return (
    <>
      <div className={style.text_container}>
        <h1 className={style.title}> 
 	
   {firstName}, Please tell us the year you will graduate</h1>
        <p className={style.description}>Choose one</p>
      </div>
      <div className={`${style.steps} ${style.step02}`}>
        <ul className={style.step02_ul}>{yearsList}</ul>
        <div style={{display: "flex",justifyContent:"center",alignItems:"center"}}> {renderError("year_of_graduation")}</div>
      </div>

      <div className={`${style.next_logout} ${style.next_logout_step02}`}>
     
        <div className={style.cta_wrapper}>
          <div
            className={style.next_button}
            onClick={handleUpdateApi}
          >
            <div className={style.text}>Next</div>
            <img
              src={rightarrowwhite}
              alt="icon"
              className={style.icon}
              loading="lazy"
            />
          </div>
          <div
            className={style.back_button}
            onClick={() => {
              previousStep(1);
              setErrors({})
              window.scrollTo(0, 0);
            }}
          >
            Back
          </div>
        </div>
        <LogoutText userExtraData={year}/>
      </div>
    </>
  );
};

export default EnthuStep02;
