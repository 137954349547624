import style from "./autocompletefield.module.scss";
import {Box,Typography} from "@mui/material";
import TextField from "@mui/material/TextField";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import {  useWindowSize } from "react-use";
import { useState, useRef } from "react";
import _ from "lodash";
import { useSelector } from "react-redux";
import {getCreateAccount} from "../../redux/app/appSelectors"
import { State } from "country-state-city";
//import  { createFilterOptions } from '@material-ui/lab/Autocomplete';

const darkTheme = createTheme({
  typography: {
    fontFamily: "Poppins",
  },
  palette: {
    mode: "dark",
  },
});
const lightThemed = createTheme({
  typography: {
    fontFamily: "Poppins",
  },
  palette: {
    mode: "light",
  },
});

const AutoCompleteField = ({ keyName,textLabel,id, data,handleUserDetail,course, ...rest }) => {
  
   const lightTheme =   useSelector((state) => getCreateAccount(state));
let allData = _.uniq(data)
const filterOptions = createFilterOptions({
  matchFrom: 'start',
  stringify: (option) => option.toLowerCase(),
});

      // let serchData = data?.filter((item) => !item?.indexOf(dataSearch))
 
  const { width } = useWindowSize();
  return (
    <>
      {lightTheme === false ? (
        <ThemeProvider theme={lightThemed}>
          <CssBaseline />
          <Autocomplete
            className={style.autocomplete_dropdown_light}
            id={id}
            options={allData}
            autoHighlight
            filterOptions={filterOptions}
            //disableClearable
            getOptionLabel={(option) => option}
            onChange={(e,newValue) =>  {
          
              handleUserDetail(keyName,newValue)} }
              sx={{
              
                "& fieldset": {
                  borderRadius: width > 768 ? "10px" : "10px",
                  border: "1px solid #707070",
                },
                "& .MuiOutlinedInput-root": {
                  borderRadius: width > 768 ? "10px" : "10px",
                },
                "& label": {
                  lineHeight: width > 768 ? "2em" : "1.5em",
                },
                "& label.Mui-focused": {
                  color: "#111",
                },
                "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                  borderRadius: width > 768 ? "10px" : "10px",
                  border: "1px solid #707070",
                },
                "& .MuiOutlinedInput-root .Mui-focused .MuiOutlinedInput-notchedOutline":
                  {
                    border: "1px solid #707070",
                  },
                "&.Mui-focused .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                  {
                    borderColor: "#111",
                  },
                "& .MuiSvgIcon-root ": {
                  fill: "#f77b00 !important",
                },
              }}
            renderOption={(props, option) => (
              <Box className={style.option_list} component="li" {...props}>
                {option}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label={textLabel}
                inputProps={{
                  style: {
                    fontSize: width > 768 ? "1.25em" : "1.25em",
                    // height: "2em",
                  },
                  ...params.inputProps,
                  // autoComplete: "new-password", // disable autocomplete and autofill
                }}
              />
            )}
            {...rest}
          />
        </ThemeProvider>
      ) :(
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <Autocomplete
        className={style.autocomplete_dropdown}
        id={id}
        options={allData}
        autoHighlight
        //disablePortal
      
        //disableClearable
        filterOptions={filterOptions}
        getOptionLabel={(option) => option}
     
        onChange={(e,newValue) =>  {
          
          handleUserDetail(keyName,newValue)} }
        sx={{
          "& fieldset": {
            borderRadius: width > 768 ? "10px" : "10px",
            border: "1px solid $color-707070",
          },
          "& .MuiOutlinedInput-root": {
            borderRadius: width > 768 ? "10px" : "10px",
            border: "1px solid $color-707070",
          },
          "& label": {
            lineHeight: width > 768 ? "2em" : "1.5em",
          },
          "& label.Mui-focused": {
            color: "#fff",
          },
          "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderRadius: width > 768 ? "10px" : "10px",
            border: "1px solid $color-707070",
          },
          "& .MuiOutlinedInput-root .Mui-focused .MuiOutlinedInput-notchedOutline":
            {
              border: "1px solid $color-707070",
            },
          "&.Mui-focused .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
            {
              borderColor: "#fff",
            },
          "& .MuiSvgIcon-root ": {
            fill: "#f77b00 !important",
          },
        }}
        renderOption={(props, option) => (
          <Box className={style.option_list} component="li" {...props}>
            {option}
          </Box>
        )}
        renderInput={(params) => (
         
        
         
           <TextField
            {...params}
            label={textLabel}
        
            inputProps={{
              style: {
                fontSize: width > 768 ? "1.25em" : "1.25em",
                // height: "2em",
              },
              ...params.inputProps,
            
              // autoComplete: "new-password", // disable autocomplete and autofill
            }}
          />
          
          
        
  )}
        {...rest}
      />
    </ThemeProvider>
    )}
    </>
  );
};

export default AutoCompleteField;


