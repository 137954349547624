import {Link, useNavigate} from "react-router-dom";
import style from "./logout.module.scss";
import {useDispatch, useSelector} from "react-redux";
import {updateCustomerArchPro, updateCustomerProfile,setCreateAccount} from "../../redux/app/appAction";
import {getBrandId, getUserId} from "../../redux/app/appSelectors";
import {consoleUrl} from "../helpers/constant-words";
import _ from "lodash";
import { useEffect } from "react";

const LogoutText = ({userExtraData, userData}) => {
 
  const dispatch = useDispatch();
  const userId = useSelector(state => getUserId(state))
  const brandId = useSelector(state => getBrandId(state))
  const authState = useSelector((state) => state?.app?.authState);


  const handleSaveUserdetail =(userId, userExtraData, userData,brandId) => {
    dispatch(setCreateAccount(true))
        if(userId && !_.isEmpty(userExtraData)){
          dispatch(updateCustomerProfile(userId,userExtraData))
        }
        if(!_.isEmpty(userData) && brandId){
          dispatch(updateCustomerArchPro(brandId, userData))
        }
  }
  return (
    <div className={style.notice} onClick={() => handleSaveUserdetail(userId,userExtraData, userData,brandId)}>
      <Link className={style.anchor} onClick={() => {
        window.location = consoleUrl;
      }}>click here</Link> to save & exit
    </div>
  );
};

export default LogoutText;

