import React from "react";
import "./breadcrumb.scss";
import { Link } from "react-router-dom";
import { facebook, insta, linkedIn } from "../../images";

const BreadCrumb = ({
  link,
  link1,
  text1,
  text,
  linkDisabled,
  linkDisabled1,
  title,
  socialIcon,
  facebookLink,
  instaLink,
  linkedInLink,
}) => {
  return (
    <>
      <div className="breadcrumb_wrapper" style={{marginTop:"20px"}}>
        <div>
        {/* <Link to="/" className="breadcrumb_link">
          Home
        </Link>{" "}
        <Link
          to={link}
          className={`breadcrumb_link ${linkDisabled ? "disabled" : ""}`}
        >
          {text ? "| " + text : ""}
        </Link> */}
          <Link to="/" className="breadcrumb_link">
            Home
          </Link>{" "}
          <Link
            to={link}
            className={`breadcrumb_link ${linkDisabled ? "disabled" : ""}`}
          >
            {text ? "| " + text : ""}
          </Link>
          {text1 ? (
            <Link
              to={link1}
              className={`breadcrumb_link ${linkDisabled1 ? "disabled" : ""}`}
            >
              {text1 ? " | " + text1 : ""}
            </Link>
          ) : null}
        </div>
        {/* <h1 className="title">{title}</h1> */}
        <h1 className="title" dangerouslySetInnerHTML={{ __html: title }}></h1>
        {socialIcon ? (
          <div className="social_media_wrapper">
            <a href={facebookLink} target="_blank">
              <img
                src={facebook}
                alt="facebook"
                className="socila_media_icon"
              />
            </a>
            <a href={instaLink} target="_blank">
              <img src={insta} alt="instagram" className="socila_media_icon" />
            </a>
            <a href={linkedInLink} target="_blank">
              <img
                src={linkedIn}
                alt="linkedIn"
                className="socila_media_icon"
              />
            </a>
          </div>
        ) : null}
      </div>
    </>
  );
};

export default BreadCrumb;

