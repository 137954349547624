import React, { useEffect} from "react";
import "./footerv2.scss";
import "./footerv3.scss";
import {
  blacklogo,
  facebook,
  fbHeaderB,
  insta,
  instaHeaderB,
  linkedIn,
  linkedinHeaderB,
  orangeRightArrow,
  prologo,
  prologoblack,
  shareIcon,
  shareIconWhite,
  whitelogo,
} from "../../images";
import {
  BlogsListingURL,
  businessFormFiveURL,
  facebookURL,
  faqsURL,
  instagramURL,
  linkedinURL,
  privacypolicyURL,
  termsandconditionURL,
} from "../helpers/constant-words";
import { Link, useLocation } from "react-router-dom";
import { useWindowSize } from "react-use";
import FloatingIcon from "../FloatingIcon/FloatingIcon";
import {useState} from "react";
import { useDispatch , useSelector} from "react-redux";
import { newsletterUpdate, setError } from "../../redux/app/appAction";
import { getISPro } from "../../redux/app/appSelectors";

const FooterV2 = ({ bgColor = "transparent" }) => {
  const ispro = useSelector((state) => getISPro(state))
  const [isLightTheme, setIsLightTheme] = useState(false);
  const { width } = useWindowSize();
  const location = useLocation();
  const dispatch = useDispatch()
  const current_year = new Date();
  const [emailNewsletter, setEmailNewsletter] = useState("")
  const [errors, setErrors] = useState({});
  const [thankyouMsg,setThankyouMsg] = useState(true)
  // const [emailMobile, setEmailMobile] = useState("")
  const handleValidation =(email) => {
    let isValid = true;
    let errors ={}
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
        isValid = false;
        errors["email"] = "Invalid email";
    }
    return {
      errors,
      isValid
    }
  }
  const handleSubmitEmail = (e) => {
    if (e) {
      e.preventDefault();
  }
    let check = handleValidation(emailNewsletter)
    if(check.isValid){
    dispatch(newsletterUpdate({email: emailNewsletter}))
    setThankyouMsg(false)
    setErrors({})
    }else{
      setErrors(check?.errors);
    }

  }
 

  useEffect(() => {
    if (location.pathname === businessFormFiveURL) {
      setIsLightTheme(true);
    } else {
      setIsLightTheme(false);
    }
  }, [location.pathname]);

  const renderError = (keyName) => {
    return errors && errors[keyName] ? <p style={{position:"relative",display:"flex", justifyContent:"center", margin:"10px 0"}} className="error">{errors[keyName]}</p> : null;
  };

  // const handleSubmitMobile = () => {
  //   dispatch(newsletterUpdate())
  // }

  return (
    <>
     <div className="horizontal_line" />
      <footer
        className={isLightTheme ? "footerv3_sec1" : "footerv2_sec1"}
        style={{ background: bgColor }}
      >
        <div className="my_container">
          <div className="footer_wrapper">
            <div className="row footer_row">
              <div className="col-md-4 footer_col">
                <div
                  className="logo_wrapper"
                  // onClick={() => {
                  //   setIsPro(true);
                  // }}
                >
                  <img
                    src={
                      ispro
                        ? isLightTheme
                          ? prologoblack
                          : prologo
                        : isLightTheme
                        ? blacklogo
                        : whitelogo
                    }
                    alt="logo"
                    className="footer_logo"
                  />
                  {/* <p className="logo_desc">Empowering the design ecosystem.</p> */}
                  <div className="social_wrapper">
                    <img
                      src={isLightTheme ? shareIconWhite : shareIcon}
                      alt="facebook"
                      className="social_icon"
                    />
                    <a href={facebookURL} target="_blank" rel="noreferrer">
                      <img
                        src={isLightTheme ? fbHeaderB : facebook}
                        alt="facebook"
                        className="social_icon"
                      />
                    </a>
                    <a href={instagramURL} target="_blank" rel="noreferrer">
                      <img
                        src={isLightTheme ? instaHeaderB : insta}
                        alt="instagram"
                        className="social_icon"
                      />
                    </a>
                    <a href={linkedinURL} target="_blank" rel="noreferrer">
                      <img
                        src={isLightTheme ? linkedinHeaderB : linkedIn}
                        alt="linkedIn"
                        className="social_icon"
                      />
                    </a>
                  </div>
                </div>
              </div>
              {width > 767 ? (
                <div className="col-md-5 footer_col">
                 <form className="input_wrapper" onSubmit={handleSubmitEmail}>
                    <p className="input_desc">
                      To get regular updates, subscribe to our newsletter!
                    </p>
                    {thankyouMsg === true ? <div className="input_field_wrapper">
                      <input
                        type="text"
                        placeholder="Enter your email"
                        className="input_details"
                        value={emailNewsletter}
                        onChange={(e) =>  {if(e.target.value){
                          setErrors({})
                          }
                          setEmailNewsletter(e.target.value)}}
                      />
                      <p className="error"></p>
                      <button className="submit_btn">
                        <img
                          src={orangeRightArrow}
                          alt="right arrow"
                          className="arrow_img"
                        />
                      </button>
                    </div> : <p style={{display:"flex", justifyContent:"center",color:"white", margin:"10px 0", fontSize:"22px"}}>Thank You!</p> }
                    {renderError("email")}
                  </form> 
                </div>
              ) : null}
              <div className="col-md-3 footer_col">
                <div className="contact_wrapper">
                  <div className="details_wrapper">
                    <h4 className="detail_title">FOR MARKETING QUERIES:</h4>
                    <a href="mailto:reach@archinza.com" className="detail_desc">
                      reach@archinza.com
                    </a>
                  </div>
                  <div className="details_wrapper">
                    <h4 className="detail_title">FOR GENERAL QUERIES:</h4>
                    <a href="mailto:hello@archinza.com" className="detail_desc">
                      hello@archinza.com
                    </a>
                  </div>
                  <div className="details_wrapper">
                    <Link to={BlogsListingURL} onClick={() => {window.scrollTo(0,0)}} className="detail_title">
                      BLOG
                    </Link>
                  </div>
                </div>
              </div>
              {width <= 767 ? (
                <div className="col-md-5 footer_col">
                  <form className="input_wrapper" onSubmit={handleSubmitEmail}>
                    <p className="input_desc">
                      To get regular updates, subscribe to our newsletter!
                    </p>
                    {thankyouMsg === true ? <div className="input_field_wrapper">
                      <input
                        type="text"
                        placeholder="Enter your email"
                        className="input_details"
                        value={emailNewsletter}
                        onChange={(e) => {if(e.target.value){
                            setErrors({})
                          }
                          setEmailNewsletter(e.target.value)}}
                      />
                      <button className="submit_btn">
                        <img
                          src={orangeRightArrow}
                          alt="right arrow"
                          className="arrow_img"
                        />
                      </button>
                    </div> : <p style={{color:"white", margin:"10px 0", fontSize:"22px"}}>Thank You!</p> }
                    {renderError("email")}
                  </form>
                </div>
              ) : null}
            </div>
            <div className="copy_right_wrapper">
              <p className="copy_right">
                Copyright © {current_year.getFullYear()} Archinza Connect Pvt
                Ltd. | <Link to={termsandconditionURL}  onClick={() => {window.scrollTo(0,0)}}>Terms & Conditions</Link>
                | <Link to={privacypolicyURL} target="_blank" onClick={() => {window.scrollTo(0,0)}}>Privacy Policy</Link> |{" "}
                <Link to={faqsURL}  onClick={() => {window.scrollTo(0,0)}}>FAQs</Link>
              </p>
            </div>
          </div>
        </div>
      </footer>
      <FloatingIcon />
    </>
  );
};

export default FooterV2;
