import "./datatypes.scss";
import FullWidthTextField from "../../components/TextField/FullWidthTextField";
import { countries } from "../../db/dataTypesData";
import CountryCodeDropdown from "../../components/CountryCodeDropdown/CountryCodeDropdown";
import { useEffect, useState } from "react";
import RadioButton from "../../components/RadioButton/RadioButton";
import PasswordInput from "../../components/PasswordInput/PasswordInput";
import CheckboxButton from "../../components/CheckboxButton/CheckboxButton";
import BorderLinearProgress from "../../components/ProgressBar/ProgressBar";
import AutoCompleteField from "../../components/AutoCompleteField/AutoCompleteField";


const DataTypes = () => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 100) {
          return 0;
        }
        const diff = Math.random() * 10;
        return Math.min(oldProgress + diff, 100);
      });
    }, 500);

    return () => {
      clearInterval(timer);
    };
  }, []);
  return (
    <>
      <section className="datatypes_sec1">
        <div className="my_container">
          <FullWidthTextField label="Your full name?*" />
          <br />
          <br />
          <br />
          <br />
          <div className="row">
            <div className="col-4 col-sm-4 col-md-4 ps-0">
              <CountryCodeDropdown textLabel="Code" />
            </div>
            <div className="col-8 col-sm-8 col-md-8 pe-0">
              <FullWidthTextField label="Phone*" type="number" />
            </div>
          </div>
          <br />
          <br />
          <br />
          <br />
          <AutoCompleteField textLabel="Country*" data={countries} />
          <br />
          <br />
          <br />
          <br />
          <BorderLinearProgress variant="determinate" value={progress} />
          <br />
          <br />
          <br />
          <br />
          <ul>
            <RadioButton
              label="BUSINESS | FIRM OWNER"
              labelId="BUSINESS | FIRM OWNER"
              isPro={true}
            />
            <RadioButton label="STUDENT" labelId="STUDENT" isPro={true} />
            <RadioButton label="TEAM MEMBER" labelId="TEAM MEMBER" isPro={true} />
            <RadioButton
              label="DESIGN ENTHUSIAST"
              labelId="DESIGN ENTHUSIAST"
            />
          </ul>
          <br />
          <br />
          <br />
          <br />
          <PasswordInput />
          <br />
          <br />
          <br />
          <br />
          <FullWidthTextField label="Email*" type="email" />
          <br />
          <br />
          <br />
          <br />
          <FullWidthTextField label="Set Password*" type="password" />
          <br />
          <br />
          <br />
          <br />
          <FullWidthTextField label="Whatsapp Number*" type="number" />
          <br />
          <br />
          <br />
          <br />
          <ul>
            <CheckboxButton
              label="Jobs | internships"
              labelId="Jobs | internships"
            />
            <CheckboxButton
              label="Searching products materials for projects"
              labelId="Searching products materials for projects"
            />
            <CheckboxButton
              label="Knowledge on trends | materials"
              labelId="Knowledge on trends | materials"
            />
          </ul>
          <br />
          <br />
          <br />
          <br />
        </div>
      </section>
    </>
  );
};

export default DataTypes;


