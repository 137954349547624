import React, { useEffect, useState } from "react";
import style from "../Form/formfive.module.scss";
import LogoutText from "../../../components/LogoutText/LogoutText";
import RadioButton from "../../../components/RadioButton/RadioButton";
import { rightarrowblack, rightarrowwhite } from "../../../images";
import callApi from "../../../util/apiCaller";
import { updateCustomerArchPro } from "../../../redux/app/appAction";
import { useDispatch, useSelector } from "react-redux";
import { getBrandId, getBrand } from "../../../redux/app/appSelectors";
import {ErrorsMsg, SuccessMsg} from "../../../util/Message";
import {toast} from "react-toastify";
import { setBrand, setBrandId,updateCustomerArchProV2,setCustomerData, setCreateAccount} from "../../../redux/app/appAction";
import { useNavigate } from "react-router-dom";
import { consoleUrl } from "../../../components/helpers/constant-words";
import RadioButtonLightTheme from "../../../components/RadioButtonLightTheme/RadioButtonLightTheme";

// const expArr = ["0 - 5 years", "5 - 10 years", "10 - 20 years", "20+ years"];

const FHStep04 = ({ nextStep, previousStep, currentStep, totalSteps, progressStatus, handleUserExtraData, userExtraData }) => {

  const dispatch = useDispatch();
  const brandId = useSelector((state) => getBrandId(state)) ;
  const brandData = useSelector((state) => getBrand(state))
  const [code, setCode] = useState([])
  //const [showError, setShowError] = useState(true)
  const [errors, setErrors] = useState({});
  const navigate = useNavigate()
  const [firmEstablishment, setFirmEstablishment ] = useState()

  useEffect(() => {
    setFirmEstablishment({"firm_established": brandData?.firm_established })
  },[brandData])

  const handleChangeUser = ( keyName,keyValue) => {
    if(keyValue){
      setErrors({})
    }
    let update ={...firmEstablishment};
    update["firm_established"] = keyValue;
    update.brand_current_flow = currentStep;
    setFirmEstablishment(update);

  }
  const handleValidation = (user) => {
    let isValid = true;
    let errors = {};
    if (!user?.firm_established) {
        isValid = false;
        errors["firm_established"] = "Please select firm established since";
    }

      return {
        isValid,
        errors
    }
  }
 const handleUpdateApi = (brandId,firmEstablishment) => {
  let check = handleValidation(firmEstablishment);
    
    if(check.isValid && brandId && firmEstablishment){
      updateCustomerArchProV2(brandId, firmEstablishment).then(res => {
        if(res?.status === 'Success' && res?.data?.brand) {
          dispatch(setCustomerData(res?.data?.brand));
        
          nextStep()
          window.scrollTo()
        } else {
          toast(ErrorsMsg(res?.data?.message),{
            className:"mail_toast",
            position: toast.POSITION.TOP_CENTER,
            hideProgressBar: true,
            closeButton: false,
          })
        }
      });

      setErrors({})
    }else {
      setErrors(check?.errors);
      }
  }
  const renderError = (keyName) => {
    return errors && errors[keyName] ? <p className="error" >{errors[keyName]}</p> : null;
  };

  useEffect(() => {

    fetchContentCode("firm-established-since")
   },[])

  async function fetchContentCode(code) {

    try {
      const res = await callApi(`content/code/${code}`, "get");
      if (res && res?.status === 'Success') {
        const { params } = res?.data?.content;

        setCode(params);
      } else {
      }
    } catch (err) {

    }
  }

  const expList = code?.map((option,i) => (
    <React.Fragment key={`${option.value}fimav2+${i}`}>
      <RadioButtonLightTheme label={option.label} labelId={`firmH5${option.value+i}`} value={option.value} checked={option?.value === firmEstablishment?.firm_established} handleChangeUser={handleChangeUser}/>
    </React.Fragment>
  ));

  return (
    <>
      <div className={style.text_container}>
      <p className={`${style.head_text1}`}>About The Business</p>
        <h1 className={style.title}>Year of establishment</h1>
        <p className={style.description}></p>
      </div>
      <div className={`${style.steps} ${style.step10}`}>
        <ul className={style.steps_ul}>{expList}</ul>
        <div style={{display:"flex",justifyContent:"center",alignItems:"center"}}>{renderError("firm_established")}</div>
      </div>

      <div className={style.next_logout}>
        
        <div className={style.cta_wrapper}>
          <div
            className={style.next_button}
            onClick={() =>  handleUpdateApi(brandId,firmEstablishment)}
          >
            <div className={style.text}>Next</div>
            <img
              src={rightarrowblack}
              alt="icon"
              className={style.icon}
              loading="lazy"
            />
          </div>
          <div
            className={style.back_button}
            onClick={() => {
              previousStep(8);
              window.scrollTo(0, 0);
            }}
          >
            Back
          </div>
        </div>
        {/* <LogoutText  userData={firmEstablishment}/> */}
      </div>
    </>
  );
};

export default FHStep04;
