import React from "react";
import "./consolnotice.scss";

const ConsoleNoticeCard = ({ title, notice }) => {
  return (
    <div className="notice_card">
      <h2 className="heading">{title}</h2>
      <p className="desc">{notice}</p>
    </div>
  );
};

export default ConsoleNoticeCard;

