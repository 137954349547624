import React, { useEffect, useState } from "react";
import style from "../Form/formfive.module.scss";
import LogoutText from "../../../components/LogoutText/LogoutText";
import {
  catalougeDelete,
  formupload,
  plusicon,
  rightarrowblack,
  rightarrowwhite,
} from "../../../images";
import FullWidthTextField from "../../../components/TextField/FullWidthTextField";
import CheckboxButton from "../../../components/CheckboxButton/CheckboxButton";
import { Link, useNavigate } from "react-router-dom";
import { useWindowSize } from "react-use";
import callApi from "../../../util/apiCaller";
import { addDocument, updateCustomerArchPro, updateCustomerArchProV2 ,setCustomerData,setCreateAccount} from "../../../redux/app/appAction";
import { useDispatch, useSelector } from "react-redux";
import { getBrandId, getUserId, getBrand } from "../../../redux/app/appSelectors";
import axios from "axios";
import { render } from "@testing-library/react";
import _ from "lodash";
import {toast} from "react-toastify";
import {MsgWithVarText} from "../../EditProfile/EditProfile";
import { ErrorsMsg } from "../../../util/Message";
import {PDF_IMAGE_FILE_SIZE} from "../../../constants/enums";

const API_URL = process.env.REACT_APP_API_URL || `https://apidev.archinza.com`;

// const newFormBCatalougeBrandArr = ["Kohler"];
// const newFormBNewCatalougeBrandArr = ["New Brand"];

const FBStep13 = ({
  nextStep,
  previousStep,
  currentStep,
  totalSteps,
  progressStatus,
  handleUserExtraData,
  userExtraData,
}) => {
  const dispatch = useDispatch();
  const userId = useSelector((state) => getUserId(state));
  const brandId = useSelector((state) => getBrandId(state))
  const [upload, setUpload] = useState(false);
  const brandData = useSelector((state) => getBrand(state));
  const navigate = useNavigate()
  // const [file1, setFile1] = useState(null);
  // const [file, setFile] = useState(null)
  const [errors, setErrors] = useState({});
  const [error] = useState(false);
  const { width } = useWindowSize();
  const [catalogueData, setCatalogueData] = useState([])
  const [dataErrors, setDataErrors] = useState([]);
  //   const [code, setCode] = useState([])
  // const [newCode, setNewCode] = useState([])
  const [user, setUser] = useState([{
    document_name: "",
    catalogue_image: "",
    brand_name: [],
    image_name: ""
  }]);

 const [otherInputValue, setAddOtherInputValue] = useState([{brand_name: ""}]);
 const [otherInputValueData, setAddOtherInputValueData] = useState([])
 const [indexCat, setIndexCat] = useState(0)

 useEffect(() => {
  if(brandId){
  async function fetchImages(brandId){
        try {
            const res = await callApi(`document/list`, "post", {
              "pageNum": 1,
              "pageSize": 20,
              "filters": {"brand_id": brandId}
          });
         
          if (res && res?.status === 'Success') {
            const {documentList, documentCount} = res?.data;
          
            let id = documentList[0]?.document_detail[0]?._id
            
            let arr1 = [...user]
           
             arr1 = [...documentList[0]?.document_detail]
         
            setUser(arr1)
             
              let data2 = documentList[0]?.document_detail?.filter((item) => {
              return item?._id !== id
              })
              
              setCatalogueData(data2)
            }

          
        } catch (err) {
            // dispatch(SET_IS_LOADING(false));
        }
    }
    fetchImages(brandId)
  }
 },[brandId])

  useEffect(() => {
    const handleKeyPress = (e) => {
      let updateOthers = [...otherInputValue]
      if (e.key === 'Enter' && (!_.isEmpty(otherInputValue[0]?.brand_name) ) ) {
       // handleBrandName("brand_name", otherInputValue[0]?.brand_name);
        if(user[0]?.brand_name?.indexOf(otherInputValue[0]?.brand_name) === -1) {
          handleBrandName("brand_name", otherInputValue[0]?.brand_name);

        } else {
          toast(<MsgWithVarText text={'Tag already present'} />, {
            className: "mail_toast",
            position: toast.POSITION.TOP_CENTER,
            hideProgressBar: true,
            closeButton: false,
          })
        }
        updateOthers[0]["brand_name"] = "";
          setAddOtherInputValue(updateOthers);

      }
    };

    window.addEventListener('keypress', handleKeyPress);

    return () => {
      window.removeEventListener('keypress', handleKeyPress);
    };
  }, [otherInputValue[0]?.brand_name]);

  const handleBrandName = (keyName, keyValue) => {

    let prevIndex = user[0].brand_name.indexOf(keyValue);

    if(prevIndex !== -1) {
      let update = [
        ...user

        ];
        update[0][keyName]= user[0].brand_name?.filter(
          (_, index) => index !== prevIndex
      )
      setUser(update);
    } else {
      let update =[...user];
      update[0][keyName] = [...user[0].brand_name];
      update[0][keyName]?.push(keyValue);
      setUser(update);
      setErrors({});
    }
  }

  const handleChangeUser = async (e, i) => {
    let update = [...user];
    const { name, value } = e.target;
    setErrors((prev) => ({ ...prev, [name]: "" }))
    let updateOthers = [...otherInputValue]
    if(!_.isEmpty(e?.target?.files) && e?.target?.files[0].size/(1024*1024) > PDF_IMAGE_FILE_SIZE) {
      toast(ErrorsMsg(`File size should be < ${PDF_IMAGE_FILE_SIZE} mb`),{
        className: "mail_toast",
        position: toast.POSITION.TOP_CENTER,
        hideProgressBar: true,
        closeButton: false,
      });
    } else {
      if (name === "catalogue_image") {
        const files = e?.target?.files[0]
        const fileExtension = files.name.split(".").pop().toLowerCase();
        const fileType = files.type; 
        const allowedTypes = ["application/pdf", "image/png", "image/jpeg", "image/jpg"];
        if (allowedTypes.includes(fileType) || (fileExtension === "pdf" || fileExtension === "png" || fileExtension === "jpg" || fileExtension === "jpeg")) {
            if(!update[0]?.image_name?.includes(e.target.files[0].name)) {
                update[0]["image_name"] = e.target.files[0].name;
                let data = new FormData();
                data.append('file', e.target.files[0]);

                let config = {
                  method: 'post',
                  url: `${API_URL}/media/upload`,
                  data: data
                };

                let result = await axios.request(config);
                //console.log(result);
                if (result?.data?.data?.link) {
                  update[0]["catalogue_image"] = result?.data?.data?.link;
                }
              } else {
                toast(ErrorsMsg("File already uploaded"),{
                  className: "mail_toast",
                  position: toast.POSITION.TOP_CENTER,
                  hideProgressBar: true,
                  closeButton: false,
                });
              }
        }else{
          toast(ErrorsMsg("Invalid file type. Please select a PDF, PNG, JPG, or JPEG file."),{
            className: "mail_toast",
            position: toast.POSITION.TOP_CENTER,
            hideProgressBar: true,
            closeButton: false,
          });
        }
      } else if(name === "document_name"){

        update[0]["document_name"] = value;
      }else if(name === "brand_name"){
        updateOthers[0]["brand_name"] = value
      }
  }

      setAddOtherInputValue(updateOthers)
    setUser(update)
  }


  useEffect(() => {
    const handleKeyPress = (e) => {
      let updateotherdata = [...otherInputValueData]
      if (e.key === 'Enter' && !_.isEmpty(otherInputValueData[indexCat]?.brand_name) ) {
        //handleBrandNameData("brand_name", otherInputValueData[indexCat]?.brand_name,indexCat);
        if(catalogueData[indexCat]?.brand_name?.indexOf(otherInputValueData[indexCat]?.brand_name) === -1) {
          handleBrandNameData("brand_name", otherInputValueData[indexCat]?.brand_name,indexCat);
        } else {
          toast(<MsgWithVarText text={'Tag already present'} />, {
            className: "mail_toast",
            position: toast.POSITION.TOP_CENTER,
            hideProgressBar: true,
            closeButton: false,
          })
        }
        updateotherdata[indexCat]["brand_name"] = "";
       setAddOtherInputValueData(updateotherdata)
      }

    };

    window.addEventListener('keypress', handleKeyPress);

    return () => {
      window.removeEventListener('keypress', handleKeyPress);
    };
  }, [otherInputValueData[indexCat]?.brand_name]);


  const handleUser = async (e, i) => {


    const { name, value } = e.target;

    let tempErrors = [...dataErrors];
    tempErrors[i] = dataErrors[i] ? {...dataErrors[i]} : {};
    //tempErrors[i][name] = "";

    const tempbrand = [...otherInputValueData]
    const onchangeVal = [...catalogueData];

        if (name === "catalogue_image") {
          if(!_.isEmpty(e?.target?.files) && e?.target?.files[0].size/(1024*1024) > PDF_IMAGE_FILE_SIZE) {
            toast(ErrorsMsg(`File size should be < ${PDF_IMAGE_FILE_SIZE} mb`),{
              className: "mail_toast",
              position: toast.POSITION.TOP_CENTER,
              hideProgressBar: true,
              closeButton: false,
            });
          } else {
            const files = e?.target?.files[0]
            const fileExtension = files.name.split(".").pop().toLowerCase();
            const fileType = files.type; 
            const allowedTypes = ["application/pdf", "image/png", "image/jpeg", "image/jpg"];
            if (allowedTypes.includes(fileType) || (fileExtension === "pdf" || fileExtension === "png" || fileExtension === "jpg" || fileExtension === "jpeg")) {
                  if(!onchangeVal[i]?.image_name?.includes(e.target.files[0].name)) {
                    tempErrors[i]["catalogue_image"] = "";
                    onchangeVal[i]["image_name"] = e.target.files[0].name;
                    let data = new FormData();
                    data.append('file', e.target.files[0]);

                    let config = {
                      method: 'post',
                      url: `${API_URL}/media/upload`,
                      data: data
                    };

                    let result = await axios.request(config);
                    //console.log(result);
                    if (result?.data?.data?.link) {
                      onchangeVal[i]["catalogue_image"] = result?.data?.data?.link;
                    }
                  } else {
                    toast(ErrorsMsg("File already uploaded"),{
                      className: "mail_toast",
                      position: toast.POSITION.TOP_CENTER,
                      hideProgressBar: true,
                      closeButton: false,
                    });
                  }
            }else{
              toast(ErrorsMsg("Invalid file type. Please select a PDF, PNG, JPG, or JPEG file."),{
                className: "mail_toast",
                position: toast.POSITION.TOP_CENTER,
                hideProgressBar: true,
                closeButton: false,
              });
            }
          }
        } else if(name === "document_name"){
          tempErrors[i]["document_name"] = "";
          onchangeVal[i]["document_name"] = value;
        }else{
          tempErrors[i]["brand_name"] = ""
          tempbrand[i]["brand_name"] = value;

          setIndexCat(i)

        }

    setAddOtherInputValueData(tempbrand)
    setCatalogueData(onchangeVal)
    setDataErrors(tempErrors);

  }
  const handleAddMore = () => {

    setAddOtherInputValueData([...otherInputValueData,{brand_name:""}])
    setCatalogueData([...catalogueData, { brand_name: "", document_name: "", catalogue_image: "", image_name: "" }])
  }
  const handleDelete = (i) => {
    const deletVal = [...catalogueData];
    deletVal.splice(i, 1)
    setCatalogueData(deletVal)
  }

  const handleValidation = (user) => {
    let isValid = true;
    let errors = {};

    if (!user[0]?.document_name) {
      isValid = false;
      errors["document_name"] = "Catalogue name should not be blank";
    }
    if (!user[0]?.catalogue_image) {
      isValid = false;
      errors["catalogue_image"] = "Catalogue image should not be blank";
    }
    if (user[0]?.brand_name.length === 0) {
      isValid = false;
      errors["brand_name"] = "Brand name should not be blank";
    }

    return {
      isValid,
      errors
    }
  };

  const handleDataValidation = (data) => {
    let isValid = true;
    let errors = [];

    if (data?.length > 0) {
      errors = data.map((dataUser, i) => {
        let error = {};
        if (!dataUser?.document_name) {
          isValid = false;
          error["document_name"] = "Catalogue name should not be blank";
        }

        if (!dataUser?.catalogue_image) {
          isValid = false;
          error["catalogue_image"] = "Catalogue image should not be blank";
        }

        if (dataUser?.brand_name.length === 0) {
          isValid = false;
          error["brand_name"] = "Brand name should not be blank";
        }

        return error;
      })
    }

    return {
      isValid,
      errors
    };
  };

  const handleUpdateApi = () => {
    let check = handleValidation(user);
    let dataCheck = handleDataValidation(catalogueData);

    if (check.isValid && dataCheck.isValid && brandId && userId) {
      dispatch(addDocument({ brand_id: brandId, customer_id: userId, document_detail: [{
        document_name: user[0]?.document_name,
        catalogue_image: user[0]?.catalogue_image,
        brand_name: user[0]?.brand_name,
        image_name: user[0]?.image_name
      }, ...catalogueData] },navigate))
      updateCustomerArchProV2(brandId, { brand_current_flow : currentStep}).then(res => {
        if(res?.status === 'Success' && res?.data?.brand) {
          dispatch(setCustomerData(res?.data?.brand));
          dispatch(setCreateAccount(true))
        } else {
          toast(ErrorsMsg(res?.data?.message),{
            className:"mail_toast",
            position: toast.POSITION.TOP_CENTER,
            hideProgressBar: true,
            closeButton: false,
          })
        }
      });
      
      setDataErrors({})
      window.scrollTo(0, 0);

    } else {
      setErrors(check?.errors)
      setDataErrors(dataCheck?.errors);
    }
  }
  const renderError = (keyName) => {
    return errors && errors[keyName] ? <p className="error" style={{ margin: "10px 0" }}>{errors[keyName]}</p> : null;
  };

  const renderDataErrors = (keyName, index) => {
    return dataErrors && dataErrors[index] && dataErrors[index][keyName] ? <p className="error" >{dataErrors[index][keyName]}</p> : null;
  }

  const handleBrandNameData = (keyName,keyValue,i) => {
    // if(indexCat){
    let prevIndex = catalogueData[i]?.brand_name?.indexOf(keyValue);

    if(prevIndex !== -1) {
      let update = [
        ...catalogueData

        ];
        update[i][keyName]= catalogueData?.[i]?.brand_name?.filter(
          (_, index) => index !== prevIndex
      )
      setCatalogueData(update);
    } else {
      let update =[...catalogueData];
      update[i][keyName] = [...catalogueData[i].brand_name];
      update[i][keyName]?.push(keyValue);
      setCatalogueData(update);
      setErrors({});

  }
}

  const newFormBCatalougeBrandList = user[0]?.brand_name?.map((option) => (
    <React.Fragment key={option}>
      <CheckboxButton
        label={option}
        labelId={`form-b-catalouge-${`gg${option}`}`}
        isChecked={true}
        keyName={"brand_name"}
        handleUserDetail={handleBrandName}
      />
    </React.Fragment>
  ));
  // const data =catalogueData?.map((option) => (
  //   <React.Fragment>
  //   {option?.brand_name?.map(
  //   (item) => (

  //     <React.Fragment key={`rtf${item}`}>
  //       <CheckboxButton
  //         label={item}
  //         labelId={`form-b2-new-catalouge-${item}-${index}`}
  //         isChecked={true}
  //         keyName={"brand_name"}

  //         handleUserDetail={handleBrandNameData}
  //       />
  //     </React.Fragment>

  //   )
  // )}
  // </React.Fragment>
  // ))



  return (
    <>
      <div className={style.text_container}>
      <p className={`${style.head_text1}`}>Business Connect Data</p>
        <h1 className={style.title}>Upload Product Catalogues</h1>
        <p className={style.description}></p>
      </div>
      <div className={`${style.steps} ${style.fastep12} ${style.fbstep13}`}>
        <div className="row">
          <div className="col-md-6">
            <div className={style.fileupload_wrapper}>
              <div className={style.fileupload}>
                <input
                  className={style.input_box}
                  type="file"
                  accept={'application/pdf, image/*'}
                  id="uploadFile4d5"
                  hidden
                  name="catalogue_image"
                  onChange={(e) => handleChangeUser(e, "")}
                />
                <label
                  htmlFor="uploadFile4d5"
                  className={style.upload_label}
                  onClick={() => setUpload(true)}
                >
                  <div className={style.img_wrapper}>
                    <img
                      width={57}
                      height={40}
                      src={formupload}
                      alt="upload"
                      className={style.upload_icon}
                      loading="lazy"
                    />
                  </div>
                  <div className={style.cta_wrapper}>
                    <div className={style.next_button}>
                      <div className={style.text}>Upload file</div>
                    </div>
                  </div>
                  <div
                    className={style.filename}
                    style={{
                      display: "block",
                    }}
                  >

                    {user[0]?.image_name}
                  </div>
                  <div className={style.notice}>

                    only single file to be selected
                    Maximum file size is {PDF_IMAGE_FILE_SIZE}MB. PDF, JPEG, PNG allowed
                  </div>
                </label>
              </div>

            </div>
            {renderError("catalogue_image")}
          </div>
          <div className="col-md-6">
            <div className={style.field_wrapper}>
              <FullWidthTextField label="Name Of The Catalogue*" value={user[0]?.document_name} name="document_name"
                onChange={(e) => handleChangeUser(e, "")} />
              {renderError("document_name")}
            </div>
            <div className={style.field_wrapper}>
              <FullWidthTextField key="brand" label="Add Your Brand Name" value={otherInputValue[0]?.brand_name} name="brand_name"
                onChange={(e) => handleChangeUser(e, "")} />
              {renderError("brand_name")}
            </div>
            <ul className={`${style.steps_ul} ${style.fbstep13_ul}`}>
              {newFormBCatalougeBrandList}
            </ul>
          </div>
        </div>

        {!_.isEmpty(catalogueData) && catalogueData?.map((val, i) => (
          <div className={`row ${style.new_category_row}`} key={i}>
            <div className="col-md-6">
              <div className={style.fileupload_wrapper}>
                <div className={style.fileupload}>
                  <input
                    className={style.input_box}
                    type="file"
                    accept={'application/pdf, image/*'}
                    id={`udp${i}`}
                    hidden
                    name="catalogue_image"
                    onChange={(e) => handleUser(e, i)}
                  />
                  <label
                    htmlFor={`udp${i}`}
                    className={style.upload_label}
                    onClick={() => setUpload(true)}
                  >
                    <div className={style.img_wrapper}>
                      <img
                        width={57}
                        height={40}
                        src={formupload}
                        alt="upload"
                        className={style.upload_icon}
                        loading="lazy"
                      />
                    </div>
                    <div className={style.cta_wrapper}>
                      <div className={style.next_button}>
                        <div className={style.text}>Upload file</div>
                      </div>
                    </div>
                    <div
                      className={style.filename}
                      style={{
                        display: "block",
                      }}
                    >
                      {catalogueData[i]?.image_name }
                    </div>
                    <div className={style.notice}>
                    only single file to be selected
                      Maximum file size is {PDF_IMAGE_FILE_SIZE}MB. PDF, JPEG, PNG allowed
                    </div>
                  </label>
                </div>
              </div>
              {renderDataErrors("catalogue_image",i)}
            </div>
            <div className="col-md-6">
              <div className={style.delete_col}>
                <div className={style.field_wrapper}>
                  <FullWidthTextField key={i+'catalog'} label="Name Of The Catalogue*" value={val?.document_name} name="document_name"
                    onChange={(e) => handleUser(e, i)} />
                  {renderDataErrors("document_name",i)}
                  {/* <p className={style.error}></p> */}
                </div>
                <div className={style.field_wrapper}>
                  <FullWidthTextField key={i+'brand'} label="Add Your Brand Name" value={otherInputValueData[i]?.brand_name} name="brand_name"
                    onChange={(e) => handleUser(e,i)} />
                  {renderDataErrors("brand_name",i)}

                  {/* <p className={style.error}></p> */}
                </div>
                {width > 768 && (
                  <Link>
                    <img
                      src={catalougeDelete}
                      alt="delete"
                      className={style.delete_icon}
                      loading="lazy"
                      onClick={handleDelete}
                    />
                  </Link>
                )}
              </div>
              <ul className={`${style.steps_ul} ${style.fbstep13_ul}`}>
              {!_.isEmpty(catalogueData?.[i]?.brand_name) && catalogueData[i]?.brand_name?.map((item, index) => (
                  <React.Fragment key={`rtf${item}`}>
                  <CheckboxButton
                    i={i}
                    label={item}
                    labelId={`form-b2-new-catalouge-${item}-${index}`}
                    isChecked={true}
                    keyName={"brand_name"}

                    handleUserDetail={handleBrandNameData}
                  />
                </React.Fragment>
                ))}
              </ul>
              {width < 768 && (
                <Link>
                  <img
                    src={catalougeDelete}
                    alt="delete"
                    className={style.delete_icon}
                    loading="lazy"
                  />
                </Link>
              )}
            </div>
          </div>
        ))}
        <div className={style.add_category}>
          <div class={style.dashed_line}></div>
          <div className={style.add_flex} onClick={handleAddMore}>
            <img src={plusicon} alt="icon" className={style.icon} />
            <div className={style.title}>Add more catalogues</div>
          </div>
          <div class={style.dashed_line}></div>
        </div>
      </div>


      <div className={style.next_logout}>
        <div className={style.cta_wrapper}>
          <div
            className={style.next_button}
            onClick={handleUpdateApi}
          >
            <div className={style.text}>Submit</div>
            <img
              src={rightarrowblack}
              alt="icon"
              className={style.icon}
              loading="lazy"
            />
          </div>
          <div
            className={style.back_button}
            onClick={() => {
              previousStep(8);
              setErrors({})
              window.scrollTo(0, 0);
            }}
          >
            Back
          </div>
        </div>
        {/*<LogoutText userData={userExtraData} />*/}
      </div>
    </>
  );
};

export default FBStep13;
