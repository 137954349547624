import React, { useEffect, useState } from "react";
import style from "../Form/formfive.module.scss";
import CheckboxButton from "../../../components/CheckboxButton/CheckboxButton";
import RadioButton from "../../../components/RadioButton/RadioButton";
import LogoutText from "../../../components/LogoutText/LogoutText";
import { rightarrowblack, rightarrowwhite } from "../../../images";
import FullWidthTextField from "../../../components/TextField/FullWidthTextField";
import callApi from "../../../util/apiCaller";
import { useNavigate } from "react-router-dom";
import { formAURL, formBURL, formDURL, formCURL } from "../../../components/helpers/constant-words";
import { useDispatch, useSelector } from "react-redux";
import { getBrand, getBrandId} from "../../../redux/app/appSelectors";
import { BRAND_ID } from "../../../constants/enums";
import _ from "lodash";
import {toast} from "react-toastify";
import FormFiveModal from "../../../components/FormFiveModal/FormFiveModal";
import {MsgWithVarText} from "../../EditProfile/EditProfile";
import {ErrorsMsg, SuccessMsg} from "../../../util/Message";
import { setBrand, setBrandId, updateCustomerArchPro,updateCustomerArchProV2,setCustomerData} from "../../../redux/app/appAction";
import RadioButtonLightTheme from "../../../components/RadioButtonLightTheme/RadioButtonLightTheme";
import businessType from "../../../constants/businessType.json";

const RStep02 = ({
    nextStep,
    previousStep,
    currentStep,
    totalSteps,
    progressStatus,
    userExtraData, handleUserExtraData ,goToStep
  }) => {
    const [errors, setErrors] = useState({});
    const [modalShow, setModalShow] = useState(false);
    const [code,setCode ] = useState()
    const [firm, setFirm] = useState({
        "business_type": "",
    })
    
    const bandData = useSelector((state) => getBrand(state))
    const dispatch = useDispatch()
    const brandId = useSelector((state) => getBrandId(state)) ;
    useEffect(() => {
      fetchContent("define-your-business")
    },[])

    useEffect(() => {
      if(bandData?.business_type){
        setFirm({
          "business_type": bandData?.business_type
        })
      }
    },[bandData])
    async function fetchContent(code) {

      try {
        const res = await callApi(`content/code/${code}`, "get");
       // console.log(res);
        if (res && res?.status === 'Success') {
          const { content } = res?.data;
            setCode(content)
        } else {
        }
      } catch (err) {

      }
    }
    const handleChangeUser = (keyName,keyValue) => {
    
      let update = {...firm}
        update["business_type"] = keyValue
        update.brand_current_flow = currentStep;
      
        setFirm(update)
    }
    const handleValidation = (user) => {
        let isValid = true;
        let errors = {};

        if (!user?.business_type) {
            isValid = false;
            errors["business_type"] = "Business type name cannot be blank";
        }
        // if (user?.business_category?.length === 0) {
        //   isValid = false;
        //   errors["business_category"] = "Business category cannot be blank";
        // }

        return {
            isValid,
            errors
        }
      };
      const handleApi = (firm,brandId) => {
        const check = handleValidation(firm)
     
        if(check.isValid && brandId){
          updateCustomerArchProV2(brandId, firm).then(res => {
            if(res?.status === 'Success' && res?.data?.brand) {
              dispatch(setCustomerData(res?.data?.brand));
              if(firm?.business_type === 'Design Firm | Consultancy'){
                nextStep();
                window.scrollTo(0, 0);
              }else if(firm?.business_type === 'Services Design Firm | Consultancy'){
                goToStep(18);
                window.scrollTo(0, 0);
              }else if(firm?.business_type === 'Design & Build'){
                goToStep(33);
                window.scrollTo(0, 0);
              }else if(firm?.business_type === 'Product | Material - Seller | Installer | Contractor'){
                goToStep(47);
                window.scrollTo(0, 0);
              }else if(firm?.business_type === 'E Commerce Retailer'){
                goToStep(61);
                window.scrollTo(0, 0);
              }else if(firm?.business_type === 'Curator | Stylist | Gallery'){
                goToStep(73);
                window.scrollTo(0, 0);
              }else if(firm?.business_type === 'Event & Publishing House'){
                goToStep(85);
                window.scrollTo(0, 0);
              }else if(firm?.business_type === 'PR | Marketing | Content | Photography'){
                goToStep(91);
                window.scrollTo(0, 0);
              }else if(firm?.business_type === 'National | International Brand'){
                goToStep(98);
                window.scrollTo(0, 0);
              }
            
            
             
            } else {
              toast(ErrorsMsg(res?.data?.message),{
                className:"mail_toast",
                position: toast.POSITION.TOP_CENTER,
                hideProgressBar: true,
                closeButton: false,
              })
            }
          });
          setErrors({})
         
        }else{
          setErrors(check.errors)
        }
      }
      const renderError = (keyName) => {
        return errors && errors[keyName] ? <p className="error" style={{margin:"10px 0"}}>{errors[keyName]}</p> : null;
      };

return (
    <>
      <div className={style.text_container}>
        <h1 className={style.title}>Define Your Business</h1>
        <p className={style.description}>
          <div>Select one</div>
          <div className={style.entity} onClick={() => setModalShow(true)}>
            &#9432;
          </div>
        </p>
      </div>
      <div className={`${style.steps} ${style.rstep02}`}>
        {/* <div className={style.title_list}> */}
        <ul className="radio_container1">
                            {code?.params?.map(item => {
                                return (
                                    <RadioButtonLightTheme
                                        key={item.label}
                                        checked={item?.label === firm?.business_type}
                                        value={item.label}
                                        label={item.label}
                                        labelId={item.label}
                                        handleChangeUser={handleChangeUser}
                                    />
                                )
                            })}
                            {renderError("user_type")}
            </ul>
            {/* </div> */}
      </div>
      <div className={style.next_logout}>
      {renderError("business_type")}
        <div className={style.cta_wrapper}>
          <div
            className={style.next_button}
            onClick={() => {
              handleApi(firm,brandId)
            }}
          >
            <div className={style.text}>Next</div>
            <img
              src={rightarrowblack}
              alt="icon"
              className={style.icon}
              loading="lazy"
            />
          </div>
          {/* <div
            className={style.back_button}
            onClick={() => {
              previousStep(8);
              setErrors({})
              window.scrollTo(0, 0);
            }}
          >
            Back
          </div> */}
        </div>
        <LogoutText userData={firm} />
      </div>
      <FormFiveModal
        className="white_theme"
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
};

export default RStep02;
