import { connectIcon, reachIcon, searchIcon } from "../../images";

export const bannerData = [
  {
    title: "Get client enquiries, ",
    animTitle: `<span class="orange_text">searching just for you in a minute</span>`,
    desc: "",
    ctaText: "Get Early Access",
  },
  {
    title: "Connect instantly ",
    animTitle: `<span class="orange_text">with design professionals <br />near you</span>`,
    desc: "",
    ctaText: "Get Early Access",
  },
  {
    title: "FInd now, the ideal power source ",
    animTitle: `<span class="orange_text">for your style | budget | location</span>`,
    desc: "",
    ctaText: "Get Early Access",
  },
  {
    title: "Search and save connections ",
    animTitle: `<span class="orange_text">for your future needs, at a click</span>`,
    desc: "",
    ctaText: "Get Early Access",
  },
  {
    title: "Build design teams and ",
    animTitle: `<span class="orange_text">find ideal jobs <br />near you</span>`,
    desc: "",
    ctaText: "Get Early Access",
  },
  {
    title: "Find reliable vendors and ",
    animTitle: `<span class="orange_text">consultants for your site location</span>`,
    desc: "",
    ctaText: "Get Early Access",
  },
  {
    title: "Search easily, ",
    animTitle: `<span class="orange_text">Reach more audience &amp; Connect instantly</span>`,
    desc: "",
    ctaText: "Get Early Access",
  },
];

export const perksData = [
  {
    icon: searchIcon,
    title: "SEARCH",
    desc: "for verified service, products, information and materials near you",
  },
  {
    icon: reachIcon,
    title: "REACH",
    desc: "matchmade clients, events, and sales leads led by Artificial Intelligence",
  },
  {
    icon: connectIcon,
    title: "CONNECT",
    desc: "with reliable services to solve your business, marketing, education & hiring needs.",
  },
];

export const builtData = [
  {
    title: "DESIGNERS & CONSULTANTS",
    content:
      "<ul><li>Search anything by text | image</li><li>Get found by clients</li><li>Connect on demand</li><li>Niche Hiring & Jobs</li></ul>",
  },
  {
    title: "PRODUCT | MATERIAL VENDORS & BUILDERS",
    content:
      "<ul><li>AI-led Reach to Designers & Clients</li><li>Need-based showcase Products & Services</li><li>Higher conversions without cold calls | emails</li><li>Participate in Events & Campaigns</li><li>Niche Hiring & Jobs</li></ul>",
  },
  {
    title: "STUDENTS AND DESIGN ENTHUSIASTS",
    content:
      "<ul><li>Search Products from Reference Images</li><li>Access to Expert Designers</li><li>Ask, Learn & Upskill</li><li>Find Design Jobs/Internships</li></ul>",
  },
  // {
  //   title: "Students & Design Enthusiasts",
  //   content:
  //     "<ul><li>Search anything by text | image.</li><li>Get found by clients.</li><li>Connect on demand.</li><li>Niche Hiring & Jobs.</li></ul>",
  // },
];

export const homeFaqData = [
  {
    title: "What is Archinza all about?",
    content:
      "Archinza is an AI-Powered Design Assistant for stakeholders in the Design and Build Industry that enables them to search and access information effortlessly. Whether you're looking for materials, services, or new opportunities, Archinza helps you search, reach and connect with others in the industry.",
  },
  {
    title: "Who is Archinza for?",
    content:
      "Archinza is for designers, architects, product vendors and sellers, service consultants, service providers, students, homeowners, influencers and all design enthusiasts.",
  },
  {
    title: "When will Archinza launch?",
    content:
      "We are a beta version in the stage of experimentation and evolution. Stay tuned!",
  },
  {
    title: "How will Archinza benefit me?",
    content:
      "<ul><li>Archinza is an AI-Powered Design Assistant provides AI-based matchmaking for products and services, business and sourcing, and opportunities for jobs.</li><li>With easy WhatsApp adoption, Archinza is quick, easy, hyperlocal and gives equal access to all.</li></ul>",
  },
  {
    title: "Where do I sign up?",
    content: "Click here to be a part of the Archinza digital tribe.",
  },
  {
    title: "Is Archinza free?",
    content:
      "Archinza offers free access to its features for the first 500 users.",
  },
];

export const noticeData = [
  {
    title: "Did you know?",
    desc: "The architecture, design and construction industry provides a living for more than 25% of the working population in India.",
  },

  {
    title: "Did you know?",
    desc: "By 2030, the Indian Real-estate market will be valued at $1 trillion. ",
  },
  {
    title: "Did you know?",
    desc: `“The human factor still trounces AI,” says RIBA Journal`,
  },
  {
    desc: `The global construction robotics market is projected to reach $10.7 billion by 2028.`,
  },
  {
    desc: `97% of mobile users are using AI-powered voice assistants.`,
  },
  {
    desc: `Chatbots responded to 85% of customer service interactions.`,
  },
];

