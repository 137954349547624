import React from "react";
import "./teammemberconsole.scss";
import {
  consoleArrow,
  consoleDot,
  rightarrowwhite,
  rocket,
  consoleBackground_1
} from "../../images";
import ProfileCard from "../../components/ProfileCard/ProfileCard";
import ConsoleNoticeCard from "../../components/ConsoleNoticeCard/ConsoleNoticeCard";
import consoleDataJSON from "../../constants/consoleData.json"
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import { Link } from "react-router-dom";
import ReachCard from "../../components/ReachCard/ReachCard";
import { useWindowSize } from "react-use";
import { teamMemeberData } from "../../components/Data/teamMemeberData";
import { useSelector } from "react-redux";
import {getCustomer} from "../../redux/app/appSelectors";
import { businessAccessURL, businessFormFiveURL, teamAccesssURL } from "../../components/helpers/constant-words";

const TeamMemberConsole = () => {
  const { width } = useWindowSize();
  const customer = useSelector(state => getCustomer(state));
  return (
    <>
      <main className="main_container">
        <section className="teammemberconsole_sec1">
          
            <div >
              <div className="my_container">
                <img
                  src={consoleBackground_1}
                  alt="background"
                  className="console_background"
                />
                <img src={consoleDot} alt="dot" className="console_dot1" />
                {/* <img src={consoleDot} alt="dot" className="console_dot2" /> */}

                <div className="row console_row" >
                  <div className="col-lg-8 console_col">
                    <ProfileCard
                      name={customer?.contact_name}
                      ctaTextColor={consoleDataJSON.USER_CONSOLE_BTN_COLOR}
                      title={customer?.user_type}
                      contactNo={customer?.contact_phone}
                      emailId={customer?.contact_email}
                      address={customer?.address}
                      />
                  </div>
                  <div className="col-lg-4 console_col">
                    <Swiper
                      modules={[Pagination]}
                      spaceBetween={20}
                      pagination={true}
                    >
                      <SwiperSlide>
                          <ConsoleNoticeCard
                            title={consoleDataJSON.USER_CONSOLE_HEADING}
                            notice={consoleDataJSON.USER_CONSOLE_DESCRIPTION}
                          />
                      </SwiperSlide>
                      <SwiperSlide>
                        <ConsoleNoticeCard
                          title={consoleDataJSON.USER_CONSOLE_HEADING}
                          notice={consoleDataJSON.USER_CONSOLE_DESCRIPTION}
                          />
                      </SwiperSlide>
                      <SwiperSlide>
                        <ConsoleNoticeCard
                        title={consoleDataJSON.USER_CONSOLE_HEADING}
                        notice={consoleDataJSON.USER_CONSOLE_DESCRIPTION}
                        />
                      </SwiperSlide>
                      <SwiperSlide>
                    <ConsoleNoticeCard
                        title={consoleDataJSON.USER_CONSOLE_HEADING}
                        notice={consoleDataJSON.USER_CONSOLE_DESCRIPTION}
                        />
                    </SwiperSlide>
                    </Swiper>
                  </div>
                </div>
              </div>
            </div>
          
        </section>

        <section className="team_reach_sec">
          <div className="my_container">
            <div className="row">
              <div className="col-lg-8 p-0">
                <ReachCard
                  url={businessFormFiveURL}
                  ctaText="Onboard Your Business | Service"
                  noticeText="You will be required to upload product catalogues, company
              profile, logo"
                />
              </div>
              <div className="col-lg-4"></div>
            </div>
          </div>
        </section>

        <section
          className="teammemberconsole_sec2"
          // style={{ display: "none" }}
        >
          <img src={consoleDot} alt="dot" className="console_dot2" />
          <div className="my_container">
            <div className="row intro_row">
              <div className="col-lg-12 intro_col">
                <h2 className="heading">
                  Welcome to the Archinza Design Network!
                </h2>
                <div className="intro_wrapper">
                  <p className="desc">For The Design & Build Community</p>
                </div>
              </div>

              <div className="row intro_col">
                <div className="sub_content_wrapper  col-lg-7">
                  <h2 className="subheading">Congratulations!</h2>
                  <p className="subdesc">
                    <span className="orange_text">
                      You are eligible for free early access to{" "}
                    </span>{" "}
                    Archinza PRO!
                  </p>
                </div>
                <div className="sub_content_wrapper  col-lg-7">
                  <p className="subdesc subdesc2">
                    Meanwhile, let's find your <br />
                    <span className="blue_text">
                      Design Business Awareness Quotient.
                    </span>
                  </p>
                </div>

                <div className="col-lg-5 intro_col">
                  <div className="button_container">
                    <Link to={teamAccesssURL} className="cta_wrapper">
                      <p className="cta_text">
                        Complete Claiming Free Access To ARCHINZA PRO Now
                        {width < 600 && (
                          <img
                            src={rightarrowwhite}
                            alt="arrow"
                            className="arrow_img"
                          />
                        )}
                      </p>
                      {width > 600 && (
                        <img
                          src={rightarrowwhite}
                          alt="arrow"
                          className="arrow_img"
                        />
                      )}
                    </Link>
                    <span className="link">
                      <button className="remind_btn">Click here</button> to Save
                      & Exit
                    </span>
                  </div>
                  <div className="next_button_container">
                    {/* <Link to={() => false} className="cta_wrapper">
                      <img
                        src={consoleArrow}
                        alt="arrow"
                        className="arrow_img"
                      />
                      <p className="cta_text">start</p>
                    </Link>
                    <button className="remind_btn">Remind me later</button> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="mobile_notice">
              {teamMemeberData.map((item, i) => (
                <Swiper
                  modules={[Pagination]}
                  spaceBetween={20}
                  pagination={true}
                >
                  <SwiperSlide>
                    <ConsoleNoticeCard
                      title={item.heading}
                      notice={item.desc}
                    />
                  </SwiperSlide>
                  <SwiperSlide>
                    <ConsoleNoticeCard
                      title={item.heading}
                      notice={item.desc}
                    />
                  </SwiperSlide>
                  <SwiperSlide>
                    <ConsoleNoticeCard
                      title={item.heading}
                      notice={item.desc}
                    />
                  </SwiperSlide>
                  <SwiperSlide>
                    <ConsoleNoticeCard
                      title={item.heading}
                      notice={item.desc}
                    />
                  </SwiperSlide>
                </Swiper>
              ))}
            </div>
          </div>
        </section>

        {/* <section className="team_console_2" 
        style={{ display: "none" }}
        >
          <section className="reach_sec">
            <div className="my_container">
              <div className="row">
                <div className="col-lg-8 p-0">
                  <ReachCard
                    ctaText="Onboard Your Business | Service"
                    noticeText="You will be required to upload product catalogues, company
              profile, logo"
                  />
                </div>
                <div className="col-lg-4"></div>
              </div>
            </div>
          </section>

          <section className="teammemberconsole_sec3">
            <div className="my_container">
              <div className="row intro_row">
                <div className="col-lg-12 intro_col">
                  <h2 className="heading">
                    Welcome to the Archinza Design Network!
                  </h2>
                  <div className="intro_wrapper">
                    <p className="desc">We will notify you when we launch!</p>
                    <img
                      src={rocket}
                      alt="rocket launch"
                      className="rocket_img"
                    />
                  </div>
                </div>

                <div className="row intro_col">
                  <div className="sub_content_wrapper  col-lg-7">
                    <div className="subdesc">
                      <p>Meanwhile, let's find your</p>{" "}
                      <p className="blue_text">
                        Design Business Awareness Quotient.
                      </p>
                    </div>
                  </div>

                  <div className="col-lg-5 intro_col">
                    <div className="next_button_container">
                      <Link to={() => false} className="cta_wrapper">
                        <img
                          src={consoleArrow}
                          alt="arrow"
                          className="arrow_img"
                        />
                        <p className="cta_text">start</p>
                      </Link>
                      <button className="remind_btn">Remind me later</button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mobile_notice">
                {teamMemeberData.map((item, i) => (
                  <Swiper
                    modules={[Pagination]}
                    spaceBetween={20}
                    pagination={true}
                  >
                    <SwiperSlide>
                      <ConsoleNoticeCard
                        title={item.heading}
                        notice={item.desc}
                      />
                    </SwiperSlide>
                    <SwiperSlide>
                      <ConsoleNoticeCard
                        title={item.heading}
                        notice={item.desc}
                      />
                    </SwiperSlide>
                    <SwiperSlide>
                      <ConsoleNoticeCard
                        title={item.heading}
                        notice={item.desc}
                      />
                    </SwiperSlide>
                    <SwiperSlide>
                      <ConsoleNoticeCard
                        title={item.heading}
                        notice={item.desc}
                      />
                    </SwiperSlide>
                  </Swiper>
                ))}
              </div>
            </div>
          </section>
        </section> */}
      </main>
    </>
  );
};

export default TeamMemberConsole;
