import React, { useEffect, useState } from "react";
import style from "../TeamAccess/teammember.module.scss";
import LogoutText from "../../../components/LogoutText/LogoutText";
import { rightarrowwhite } from "../../../images";
import RadioButton from "../../../components/RadioButton/RadioButton";
import { useDispatch, useSelector } from "react-redux";
import { updateCustomerProfile } from "../../../redux/app/appAction";
import { getCustomer, getUserId } from "../../../redux/app/appSelectors";
import callApi from "../../../util/apiCaller";

// const numbRangeArr = ["0-5", "5-10", "10-20", "20+"];

const TAStep04 = ({
  previousStep,
  nextStep,
  currentStep,
  totalSteps,
  progressStatus,
  handleUserExtraData,
  userExtraData
}) => {
  const dispatch = useDispatch();
  const userId = useSelector((state) => getUserId(state)) ;
  const customer = useSelector((state) => getCustomer(state));
  const [experience, setExperience] = useState({
    years_of_experience:"",
  })
  const [firstName, setFirstName] = useState("")
  const [code ,setCode] = useState({})
  const [showError, setShowError] = useState(true)
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if(customer?.contact_name){
      if(customer?.years_of_experience){
        setExperience({
          years_of_experience: customer?.years_of_experience
        })
      }
    let str = customer?.contact_name
    let str1 = ""
    for(let i=0; i<str.length; i++){
      if(str[i] === " "){
        break;
      }else{
        str1 += str[i];
      }
    }
setFirstName(str1)
  }
  },[customer])


  useEffect(() => {

    fetchContentCode("business-firm-experience-years")
   },[])
   async function fetchContentCode(code) {

    try {
      const res = await callApi(`content/code/${code}`, "get");
      console.log(res);
      if (res && res?.status === 'Success') {
        const { content } = res?.data;
        setCode(content);
      } else {
      }
    } catch (err) {

    }

  }

  const handleChangeUser = ( keyName,keyValue) => {
      let update = {...experience};
      if(keyValue){
        setErrors({})
      }
      update["years_of_experience"] = keyValue;
      update.current_flow = currentStep;
      setExperience(update);
      //handleUserExtraData()
  }
  const handleValidation = (user) => {
    let isValid = true;
    let errors = {};
    if (!user?.years_of_experience) {
        isValid = false;
        errors["years_of_experience"] = "Please select years of experience";
    }

      return {
        isValid,
        errors
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleUpdateApi  = () => {
    let check = handleValidation(experience);
    let update= {...experience}
    update.current_flow = currentStep;
    if(check.isValid && userId && experience){
      dispatch(updateCustomerProfile(userId, update))
      //handleUserExtraData(experience);
      nextStep(5);
      setErrors({})
      window.scrollTo(0, 0);
    }else {
      setErrors(check.errors);
    }

  }
  const renderError = (keyName) => {
    return errors && errors[keyName] ? <p className="error" style={{marginTop:"20px"}}>{errors[keyName]}</p> : null;
  };

  const numbRangeList = code?.params?.map((option) => (
    <React.Fragment key={option.label}>
      <RadioButton
        extraSpace={true}
        label={option.label}
        labelId={option.label}
        value={option.value}
        checked={option?.label === experience?.years_of_experience}
        radio_label_class={style.radio_label}
        handleChangeUser={handleChangeUser}
      />
    </React.Fragment>
  ));

  useEffect(() => {
    progressStatus((currentStep / totalSteps) * 100);
  }, [currentStep, progressStatus, totalSteps]);

  return (
    <>
      <div className={style.text_container}>
        <h1 className={style.title}>
           
 	
        {firstName}, Please tell us how many years of experience do you have ?
        </h1>
        <p className={`${style.description} ${style.description_step04}`}>
          Choose one
        </p>
      </div>
      <div className={`${style.steps} ${style.step02}`}>
        <ul className={`${style.step02_ul} ${style.step04_ul}`}>
          {numbRangeList}
        </ul>
        <div style={{display:"flex",justifyContent:"center",alignItems:"center"}}>{renderError("years_of_experience")} </div>
      </div>

      <div className={`${style.next_logout} ${style.step04_next_logout}`}>
     
        <div className={style.cta_wrapper}>
          <div
            className={style.next_button}
            onClick={handleUpdateApi}
          >
            <div className={style.text}>Next</div>
            <img
              src={rightarrowwhite}
              alt="icon"
              className={style.icon}
              loading="lazy"
            />
          </div>
          <div
            className={style.back_button}
            onClick={() => {
              previousStep(3);
              setErrors({})
              window.scrollTo(0, 0);
            }}
          >
            Back
          </div>
        </div>
        <LogoutText userExtraData={experience} />
      </div>
    </>
  );
};

export default TAStep04;
