import React, {useEffect, useState} from "react";
import "./resetpassword.scss";
import {reseticon, rightarrowwhite} from "../../images";
import FullWidthTextField from "../../components/TextField/FullWidthTextField";
import callApi from "../../util/apiCaller";
import {useNavigate, useSearchParams} from "react-router-dom";
import {loginURL} from "../../components/helpers/constant-words";
import { ErrorsMsg, SuccessMsg } from "../../util/Message";
import { toast } from "react-toastify";
import {errorMsg} from "../../util/toast";
import PasswordInput from "../../components/PasswordInput/PasswordInput";
import BlinkingDots from "../../Animations/BlinkingDots/BlinkingDots";

const ResetPassword = () => {

    const navigate = useNavigate();
    let [searchParams, setSearchParams] = useSearchParams();
    const [hideInput, setHideInput] = useState(true)

    useEffect(() => {
        let q_token = searchParams.get("reset_token");
        let q_email = searchParams.get("email");
        setEmail(q_email);
        setToken(q_token);
    }, [])

    const [email, setEmail] = useState("");
    const [token, setToken] = useState("");
    const [password, setPassword] = useState("");
    const [confirm_password, setConfirmPassword] = useState("");
    const [errors, setErrors] = useState("");

    const handleChangeUser = (keyName, keyValue) => {
        let update = ""
        if(keyName === "confirm_password"){
            update = keyValue
            setConfirmPassword(update)
        }else{
            update = keyValue
            setPassword(update)
        }
    }

    const onSubmit = async () => {
        
        if (confirm_password !== password) {
            setErrors("Passwords are not matching");
        } else if (password.toString().length < 8) {
            setErrors("Password should be atleast 8 characters");
        } else {
            try {
                let result = await callApi(`customer/auth/reset-password/${token}`, "post", {
                    customer: {
                        contact_email: email,
                        password: password
                    }
                });
                if (result?.data?.isReset) {
                    // toast(SuccessMsg("password has been reset successfully"), {
                    //     className: "mail_toast",
                    //     position: toast.POSITION.TOP_CENTER,
                    //     hideProgressBar: true,
                    //     closeButton: false,
                    // });
                    setHideInput(false)
                } else {
                    //errorMsg(result?.data?.message);
                    toast(ErrorsMsg(result?.data?.message),{
                        className:"mail_toast",
                        position: toast.POSITION.TOP_CENTER,
                        hideProgressBar: true,
                        closeButton: false,
                      })
                }
                setErrors("");
            } catch (e) {
                console.log(e);
            }
        }
    };

    return (
        <>
         <BlinkingDots />
            <main className="rpass_container">
                <section className="rpass_sec1">
                   <div className="my_container">
                    <div className="text_container">
                            <div className="icon_wrapper">
                                <img
                                    width={108}
                                    height={102}
                                    src={reseticon}
                                    alt=""
                                    className="reset_icon"
                                />
                            </div>
                            <h3 className="title">Reset Your Password</h3> 
                            
                        </div> 
                        {hideInput === true ? <div className="login_field_wrapper">
                           <div className="field_wrapper">
                                <PasswordInput
                                    value={password}
                                    handleChangeUser={handleChangeUser}
                                    keyName={"password"}
                                    label="New password*" 
                                    id={"reset-password"}
                                />
                                <p className="reset_notice">
                                    your password should be at least 8 characters
                                </p>
                            </div> 
                            <div className="field_wrapper">
                                <PasswordInput
                                    value={confirm_password}
                                    handleChangeUser={handleChangeUser}
                                    keyName={"confirm_password"}
                                    label="Re-enter password*"
                                    id={"re-enter-reset-password"}
                                />
                            </div>
                            {errors ? <p className="error">{errors}</p> : null}
                        </div> :<p style={{display:"flex", justifyContent:"center", fontSize:"18px", textAlign:"center"}}>Password reset successful, please login with new credentials</p>}
                        {hideInput === true ? <div className="cta_wrapper">
                            <div className="btn_wrapper">
                                <button
                                    onClick={onSubmit}
                                    className="form_btn">
                                    Reset Password
                                    <img
                                        src={rightarrowwhite}
                                        alt="icon"
                                        className="icon"
                                        loading="lazy"
                                    />
                                </button>
                            </div>
                        </div> :null}
                    </div> 

                    
                </section>
            </main>
        </>
    );
};
export default ResetPassword;


