import React from "react";
import "./teammemberchangerole.scss";
import { consoleDot, regBackground, rightarrowwhite } from "../../images";
import RadioButton from "../../components/RadioButton/RadioButton";

const TeamMemberChangeRole = () => {
  return (
    <>
      <section className="team_change_sec1">
        <div className="my_container">
          <img
            src={regBackground}
            alt="background"
            className="console_background"
          />
          <img src={consoleDot} alt="dot" className="console_dot1" />
          <img src={consoleDot} alt="dot" className="console_dot2" />
        </div>
      </section>
      <section className="team_change_sec2">
        <div className="my_container">
          <div className="text-container">
            <h1 className="title">Fill Us In</h1>
            <p className="desc">What's New!</p>
            <h2 className="sub_heading">
              Your Role In The Design & Build Industry?
            </h2>
            <p className="choose_text">Choose one</p>
          </div>
          <ul className="radio_container">
            <RadioButton
              label="Business | Firm Owner"
              labelId="Business | Firm Owner"
              isPro={true}
            />
            <RadioButton
              label="Team Member"
              labelId="Team Member"
              isPro={true}
              extraSpace={true}
            />
          </ul>
          <div className="next_logout">
            <div className="cta_wrapper">
              <div className="next_button">
                <div className="text">Change My Role</div>
                <img
                  src={rightarrowwhite}
                  alt="icon"
                  className="icon"
                  loading="lazy"
                />
              </div>
              <div className="back_button">Back</div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default TeamMemberChangeRole;
