import React, { useEffect, useState } from "react";
import style from "../TeamAccess/teammember.module.scss";
import LogoutText from "../../../components/LogoutText/LogoutText";
import { rightarrowwhite } from "../../../images";
import AutoCompleteField from "../../../components/AutoCompleteField/AutoCompleteField";
import { coursesArr } from "../../../db/dataTypesData";
import { useDispatch, useSelector } from "react-redux";
import {  updateCustomerProfile } from "../../../redux/app/appAction";
import { getCustomer, getUserId } from "../../../redux/app/appSelectors";
import callApi from "../../../util/apiCaller";
import {toast} from "react-toastify";
import { plusicon } from "../../../images";
import {MsgWithVarText} from "../../EditProfile/EditProfile";
import _ from "lodash";
import FullWidthTextField from "../../../components/TextField/FullWidthTextField";
import CheckboxButton from "../../../components/CheckboxButton/CheckboxButton";

const TAStep01 = ({ nextStep, currentStep, totalSteps, progressStatus , handleUserExtraData, userExtraData }) => {
  const dispatch = useDispatch();
  const userId = useSelector((state) => getUserId(state)) ;
  const customer = useSelector((state) => getCustomer(state));
  const [code ,setCode] = useState([]);
  const [user, setUser] = useState({
    past_work_experience: []
  });
  const [errors, setErrors] = useState({});


  const [otherInputValue, setAddOtherInputValue] = useState("");

  useEffect(() => {
    if(customer?.past_work_experience){
    setUser({
      past_work_experience: customer?.past_work_experience
    })
  }
  },[customer])

  useEffect(() => {
   const handleKeyPress = (e) => {
     if (e.key === 'Enter' && otherInputValue !== "") {
       //handleUserDetail("past_work_experience", otherInputValue);
       if(user?.past_work_experience?.indexOf(otherInputValue) === -1) {
         handleUserDetail("past_work_experience", otherInputValue);
        //  let arr = [...code]
        //  arr?.push(otherInputValue)
        //  setCode([...arr])
       } else {
         toast(<MsgWithVarText text={'Tag already present'} />, {
           className: "mail_toast",
           position: toast.POSITION.TOP_CENTER,
           hideProgressBar: true,
           closeButton: false,
         })
       }
       setAddOtherInputValue("");
     }
   };

   window.addEventListener('keypress', handleKeyPress);

   return () => {
     window.removeEventListener('keypress', handleKeyPress);
   };
 }, [otherInputValue]);

  useEffect(() => {

    fetchContentCode("team-member-jobs")
   },[])
   async function fetchContentCode(code) {

    try {
      const res = await callApi(`content/code/${code}`, "get");
      console.log(res);
      if (res && res?.status === 'Success') {
        const { params } = res?.data.content;
        let param = params?.map((option) => {
            return option?.label
      })
        setCode([...param]);
      } else {
      }
    } catch (err) {

    }

  }
  const handleUser = (keyName,keyValue) => {
    if(keyValue && user?.past_work_experience?.indexOf(keyValue) === -1){
     
          handleUserDetail("past_work_experience",keyValue)
      

      }else{
        if(user?.past_work_experience?.indexOf(keyValue) !== -1){
        toast(<MsgWithVarText text={'Tag already present'} />, {
          className: "mail_toast",
          position: toast.POSITION.TOP_CENTER,
          hideProgressBar: true,
          closeButton: false,
        })
      }
      }
  }

  const handleUserDetail = (keyName, keyValue) => {
    let prevIndex = user?.past_work_experience?.indexOf(keyValue);

    if(prevIndex !== -1) {
      let update = {
        ...user,
        [keyName]: user?.past_work_experience?.filter(
            (_, index) => index !== prevIndex
        ),
      };
      setUser(update);
    } else {
      let update ={...user};
      update[keyName] = [...user?.past_work_experience];
      update[keyName].push(keyValue);
      update.current_flow = currentStep;
      setUser(update);
      setErrors({});
    }
   }
   const handleValidation = (user) => {

    let isValid = true;
    let errors = {};

    if (_.isEmpty(user?.past_work_experience)) {
        isValid = false;
        errors["past_work_experience"] = "Please select past work experience";
    }

    return {
        isValid,
        errors
    }
  };

  const handleUpdateApi = () => {
    let check = handleValidation(user);
    if(check.isValid && userId){
      let update= {...user}
      update.current_flow = currentStep;
      dispatch(updateCustomerProfile(userId, update))
      //handleUserExtraData(course);
      nextStep(2);
      setErrors({})
      window.scrollTo(0, 0);
    }else {
      setErrors(check.errors);
    }

   }
   useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
   const renderError = (keyName) => {
    return errors && errors[keyName] ? <p className="error" style={{marginTop:"10px"}}>{errors[keyName]}</p> : null;
  };

  const handleAddMore = () => {
    if(otherInputValue) {
        if(user?.past_work_experience?.indexOf(otherInputValue) === -1){
            handleUserDetail("past_work_experience",otherInputValue)

        }else{
            toast(<MsgWithVarText text={'Tag already present'} />, {
                className: "mail_toast",
                position: toast.POSITION.TOP_CENTER,
                hideProgressBar: true,
                closeButton: false,
            })
        }
        setAddOtherInputValue("")
    }
  }
  useEffect(() => {
    progressStatus((currentStep / totalSteps) * 100);
  }, [currentStep, progressStatus, totalSteps]);

  return (
    <>
      <div className={style.text_container}>
        <h1 className={style.title}>


    What is your current | past Job Profile ?
          <span className={style.break_title}>  </span>
        </h1>
      </div>
      <div className={`${style.steps} ${style.step01}`}>
      <div className={`${style.steps} ${style.step01}`}>
        <div className={style.field_wrapper}>
          <AutoCompleteField
            freeSolo={false}
            popupIcon={""}
            textLabel="Enter roles"
            data={code}
            //course={user?.past_work_experience}
            handleUserDetail={handleUser}
          />
             <div style={{display:"flex",justifyContent:"center", alignItems:"center"}}> {renderError("past_work_experience")} </div>
        </div>
    
      </div>
      <ul className={`${style.steps_ul}`}>
        {/* <div className={style.field_wrapper2}> */}
             <FullWidthTextField label="Add Others" key={"Add Others"} onChange={e => {
              setAddOtherInputValue(e.target.value)
              setErrors({});
            }} value={otherInputValue} />
              <img
              src={plusicon}
              alt="icon"
              className={style.icon}
              loading="lazy"
              onClick={handleAddMore}
            />
            {/* </div> */}
        </ul>
        {/* <div className={`${style.reduceSpace}`} > */}
        <div className={'my-4'}>
        {

            !_.isEmpty(user?.past_work_experience) ?
            user?.past_work_experience?.map((key, i) => (
                <React.Fragment key={`{key}+${i}ios`}>
                  <CheckboxButton isChecked={true} label={key} labelId={i + key} handleUserDetail={handleUserDetail} keyName={"past_work_experience"}/>
                </React.Fragment>
            )) :
            null

          }
        </div>
      </div>
      <div className={style.next_logout}>
      
        <div className={style.cta_wrapper}>
          <div
            className={style.next_button}
            onClick={handleUpdateApi}
          >
            <div className={style.text}>Next</div>
            <img
              src={rightarrowwhite}
              alt="icon"
              className={style.icon}
              loading="lazy"
            />
          </div>
        </div>
        <LogoutText userExtraData={user}/>
      </div>
    </>
  );
};

export default TAStep01;
