import React, {useEffect, useState} from "react";
import {checkiconWhite, otpicon, rightarrowwhite} from "../../../images";
import "./registerotp.scss";
import OtpInput from "react-otp-input";
import {toast} from "react-toastify";
import {useDispatch, useSelector} from "react-redux";
import {loginCustomerViaOTP, verifyCustomerOTP} from "../../../redux/app/appAction";
import {useNavigate} from "react-router-dom";
import {getCustomer} from "../../../redux/app/appSelectors";
import BlinkingDots from "../../../Animations/BlinkingDots/BlinkingDots";
import FooterV2 from "../../../components/FooterV2/FooterV2";

const RegisterOTP = () => {

  const [resend, setResend] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const customer = useSelector((state) => getCustomer(state));
  const [errors, setErrors] = useState({});
  const [time, setTime] = useState(0);

  const [otpVal, setOtpVal] = useState({
    "local_otp": "",
    "contact_email": ""
  });


  const handleotpChange = (keyName, keyValue) => {
    let update = {...otpVal};

    setErrors({})
    update[keyName] = keyValue;
    update.contact_email = customer?.contact_email;
    setOtpVal(update);
  };
  const otpValidation = (user) => {
    let isValid = true;
    let errors = {};
    if (!user?.local_otp) {
        isValid = false;
        errors["local_otp"] = "otp cannot be blank";
    }else if(user?.local_otp.length !== 6){
      isValid=false;
      errors["local_otp"] = "otp should of 6 digit";
    }

    return {
        isValid,
        errors
    }
  }
  const verifyHandler = () => {
      let customer = {};
      customer.login_otp = otpVal.local_otp;
      customer.contact_email = customer?.contact_email;
      customer.contact_phone = customer?.contact_phone;

      let check = otpValidation(otpVal)
      if(check.isValid){
      dispatch(verifyCustomerOTP(otpVal, navigate))
      setErrors({})
      } else {
          setErrors(check?.errors);
      }
  }

  const Msg = () => (
    <div className="otp_box">
      <img
        width={39}
        height={39}
        src={checkiconWhite}
        className="otp_resend_icon"
        loading="lazy"
        alt="icon"
      />
      <p className="title">OTP reshared</p>
    </div>
  );
  const resendHandler = () => {

    if (resend === false && customer) {
      dispatch(loginCustomerViaOTP(customer, ""));
      resetTimer();
      setErrors({})
      setOtpVal({
        local_otp:""
      })
      setResend(true);
    } else {
      setResend(false);
    }
  };

  const renderError = (keyName) => {
    return errors && errors[keyName] ? (<p className="error" style={{ marginTop: "20px"}}>{errors[keyName]}</p>) : null;
  };
  useEffect(() => {
    const resetButton = setTimeout(() => {
      setResend(false);
    }, 1000);
    return () => clearTimeout(resetButton);
  }, [resend]);

  useEffect(() => {
    let timerInterval = null;

    // Decrease time by 1 second every second
    if (time > 0) {
      timerInterval = setInterval(() => {
        setTime(prevTime => prevTime - 1);
      }, 1000);
    }

    // Clear interval and reset timer when time reaches 0
    if (time === 0) {
      clearInterval(timerInterval);
    }

    return () => clearInterval(timerInterval);
  }, [time])

  const resetTimer = () => {
    setTime(60);
  };

  return (
    <>
      <BlinkingDots />
      <section className="otp_sec1">
        <div className="my_container">
          <div className="text_container" style={{marginTop:"40px"}}>
            <p className="head_text">OTP Verification</p>
            <h1 className="title">
              Verify Your OTP, Sent To Your Registered&nbsp;
              <br />
              E-Mail ID & Mobile Number
              <span className="coloured_text">.</span>
            </h1>
            <div className="icon_notice_wrapper">
              <div className="icon_wrapper">
                <img
                  width={81}
                  height={81}
                  src={otpicon}
                  alt="icon"
                  className="icon"
                  loading="lazy"
                />
              </div>
              <p className="description">Enter OTP</p>
            </div>
          </div>

          <div className="otp_box_wrapper">
            <OtpInput
              value={otpVal?.local_otp}
              onChange={(e) => handleotpChange("local_otp", e)}
              numInputs={6}
              containerStyle="otp_input_wrapper"
              inputStyle="otp_input"
              isInputNum={true}
              focusStyle="otp_focused"
              shouldAutoFocus
            />
            <div style={{display:"flex",alignItems:"centre",justifyContent:"center"}}>{renderError("local_otp")}</div>
          </div>
          <div className="next_logout" style={{ marginBottom:"40px"}}>
           
            <div className="cta_wrapper">
              <div
                  className="next_button"
                  style={{
                    opacity: otpVal === "" ? 0.5 : 1,
                    cursor: otpVal === "" ? "no-drop" : "pointer",
                  }}
                  onClick={verifyHandler}
              >
                <div className="text">Verify</div>
                <img
                    src={rightarrowwhite}
                    alt="icon"
                    className="icon"
                    loading="lazy"
                />
              </div>
              {time !== 0 ?
                  <div className="back_button">
                    Resend otp in {time} sec
                  </div>
                  :
                  <div className="back_button" onClick={resendHandler}>
                    {resend === false ? "Resend OTP" : "Resending OTP"}
                  </div>
              }
            </div>
          </div>
        </div>
      
      </section>
      {/* <FooterV2 /> */}
    </>
  );
};

export default RegisterOTP;
