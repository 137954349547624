import React, { useEffect, useState } from "react";
import style from "../Form/formfive.module.scss";
import CheckboxButton from "../../../components/CheckboxButton/CheckboxButton";
import LogoutText from "../../../components/LogoutText/LogoutText";
import { rightarrowblack, rightarrowwhite } from "../../../images";
import FullWidthTextField from "../../../components/TextField/FullWidthTextField";
import callApi from "../../../util/apiCaller";
import { updateCustomerArchPro } from "../../../redux/app/appAction";
import { plusicon } from "../../../images";
import { useDispatch, useSelector } from "react-redux";
import { getBrandId, getBrand } from "../../../redux/app/appSelectors";
import AutoCompleteField from "../../../components/AutoCompleteField/AutoCompleteField";
import {toast} from "react-toastify";
import {MsgWithVarText} from "../../EditProfile/EditProfile";
import {ErrorsMsg, SuccessMsg} from "../../../util/Message";
import { setBrand, setBrandId,updateCustomerArchProV2,setCustomerData} from "../../../redux/app/appAction";
import _ from "lodash";


// const formBfirm_design_styleArr = [
//   "Art",
//   "Sculpture",
//   "Tiles",
//   "Furniture",
//   "Wallpaper",
//   "Wall Finishes",
//   "Textures",
//   "Air Conditioning",
//   "Ducting",
//   "Wooden Flooring",
//   "Curtains",
//   "Furnishing",
// ];

// const newFormBfirm_design_styleArr = ["Residential"];

const FAStep08 = ({
  previousStep,
  nextStep,
  currentStep,
  totalSteps,
  progressStatus,
  handleUserExtraData,
  userExtraData,
  goToStep
}) => {
  const dispatch = useDispatch();
  const brandId = useSelector((state) => getBrandId(state)) ;
  const brandList = useSelector((state) => getBrand(state));
  const [code, setCode] = useState([])
  const [showError, setShowError] = useState(true)
  const [errors, setErrors] = useState({});
  const [newCode, setNewCode] = useState([])
  const [user, setUser] = useState({
    "firm_design_style": []
  });
  const [otherInputValue, setAddOtherInputValue] = useState("");

 useEffect(() => {
  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && otherInputValue !== "") {
      //handleUserDetail("firm_design_style", otherInputValue);
      if(user?.firm_design_style?.indexOf(otherInputValue) === -1) {
        handleUserDetail("firm_design_style", otherInputValue);
        let arr = [...code]
        arr?.push(otherInputValue)
        setCode([...arr])
      } else {
        toast(<MsgWithVarText text={'Tag already present'} />, {
          className: "mail_toast",
          position: toast.POSITION.TOP_CENTER,
          hideProgressBar: true,
          closeButton: false,
        })
      }
      setAddOtherInputValue("");
    }
  };

  window.addEventListener('keypress', handleKeyPress);

  return () => {
    window.removeEventListener('keypress', handleKeyPress);
  };
}, [otherInputValue]);

useEffect(() => {
  if(brandList?.firm_design_style){
    setUser({
      "firm_design_style": brandList?.firm_design_style,

   })
  }
},[brandList])

 const handleUserDetail = (keyName, keyValue) => {
  let prevIndex = user?.firm_design_style?.indexOf(keyValue);

  if(prevIndex !== -1) {
    let update = {
      ...user,
      [keyName]: user?.firm_design_style?.filter(
          (_, index) => index !== prevIndex
      ),
    };
    setUser(update);
  } else {
    let update ={...user};
    update[keyName] = [...user?.firm_design_style];
    update[keyName].push(keyValue);
    update.brand_current_flow = currentStep;
    setUser(update);
    setErrors({});
  }
 }
  const handleValidation = (user) => {

    let isValid = true;
    let errors = {};

    if (_.isEmpty(user?.firm_design_style)) {
        isValid = false;
        errors["firm_design_style"] = "Please select firm design style";
    }

    return {
        isValid,
        errors
    }
  };

 const handleUpdateContactDetail = () => {
   // if (e) {
   //     e.preventDefault();
   // }
   
   let check = handleValidation(user);
    if(check.isValid && brandId){
      updateCustomerArchProV2(brandId, user).then(res => {
        if(res?.status === 'Success' && res?.data?.brand) {
          dispatch(setCustomerData(res?.data?.brand));
          nextStep();
          window.scrollTo(0, 0);
        } else {
          toast(ErrorsMsg(res?.data?.message),{
            className:"mail_toast",
            position: toast.POSITION.TOP_CENTER,
            hideProgressBar: true,
            closeButton: false,
          })
        }
      });
      setErrors({})
    }else {
      setErrors(check?.errors);
  }

}
const handleUser = (keyName,keyValue) => {
  if(keyValue && user?.firm_design_style?.indexOf(keyValue) === -1){
    handleUserDetail("firm_design_style",keyValue)

    }else{
      toast(<MsgWithVarText text={'Tag already present'} />, {
        className: "mail_toast",
        position: toast.POSITION.TOP_CENTER,
        hideProgressBar: true,
        closeButton: false,
      })
    }
}
const renderError = (keyName) => {
  return errors && errors[keyName] ? <p className="error" style={{margin: "10px 0"}}>{errors[keyName]}</p> : null;
};

  useEffect(() => {

    fetchContent("03-a-design-style")
    // fetchContent("form-b-new-firm_design_style")
   },[])


  //  function setContentCode(params,code){
  //   switch (code) {
  //     case "firm_design_style-products" :

  //       setCode(params);
  //       break;
  //     case "form-b-new-firm_design_style":

  //       setNewCode(params);
  //       break;
  //     default :
  //     break;
  //   }
  //  }

  async function fetchContent(code) {

    try {
      const res = await callApi(`content/code/${code}`, "get");

      if (res && res?.status === 'Success') {
        const { params } = res?.data?.content;
        console.log(params);
        let param = params?.map((options) => options?.label)
        setCode(param)
      } else {
      }
    } catch (err) {

    }
  }

  // const formBfirm_design_styleList = code?.map((option, i) => (
  //   user.firm_design_style?.indexOf(option.value) === -1 ?
  //   <React.Fragment key={option.value}>
  //     <CheckboxButton isChecked={false} label={option.label} labelId={option?._id + option.value} handleUserDetail={handleUserDetail} keyName={"firm_design_style"}/>
  //   </React.Fragment> :
  //   null
  // ));

  // const newFormBfirm_design_styleList = newCode?.map((option, i) => (
  //   user.firm_design_style?.indexOf(option.value) === -1 ?
  //   <React.Fragment key={option}>
  //     <CheckboxButton
  //       isChecked={false}
  //       label={option.label} labelId={option?._id + option.value}
  //       handleUserDetail={handleUserDetail}
  //       keyName={"firm_design_style"}
  //     />
  //   </React.Fragment> : null
  // ));
  const handleAddMore = () => {
    if(otherInputValue && user?.firm_design_style?.indexOf(otherInputValue) === -1){
    handleUserDetail("firm_design_style",otherInputValue)

    }else{
      toast(<MsgWithVarText text={'Tag already present'} />, {
        className: "mail_toast",
        position: toast.POSITION.TOP_CENTER,
        hideProgressBar: true,
        closeButton: false,
      })
    }
    setAddOtherInputValue("")
  }


  return (
    <>
      <div className={style.text_container}>
      <p className={`${style.head_text1}`}>About Your Project</p>
        <h1 className={style.title}>What Firm Design Style Do You Provide?</h1>
        <p className={style.description}></p>
      </div>
      <div className={`${style.steps} ${style.reduceSpace}`}>

        <div className={`${style.add_more_wrap} ${style.add_more_v2}`}>
          <div className={style.field_wrapper3}>
          <AutoCompleteField textLabel="Your Design Style*" id={"firm_design_stylec2"} data={code} disableClearable={true} handleUserDetail={handleUser} keyName={"firm_design_style"} />

            <p style={{margin:"10px 0", textAlign: 'center'}}>{renderError("firm_design_style")}</p>
          </div>
        </div>
        <ul className={`${style.steps_ul} ${style.new_list}`}>
        <div className={style.field_wrapper2}>
             <FullWidthTextField label="Add Others" key={"Add Others"} onChange={e => {
              setAddOtherInputValue(e.target.value)
              setErrors({});
            }} value={otherInputValue} />
              <img
              src={plusicon}
              alt="icon"
              className={style.icon}
              loading="lazy"
              onClick={handleAddMore}
            />
            </div>
        </ul>
        <ul className={style.steps_ul} style={{marginTop:"50px"}}>
        {
            !_.isEmpty(user?.firm_design_style) ?
                user?.firm_design_style?.map((key, i) => (
                    <React.Fragment key={`{key}+${i}2c1`}>
                      <CheckboxButton isChecked={true} label={key} labelId={i + key} handleUserDetail={handleUserDetail} keyName={"firm_design_style"}/>
                    </React.Fragment>
                )) :
                null
          }
        </ul>
      </div>

      <div className={style.next_logout}>
        <div className={style.cta_wrapper}>
          <div
            className={style.next_button}
            onClick={handleUpdateContactDetail}
          >
            <div className={style.text}>Next</div>
            <img
              src={rightarrowblack}
              alt="icon"
              className={style.icon}
              loading="lazy"
            />
          </div>
          <div
            className={style.back_button}
            onClick={() => {
              previousStep()
              setErrors({});
              window.scrollTo(0, 0);
            }}
          >
            Back
          </div>
        </div>
        <LogoutText userData={user}/>
      </div>
    </>
  );
};

export default FAStep08;
