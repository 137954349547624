import Modal from "react-bootstrap/Modal";
import "./formfive-modal.scss";

const defineArr = [
  "Design Firm | Consultancy : Architecture, Interior, Landscape, Urban Design, Landscape etc.",
  "Services Design Firm | Consultancy : MEP, Vaastu, HVAC, Lighting, Waterbody, LEED, art etc. related consultancy",
  "Design & Build : you do both the design and build/ install steps of a project",
  "Product | Material - Seller | Installer | Contractor : you execute/ manufacture/ install/ sell elements of a project, designed by another",
  "E Commerce Retailer : you sell products on your E-commerce store/website",
  "Curator | Stylist | Gallery : You curate art, exhibitions and style visual elements", 
  "Event & Publishing House : Design based Events for the fraternity; Publishing House: Produces written/digital content.",
  "PR | Marketing | Content | Photography :  you contribute to the design industry through PR, Marketing, Content and Photography",
  "National | International Brand :  you manufacture and distribute products through retailers and distributors",
];

function FormFiveModal(props) {
  const defineList = defineArr.map((data, i) => (
    <li className="list_item" key={`define-${Math.random(i)}`}>
      {data}
    </li>
  ));
  return (
    <Modal
      {...props}
      className={`define_modal ${props.className}`}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <div className="define_modal_wrapper">
          <div className="icon">&#9432;</div>
          <ul className="define_list">{defineList}</ul>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default FormFiveModal;
