import React, { useState } from "react";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { useWindowSize } from "react-use";

const PasswordInputLightTheme = ({handleChangeUser, value, keyName, label,id}) => {
  const [showPassword, setShowPassword] = useState(false);
  const { width } = useWindowSize();

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const lighttheme = createTheme({
    typography: {
      fontFamily: "Poppins",
    },
    palette: {
      mode: "light",
    },
  });

  return (
    <>
      <ThemeProvider theme={lighttheme}>
        <CssBaseline />
        <FormControl fullWidth variant="outlined">
          <InputLabel
            htmlFor="outlined-adornment-password"
            sx={{
              "& label": {
                lineHeight: width > 768 ? "2em" : "1.5em",
              },
            }}
          >
            {label}
          </InputLabel>
          <OutlinedInput
             id={id}
            type={showPassword ? "text" : "password"}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            label={label}
            value={value}
            onChange={(e) => handleChangeUser(keyName, e.target.value)}
            inputProps={{
              style: {
                fontSize: width > 768 ? "1.25em" : "1.25em",
                borderRadius: width > 768 ? "10px" : "10px",
              },
            }}
            sx={{
              "& fieldset": {
                borderRadius: width > 768 ? "10px" : "10px",
                border: "1px solid #707070",
                background: "rgba(255, 255, 255,0.05)",
                // color: "#111",
              },
              // input label when focused
              "& label": {
                lineHeight: width > 768 ? "2em" : "1.5em",
              },
              // input label when focused
              "& label.Mui-focused": {
                color: "#111",
              },
              // focused color for input with variant='standard'
              "& .MuiInput-underline:after": {
                borderBottomColor: "#111",
              },
              // focused color for input with variant='filled'
              "& .MuiFilledInput-underline:after": {
                borderBottomColor: "#111",
              },
              // focused color for input with variant='outlined'
              "& .MuiOutlinedInput-root": {
                "&.Mui-focused fieldset": {
                  borderColor: "#111",
                },
              },
            }}
          />
        </FormControl>
      </ThemeProvider>
    </>
  );
};

export default PasswordInputLightTheme;
