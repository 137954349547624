import React, { useEffect, useState } from "react";
import style from "../Form/formfive.module.scss";
import LogoutText from "../../../components/LogoutText/LogoutText";
import FullWidthTextField from "../../../components/TextField/FullWidthTextField";
import {
  blackDeleteicon,
  deleteicon,
  formupload,
  imgclose,
  plusicon,
  rightarrowblack,
  rightarrowwhite,
} from "../../../images";
import TextFieldWithIcon from "../../../components/TextFieldWithIcon/TextFieldWithIcon";
import { updateCustomerArchPro, onAddProjects } from "../../../redux/app/appAction";
import { useDispatch, useSelector } from "react-redux";
import { getBrandId, getUserId } from "../../../redux/app/appSelectors";
import { useNavigate } from "react-router-dom";
import { consoleUrl } from "../../../components/helpers/constant-words";
import { useWindowScroll } from "react-use";
import axios from "axios";
import _ from "lodash";
import {PDF_IMAGE_FILE_SIZE} from "../../../constants/enums";
import { PDF_IMAGE_FILE_COMPANY_PROFILE_SIZE } from "../../../constants/enums";
import {ErrorsMsg, SuccessMsg} from "../../../util/Message";
import {toast} from "react-toastify";
import { setBrand, setBrandId,updateCustomerArchProV2,setCustomerData} from "../../../redux/app/appAction";
import callApi from "../../../util/apiCaller";
import FullWidthTextFieldLightTheme from "../../../components/TextFieldLightTheme/FullWidthTextFieldLightTheme";
const API_URL = process.env.REACT_APP_API_URL || `https://apidev.archinza.com`;

//const formAProjectPhotos = ["Img123.jpg", "Img123.jpg", "Img123.jpg"];

const FAStep16 = ({
  previousStep,
  nextStep,
  currentStep,
  totalSteps,
  progressStatus,
  handleUserExtraData,
  userExtraData,
}) => {
  const navigate = useNavigate()
  const dispatch = useDispatch();
  const userId = useSelector((state) => getUserId(state)) ;
  const brandId = useSelector((state) => getBrandId(state))
  const [errors, setErrors] = useState({});
  const [dataErrors, setDataErrors] = useState([])
  const [error, setError] = useState(false);
  const [catalogueData, setCatalogueData] = useState([])
  const [user, setUser] = useState([{
    project_name: "",
    project_images: [],
    wip_images: [],
    project_image_name: [],
    wip_image_name: []
 }]);

 useEffect(() => {
  if(brandId){
  async function fetchImages(brandId){
        try {
            const res = await callApi(`project/list`, "post", {
              "pageNum": 1,
              "pageSize": 20,
              "filters": {"brand_id": brandId}
          });
         
          if (res && res?.status === 'Success') {
            const {projectList, projectCount} = res?.data;
          
            let id = projectList[0]?.project_detail[0]?._id
            
            let arr1 = [...user]
           
             arr1 = [...projectList[0]?.project_detail]
         
            setUser(arr1)
             
              let data2 = projectList[0]?.project_detail?.filter((item) => {
              return item?._id !== id
              })
              
              setCatalogueData(data2)
            }

          
        } catch (err) {
            // dispatch(SET_IS_LOADING(false));
        }
    }
    fetchImages(brandId)
  }
 },[brandId])

  const handleChangeUser = async( e,i) => {

    let update = [...user];
    const {name, value} = e.target;
      if(!_.isEmpty(e?.target?.files) && e?.target?.files[0].size/(1024*1024) > PDF_IMAGE_FILE_SIZE) {
        toast(ErrorsMsg(`File size should be < ${PDF_IMAGE_FILE_SIZE} mb`),{
          className: "mail_toast",
          position: toast.POSITION.TOP_CENTER,
          hideProgressBar: true,
          closeButton: false,
        });
      } else {
        if(name === "project_images" && name && value && !_.isEmpty(e?.target?.files)){
          const files = e?.target?.files[0]
          const fileExtension = files.name.split(".").pop().toLowerCase();
          const fileType = files.type; 
          const allowedTypes = ["application/pdf", "image/png", "image/jpeg", "image/jpg"];
          if (allowedTypes.includes(fileType) || (fileExtension === "pdf" || fileExtension === "png" || fileExtension === "jpg" || fileExtension === "jpeg")) {
              if(!update[0]?.project_image_name?.includes(e.target.files[0].name)) {
                update[0]["project_image_name"]?.push(e.target.files[0].name);
                let data = new FormData();
                let img1 = e.target.files[0]
                data.append('file', img1);

                let config = {
                  method: 'post',
                  url: `${API_URL}/media/upload`,
                  data : data
                };

                let result = await axios.request(config);
                //console.log(result);
                if (result?.data?.data?.link) {
                  update[0]["project_images"]?.push(result?.data?.data?.link);
                }
              } else {
                toast(ErrorsMsg("File already uploaded"),{
                  className: "mail_toast",
                  position: toast.POSITION.TOP_CENTER,
                  hideProgressBar: true,
                  closeButton: false,
                });
              }
          }else{
            toast(ErrorsMsg("Invalid file type. Please select a PDF, PNG, JPG, or JPEG file."),{
              className: "mail_toast",
              position: toast.POSITION.TOP_CENTER,
              hideProgressBar: true,
              closeButton: false,
            });
          }
        }else if(name === "wip_images" && name && value && !_.isEmpty(e?.target?.files)){
          const files = e?.target?.files[0]
          const fileExtension = files.name.split(".").pop().toLowerCase();
          const fileType = files.type; 
          const allowedTypes = ["application/pdf", "image/png", "image/jpeg", "image/jpg"];
          if (allowedTypes.includes(fileType) || (fileExtension === "pdf" || fileExtension === "png" || fileExtension === "jpg" || fileExtension === "jpeg")) {
              if(!update[0]?.wip_image_name?.includes(e.target.files[0].name)) {
                update[0]["wip_image_name"]?.push(e.target.files[0].name);
                let data = new FormData();
                data.append('file', e.target.files[0]);

                let config = {
                  method: 'post',
                  url: `${API_URL}/media/upload`,
                  data : data
                };

                let result = await axios.request(config);
                //console.log(result);
                if (result?.data?.data?.link) {
                  update[0]["wip_images"]?.push(result?.data?.data?.link);
                }
              } else {
                toast(ErrorsMsg("File already uploaded"),{
                  className: "mail_toast",
                  position: toast.POSITION.TOP_CENTER,
                  hideProgressBar: true,
                  closeButton: false,
                });
              }
           }else{
            toast(ErrorsMsg("Invalid file type. Please select a PDF, PNG, JPG, or JPEG file."),{
              className: "mail_toast",
              position: toast.POSITION.TOP_CENTER,
              hideProgressBar: true,
              closeButton: false,
            });
           }
        }else {

          update[0][name] = value;
        }
      }
    e.target.value = null;
    setErrors(prev => ({...prev, [name]:""}))
    setUser(update)
  }

  const handleUser = async(e,i) => {
    const {name, value} = e.target;
    let tempErrors = [...dataErrors];
    const onchangeVal = [...catalogueData];
    tempErrors[i] = dataErrors[i] ? {...dataErrors[i]} : {};
    if(!_.isEmpty(e?.target?.files) && e?.target?.files[0].size/(1024*1024) > PDF_IMAGE_FILE_SIZE) {
      toast(ErrorsMsg(`File size should be < ${PDF_IMAGE_FILE_SIZE} mb`),{
        className: "mail_toast",
        position: toast.POSITION.TOP_CENTER,
        hideProgressBar: true,
        closeButton: false,
      });
    } else {
        if(name === "project_images" && name && value){
          const files = e?.target?.files[0]
          const fileExtension = files.name.split(".").pop().toLowerCase();
          const fileType = files.type; 
          const allowedTypes = ["application/pdf", "image/png", "image/jpeg", "image/jpg"];
          if (allowedTypes.includes(fileType) || (fileExtension === "pdf" || fileExtension === "png" || fileExtension === "jpg" || fileExtension === "jpeg")) {
              if(!onchangeVal[i]?.project_image_name?.includes(e.target.files[0].name)) {
              tempErrors[i]["project_images"] = "";
              onchangeVal[i]["project_image_name"]?.push(e.target.files[0].name);
              let data = new FormData();
              data.append('file', e.target.files[0]);

                  let config = {
                    method: 'post',
                    url: `${API_URL}/media/upload`,
                    data : data
                  };

                  let result = await axios.request(config);
                  //console.log(result);
                  if (result?.data?.data?.link) {
                    onchangeVal[i]["project_images"]?.push(result?.data?.data?.link);
                  }
                } else {
                  toast(ErrorsMsg("File already uploaded"),{
                    className: "mail_toast",
                    position: toast.POSITION.TOP_CENTER,
                    hideProgressBar: true,
                    closeButton: false,
                  });
                }
          }else{
            toast(ErrorsMsg("Invalid file type. Please select a PDF, PNG, JPG, or JPEG file."),{
              className: "mail_toast",
              position: toast.POSITION.TOP_CENTER,
              hideProgressBar: true,
              closeButton: false,
            });
          }

      }else if(name === "wip_images" && name && value && !_.isEmpty(e?.target?.files)){
        const files = e?.target?.files[0]
        const fileExtension = files.name.split(".").pop().toLowerCase();
        const fileType = files.type; 
        const allowedTypes = ["application/pdf", "image/png", "image/jpeg", "image/jpg"];
        if (allowedTypes.includes(fileType) || (fileExtension === "pdf" || fileExtension === "png" || fileExtension === "jpg" || fileExtension === "jpeg")) {
            if(!onchangeVal[i]?.wip_image_name?.includes(e.target.files[0].name)) {
                tempErrors[i]["wip_images"] = "";
                onchangeVal[i]["wip_image_name"]?.push(e.target.files[0].name);
                let data = new FormData();
                data.append('file', e.target.files[0]);

                let config = {
                  method: 'post',
                  url: `${API_URL}/media/upload`,
                  data : data
                };

                let result = await axios.request(config);
                //console.log(result);
                if (result?.data?.data?.link) {
                  onchangeVal[i]["wip_images"]?.push(result?.data?.data?.link);
                }
            }else {
              toast(ErrorsMsg("File already uploaded"),{
                className: "mail_toast",
                position: toast.POSITION.TOP_CENTER,
                hideProgressBar: true,
                closeButton: false,
              });
            }
        }else{
          toast(ErrorsMsg("Invalid file type. Please select a PDF, PNG, JPG, or JPEG file."),{
            className: "mail_toast",
            position: toast.POSITION.TOP_CENTER,
            hideProgressBar: true,
            closeButton: false,
          });
        }
      }else {
        tempErrors[i][name] = ""
        onchangeVal[i][name] = value;
      }
    }

    e.target.value = null;
    setCatalogueData(onchangeVal)
    setDataErrors(tempErrors);

  }
  const handleAddMore = () => {
    setCatalogueData([...catalogueData,{
      project_name: "",
      project_images: [],
      wip_images: [],
      project_image_name: [],
      wip_image_name: []
   }])
    }

    const handleDeleteCatalogueImaged = (keyName, keyIndex, dataIndex) => {
      let tempData = catalogueData.map((data, i) => {
        if(i === dataIndex) {
          let tempCatalogueData = {...data};
          if(!_.isEmpty(tempCatalogueData?.[keyName])) {
            if(keyName === 'project_image_name') {
              tempCatalogueData[keyName].splice(keyIndex, 1);
              if(!_.isEmpty( tempCatalogueData?.project_images)) {
                tempCatalogueData.project_images.splice(i, 1);
              }
            } else if(keyName === 'wip_image_name') {
              tempCatalogueData[keyName].splice(keyIndex, 1);
              if(!_.isEmpty( tempCatalogueData?.wip_images)) {
                tempCatalogueData.wip_images.splice(keyIndex, 1);
              }
            }
          }

          return tempCatalogueData;
        } else {
          return data
        }
      })

      setCatalogueData(tempData);
    }

    const handleDeleteUserImages = (keyName, i) => {
      let tempUser = [...user];
      let tempData = {...tempUser[0]}
      if(!_.isEmpty(tempData?.[keyName])) {
        if(keyName === 'project_image_name') {
          tempData[keyName].splice(i, 1);
         if(!_.isEmpty( tempData?.project_images)) {
           tempData.project_images.splice(i, 1);
         }
        } else if(keyName === 'wip_image_name') {
          tempData[keyName].splice(i, 1);
          if(!_.isEmpty( tempData?.wip_images)) {
            tempData.wip_images.splice(i, 1);
          }
        }
      }
      tempUser[0] = tempData;
      setUser(tempUser);
    }

    const handleDelete= (i) => {
      const deletVal = [...catalogueData];
      deletVal.splice(i,1)
      setCatalogueData(deletVal)
    }

    const handleValidation = (user) => {
      let isValid = true;
      let errors = {};

      if (!user[0]?.project_name) {
        isValid = false;
        errors["project_name"] = "Project name should not be blank";
      }
      if (user[0]?.project_images?.length === 0 ) {
        isValid = false;
        errors["project_images"] = "Project images should not be blank";
      }

      if (user[0]?.wip_images?.length === 0) {
        isValid = false;
        errors["wip_images"] = "Wip images should not be blank";
      }

      return {
          isValid,
          errors
      }
    };
    const handleDataValidation = (data) => {
      let isValid = true;
      let errors = [];
      if(data?.length > 0){
        errors = data?.map((dataUser,i) => {

         let error = {}

      if (!dataUser?.project_name) {
        isValid = false;
        error["project_name"] = "Project name should not be blank";
      }
      if (dataUser?.project_images?.length === 0 ) {
        isValid = false;
        error["project_images"] = "Project images should not be blank";
      }
      if (dataUser?.wip_images?.length === 0) {
        isValid = false;
        error["wip_images"] = "Wip images should not be blank";
      }
      return error;
    })

  }
      return {
        isValid,
        errors
    }
  }



  const handleUpdateApi = () => {

    // let check = handleValidation(user);
    // let checkData = handleDataValidation(catalogueData)

    if( brandId && userId){
      dispatch(onAddProjects({brand_id: brandId,customer_id: userId, project_detail: [{
        project_name: user[0]?.project_name,
        project_images: user[0]?.project_images,
        wip_images: user[0]?.wip_images,
        project_image_name: user[0]?.project_image_name,
        wip_image_name: user[0]?.wip_image_name
      }, ...catalogueData], brand_current_flow : currentStep},""))
      updateCustomerArchProV2(brandId, { brand_current_flow : currentStep}).then(res => {
        if(res?.status === 'Success' && res?.data?.brand) {
          dispatch(setCustomerData(res?.data?.brand));
          nextStep();
          window.scrollTo(0, 0);
        } else {
          toast(ErrorsMsg(res?.data?.message),{
            className:"mail_toast",
            position: toast.POSITION.TOP_CENTER,
            hideProgressBar: true,
            closeButton: false,
          })
        }
      });
      setErrors({});
      setDataErrors([]);

    }else{
      // setErrors(check?.errors)
      // setDataErrors(checkData?.errors)
    }

  }
  const renderError = (keyName) => {
    return errors && errors[keyName] ? <p className="error" style={{margin: "10px 0"}}>{errors[keyName]}</p> : null;
  };
  const renderDataError = (keyName,index) => {
    return dataErrors  && dataErrors[index] && dataErrors[index][keyName] ? <p className="error" style={{margin: "10px 0"}}>{dataErrors[index][keyName]}</p> : null;
  };


  return (
    <>
      <div className={style.text_container}>
      <p className={`${style.head_text1}`}>Business Connect Data</p>
        <h1 className={style.title}>
          Upload <br />
          Project Photos | Renders
        </h1>
        <p className={style.description}></p>
      </div>
      <div className={`${style.steps} ${style.fastep16}`}>
        <div className={`row  ${style.owner_row}`}>
          <div className="col-md-6">
            <div className={style.field_wrapper}>
              <FullWidthTextFieldLightTheme key={'Project Name | Type*'} label="Project Name | Type" name="project_name" value={user[0]?.project_name}  onChange={(e) => handleChangeUser(e,"")}/>
              {renderError("project_name")}
            </div>
          </div>
          <div className="col-md-6">
            <div className={style.field_wrapper}>
              <div className={style.image_upload_wrapper}>
                <div className={style.image}>
                  <input
                    className={style.input_box}
                    accept={'application/pdf, image/*'}
                    type="file"
                    id="uploadFile6e3"
                    hidden
                    name="project_images"
                    onChange={(e) => handleChangeUser(e,"")}
                  />
                  <label htmlFor="uploadFile6e3" className={style.upload_label}>
                    <div className={style.text}>Project Photos | Renders</div>
                    <img
                      src={formupload}
                      alt="icon"
                      className={style.icon}
                      loading="lazy"
                    />
                  </label>
                </div>
              </div>
              {renderError("project_images")}
              <p
                className={error === false ? style.pass_notice : style.error}
              >
                <p>Maximum file size is {PDF_IMAGE_FILE_COMPANY_PROFILE_SIZE}MB. PDF, JPEG, PNG allowed</p>
                {user[0]?.project_image_name?.map((item, i) => (
                  <span key={i} onClick={() => handleDeleteUserImages("project_image_name", i)} className={style.img_name} style={{margin: "5px"}}>
                    {item}
                    <img
                      src={imgclose}
                      alt=""
                      className={style.close_icon}
                      loading="lazy"
                    />
                  </span>
                ))}
              </p>
            </div>
          </div>
          <div className="col-md-6">
            <div className={style.field_wrapper}>
              <div className={style.image_upload_wrapper}>
                <div className={style.image}>
                  <input
                      accept={'application/pdf, image/*'}
                    className={style.input_box}
                    type="file"
                    id="uploadFile4e7"
                    hidden
                    name="wip_images"
                    onChange={(e) => handleChangeUser(e,"")}
                  />
                  <label htmlFor="uploadFile4e7" className={style.upload_label}>
                    <div className={style.text}>WIP Photos | Renders</div>
                    <img
                      src={formupload}
                      alt="icon"
                      className={style.icon}
                      loading="lazy"
                    />
                  </label>
                </div>
              </div>
              {renderError("wip_images")}
              <p
                className={error === false ? style.pass_notice : style.error}
              >
                <p>Maximum file size is {PDF_IMAGE_FILE_COMPANY_PROFILE_SIZE}MB. PDF, JPEG, PNG allowed</p>
                <span className={style.error_msg}> </span>
                {user[0]?.wip_image_name?.map((item, i) => (
                  <span key={i} onClick={() => handleDeleteUserImages('wip_image_name', i)} className={style.img_name} style={{margin: "5px"}}>
                    {item}
                    <img
                      src={imgclose}
                      alt=""
                      className={style.close_icon}
                      loading="lazy"
                    />
                  </span>
                ))}
              </p>
            </div>
          </div>
        </div>
        {/* !_.isEmpty(catalogueData) && */}
        {!_.isEmpty(catalogueData) && catalogueData?.map((val,i) => (
        <div className={`row  ${style.owner_row}`} key={i}>
          <div className="col-md-6">
            <div className={style.field_wrapper}>
              <FullWidthTextFieldLightTheme key={i + 'Project Name | Type*'} label="Project Name | Type" name="project_name" value={val?.project_name} onChange={(e) => handleUser(e,i)} />
               {renderDataError("project_name",i)}
              <p className={style.error}></p>
            </div>
          </div>
          <div className="col-md-6">
            <div className={style.field_wrapper}>
              <div className={style.image_upload_wrapper}>
                <div className={style.image}>
                  <input
                      accept={'application/pdf, image/*'}
                    className={style.input_box}
                    type="file"
                    id={`uploadFile4e7${i}`}
                    hidden
                    name="project_images"
                    onChange={(e) => handleUser(e,i)}
                  />
                  <label htmlFor={`uploadFile4e7${i}`} className={style.upload_label}>
                    <div className={style.text}>Project Photos | Renders</div>
                    <img
                      src={formupload}
                      alt="icon"
                      className={style.icon}
                      loading="lazy"
                    />
                  </label>
                </div>
              </div>
               {renderDataError("project_images",i)}
              <p
                className={error === false ? style.pass_notice : style.error}
              >
                <p>Maximum file size is {PDF_IMAGE_FILE_COMPANY_PROFILE_SIZE}MB. PDF, JPEG, PNG allowed</p>
                {!_.isEmpty(catalogueData?.[i]?.project_image_name) && catalogueData[i]?.project_image_name?.map((item, index) => (
                  <span key={index} onClick={() => handleDeleteCatalogueImaged('project_image_name', index, i)} className={style.img_name} style={{margin: "5px"}}>
                    {item}
                    <img
                      src={imgclose}
                      alt=""
                      className={style.close_icon}
                      loading="lazy"
                    />
                  </span>
                ))}
              </p>
            </div>
          </div>
          <div className="col-md-6">
            <div className={`${style.field_wrapper} ${style.field_with_crud}`}>
              <div className={style.image_upload_wrapper}>
                <div className={style.image}>
                  <input
                      accept={'application/pdf, image/*'}
                    className={style.input_box}
                    type="file"
                    id={`uploadFile9e8${i}`}
                    hidden
                    name="wip_images"
                    onChange={(e) => handleUser(e,i)}
                  />
                  <label htmlFor={`uploadFile9e8${i}`} className={style.upload_label}>
                    <div className={style.text}>WIP Photos | Renders</div>
                    <img
                      src={formupload}
                      alt="icon"
                      className={style.icon}
                      loading="lazy"
                    />
                  </label>
                </div>
              </div>
               {renderDataError("wip_images",i)}
              <p
                className={error === false ? style.pass_notice : style.error}
              >
                <p>Maximum file size is {PDF_IMAGE_FILE_COMPANY_PROFILE_SIZE}MB. PDF, JPEG, PNG allowed</p>
                {!_.isEmpty(catalogueData?.[i]?.wip_image_name) && catalogueData[i]?.wip_image_name?.map((item, index) => (
                  <span key={index} onClick={() => handleDeleteCatalogueImaged('wip_image_name', index, i)} className={style.img_name} style={{margin: "5px"}}>
                    {item}
                    <img
                      src={imgclose}
                      alt=""
                      className={style.close_icon}
                      loading="lazy"
                    />
                  </span>
                ))}
              </p>
              <div className={`${style.add_delete_icon} ${style.form16_upload_delete}`} onClick={handleDelete}>
                {/* <img
                  src={plusicon}
                  alt="icon"
                  className={style.plusicon}
                  loading="lazy"
                /> */}
                <img
                  src={blackDeleteicon}
                  alt="icon"
                  className={style.deleteicon}
                  loading="lazy"
                  style={{ visibility: "visible" }}
                />
              </div>
            </div>
          </div>
        </div>
        ))}
        <div className={style.add_category}>
          <div className={style.dashed_line}></div>
          <div className={style.add_flex} onClick={handleAddMore}>
            <img src={plusicon} alt="icon" className={style.icon} />
            <div className={style.title}>Add more projects</div>
          </div>
          <div className={style.dashed_line}></div>
        </div>
      </div>

      <div className={style.next_logout}>
        <div className={style.cta_wrapper}>
          <div
            className={style.next_button}
            onClick={handleUpdateApi}
          >
            <div className={style.text}>Next</div>
            <img
              src={rightarrowblack}
              alt="icon"
              className={style.icon}
              loading="lazy"
            />
          </div>
          <div
            className={style.back_button}
            onClick={() => {
              previousStep();
              window.scrollTo(0, 0);
            }}
          >
            Back
          </div>
        </div>
        {/*<LogoutText userData={userExtraData}/>*/}
      </div>
    </>
  );
};

export default FAStep16;
