import { consoleBackground_1 } from "../../images";

export const consoleData = [
  {
    id: 1,
    greet: "Hey there,",
    name: "Tanvi",
    btn: "Design enthusiast",
    btnColor: "#014fe0",
    phone: "+91 7874547547",
    tel: "+917874547547",
    email: "tanvi.shah123@gmail.com",
    address: "Mumbai, Maharashtra, India",
    heading: "Did you know?",
    desc: "The Design & Build Industry accounts for 60% employment in india, with 52% being women.",
    bckImg: consoleBackground_1,
  },
  //   {
  //     id: 2,
  //     greet: "Hey there,",
  //     name: "Tanvi",
  //     btn: "Student",
  //     btnColor: "#FF4A68",
  //     phone: "+91 7874547547",
  //     tel: "+917874547547",
  //     email: "tanvi.shah123@gmail.com",
  //     address: "Mumbai, Maharashtra, India",
  //     heading: "Did you know?",
  //     desc: "The Design & Build Industry accounts for 60% employment in india, with 52% being women.",
  //     bckImg: consoleBackground_1,
  //   },
  //   {
  //     id: 3,
  //     greet: "Hey there,",
  //     name: "Tanvi",
  //     btn: "Team member",
  //     btnColor: "#12CC50",
  //     phone: "+91 7874547547",
  //     tel: "+917874547547",
  //     email: "tanvi.shah123@gmail.com",
  //     address: "Mumbai, Maharashtra, India",
  //     heading: "Did you know?",
  //     desc: "The Design & Build Industry accounts for 60% employment in india, with 52% being women.",
  //     bckImg: consoleBackground_1,
  //   },
];

