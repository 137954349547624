import React, { useEffect, useState } from "react";
import style from "./students.module.scss";
import BorderLinearProgress from "../../../components/ProgressBar/ProgressBar";
import StepWizard from "react-step-wizard";
import SAStep01 from "../FormSteps/SAStep01";
import SAStep02 from "../FormSteps/SAStep02";
import SAStep03 from "../FormSteps/SAStep03";
import { DIFFERENT_USER_TYPES,STUDENT_STEP_FLOW } from "../../../constants/appEnums";
import { useSearchParams } from "react-router-dom";
import {useSelector} from "react-redux";
import {getCustomer} from "../../../redux/app/appSelectors";
import _ from "lodash";
import callApi from "../../../util/apiCaller";
import { FORM_FIVE_FLOW, CUSTOMER_ID } from "../../../constants/enums";
import BlinkingDots from "../../../Animations/BlinkingDots/BlinkingDots";
import FooterV2 from "../../../components/FooterV2/FooterV2";

const StudentsAccess = () => {
  let brandId = window.localStorage.getItem(CUSTOMER_ID);
  // const [params, setParams] = useSearchParams();
  // const [progress, setProgress] = useState(1/3*100);
  // //const [formStep, setformStep] = useState(1);
  // const [formStep, setFormStep] = useState(1);
  // const [userExtraData, setUserExtraData ] = useState({});
  const [params, setParams] = useSearchParams();
  const [progress, setProgress] = useState(1/3*100);
  //const [formStep] = useState(1);
  const [formStep, setFormStep] = useState();
  const [userExtraData, setUserExtraData ] = useState({});
  const customer = useSelector(state => getCustomer(state));
  //window.localStorage.setItem(STUDENT_STEP_FLOW,customer?.current_flow) 
  const student_step_flow = window.localStorage.getItem(STUDENT_STEP_FLOW) 

  // const handleCalculateProgress = (step) => {
  //   let progress = 0;

  //     progress = step/3*100

  //   // setParams(prev => ({...prev, stepNum: step.toString()}))
  //   setProgress(progress);
  // }
  // useEffect(() => {
  //   if(brandId){
  //   fetchContent(brandId)   
  //   }  
  // },[])
  // async function fetchContent(brandId) {

  //   try {
  //     const res = await callApi(`customer/${brandId}/v2`, "get");

  //     if (res && res?.status === 'Success') {
  //       const { customer } = res?.data;
  //       window.localStorage.setItem(STUDENT_STEP_FLOW ,customer?.current_flow)
       
  //     } else {
  //     }
  //   } catch (err) {

  //   }
  // }

  const handleCalculateProgress = (step) => {
    let progress = 0;
    progress = step/3*100
    setProgress(progress);
    setFormStep(step);
  }

  useEffect(() => {
    setUserExtraData({
      user_type: DIFFERENT_USER_TYPES[1].label
    })
  },[])

  const handleUserExtraData = (val) => {
      setUserExtraData((userExtraData) => ({
        ...userExtraData,
        ...val,
      }));

  }
  const finalUserExtraData = (val) => {
    setUserExtraData((userExtraData) => ({
      ...userExtraData,
      ...val
    }));
  }
  //console.log(userExtraData);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <BlinkingDots />
      <section className={style.students_sec1}>
        <div className={style.students_form_wrap}>
          <div className="my_container">
            <div className={style.progress_wrapper}>
              <BorderLinearProgress variant="determinate" value={progress} />
            </div>
            <div className={style.steps_wrapper}>
              <StepWizard
                      initialStep={student_step_flow}
                      onStepChange={e => handleCalculateProgress(e.activeStep)}
                      transitions={{
                        enterRight: "formChangeAnimation",
                        enterLeft: "formChangeAnimation",
                        intro: "formChangeAnimation",
                      }}
                  >
                    <SAStep01 progressStatus={setProgress} userExtraData={userExtraData} handleUserExtraData={handleUserExtraData} />
                    <SAStep02 progressStatus={setProgress} userExtraData={userExtraData} handleUserExtraData={handleUserExtraData} />
                    <SAStep03 progressStatus={setProgress} userExtraData={userExtraData} finalUserExtraData={finalUserExtraData} />
                  </StepWizard>
            </div>
          </div>
        </div>
      </section>
      <FooterV2 />
    </>
  );
};

export default StudentsAccess;
