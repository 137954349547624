import React, {useEffect, useState} from "react";
import style from "./business.module.scss";
import BorderLinearProgress from "../../../components/ProgressBar/ProgressBar";
import StepWizard from "react-step-wizard";
import BAStep02 from "../EarliyAccess/BAStep02";
import BAStep03 from "../EarliyAccess/BAStep03";
import {DIFFERENT_USER_TYPES, BUSINESS_STEP_FLOW} from "../../../constants/appEnums";
import {useSelector} from "react-redux";
import {getCustomer} from "../../../redux/app/appSelectors";
import callApi from "../../../util/apiCaller";
import { FORM_FIVE_FLOW, CUSTOMER_ID } from "../../../constants/enums";
import BlinkingDots from "../../../Animations/BlinkingDots/BlinkingDots";
import FooterV2 from "../../../components/FooterV2/FooterV2";

const BusinessAccess = () => {
  const [progress, setProgress] = useState(1/2*100);
  //const [formStep] = useState(1);
  let brandId = window.localStorage.getItem(CUSTOMER_ID);
  const [formStep, setFormStep] = useState(1);
  const [userExtraData, setUserExtraData ] = useState({});

  const customer = useSelector(customer => getCustomer(customer));
  //window.localStorage.setItem(BUSINESS_STEP_FLOW,customer?.current_flow) 
  const business_step_flow = window.localStorage.getItem(BUSINESS_STEP_FLOW) 
  useEffect(() => {
    setUserExtraData({
      user_type: DIFFERENT_USER_TYPES[0].label
    })

  },[])
  
  // useEffect(() => {
  //   if(brandId){
  //   fetchContent(brandId)   
  //   }  
  // },[])
  // async function fetchContent(brandId) {

  //   try {
  //     const res = await callApi(`customer/${brandId}/v2`, "get");

  //     if (res && res?.status === 'Success') {
  //       const { customer } = res?.data;
  //       window.localStorage.setItem(BUSINESS_STEP_FLOW ,customer?.current_flow)
       
  //     } else {
  //     }
  //   } catch (err) {

  //   }
  // }

  const handleCalculateProgress = (step) => {
    let progress = 0;

      progress = step/3*100

    setProgress(progress);
  }


  const handleUserExtraData = (val) => {
      setUserExtraData((userExtraData) => ({
        ...userExtraData,
        ...val
      }));

  }
  console.log(userExtraData);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  return (
    <>
     <BlinkingDots />
      <section className={style.business_sec1}>
        <div className={style.business_form_wrap}>
          <div className="my_container">
            <div className={style.progress_wrapper}>
              <BorderLinearProgress variant="determinate" value={progress} />
            </div>
            <div className={style.steps_wrapper}>
              <StepWizard
                onStepChange={e => handleCalculateProgress(e.activeStep)}
                initialStep={business_step_flow}
                transitions={{
                  enterRight: "formChangeAnimation",
                  enterLeft: "formChangeAnimation",
                  intro: "formChangeAnimation",
                }}
              >
                {/* <BAStep01 progressStatus={setProgress} userExtraData={userExtraData} handleUserExtraData={handleUserExtraData}/> */}
                <BAStep02 progressStatus={setProgress} userExtraData={userExtraData} handleUserExtraData={handleUserExtraData}/>
                <BAStep03 progressStatus={setProgress} userExtraData={userExtraData} handleUserExtraData={handleUserExtraData}/>
              </StepWizard>
            </div>
          </div>
        </div>
       
      </section>
      <FooterV2 />
    </>
  );
};

export default BusinessAccess;
