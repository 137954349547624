import React, { useEffect, useState } from "react";
import "./registrationform.scss";
import RadioButton from "../../components/RadioButton/RadioButton";
import FullWidthTextField from "../../components/TextField/FullWidthTextField";
import CountryCodeDropdown from "../../components/CountryCodeDropdown/CountryCodeDropdown";
import { countries } from "../../db/dataTypesData";
import { Link, useNavigate } from "react-router-dom";
import { regBackground, rightarrowwhite } from "../../images";
import { useWindowSize } from "react-use";
import { DIFFERENT_USER_TYPES } from "../../constants/appEnums";
import { useDispatch, useSelector } from "react-redux";
import { signupCustomer } from "../../redux/app/appAction";
import { validatePhoneNumber } from "../../util/phoneValidator";
import { businessFormFiveURL, privacypolicyURL, regiserOTPURL } from "../../components/helpers/constant-words";
import PasswordInput from "../../components/PasswordInput/PasswordInput";
import SelectDropdown from "../../components/SelectDropdown/SelectDropdown";
import AutoCompleteField from "../../components/AutoCompleteField/AutoCompleteField";
import { Country, State, City } from "country-state-city";
import AccountCategory from "./AccountCategory/AccountCategory";
import { toast } from "react-toastify";
import { ErrorsMsg } from "../../util/Message";
import _ from "lodash";
import callApi from "../../util/apiCaller";
import BlinkingDots from "../../Animations/BlinkingDots/BlinkingDots";
import { useCallback } from "react";
import FooterV2 from "../../components/FooterV2/FooterV2";
//import AutocompleteHint from "../../components/AutoCompleteHint/AutoCompleteHint";
// import {getCustomer} from "../../redux/app/appSelectors";
// import {consoleUrl} from "../../components/helpers/constant-words";



const RegistrationForm = () => {

    const dispatch = useDispatch();
    const [code, setCode] = useState([])
    const navigate = useNavigate();
    const [country, setCountry] = useState([])
    const [city, setCity] = useState([])
    const [pincodee, setPincodee] = useState("")
    const [phonee, setPhonee] = useState("")
    const [isphoneSame, setIsphoneSame] = useState(false)
    useEffect(() => {
        window.scrollTo({
            top:0,
            behavior:'smooth'
        })
    }, [])

    useEffect(() => {
        let country = Country.getAllCountries().map((option) => {
            return option.name
        })

        setCountry(country)
    }, [])
    useEffect(() => {
        // if(customer && customer._id) {
        //     navigate(consoleUrl);
        // }
        let cityList = City.getCitiesOfCountry(code).map((option) => option.name);
        let city = _.uniq(cityList);
        setCity(city)
    }, [code])

    const [user, setUser] = useState({
        user_type: "",
        contact_name: "",
        contact_email: "",
        password: "",
        country_code: "91",
        contact_phone: "",
        whatsapp_no: "",
        country: "",
        city: "",
        pincode: ""
    });

    const [errors, setErrors] = useState({});

    const debouncedHandlePincode = useCallback(
        _.debounce((city,pincode) => {
            fetchByPincode(city,pincode);
        }, 700),
        []
      );
      const handlePincode = (city,pincode) => {
        debouncedHandlePincode(city,pincode)
      }
      const fetchByPincode = (city,pincode) => {
        
        if ( pincode) {
            
        
        callApi(`city/validate-pincode`,'post', {
          "city" : city,
          "pincode": pincode
           
        }).then(res => {
            if (res?.status === "Success" && res?.data?.isValid === 'true') {
                
      
            } else {
              setPincodee(res?.data?.errors[0])
            }
        });
      }
      }

    const handleChangeUser = (keyName, keyValue) => {
        if (keyName === "country") {
            Country.getAllCountries().forEach((option) => {
                if (option.name === keyValue) {
                    if (option.isoCode) {
                        setCode(option.isoCode)
                    }
                }
            })

        }
    
        let update = { ...user };
      
        setErrors((prev) => ({ ...prev, [keyName]: "" }))
        if(keyName === 'contact_phone'){
            if( keyValue.substring(0,1) === "0"){
                setPhonee("Please remove zero from start")
               
                update.contact_phone = keyValue;
            }else{
                // if(keyValue && keyValue.length !== 10){
                //     setPhonee("Please enter correct phone number")
                //     //update.whatsapp_no = keyValue;
                //     update.contact_phone = keyValue;
                // }else{
                     setPhonee("")
                 update.contact_phone = keyValue;
                // }
            }
        }else{
        if (isphoneSame && (keyName === 'whatsapp_no' || keyName === 'contact_phone')) {
         
                update.whatsapp_no = keyValue;
                update.contact_phone = keyValue;
          
            
            
        } else {
            if(keyName === 'pincode'){
                if(keyValue.length>=4 && keyValue.length !== 6){
                    //setPincodee("Please enter 6 digit pincode")
                    handlePincode(user?.city,keyValue)
                    update['pincode'] = keyValue
                }else{
                   
                    handlePincode(user?.city,keyValue)
                    update['pincode'] = keyValue
                }
            }else{
            update[keyName] = keyValue;
            }
            
        }
    }
        setUser(update);
    }

    const handleValidation = (user) => {
        let isValid = true;
        let errors = {};
        let validation = validatePhoneNumber(user?.contact_phone, user?.country_code)
        if (!user?.user_type) {
            isValid = false;
            errors["user_type"] = "Please select a user type";
        }
        if (!user?.contact_name) {
            isValid = false;
            errors["contact_name"] = "Contact name cannot be blank";
        }
        if (!user?.password) {
            isValid = false;
            errors["password"] = "Password cannot be blank";
        } else if (user?.password && user?.password.length < 8) {
            isValid = false;
            errors["password"] = "Your password should be at least 8 characters";
        }
        if (!user?.contact_email) {
            isValid = false;
            errors["contact_email"] = "Contact email cannot be blank";
        } else {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!emailRegex.test(user?.contact_email)) {
                isValid = false;
                errors["contact_email"] = "Invalid email";
            }
        }
        if (!user?.contact_phone) {
            isValid = false;
            errors["contact_phone"] = "Contact phone cannot be blank";
        } else if (!validation.isValid) {
            isValid = false;
            errors['contact_phone'] = validation.message;
        }
        let WhatsAppno = validatePhoneNumber(user?.whatsapp_no, user?.country_code)
        if (!user?.whatsapp_no) {
            isValid = false;
            errors["whatsapp_no"] = "WhatsApp number cannot be blank";
        } else if (!WhatsAppno.isValid) {
            isValid = false;
            errors['whatsapp_no'] = WhatsAppno.message;
        }

        if (!user?.country) {
            isValid = false;
            errors["country"] = "Country cannot be blank";
        }

        if (user.country === "India") {
            if (!user?.city) {
                isValid = false;
                errors["city"] = "City cannot be blank";
            }
            if (!user?.pincode) {
                isValid = false;
                errors["pincode"] = "Pincode cannot be blank";
            }

            if (user?.pincode.length !== 6) {
                isValid = false;
                errors["pincode"] = "Enter a valid Indian pincode";
            }
        }

        return {
            isValid,
            errors
        }
    };

    const handleSubmit = (e) => {
        let update = {...user}
        let str = user?.contact_name
        let str1 = ""
        for(let i=0; i<str.length; i++){
        
            if(i === 0){
              let s =str[0].toUpperCase()
              str1 +=s
            }else{
            str1 += str[i];
            
            }
          
        }
            update["contact_name"] = str1;

        if (e) {
            e.preventDefault();
        }
        let check = handleValidation(update);


        if (check.isValid) {
            dispatch(signupCustomer(update, navigate));
            window.scrollTo(0, 0)
        } else {
            setErrors(check.errors);
        }
    }

    const renderError = (keyName) => {
        return errors && errors[keyName] ? <p className="error my-1">{errors[keyName]}</p> : null;
    };

    const { width } = useWindowSize();
    return (
        <>
        <BlinkingDots />
            <main className="reg_main">

                <section className="reg_sec1">
                    <div className="my_container">
                        <div className="text_container" >
                            <p className="head_text">Get Early Access</p>
                            <h1 className="heading">Tell Us About Yourself</h1>
                            <h2 className="sub_heading">
                                Your Role In The Design &<br /> Build Industry?
                            </h2>
                            <p className="choose_text">Choose one</p>
                        </div>
                        <ul className="radio_container">
                            {DIFFERENT_USER_TYPES.map(item => {
                                return (
                                    <RadioButton
                                        key={item.label}
                                        checked={item?.label === user?.user_type}
                                        value={item.label}
                                        label={item.display_name}
                                        labelId={item.label}
                                        handleChangeUser={handleChangeUser}
                                    />
                                )
                            })}
                            {<p style={{fontSize:"20px"}}>{renderError("user_type")}</p>}
                        </ul>
                        <form onSubmit={(e) => handleSubmit(e)} className="form_container">
                            <div className="row form_row">
                                <div className={`col-md-6 form_col ${width > 992 && "ps-0"}`}>
                                    <div className="field_wrapper">
                                        <FullWidthTextField
                                            value={user?.contact_name}
                                            onChange={(e) => handleChangeUser("contact_name", e.target.value)}
                                            label="Your full name?*" type="text" />
                                        {renderError("contact_name")}
                                    </div>
                                </div>
                                <div className={`col-md-6 form_col ${width > 992 && "pe-0"}`}>
                                    <div className="field_wrapper">
                                        <FullWidthTextField
                                            value={user?.contact_email}
                                            onChange={(e) => handleChangeUser("contact_email", e.target.value)}
                                            label="E-mail*" type="email" />
                                        {renderError("contact_email")}

                                    </div>
                                </div>
                                <div className={`col-md-6  form_col ${width > 992 && "ps-0"}`}>
                                    <div className="field_wrapper">
                                        <PasswordInput
                                            value={user?.password}
                                            keyName={"password"}
                                            label={"Set Password*"}
                                            id={"main-password"}
                                            handleChangeUser={handleChangeUser} />
                                        {/* <FullWidthTextField
                                            value={user?.password}
                                            onChange={(e) => handleChangeUser("password", e.target.value)}
                                            label="Set Password*" type="password"/> */}
                                        <p className="pass_help">
                                            Your password should be at least 8 characters
                                        </p>
                                        {renderError("password")}
                                    </div>
                                </div>
                                <div className="col-md-6  form_col pe-0">
                                    <div className="field_wrapper">
                                        <div className="row">
                                            <div className="col-12 col-sm-4 col-md-4 ps-0">
                                                <div className="field_wrapper_1">
                                                    <CountryCodeDropdown
                                                        handleChangeUser={handleChangeUser}
                                                        textLabel="Code*" />
                                                    {renderError("country_code")}
                                                </div>
                                            </div>
                                            <div
                                                className={`col-12 col-sm-8 col-md-8 ${
                                                    width > 992 ? "pe-0" : "ps-0"
                                                  } ${width <= 575 && "mobile_stack"}`}
                                            >
                                                <div className="field_wrapper_1">
                                                    <FullWidthTextField
                                                        key="contact_phone"
                                                        value={user?.contact_phone}
                                                        onChange={(e) => { handleChangeUser("contact_phone", e.target.value) }}
                                                        label="Phone*" type="number" />
                                                    {renderError("contact_phone")}
                                                    <p className="error">{phonee}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={`col-md-6  form_col ${width > 992 && "ps-0"}`}>
                                    <div className="field_wrapper">
                                        <FullWidthTextField
                                            key="WhatsApp number*"
                                            value={!isphoneSame ? user?.whatsapp_no : user?.contact_phone}
                                            onChange={(e) => {
                                                handleChangeUser("whatsapp_no", e.target.value)
                                            }}
                                            label="WhatsApp number*"
                                            type="number"
                                        />
                                    </div>
                                    <div className="checkbox_wrapper">
                                        <label className="checkbox_label">
                                            <input
                                                checked={isphoneSame}
                                                type="checkbox" className="check_box"
                                                onChange={() => {
                                                    if (!isphoneSame) {
                                                        handleChangeUser("whatsapp_no", user?.contact_phone)
                                                    }
                                                    setIsphoneSame(prev => !prev)
                                                }} />
                                            Same as phone number
                                        </label>

                                    </div>
                                    {renderError("whatsapp_no")}
                                    {/* <p className="error">{phonee}</p> */}
                                </div>
                                <div className={`col-md-6  form_col ${width > 992 && "pe-0"}`}>
                                    <div className="field_wrapper">
                                        {/* <AutocompleteHint textLabel="Country*" data={country} keyName={"country"} handleUserDetail={handleChangeUser} input={user?.country}/> */}
                                        <AutoCompleteField textLabel="Country*" disableClearable={false} data={country} handleUserDetail={handleChangeUser} keyName={"country"} course={user?.country} />

                                        {renderError("country")}
                                    </div>
                                </div>
                                {
                                    user.country === 'India' && <>
                                        <div className={`col-md-6  form_col ${width > 992 && "ps-0"}`}>
                                            <div className="field_wrapper">
                                                <AutoCompleteField textLabel="City*" disableClearable={false} data={city} handleUserDetail={handleChangeUser} keyName={"city"} course={user?.city} />
                                                {renderError("city")}
                                            </div>
                                        </div>
                                        <div className={`col-md-6  form_col ${width > 992 && "pe-0"}`}>
                                            <div className="field_wrapper">
                                                <FullWidthTextField
                                                    value={user?.pincode}
                                                    onChange={(e) => handleChangeUser("pincode", e.target.value)}
                                                    label="Pincode*" type="text" />
                                                {renderError("pincode")}
                                                <p className="error">{pincodee}</p>
                                            </div>
                                        </div>
                                    </>
                                }

                                <div className={`col-md-12 form_col ${width > 992 && "p-0"}`}>
                                    <div className="privacy_checbox">
                                        By continuing, you agree to <br />
                                        the terms of&nbsp;
                                        <Link target={'_blank'} className="policy_link" to={privacypolicyURL}>Archinza Policy</Link>.
                                    </div>
                                </div>
                                <div className={`col-md-12 form_col ${width > 992 && "p-0"}`}>
                                    <div className="btn_wrapper">
                                        <button

                                            className="form_btn">
                                            Get OTP To Connect
                                            <img
                                                src={rightarrowwhite}
                                                alt="icon"
                                                className="icon"
                                                loading="lazy"
                                            />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </section>
               
            </main>
            <FooterV2 />
        </>
    );
};

export default RegistrationForm;
