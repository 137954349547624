import React, { useEffect, useState } from "react";
import style from "../Form/formfive.module.scss";
import LogoutText from "../../../components/LogoutText/LogoutText";
import { rightArrowBlue, rightarrowblack, rightarrowwhite } from "../../../images";
import RadioButton from "../../../components/RadioButton/RadioButton";
import callApi from "../../../util/apiCaller";
import { updateCustomerArchPro } from "../../../redux/app/appAction";
import { useDispatch, useSelector } from "react-redux";
import { getBrandId, getBrand } from "../../../redux/app/appSelectors";
import {ErrorsMsg, SuccessMsg} from "../../../util/Message";
import {toast} from "react-toastify";
import { setBrand, setBrandId,updateCustomerArchProV2,setCustomerData} from "../../../redux/app/appAction";
import RadioButtonLightTheme from "../../../components/RadioButtonLightTheme/RadioButtonLightTheme";
// const formAProjectBudgetArr = [
//   "Anything",
//   "1000 - 2500 Rs/sft",
//   "2500-5000 Rs/sft",
//   "Above 5000 Rs/sft",
// ];

const FAStep09 = ({
  nextStep,
  previousStep,
  currentStep,
  totalSteps,
  progressStatus,
  handleUserExtraData, userExtraData
}) => {
  const dispatch = useDispatch();
  const brandId = useSelector((state) => getBrandId(state)) ;
  const brandData = useSelector((state) => getBrand(state))
  const [errors, setErrors] = useState({});
  //const [showError, setShowError] = useState(true)
  const [code, setCode] = useState([])
  const [projectBudget, setProjectBudget ] = useState()

    useEffect(() => {
      setProjectBudget({"project_budget": brandData?.project_budget})
    },[brandData])
  const handleUserDetail = ( keyName,keyValue) => {
    if(keyValue){
     setErrors({})
    }
    let update ={...projectBudget};
    update["project_budget"] = keyValue;
    update.brand_current_flow = currentStep;
    setProjectBudget(update);

  }
  const handleValidation = (user) => {
    let isValid = true;
    let errors = {};
    if (!user?.project_budget) {
        isValid = false;
        errors["project_budget"] = "Please select project budget";
    }

      return {
        isValid,
        errors
    }
  }
  const handleUpdateApi = (brandId,projectBudget) => {
    let check = handleValidation(projectBudget);
   
    if(check.isValid && brandId && projectBudget){
      updateCustomerArchProV2(brandId, projectBudget).then(res => {
        if(res?.status === 'Success' && res?.data?.brand) {
          dispatch(setCustomerData(res?.data?.brand));
          nextStep();
          window.scrollTo(0, 0);
        } else {
          toast(ErrorsMsg(res?.data?.message),{
            className:"mail_toast",
            position: toast.POSITION.TOP_CENTER,
            hideProgressBar: true,
            closeButton: false,
          })
        }
      });
      setErrors({})
    }else {
      setErrors(check?.errors);
      }
  }
  const renderError = (keyName) => {
    return errors && errors[keyName] ? <p className="error" >{errors[keyName]}</p> : null;
  };

  useEffect(() => {

    fetchContentCode("minimum-project-budget")
   },[])

  async function fetchContentCode(code) {

    try {
      const res = await callApi(`content/code/${code}`, "get");
      if (res && res?.status === 'Success') {
        const { params } = res?.data?.content;
        //let param = params?.map((option) => option.label)
        setCode(params);
      } else {
      }
    } catch (err) {

    }
  }

  const formAProjectBudgetList = code?.map((option,i) => (
    <React.Fragment key={`${option.value}3${i}`}>
      <RadioButtonLightTheme label={option.label} labelId={`minw${option.value}`} value={option.value} checked={option?.value === projectBudget?.project_budget} handleChangeUser={handleUserDetail} />
    </React.Fragment>
  ));

  return (
    <>
      <div className={style.text_container}>
      <p className={`${style.head_text1}`}>About Your Project</p>
        <h1 className={style.title}>
        Average Budget of your Projects
        </h1>
        <p className={style.description}></p>
      </div>
      <div className={`${style.steps} ${style.step06}`}>
        <ul className={style.steps_ul}>{formAProjectBudgetList}</ul>
        <div style={{display:"flex",justifyContent:"center",alignItems:"center"}}> {renderError("project_budget")}</div>
      </div>

      <div className={style.next_logout}>
      
        <div className={style.cta_wrapper}>
          <div
            className={style.next_button}
            onClick={() => handleUpdateApi(brandId,projectBudget)}
          >
            <div className={style.text}>Next</div>
            <img
              src={rightarrowblack}
              alt="icon"
              className={style.icon}
              loading="lazy"
            />
          </div>
          <div
            className={style.back_button}
            onClick={() => {
              previousStep(5);
              window.scrollTo(0, 0);
            }}
          >
            Back
          </div>
        </div>
        <LogoutText  userData={projectBudget}/>
      </div>
    </>
  );
};

export default FAStep09;
