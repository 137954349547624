import React, {useEffect, useState} from "react";
import "./congratulations.scss";
import {
    // congraDesktopBanner,
    // congraMobileBanner,
    congratCup,
} from "../../images";
import GlowCta from "../../components/GlowCta/GlowCta";
import {Link, useNavigate} from "react-router-dom";
import {useWindowSize} from "react-use";
import ReactConfetti from "react-confetti";
import FooterV2 from "../../components/FooterV2/FooterV2";
import {useSelector} from "react-redux";
import {getCustomer} from "../../redux/app/appSelectors";
import {
    businessAccessURL,
    consoleUrl,
    enthusiastAccesssURL,
    studentAccesssURL,
    teamAccesssURL
} from "../../components/helpers/constant-words";

const Congratulations = () => {
    const [link, setLink] = useState(studentAccesssURL)
    const { width, height } = useWindowSize();

    const customer = useSelector(state => getCustomer(state));

    useEffect(() => {
        handleOpenProAccessForm()
    }, [customer]);

    const handleOpenProAccessForm = () => {
        let newLink = ""
        if (customer && customer.user_type) {
            switch (customer.user_type) {
                case 'student':
                    newLink = studentAccesssURL
                    break;
                case 'team-member':
                    newLink = teamAccesssURL
                    break
                case 'business':
                    newLink = businessAccessURL
                    break;
                case 'design-enthusiast':
                    newLink = enthusiastAccesssURL
                    break;
            }
        }
        setLink(newLink)
    }

    return (
        <>
            <main className="congrat_main">
                {/* <img
                    src={width > 767 ? congraDesktopBanner : congraMobileBanner}
                    alt="background"
                    className="blogslisting_background"
                /> */}
                    <div className="confetti_wrapper">
                        <ReactConfetti
                            width={width - 50}
                            height={height}
                            colors={["#a67c00", "#bf9b30", "#ffbf00"]}
                        />
                        </div>

                <section className="congrat_sec1">
                    <div className="my_container">
                        <div className="content_wrapper">
                            <img src={congratCup} alt="cup" className="cup" loading="lazy"/>
                            <h2 className="title">Congratulations!</h2>
                            <p className="desc">
                                You Are Eligible For Free Early <br/> Access To{" "}
                                <span className="col_white">Archinza Pro!</span>
                            </p>
                            <div className="cta_wrapper">
                                <GlowCta link={link} text="Claim For Free Access Now"/>
                            </div>
                            <p className="text">
                                <Link to={consoleUrl} className="link">
                                    Click here
                                </Link>{" "}
                                to Save & Exit
                            </p>
                        </div>
                    </div>
                </section>
            </main>
        </>
    );
};

export default Congratulations;
