import React, { useEffect, useState } from "react";
import "./login.scss";
import { useWindowSize } from "react-use";
import { checkiconWhite, otpicon, rightarrowwhite } from "../../images";
import OtpInput from "react-otp-input";
import CountryCodeDropdown from "../../components/CountryCodeDropdown/CountryCodeDropdown";
import FullWidthTextField from "../../components/TextField/FullWidthTextField";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import {
    loginCustomerViaOTP,
    loginViaPassword,
    OnForgetPassword,
    verifyCustomerOTP,
    loginCustomerViaMobile,
    verifyCustomerOTPV2,
    fetchBrandAccounts, fetchCustomerProfile,
    setUrl
} from "../../redux/app/appAction";
import { useNavigate, useLocation } from "react-router-dom";
import RadioButton from "../../components/RadioButton/RadioButton";
import { SELECT_AC_TYPE } from "../../constants/appEnums";
import { getCustomer, getError, getBrandAccounts } from "../../redux/app/appSelectors";
import { consoleUrl } from "../../components/helpers/constant-words";
import { isValid } from "shortid";
import { ACCOUNT_TYPES } from "../../constants/appEnums";
//import {toast} from "react-toastify";
import { SuccessMsg, ErrorsMsg } from "../../util/Message";
import { validatePhoneNumber } from "../../util/phoneValidator";
import PasswordInput from "../../components/PasswordInput/PasswordInput";
import { CUSTOMER_ID } from "../../constants/enums";
import _ from "lodash";
import { Timer } from "@mui/icons-material";
import BlinkingDots from "../../Animations/BlinkingDots/BlinkingDots";
import FooterV2 from "../../components/FooterV2/FooterV2";
//const token = window.localStorage.getItem("token")

const Login = () => {


    const { width } = useWindowSize();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    //const location = useLocation()
    const [hideInput, setHideInput] = useState(true)
    const [accounts, setAccount] = useState()
    const [phonee, setPhonee] = useState("")
    const brandAccounts = useSelector((state) => getBrandAccounts(state))
    const customer = useSelector((state) => getCustomer(state));
    const loginError = useSelector((state) => getError(state));
    const [time, setTime] = useState(0);

    const [accountType, setAccountType] = useState(false)

    useEffect(() => {
        if (customer?._id) {
            navigate(consoleUrl)
        }
    }, [customer])

    const [errors, setErrors] = useState({});
    const [otpVal, setOtpVal] = useState({
        "local_otp": "",
        "contact_email": ""
    });
    const [emailForgetPassword, setEmailForgetPassword] = useState("")
    const [login, setLogin] = useState({
        "contact_phone": "",
        "country_code": "91",
        "password": "",
    })
    const [withPassword, setWithPassword] = useState({
        "email_phone": "",

        "password": ""

    })
    const [resend, setResend] = useState(false);
    const [loginStep, setLoginStep] = useState(1);

    const handleotpChange = (keyName, keyValue) => {
        let update = { ...otpVal };
        setErrors({})
        update[keyName] = keyValue;
        update.contact_email = customer?.contact_email;
        setOtpVal(update);
    };

    const handleChangeUser = (keyName, keyValue) => {
        let update = { ...login }
        if (keyName === 'contact_phone') {
            if (keyValue.substring(0, 1) === "0") {
                setPhonee("Please remove zero from start")
                update.contact_phone = keyValue;
            } else {
                setPhonee("")
                update.contact_phone = keyValue;
                // }
            }
        }
        setErrors((prev) => ({ ...prev, [keyName]: "" }))
        update[keyName] = keyValue
        setLogin(update)
    }

    const handleLoginWithPassword = (keyName, keyValue) => {

        setErrors((prev) => ({ ...prev, [keyName]: "" }))
        let update = { ...withPassword };
        update[keyName] = keyValue;

        setWithPassword(update);
    };
    const handleValidation = (user) => {
        let isValid = true;
        let errors = {};
        let validation = validatePhoneNumber(login?.contact_phone, login?.country_code)
        if (!user?.contact_phone) {
            isValid = false;
            errors["contact_phone"] = "Contact phone cannot be blank";
        } else if (!validation.isValid) {
            isValid = false;
            errors['contact_phone'] = validation.message;
        }

        if (!user?.country_code) {
            isValid = false;
            errors["country_code"] = "Country code cannot be blank";
        }

        return {
            isValid,
            errors
        }
    }
    const loginHandler = async () => {
        let check = handleValidation(login);
        if (check.isValid) {
            let res = await loginCustomerViaMobile(login)
            setErrors({})
            if (res?.data?.is_customer_found === true) {
                setLoginStep(2)
            } else {
                toast(ErrorsMsg("Customer not found, please register"), {
                    className: "otp_toast",
                    position: toast.POSITION.TOP_CENTER,
                    hideProgressBar: true,
                    closeButton: false,
                })
            }
        } else {
            setErrors(check?.errors);
        }
    }
    const renderError = (keyName) => {
        return errors && errors[keyName] ? (<p className="error" style={{ marginTop: "20px" }}>{errors[keyName]}</p>) : null;
    };
    const otpValidation = (user) => {
        let isValid = true;
        let errors = {};
        if (!user?.local_otp) {
            isValid = false;
            errors["local_otp"] = "Otp cannot be blank";
        } else if (user?.local_otp.length !== 6) {
            isValid = false;
            errors["local_otp"] = "Otp should be of 6 digit";
        }

        return {
            isValid,
            errors
        }
    }
    const verifyHandler = () => {

        let creds = {};
        creds.local_otp = otpVal.local_otp;
        creds.contact_phone = login.contact_phone;
        creds.country_code = login.country_code;
        let check = otpValidation(creds)
        if (check.isValid) {
            dispatch(verifyCustomerOTPV2(creds, setLoginStep))
            setErrors({})
        } else {
            setErrors(check?.errors);
        }
    }

    const handleWithPasswordValidation = (user) => {

        let isValid = true;
        let errors = {};

        if (!user?.password) {
            isValid = false;
            errors["password"] = "Password cannot be blank";
        } else if (user?.password && user?.password.length < 8) {
            isValid = false;
            errors["password"] = "Your password should be at least 8 characters";
        }


        // if (user?.email_phone.match("@")) {
        //     if (!user?.email_phone) {
        //         isValid = false;
        //         errors["email_phone"] = "Email cannot be blank";
        //     } else {
        //         const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        //         if (!emailRegex.test(user?.email_phone)) {
        //             isValid = false;
        //             errors["email_phone"] = "Invalid email format, format - abc@gmail.com";
        //         }
        //     }
        // } else {
        // let validation = validatePhoneNumber(user?.email_phone, login?.country_code)
        // if (!user?.email_phone) {
        //     isValid = false;
        //     errors["email_phone"] = "Email or Phone cannot be blank";
        // } else if (!validation.isValid) {
        //     isValid = false;
        //     errors['email_phone'] = validation.message;
        // }
        if (!user?.email_phone) {
            isValid = false;
            errors["email_phone"] = "User name cannot be blank";
        }
        //}

        return {
            isValid,
            errors
        }
    }
    const loginWithPasswordHandler = () => {

        let update = {};
        update.password = withPassword?.password;
        update.contact_email = withPassword?.email_phone;

        // if (withPassword?.email_phone.match("@")) {
        //     update.contact_email = withPassword?.email_phone;
        // } else {

        //     update.contact_phone = withPassword?.email_phone
        // }
        let check = handleWithPasswordValidation(withPassword)
        if (check.isValid) {
            dispatch(loginViaPassword(update, navigate))
            setErrors({})
        } else {
            setErrors(check?.errors);
        }
    }

    const OTPMsg = () => (
        <div className="otp_box">
            <img
                width={39}
                height={39}
                src={checkiconWhite}
                className="otp_resend_icon"
                loading="lazy"
                alt="icon"
            />
            <p className="title">OTP reshared</p>
        </div>
    );

    const EmailMsg = () => (
        <div className="otp_box">
            <img
                width={39}
                height={39}
                src={checkiconWhite}
                className="otp_resend_icon"
                loading="lazy"
                alt="icon"
            />
            <p className="title">
                reset password link sent
            </p>
        </div>
    );

    const resendHandler = () => {
        if (resend === false && login) {
            dispatch(loginCustomerViaOTP(login))
            resetTimer();
            setErrors({})
            setOtpVal({
                local_otp: ""
            })
            setResend(true);
        } else {
            setResend(false);
        }
    };
    const validateEmail = (update) => {
        let isValid = true;
        let errors = {};
        if (!update?.contact_email) {
            isValid = false;
            errors["contact_email"] = "Email id cannot be blank";
        } else {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!emailRegex.test(update?.contact_email)) {
                isValid = false;
                errors["contact_email"] = "Invalid email";
            }
        }
        return {
            isValid,
            errors
        }
    }
    const mailHandler = async () => {
        let update = {}
        update.contact_email = emailForgetPassword;
        let check = validateEmail(update)
        if (check.isValid) {
            const res = await OnForgetPassword(update)
            if (res && res?.status === 'Success') {
                setHideInput(false)
                const { customer } = res?.data;
                setErrors({})

                // toast(<EmailMsg/>, {
                //     className: "mail_toast",
                //     position: toast.POSITION.TOP_CENTER,
                //     hideProgressBar: true,
                //     closeButton: false,
                // });
                //setLoginStep(3)

            } else {


                toast(ErrorsMsg("Account with given credentials doesn't exist "), {
                    className: "mail_toast",
                    position: toast.POSITION.TOP_CENTER,
                    hideProgressBar: true,
                    closeButton: false,
                })

            }

        } else {
            setErrors(check?.errors);
        }
    };

    const handleBrandAccounts = (keyName, keyValue) => {
        setAccount(keyValue)
        setErrors({})
    }
    const handleValidationLogin = (account) => {
        let isValid = true;
        let errors = {};
        if (!account) {
            isValid = false;
            errors["account_type"] = "Please select account from above";
        }
        return {
            isValid,
            errors
        }
    }
    const selectAccountHandler = () => {
        // let creds = {};

        // creds.local_otp = otpVal.local_otp;
        // creds.contact_phone = login.contact_phone;
        // creds.country_code = login.country_code;
        //dispatch(verifyCustomerOTPV2(creds, ""))
        let check = handleValidationLogin(accounts)
        if (check.isValid) {
            if (brandAccounts?.customer?.contact_name === accounts) {
                dispatch(fetchCustomerProfile(brandAccounts?.customer?._id, navigate))
                window.localStorage.setItem(CUSTOMER_ID, brandAccounts?.customer?._id)
                //dispatch(setUrl(navigate))
                window.scrollTo(0, 0)

            }
            let arr = [...brandAccounts?.brands]

            for (let item of arr) {
                if (accounts === item?.brand_username) {
                    dispatch(fetchCustomerProfile(item?._id, navigate))
                    // dispatch(fetchBrandAccounts(item?._id))

                    window.localStorage.setItem(CUSTOMER_ID, item?._id)
                    // dispatch(setUrl(navigate))
                    window.scrollTo(0, 0)
                }

            }
            setErrors({})
        } else {
            setErrors(check?.errors);
        }
    }

    useEffect(() => {
        const resetButton = setTimeout(() => {
            setResend(false);
        }, 1000);
        return () => clearTimeout(resetButton);
    }, [resend]);

    useEffect(() => {
        let timerInterval = null;

        // Decrease time by 1 second every second
        if (time > 0) {
            timerInterval = setInterval(() => {
                setTime(prevTime => prevTime - 1);
            }, 1000);
        }

        // Clear interval and reset timer when time reaches 0
        if (time === 0) {
            clearInterval(timerInterval);
        }

        return () => clearInterval(timerInterval);
    }, [time])

    const resetTimer = () => {
        setTime(60);
    };
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    return (
        <>
            <BlinkingDots />
            <main className="user_login_container">

                {loginStep === 1 && (
                    <section className="login_sec1">
                        <div className="my_container">
                            <div className="text_container">
                                <h2 className="sub_title">Login</h2>
                                <h3 className="title">Login To Archinza</h3>
                            </div>
                            <div className="contact_wrapper">
                                <h4 className="mobile_title">
                                    Enter your 10 digit mobile number
                                </h4>
                                <div className="field_wrapper padding_left">
                                    <div className="row">
                                        <div className="col-12 col-sm-4 col-md-4 ps-0">
                                            <div className="field_wrapper_1">
                                                <CountryCodeDropdown textLabel="Code*"
                                                    handleChangeUser={handleChangeUser} />
                                                {renderError("country_code")}
                                            </div>
                                        </div>
                                        <div className={`col-12 col-sm-8 col-md-8 ${width > 992 ? "pe-0" : "ps-0"
                                            } ${width <= 575 && "mobile_stack"}`}>
                                            <div className="field_wrapper_1">
                                                <FullWidthTextField label="Phone*" type="number"
                                                    value={login?.contact_phone}
                                                    onChange={(e) => handleChangeUser("contact_phone", e.target.value)} />
                                                {renderError("contact_phone")}
                                                <p className="error">{phonee}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/*<h4 className="mobile_title" style={{marginTop:"80px", marginBottom:"40px"}}>*/}
                                {/*Select User Account*/}
                                {/*</h4>*/}
                                {/*<ul className="radio_container">*/}
                                {/*    {ACCOUNT_TYPES.map(item => (*/}
                                {/*        <RadioButton*/}
                                {/*            key={item.label}*/}
                                {/*            // checked={item?.label === accountType}*/}
                                {/*            value={item.label}*/}
                                {/*            label={item.display_name}*/}
                                {/*            labelId={item.label}*/}
                                {/*            handleChangeUser={handleChangeUser}*/}
                                {/*        />*/}
                                {/*    ))}*/}
                                {/*    {renderError("account_type")}*/}
                                {/*</ul>*/}
                                <div className="field_wrapper padding_left">
                                    <div className="cta_wrapper">
                                        <div className="btn_wrapper">
                                            <button
                                                className="form_btn"
                                                onClick={loginHandler}
                                            >
                                                Login with OTP
                                                <img
                                                    src={rightarrowwhite}
                                                    alt="icon"
                                                    className="icon"
                                                    loading="lazy"
                                                />
                                            </button>
                                        </div>
                                    </div>
                                    <div className="notice_wrapper">
                                        <p
                                            style={{ cursor: "pointer" }}
                                            className="notice"
                                            onClick={() => setLoginStep(3)}
                                        >
                                            or Login using Password
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                )}

                {loginStep === 2 && (
                    <section className="login_sec2">
                        <div className="my_container">
                            <div className="text_container">
                                <p className="head_text">OTP Verification</p>
                                <h1 className="title">
                                    Verify Your OTP, Sent To Your Registered&nbsp;
                                    <br />
                                    E-Mail ID & Mobile Number
                                    <span className="coloured_text">.</span>
                                </h1>
                                <div className="icon_notice_wrapper">
                                    <div className="icon_wrapper">
                                        <img
                                            width={81}
                                            height={81}
                                            src={otpicon}
                                            alt="icon"
                                            className="icon"
                                            loading="lazy"
                                        />
                                    </div>
                                    <p className="description">Enter OTP</p>
                                </div>
                            </div>

                            <div className="otp_box_wrapper">

                                <OtpInput
                                    value={otpVal?.local_otp}
                                    onChange={(e) => handleotpChange("local_otp", e)}
                                    numInputs={6}
                                    containerStyle="otp_input_wrapper"
                                    inputStyle="otp_input"
                                    isInputNum={true}
                                    focusStyle="otp_focused"
                                    shouldAutoFocus
                                />
                                <div style={{ display: "flex", alignItems: "centre", justifyContent: "center" }}>{renderError("local_otp")}</div>
                            </div>
                            <div className="next_logout">

                                <div className="cta_wrapper">
                                    <div
                                        className="next_button"
                                        style={{
                                            opacity: otpVal === "" ? 0.5 : 1,
                                            cursor: otpVal === "" ? "no-drop" : "pointer",
                                        }}
                                        onClick={verifyHandler}
                                    >
                                        <div className="text">Verify</div>
                                        <img
                                            src={rightarrowwhite}
                                            alt="icon"
                                            className="icon"
                                            loading="lazy"
                                        />
                                    </div>
                                    {time !== 0 ?
                                        <div className="back_button">
                                            Resend otp in {time} sec
                                        </div>
                                        :
                                        <div className="back_button" onClick={resendHandler}>
                                            {resend === false ? "Resend OTP" : "Resending OTP"}
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </section>
                )}

                {loginStep === 5 && (
                    <section className="login_sec2">
                        <div className="my_container">
                            <div className="text_container">
                                {/* <p className="head_text">OTP Verification</p> */}
                                <h1 className="title">
                                    Select User Account
                                </h1>
                                {/* <div className="icon_notice_wrapper">
                                    <div className="icon_wrapper">
                                        <img
                                            width={81}
                                            height={81}
                                            src={otpicon}
                                            alt="icon"
                                            className="icon"
                                            loading="lazy"
                                        />
                                    </div>
                                    <p className="description">Enter OTP</p>
                                </div> */}
                            </div>
                            {/* <ul className="radio_container1"> */}


                            {brandAccounts && !_.isEmpty(brandAccounts) && <ul className="radio_container1">
                                <RadioButton
                                    key={brandAccounts?.customer?._id}
                                    checked={brandAccounts?.customer?.contact_name === accounts}
                                    value={brandAccounts?.customer?.contact_name}
                                    label={brandAccounts?.customer?.contact_name}
                                    labelId={brandAccounts?.customer?._id}
                                    keyName={"account_type"}
                                    handleChangeUser={handleBrandAccounts}
                                />
                                {brandAccounts?.brands?.map(item => (
                                    <RadioButton
                                        key={item?._id}
                                        checked={item?.brand_username === accounts}
                                        value={item?.brand_username}
                                        label={item?.brand_username}
                                        labelId={item?._id}
                                        keyName={"account_type"}
                                        handleChangeUser={handleBrandAccounts}
                                    />
                                ))}
                                {renderError("account_type")}
                            </ul>}
                            {/* <div className="otp_box_wrapper">
                              
                            </div> */}
                            <div className="next_logout">

                                <div className="cta_wrapper">
                                    <div
                                        className="next_button"
                                        style={{
                                            opacity: otpVal === "" ? 0.5 : 1,
                                            cursor: otpVal === "" ? "no-drop" : "pointer",
                                        }}
                                        onClick={selectAccountHandler}
                                    >
                                        <div className="text">Login</div>
                                        <img
                                            src={rightarrowwhite}
                                            alt="icon"
                                            className="icon"
                                            loading="lazy"
                                        />
                                    </div>
                                    {/* <div className="back_button" onClick={resendHandler}>
                                        {resend === false ? "Resend OTP" : "Resending OTP"}
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </section>
                )}

                {loginStep === 3 && (
                    <section className="login_sec3">
                        <div className="my_container">
                            <div className="text_container">
                                <h3 className="title">Login Rightaway!</h3>
                            </div>
                            <div className="login_field_wrapper">
                                <div className="field_wrapper">
                                    <FullWidthTextField
                                        label="Email*"
                                        type="text"
                                        value={withPassword?.email_phone}
                                        onChange={(e) => handleLoginWithPassword("email_phone", e.target.value)}
                                    />
                                    {renderError("email_phone")}
                                </div>
                                <div className="field_wrapper">
                                    <PasswordInput
                                        label="Enter Password*"
                                        id={"enter-password-login-page"}
                                        value={withPassword?.password}
                                        keyName={"password"}
                                        handleChangeUser={handleLoginWithPassword} />
                                    <p className="reset_notice" onClick={() => setLoginStep(4)}>
                                        Forgot password?
                                    </p>
                                </div>
                                {renderError("password")}
                            </div>
                            <div className="cta_wrapper">
                                <div className="btn_wrapper">
                                    <button className="form_btn" onClick={loginWithPasswordHandler}>
                                        Login
                                        <img
                                            src={rightarrowwhite}
                                            alt="icon"
                                            className="icon"
                                            loading="lazy"
                                        />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </section>
                )}

                {loginStep === 4 && (
                    <section className="login_sec4">
                        <div className="my_container">
                            <div className="text_container">
                                <h3 className="title">Password Assistance</h3>
                            </div>
                            <div className="assistance_field_wrapper">
                                {hideInput === true ? <div className="field_wrapper">
                                    <FullWidthTextField label="Enter E-mail*" type="email" value={emailForgetPassword}
                                        onChange={(e) => {

                                            setEmailForgetPassword(e.target.value)
                                            if (e.target.value) {
                                                setErrors({})
                                            }
                                        }} />

                                    <p className="assist_notice">
                                        <p>{renderError("contact_email")}</p>
                                        Password reset instructions will be sent to your registered
                                        email address.

                                    </p>

                                </div> : <p style={{ display: "flex", justifyContent: "center", fontSize: "18px",textAlign: "center" }}>A reset link has been sent to your registered email address</p>}
                            </div>
                            <div className="cta_wrapper">
                                {hideInput === true ? <div className="btn_wrapper">
                                    <button
                                        className="form_btn"
                                        onClick={() => {
                                            mailHandler();
                                        }}
                                    >
                                        Submit
                                        <img
                                            src={rightarrowwhite}
                                            alt="icon"
                                            className="icon"
                                            loading="lazy"
                                        />
                                    </button>
                                </div> : null}
                            </div>
                        </div>
                    </section>
                )}

                {/* {loginStep === 5 && (
          <section className="login_sec5">
            <div className="my_container">
              <div className="text_container">
                <h3 className="title">Reset Password</h3>
              </div>
              <div className="reset_field_wrapper">
                <div className="field_wrapper">
                  <FullWidthTextField label="New password*" type="password" />
                  <p className="pass_notice">
                    your password should be at least 8 characters
                  </p>
                </div>
                <div className="field_wrapper">
                  <FullWidthTextField
                    label="Confirm password*"
                    type="password"
                  />
                </div>
              </div>
              <div className="cta_wrapper">
                <div className="btn_wrapper">
                  <button className="form_btn">
                    Update password
                    <img
                      src={rightarrowwhite}
                      alt="icon"
                      className="icon"
                      loading="lazy"
                    />
                  </button>
                </div>
              </div>
            </div>
          </section>
        )} */}
           <FooterV2 />
            </main>
        </>
    );
};
export default Login;
