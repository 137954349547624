import TextField from "@mui/material/TextField";
import style from "./fulltextfieldwithicon.module.scss";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { useEffect, useRef, useState } from "react";
import { useWindowSize } from "react-use";
import { useSelector } from "react-redux";
import { getCreateAccount } from "../../redux/app/appSelectors";

const darkTheme = createTheme({
  typography: {
    fontFamily: "Poppins",
  },
  palette: {
    mode: "dark",
  },
});
const lightThemed = createTheme({
  typography: {
    fontFamily: "Poppins",
  },
  palette: {
    mode: "light",
  },
});

const TextFieldWithIcon = ({ icon, ...rest }) => {
  const [fieldHeight, setFieldHeight] = useState(0);
  const { width } = useWindowSize();
  const fieldRef = useRef(null);
  const lightTheme =   useSelector((state) => getCreateAccount(state));
  useEffect(() => {
    if (fieldRef.current) {
      const style = window.getComputedStyle(fieldRef.current);
      setFieldHeight(style.height);
    }
  }, [fieldRef]);

  return (
    <>
    {lightTheme === false ? (
      <ThemeProvider theme={lightThemed}>
        <CssBaseline />
        <div className={style.textfield_wrapper}>
          <TextField
            outline={false}
            className={style.fullwidth_input}
            fullWidth
            inputProps={{
              style: {
                fontSize: width > 768 ? "1.25em" : "1.25em",
                WebkitBoxShadow: "0 0 0 1000px white inset",
                webkitTextFillColor: "#111",
                borderRadius: width > 768 ? "10px" : "10px",
              },
            }}
            sx={{
              "& fieldset": {
                borderRadius: width > 768 ? "10px" : "10px",
                border: "1px solid #707070",
              },
              // input label
              "& label": {
                lineHeight: width > 768 ? "2em" : "1.5em",
              },
              // input label when focused
              "& label.Mui-focused": {
                color: "#111",
              },
              // focused color for input with variant='standard'
              "& .MuiInput-underline:after": {
                borderBottomColor: "#111",
              },
              "& .MuiOutlinedInput-input": {
                paddingRight: "5em",
              },
              // focused color for input with variant='outlined'
              "& .MuiOutlinedInput-root": {
                "&.Mui-focused fieldset": {
                  borderColor: "#111",
                },
              },
            }}
            {...rest}
          />
          {icon && (
            <div className={style.img_wrapper}>
              <img src={icon} alt="icon" className={style.input_icon} />
            </div>
          )}
        </div>
      </ThemeProvider>
    ) : (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <div className={style.textfield_wrapper}>
        <TextField
          ref={fieldRef}
          outline={false}
          className={style.fullwidth_input}
          fullWidth
          inputProps={{
            style: {
              fontSize: width > 768 ? "1.25em" : "1.25em",
            },
          }}
          sx={{
            "& fieldset": {
              borderRadius: width > 768 ? "10px" : "10px",
              border: "1px solid $color-707070",
            },
            // input label when focused
            "& label": {
              lineHeight: width > 768 ? "2em" : "1.5em",
            },
            // input label when focused
            "& label.Mui-focused": {
              color: "#fff",
            },
            // focused color for input with variant='standard'
            "& .MuiInput-underline:after": {
              borderBottomColor: "#fff",
            },
            // focused color for input with variant='filled'
            "& .MuiFilledInput-underline:after": {
              borderBottomColor: "#fff",
            },
            "& .MuiOutlinedInput-input": {
              paddingRight: "5em",
            },
            // focused color for input with variant='outlined'
            "& .MuiOutlinedInput-root": {
              "&.Mui-focused fieldset": {
                borderColor: "#fff",
              },
            },
          }}
          {...rest}
        />
        {icon && (
          <div className={style.img_wrapper}>
            <img src={icon} alt="icon" className={style.input_icon} />
          </div>
        )}
      </div>
    </ThemeProvider>
    )}
    </>
  );
};

export default TextFieldWithIcon;


