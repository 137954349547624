import React from "react";
import "./deconsole.scss";
import {
  consoleArrow,
  consoleCall,
  consoleDot,
  consoleEdit,
  consoleEmail,
  consoleLocation,
  contactBg,
  rocket,
  speedMeter,
} from "../../images";
import { Link } from "react-router-dom";
import { consoleData } from "../../components/Data/consoleData";
import ProfileCard from "../../components/ProfileCard/ProfileCard";
import ConsoleNoticeCard from "../../components/ConsoleNoticeCard/ConsoleNoticeCard";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
// import { useWindowSize } from "react-use";

const DEConsole = () => {
  // const {width} = useWindowSize();

  return (
    <>
      <main className="main_container">
        <section className="deconsole_sec1">
          {consoleData.map((item, i) => (
            <>
              <div className="my_container">
                <img
                  src={item.bckImg}
                  alt="background"
                  className="console_background"
                />
                <img src={consoleDot} alt="dot" className="console_dot1" />
                <img src={consoleDot} alt="dot" className="console_dot2" />

                <div className="row console_row" key={i}>
                  <div className="col-lg-8 console_col">
                    <ProfileCard
                      name={item.name}
                      ctaTextColor={item.btnColor}
                      title={item.btn}
                      contactNo={item.tel}
                      emailId={item.email}
                      address={item.address}
                    />
                  </div>
                  <div className="col-lg-4 console_col">
                    <Swiper
                      modules={[Pagination]}
                      spaceBetween={20}
                      pagination={true}
                    >
                      <SwiperSlide>
                        <ConsoleNoticeCard
                          title={item.heading}
                          notice={item.desc}
                        />
                      </SwiperSlide>
                      <SwiperSlide>
                        <ConsoleNoticeCard
                          title={item.heading}
                          notice={item.desc}
                        />
                      </SwiperSlide>
                      <SwiperSlide>
                        <ConsoleNoticeCard
                          title={item.heading}
                          notice={item.desc}
                        />
                      </SwiperSlide>
                      <SwiperSlide>
                        <ConsoleNoticeCard
                          title={item.heading}
                          notice={item.desc}
                        />
                      </SwiperSlide>
                    </Swiper>
                  </div>
                </div>
              </div>
            </>
          ))}
        </section>

        <section className="deconsole_sec2">
          <div className="my_container">
            <div className="row intro_row">
              <div className="col-lg-12 intro_col">
                <h2 className="heading">
                  Welcome to the Archinza Design Network!
                </h2>
                <div className="intro_wrapper">
                  <p className="desc">We will notify you when we launch!</p>
                  <img
                    src={rocket}
                    alt="rocket launch"
                    className="rocket_img"
                  />
                </div>
              </div>
              <div className="col-lg-7 intro_col">
                <div className="sub_content_wrapper">
                  <h2 className="subheading">Meanwhile, let's find your</h2>
                  <p className="subdesc">Design Business Awareness Quotient.</p>
                </div>
                <div className="sub_content_wrapper">
                  <h2 className="subheading">Congratulations!</h2>
                  <p className="subdesc">Your DBAQ is above average!</p>
                </div>
              </div>
              <div className="col-lg-5 intro_col">
                <div className="button_container">
                  <Link to={() => false} className="cta_wrapper">
                    <img src={consoleArrow} alt="arrow" className="arrow_img" />
                    <p className="cta_text">start</p>
                  </Link>
                  <button className="remind_btn">Remind me later</button>
                  {/* <ConsolePopup /> */}
                </div>
              </div>
            </div>

            <div className="mobile_notice">
              {consoleData.map((item, i) => (
                <Swiper
                  modules={[Pagination]}
                  spaceBetween={20}
                  pagination={true}
                >
                  <SwiperSlide>
                    <ConsoleNoticeCard
                      title={item.heading}
                      notice={item.desc}
                    />
                  </SwiperSlide>
                  <SwiperSlide>
                    <ConsoleNoticeCard
                      title={item.heading}
                      notice={item.desc}
                    />
                  </SwiperSlide>
                  <SwiperSlide>
                    <ConsoleNoticeCard
                      title={item.heading}
                      notice={item.desc}
                    />
                  </SwiperSlide>
                  <SwiperSlide>
                    <ConsoleNoticeCard
                      title={item.heading}
                      notice={item.desc}
                    />
                  </SwiperSlide>
                </Swiper>
              ))}
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default DEConsole;


