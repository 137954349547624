// General SVGs
export { default as plusicon } from "./general/plus-icon.svg";
export { default as prologo } from "./general/archinza-pro-logo.svg";
export { default as prologoblack } from "./general/archinza-pro-logo-black.svg";
export { default as blacklogo } from "./general/archinza-logo-black.svg";
export { default as whitelogo } from "./general/white-logo.svg";
export { default as fbHeader } from "./general/fb-icon.svg";
export { default as instaHeader } from "./general/insta-icon.svg";
export { default as linkedinHeader } from "./general/linkedin-icon.svg";
export { default as fbHeaderB } from "./general/fb-black.svg";
export { default as instaHeaderB } from "./general/insta-black.svg";
export { default as linkedinHeaderB } from "./general/linkedin-black.svg";
export { default as blackright } from "./general/black-right-arrow.svg";
export { default as loginicon } from "./general/login-icon.svg";
export { default as dropdownarrow } from "./general/dropdown-arrow.svg";
export { default as rightarrowwhite } from "./general/right-arrow-white.svg";
export { default as rightarrowblack } from "./general/right-arrow-black.svg";
export { default as deleteicon } from "./general/delete-icon.svg";
export { default as blackDeleteicon } from "./general/black-delete-icon.svg";
export { default as websiteicon } from "./general/website-icon.svg";
export { default as formfb } from "./general/formfb-icon.svg";
export { default as forminsta } from "./general/forminsta-icon.svg";
export { default as formupload } from "./general/cloud_upload.svg";
export { default as formLinkedin } from "./general/formLinkedin.svg";
export { default as formBehance } from "./general/formBehance.svg";
export { default as checkicon } from "./general/check-icon.svg";
export { default as checkiconWhite } from "./general/check-icon-white.svg";
export { default as otpicon } from "./general/otp-icon.svg";
export { default as protag } from "./general/pro-tag.png";
export { default as addLocation } from "./general/location.svg";
export { default as accPlusIcon } from "./general/plusIcon.svg";
export { default as accMinusIcon } from "./general/minusIcon.svg";
export { default as orangeRightArrow } from "./general/right-arrow-orange.svg";
export { default as shareIcon } from "./general/share.svg";
export { default as shareIconWhite } from "./general/share-icon.svg";
export { default as hamburgerLinkArrow } from "./general/header_right_arrow.svg";
export { default as headerSearch } from "./general/search.svg";
export { default as floatingIcon } from "./general/floating_outer.svg";
export { default as floatingIcon1 } from "./general/floating_inner.svg";
export { default as reseticon } from "./general/reset-icon-orange.svg";
export { default as archinzaChatVideo } from "./general/Archinza bot_Desktop_v02.mp4";
export { default as imgclose } from "./general/img-delete-icon.svg";
export { default as archinzaLabel } from "./general/archinza-label.svg";

// export {default as bannerList} from "./blogsListing_banner.png"
// blogs listing page
export { default as blogsListing } from "../images/blogs/blogsListing_banner.png";
export { default as rightArrowBlue } from "../images/blogs/right-arrow-blue.svg";

// blogs inner page
export { default as blogsInner } from "../images/blogs/blogsListing_banner.png";
export {default as lanceanderson} from "../images/blogs/lance-anderson.png";
export { default as heroBlogsInnerImg } from "../images/blogs/luke-van-zyl-blogs-inner.png";
export { default as blogsShareIcon } from "../images/blogs/share.svg";

// homepage
export { default as logo } from "../images/homepage/archinza_logo_2.png";
export { default as lightthemebackground } from "../images/registrationform/light-theme-background.png";
export { default as mbLogo } from "../images/homepage/mb_archinza_logo_2.png";
export { default as homepageBanner } from "../images/homepage/banner_1.png";
export { default as mbHomepageBanner } from "../images/homepage/mb_banner_1.png";
export { default as chatImg } from "../images/homepage/chat_img_3.png";
export { default as mbChatImg } from "../images/homepage/mb_chat_img_3.png";
export { default as facebook } from "../images/homepage/facebook.png";
export { default as linkedIn } from "../images/homepage/linkedin.png";
export { default as insta } from "../images/homepage/insta.png";
export { default as dummyVideo } from "../images/homepage/dummy-video.mp4";
export { default as searchIcon } from "../images/homepage/search-icon.svg";
export { default as reachIcon } from "../images/homepage/reach-icon.svg";
export { default as connectIcon } from "../images/homepage/connect-icon.svg";

//console page
export { default as consoleBackground_1 } from "../images/console/Console_background.jpg";
export { default as consoleDot } from "../images/console/glow_2.png";
export { default as consoleCall } from "../images/console/call_icon.png";
export { default as consoleEmail } from "../images/console/email_icon.png";
export { default as consoleLocation } from "../images/console/location_icon.png";
export { default as consoleEdit } from "../images/console/edit_icon.png";
export { default as rocket } from "../images/console/rocket_launch.png";
export { default as consoleArrow } from "../images/console/arrow_right.png";
export { default as speedMeter } from "../images/console/speed_meter.png";
export { default as contactBg } from "../images/console/contact_background.png";
export { default as editicon } from "../images/console/edit-icon.svg";
export { default as editiconorange } from "../images/console/edit-icon-orange.svg";

// registration form page
export { default as regBackground } from "../images/registrationform/registration-background.jpg";
export { default as catalougeDelete } from "../images/registrationform/delete-icon.svg";

// contact us page
export { default as contactBanner } from "../images/contactus/contact_us_banner_1.jpg";

// faqs page
export { default as faqsBanner } from "../images/faqs/faqs_banner.jpg";

// congratulations page
export { default as congraDesktopBanner } from "../images/congratulations/congrats_desktop_banner.jpg";
export { default as congraMobileBanner } from "../images/congratulations/congrats_mobile_banner.jpg";
export { default as congratCup } from "../images/congratulations/cup.svg";

export let images = {
  archinzaFormBg: {
    image: require("./general/archinza-form-bg.png"),
    alt: "form bg",
  },
  formBg: {
    image: require("./general/form-bg.jpg"),
    alt: "background",
  },
  editProfileBG: {
    image: require("./general/edit-back.jpg"),
    alt: "edit profile bg",
  },
  teammemberBg: {
    image: require("./teammember/team-member-bg.jpg"),
    alt: "team member bg",
  },
  homeBg: {
    image: require("./homepage/home-bg.jpg"),
    alt: "home bg",
  },
  mannequeenImg: {
    image: require("./homepage/mannequeen.png"),
    alt: "mannequeen",
  },
  PD633x915: {
    image: require("./homepage/633x915PD.png"),
    alt: "PD633915",
  },


blogListing1: {
  image: require("./blogs/luke-van.png"),
  alt: "blogs",
},
blogListing2: {
  image: require("./blogs/ricardo-gomez.png"),
  alt: "blogs",
},
blogListing3: {
  image: require("./blogs/lance-anderson.png"),
  alt: "blogs",
},
hero1: {
  image: require("./blogs/luke-van-zyl-blogs-inner.png"),
  alt: "blogs",
},

};
