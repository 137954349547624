import TextField from "@mui/material/TextField";
import style from "./fulltextfield.module.scss";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { useWindowSize } from "react-use";
import { useSelector } from "react-redux";
import { getCreateAccount } from "../../redux/app/appSelectors";

const darkTheme = createTheme({
  typography: {
    fontFamily: "Poppins",
  },
  palette: {
    mode: "dark",
  },
});
const lightThemed = createTheme({
  typography: {
    fontFamily: "Poppins",
  },
  palette: {
    mode: "light",
  },
});

const FullWidthTextField = ({ key, value, ...rest }) => {
 // console.log(...rest);
 // const [value] = rest;
  const { width } = useWindowSize();
  const lightTheme =   useSelector((state) => getCreateAccount(state));
  return (
    <>
    {lightTheme === false? (
      <ThemeProvider theme={lightThemed}>
        <CssBaseline />
        <TextField
         InputLabelProps={{ shrink: value?.length>0 ,autoComplete:"none"}}
          key={key}
          outline={false}
          className={style.fullwidth_input_light}
          fullWidth
          value={value}
          inputProps={{
            style: {
              fontSize: width > 768 ? "1.25em" : "1.25em",
              color: "#707070",
              WebkitBoxShadow: "0 0 0 1000px white inset",
              webkitTextFillColor: "#111",
              borderRadius: width > 768 ? "10px" : "10px",
            },
          }}
          sx={{
            "& fieldset": {
              borderRadius: width > 768 ? "10px" : "10px",
              border: "1px solid #707070",
            },

            // input label when focused
            "& label": {
              lineHeight: width > 768 ? "2em" : "1.5em",
              color: "#707070",
            },
            "& label.Mui-focused": {
              color: "#707070",
              borderColor: "#707070",
            },
            // focused color for input with variant='standard'
            "& .MuiInput-underline:after": {
              borderBottomColor: "#707070",
            },
            // focused color for input with variant='filled'
            "& .MuiFilledInput-underline:after": {
              borderBottomColor: "#707070",
            },
            // focused color for input with variant='outlined'
            "& .MuiOutlinedInput-root": {
              "&.Mui-focused fieldset": {
                border: "1px solid #707070",
                borderColor: "#707070",
              },
              "&:hover fieldset": {
                border: "1px solid #707070",
              },
            },
          }}
          {...rest}
        />
      </ThemeProvider>
    ) : (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <TextField
      InputLabelProps={{ shrink: value?.length>0 ,autoComplete:"none"}}
        key={key}
        outline={false}
        
        className={style.fullwidth_input}
        fullWidth
        value={value}
        inputProps={{
          style: {
            fontSize: width > 768 ? "1.25em" : "1.25em",
          },
        }}
        sx={{
          "& fieldset": {
            borderRadius: width > 768 ? "10px" : "10px",
            border: "1px solid $color-707070",
          },
          // input label when focused
          "& label": {
            lineHeight: width > 768 ? "2em" : "1.5em",
          },
          "& label.Mui-focused": {
            color: "#fff",
          },
          // focused color for input with variant='standard'
          "& .MuiInput-underline:after": {
            borderBottomColor: "#fff",
          },
          // focused color for input with variant='filled'
          "& .MuiFilledInput-underline:after": {
            borderBottomColor: "#fff",
          },
          // focused color for input with variant='outlined'
          "& .MuiOutlinedInput-root": {
            "&.Mui-focused fieldset": {
              borderColor: "#fff",
            },
          },
        }}
        {...rest}
      />
    </ThemeProvider>
    )}
    </>
  );
};

export default FullWidthTextField;


