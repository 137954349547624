import React, { useEffect, useState } from "react";
import style from "../EnthusiastAccess/enthusiast.module.scss";
import LogoutText from "../../../components/LogoutText/LogoutText";
import AutoCompleteField from "../../../components/AutoCompleteField/AutoCompleteField";
import { coursesArr } from "../../../db/dataTypesData";
import { rightarrowwhite } from "../../../images";
import { useDispatch, useSelector } from "react-redux";
import FullWidthTextField from "../../../components/TextField/FullWidthTextField";
import CheckboxButton from "../../../components/CheckboxButton/CheckboxButton";
import { updateCustomerProfile } from "../../../redux/app/appAction";
import { getCustomer, getUserId } from "../../../redux/app/appSelectors";
import callApi from "../../../util/apiCaller";
import {toast} from "react-toastify";
import { plusicon } from "../../../images";
import {MsgWithVarText} from "../../EditProfile/EditProfile";
import _ from "lodash";

const EnthuStep01 = ({ nextStep, currentStep, totalSteps, progressStatus, handleUserExtraData , userExtraData}) => {
  useEffect(() => {
    progressStatus((currentStep / totalSteps) * 100);
  }, [currentStep, progressStatus, totalSteps]);

  const dispatch = useDispatch();
  const userId = useSelector((state) => getUserId(state)) ;
  const customer = useSelector((state) => getCustomer(state));
  const [code ,setCode] = useState([])
  const [user, setUser] = useState({
      student_course_applied: []
  });
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if(customer?.student_course_applied){
    setUser({
      student_course_applied: customer?.student_course_applied
    })
  }
  },[customer])

  const [otherInputValue, setAddOtherInputValue] = useState("");

  useEffect(() => {
   const handleKeyPress = (e) => {
     if (e.key === 'Enter' && otherInputValue !== "") {
       //handleUserDetail("student_course", otherInputValue);
       if(user?.student_course_applied?.indexOf(otherInputValue) === -1) {
         handleUserDetail("student_course_applied", otherInputValue);
        //  let arr = [...code]
        //  arr?.push(otherInputValue)
        //  setCode([...arr])
       } else {
         toast(<MsgWithVarText text={'Tag already present'} />, {
           className: "mail_toast",
           position: toast.POSITION.TOP_CENTER,
           hideProgressBar: true,
           closeButton: false,
         })
       }
       setAddOtherInputValue("");
     }
   };

   window.addEventListener('keypress', handleKeyPress);

   return () => {
     window.removeEventListener('keypress', handleKeyPress);
   };
 }, [otherInputValue]);

  useEffect(() => {

    fetchContentCode("student-field-of-study")
   },[])
   async function fetchContentCode(code) {

    try {
      const res = await callApi(`content/code/${code}`, "get");
      console.log(res);
      if (res && res?.status === 'Success') {
        const { params } = res?.data.content;
        let param = params?.map((option) => {
            return option?.label
      })
        setCode([...param]);
      } else {
      }
    } catch (err) {

    }

  }

  const handleUserDetail = (keyName, keyValue) => {
    let prevIndex = user?.student_course_applied?.indexOf(keyValue);

    if(prevIndex !== -1) {
      let update = {
        ...user,
        [keyName]: user?.student_course_applied?.filter(
            (_, index) => index !== prevIndex
        ),
      };
      setUser(update);
    } else {
      let update ={...user};
      update[keyName] = [...user?.student_course_applied];
      update[keyName].push(keyValue);
      update.current_flow = currentStep;
      setUser(update);
      setErrors({});
    }
   }
   const handleValidation = (user) => {

    let isValid = true;
    let errors = {};

    if (_.isEmpty(user?.student_course_applied)) {
        isValid = false;
        errors["student_course_applied"] = "Please select past work experience";
    }

    return {
        isValid,
        errors
    }
  };

  const handleUpdateApi = () => {
    let check = handleValidation(user);
    if(check.isValid && userId){
      let update= {...user}
      update.current_flow = currentStep;
      dispatch(updateCustomerProfile(userId, update))
      //handleUserExtraData(course);
      nextStep(2);
      setErrors({})
      window.scrollTo(0, 0);
    }else {
      setErrors(check.errors);
    }

   }

   const renderError = (keyName) => {
    return errors && errors[keyName] ? <p className="error" style={{marginBottom:"20px"}}>{errors[keyName]}</p> : null;
  };

  const handleAddMore = () => {
    if(otherInputValue) {
        if(user?.student_course_applied?.indexOf(otherInputValue) === -1){
            handleUserDetail("student_course_applied",otherInputValue)

        }else{
            toast(<MsgWithVarText text={'Tag already present'} />, {
                className: "mail_toast",
                position: toast.POSITION.TOP_CENTER,
                hideProgressBar: true,
                closeButton: false,
            })
        }
        setAddOtherInputValue("")
    }
  }

    const handleUser = (keyName,keyValue) => {

        if(keyValue && keyValue !== "") {
            if(user?.student_course_applied?.indexOf(keyValue) === -1){
                if(keyValue !== "") {
                    handleUserDetail("student_course_applied", keyValue)
                }
            } else{
                toast(<MsgWithVarText text={'Tag already present'} />, {
                    className: "mail_toast",
                    position: toast.POSITION.TOP_CENTER,
                    hideProgressBar: true,
                    closeButton: false,
                })
            }
        }
    }

    const handleRemoveTag = (keyName, keyValue) => {
        if(keyValue) {
            let prevIndex = user?.student_course_applied.indexOf(keyValue);

            if(prevIndex !== -1) {
                let update = {
                    ...user,
                    [keyName]: user?.student_course_applied?.filter(
                        (_, index) => index !== prevIndex
                    ),
                };
                setUser(update);
            } else {

            }
        }
    }

  return (
    <>
      <div className={style.text_container}>
        <h1 className={style.title}>
        What is your field of Study?
          <span className={style.coloured_text}></span>
        </h1>
      </div>
      <div className={`${style.steps} ${style.step01}`}>
      <div className={`${style.steps} ${style.step01}`}>
        <div className={style.field_wrapper}>
         {code && <AutoCompleteField
            freeSolo={false}
            popupIcon={""}
            textLabel="What is your field of Study ?*"
            data={code}
            //course={user?.student_course}
            handleUserDetail={handleUser}

          />}
          <div className={'my-4'}> {renderError("student_course_applied")}</div>
        </div>
      </div>
      <ul className={`${style.steps_ul}`}>
        {/* <div className={style.field_wrapper2}> */}
             <FullWidthTextField label="Add Others" key={"Add Others"} onChange={e => {
              setAddOtherInputValue(e.target.value)
              setErrors({});
            }} value={otherInputValue} />
              <img
              src={plusicon}
              alt="icon"
              className={style.icon}
              loading="lazy"
              onClick={handleAddMore}
            />
            {/* </div> */}
        </ul>
        {/* <div className={`${style.reduceSpace }`} > */}
        <div className={'my-4'}>
        {

            !_.isEmpty(user?.student_course_applied) ?
            user?.student_course_applied?.map((key, i) => (
                <React.Fragment key={`{key}+${i}etho`}>
                  <CheckboxButton isChecked={true} label={key} labelId={i + key} handleUserDetail={handleRemoveTag} keyName={"student_course_applied"}/>
                </React.Fragment>
            )) :
            null

          }
        </div>
        </div>
      <div className={style.next_logout}>
     
        <div className={style.cta_wrapper}>
          <div
            className={style.next_button}
            onClick={handleUpdateApi}
          >
            <div className={style.text}>Next</div>
            <img
              src={rightarrowwhite}
              alt="icon"
              className={style.icon}
              loading="lazy"
            />
          </div>
        </div>
        <LogoutText userExtraData={user} />
      </div>
    </>
  );
};

export default EnthuStep01;
