import React, { useEffect, useState } from "react";
import style from "../EnthusiastAccess/enthusiast.module.scss";
import CheckboxButton from "../../../components/CheckboxButton/CheckboxButton";
import LogoutText from "../../../components/LogoutText/LogoutText";
import { rightarrowwhite, plusicon } from "../../../images";
import FullWidthTextField from "../../../components/TextField/FullWidthTextField";
import { useDispatch, useSelector } from "react-redux";
import { updateCustomerProfile } from "../../../redux/app/appAction";
import { getUserId, getCustomer } from "../../../redux/app/appSelectors";
import callApi from "../../../util/apiCaller";
import { useNavigate } from "react-router-dom";
import { consoleUrl } from "../../../components/helpers/constant-words";
import _ from "lodash";
import {toast} from "react-toastify";
import {MsgWithVarText} from "../../EditProfile/EditProfile";
import AutoCompleteField from "../../../components/AutoCompleteField/AutoCompleteField";

// const darkTheme = createTheme({
//   palette: {
//     mode: "dark",
//   },
// });

// const concernsArr = [
//   "Jobs | Internships",
//   "Searching Products Materials For Projects",
//   "Knowledge On Trends | Materials",
//   "Upskill & Learn New Software",
//   "Knowledge | Confidence To Start My Own Firm",
//   "Education Quality",
// ];

const EnthuStep03 = ({
  previousStep,
  nextStep,
  currentStep,
  totalSteps,
  progressStatus,
  finalUserExtraData,
  userExtraData
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userId = useSelector((state) => getUserId(state)) ;
  const customer = useSelector((state) => getCustomer(state));
  const [skill, setSkill] = useState({
    "requirements": []
  });
  const [addOthers, setAddOthers] = useState("")
  const [updatedRequirement, setUpdatedRequirement] = useState([])
  const [code ,setCode] = useState([]);
  const [errors, setErrors] = useState({});

  useEffect((state) => {
    if(customer?.requirements){
    setSkill({
      "requirements": customer?.requirements
    })
  }
  },[customer])

  useEffect(() => {

    fetchContentCode("student-unmet-needs")
   },[])
   async function fetchContentCode(code) {

    try {
      const res = await callApi(`content/code/${code}`, "get");
      console.log(res);
      if (res && res?.status === 'Success') {
        const { params } = res?.data.content;
        setUpdatedRequirement([...params]);
        setCode(params.map(item => item.label));
      } else {
      }
    } catch (err) {

    }

  }

  useEffect(() => {
    const handleKeyPress = (e) => {
      if (e.key === 'Enter' && addOthers !== "") {
        //handleUserDetail("requirements", addOthers);
        if(skill?.requirements?.indexOf(addOthers) === -1) {
          handleUserDetail("requirements", addOthers);
          // let arr = [...updatedRequirement]
          // arr?.push({label:addOthers,value:addOthers})
          // setUpdatedRequirement([...arr])
        } else {
          toast(<MsgWithVarText text={'Tag already present'} />, {
            className: "mail_toast",
            position: toast.POSITION.TOP_CENTER,
            hideProgressBar: true,
            closeButton: false,
          })
        }
        setAddOthers("");
      }
    };

    window.addEventListener('keypress', handleKeyPress);

    return () => {
      window.removeEventListener('keypress', handleKeyPress);
    };
  }, [addOthers]);

  const handleUserDetail = (keyName,keyValue) => {
    // let update = {...skill}
    // if(keyValue){
    //  setErrors({})
    // }
    // if(!update["requirements"]?.includes(keyValue)){
    // update["requirements"]?.push(keyValue);

    // setSkill(update);
    // }else{
    //   handleRemove(keyValue)
    // }
    let prevIndex = skill?.requirements.indexOf(keyValue);

    if(prevIndex !== -1) {
      let update = {
        ...skill,
        [keyName]: skill?.requirements?.filter(
            (_, index) => index !== prevIndex
        ),
      };
      setSkill(update);
    } else {
      let update ={...skill};
      update[keyName] = [...skill?.requirements];
      if(keyValue === "All of the Above"){
        let arr = [...skill?.requirements,...code.slice(0,code.length-1)]
        let set = new Set(arr)
        let arr1 = Array.from(set)
        update['requirements'] = [...arr1]
      }else{
        update['requirements']?.push(keyValue);
      }
      setSkill(update);
      setErrors({});
    }
  }

  // const handleRemove = (keyValue) => {

  //  let res = [...skill?.requirements]
  //   //let addData = [...addedRequirement]
  // let update = {requirements:[]}
  //   for(let item of res){
  //     if(item !== keyValue){
  //       update["requirements"]?.push(item)
  //     }else{
  //     // updatedRequirement?.push(item)
  //     }
  //   }
  //   setSkill(update)

  //   // let updatedData = updatedRequirement?.filter(item => item !== keyValue)
  //   // setUpdatedRequirement([...updatedData]);
  // }
  const handleValidation = (user) => {

    let isValid = true;
    let errors = {};
    if (user?.requirements.length === 0) {
        isValid = false;
        errors["requirements"] = "Please select atleast one unmet needs";
    }

      return {
        isValid,
        errors
    }
  }
  const handleUpdateApi = () => {

    let check = handleValidation(skill);
    let update= {...skill}
    update.current_flow = currentStep;
    if(check.isValid && userId ){
      dispatch(updateCustomerProfile(userId, update))
      //finalUserExtraData(skill);
      nextStep(4);
      setErrors({})
      window.scrollTo(0, 0);
      navigate(consoleUrl);
    }else {
      setErrors(check.errors);
    }

   }
   const renderError = (keyName) => {
    return errors && errors[keyName] ? <p className="error" style={{marginBottom:"20px"}}>{errors[keyName]}</p> : null;
  };

  //  const handleAddOthers = () => {
  //     setUpdatedRequirement([...updatedRequirement, {label:addOthers,value:addOthers}])

  //     setAddOthers("")
  //  }/

  const handleAddMore = () => {
    if(addOthers) {
      if(skill?.requirements?.indexOf(addOthers) === -1){
        handleUserDetail("requirements",addOthers)

      }else{
        toast(<MsgWithVarText text={'Tag already present'} />, {
          className: "mail_toast",
          position: toast.POSITION.TOP_CENTER,
          hideProgressBar: true,
          closeButton: false,
        })
      }
      setAddOthers("");
    }
  }

  const concernList = updatedRequirement?.map((option) => (
    skill?.requirements?.indexOf(option.value) === -1 ?
    <React.Fragment key={`ethu${option.label}`}>
      <CheckboxButton isChecked={false} label={option.label} labelId={`ethud${option.label}`} handleUserDetail={handleUserDetail} keyName={"requirements"}/>
    </React.Fragment>:null
  ));
  const addedList = !_.isEmpty(skill?.requirements) ? skill.requirements.map((option,i) => (
    <React.Fragment key={`ee${option}`}>
      <CheckboxButton isChecked={true} label={option} labelId={`newethud${option+i}`} handleUserDetail={handleUserDetail} keyName={"requirements"} />
    </React.Fragment> )): null


  useEffect(() => {
    progressStatus((currentStep / totalSteps) * 100);
  }, [currentStep, progressStatus, totalSteps]);

  const handleUser = (keyName,keyValue) => {
    if(keyValue && keyValue !== "") {
      if(skill?.requirements?.indexOf(keyValue) === -1){
        if(keyValue !== "") {
          handleUserDetail("requirements", keyValue)
        }
      } else{
        toast(<MsgWithVarText text={'Tag already present'} />, {
          className: "mail_toast",
          position: toast.POSITION.TOP_CENTER,
          hideProgressBar: true,
          closeButton: false,
        })
      }
    }
  }

  return (
    <>
      <div className={style.text_container}>
        <h1 className={style.title}>
        What are your Unmet needs/Concerns?
        </h1>
        <p className={style.description}>Choose as many</p>
      </div>
      <div className={`${style.steps} ${style.step01}`}>
        <div className={`${style.steps} ${style.step01}`}>
          <div className={style.field_wrapper}>
            {code && <AutoCompleteField
                freeSolo={false}
                popupIcon={""}
                textLabel="Unmet needs/Concerns"
                data={code}
                //course={user?.student_course}
                handleUserDetail={handleUser}

            />}
            <div className={'my-4'}>
          {renderError("requirements")}
        </div>
          </div>
        </div>
        {/*<ul className={style.step02_ul}>{addedList}{concernList}</ul>*/}
        <div className={`${style.add_more_wrap} ${style.add_more_v2}`}>
          <div className={style.steps_ul}>
            <FullWidthTextField label="Add Others" key={"Enthusiast Add Others"} onChange={(e) => setAddOthers(e.target.value)} value={addOthers} />
            <img
                src={plusicon}
                alt="icon"
                className={style.icon}
                loading="lazy"
                onClick={handleAddMore}
            />
            {/*<FullWidthTextField label="Add Others" value={addOthers} onChange={} />*/}
            {/* <img
              src={plusicon}
              alt="icon"
              className={style.icon}
              loading="lazy"
              onClick={handleAddOthers}
            /> */}

          </div>
        </div>
        <div className={'my-4'}>
        {/* <div className={`${style.reduceSpace }`}> */}
          {

            !_.isEmpty(skill?.requirements) ?
                skill?.requirements?.map((key, i) => (
                    <React.Fragment key={`{key}+${i}ioe`}>
                      <CheckboxButton isChecked={true} label={key} labelId={`ncewstud${key+i}`} handleUserDetail={handleUserDetail} keyName={"requirements"} />
                    </React.Fragment>
                )) :
                null

          }
        </div>
        
      </div>

      <div className={style.next_logout}>
        <div className={style.cta_wrapper}>
          <div
            className={style.next_button}
            onClick={handleUpdateApi}
          >
            <div className={style.text}>Submit</div>
            {/*<img*/}
            {/*  src={rightarrowwhite}*/}
            {/*  alt="icon"*/}
            {/*  className={style.icon}*/}
            {/*  loading="lazy"*/}
            {/*/>*/}
          </div>
          <div
            className={style.back_button}
            onClick={() => {
              previousStep(2);
              setErrors({})
              window.scrollTo(0, 0);
            }}
          >
            Back
          </div>
        </div>
        {/* <LogoutText userExtraData={skill}/> */}
      </div>
    </>
  );
};

export default EnthuStep03;
