import {businessAccessURL, studentAccesssURL, teamAccesssURL, enthusiastAccesssURL} from "../components/helpers/constant-words";
import { setIsPro } from "../redux/app/appAction";
import { useDispatch } from "react-redux";

export const APP_TOKEN = 'token';
export const USER_TYPE = 'user_type';
export const USER_TYPE_BUSINESS = 'business';
export const USER_TYPE_TEAM_MEMBER = 'team-member';
export const USER_TYPE_STUDENT = 'student';
export const USER_TYPE_DESIGN_ENTHUSIAST = 'design-enthusiast';
export const USER_ID = 'userId';
export const STUDENT_STEP_FLOW = 'student_step_flow';
export const TEAM_STEP_FLOW = 'team_step_flow';
export const BUSINESS_STEP_FLOW = 'business_step_flow';
export const ENTHUSIAST_STEP_FLOW = 'enthusiast_step_flow';

export const DIFFERENT_USER_TYPES = [
    {
        display_name: "Business | Firm Owner",
        label : "business",
        value: "1"
    },
    {
        display_name: "Student",
        label : "student",
        value: "2"
    },
    {
        display_name: "Team Member",
        label : "team-member",
        value: "3"
    },
    {
        display_name: "Design Enthusiast",
        label : "design-enthusiast",
        value: "4"
    }
];
export const ACCOUNT_TYPES = [
    {
        display_name: "Ant Creatives",
        label : "ant-creatives",
        value: "1"
    },
    {
        display_name: "KRISHNA",
        label : "krishna",
        value: "2"
    },
    {
        display_name: "POORVANSH",
        label : "poorvansh",
        value: "3"
    },
    {
        display_name: "MUKUL",
        label : "mukul",
        value: "4"
    }
];

export const SELECT_AC_TYPE = [
    {
        display_name: "Ant",
        label : "ant",
        value: "1"
    },
    {
        display_name: "STUDENT",
        label : "student",
        value: "2"
    },
    {
        display_name: "TEAM MEMBER",
        label : "team-member",
        value: "3"
    },
    {
        display_name: "DESIGN ENTHUSIAST",
        label : "design-enthusiast",
        value: "4"
    }
];

let displayMapper = {
    "business-owner" : "Business",
    "business" : "Business | Firm Owner",
    "student" : "Student",
    "team-member" : "Team Member",
    "design-enthusiast" : "Design Enthusiast"
};

const USES_WITH_BUSINESS_ACCESS = [USER_TYPE_BUSINESS, USER_TYPE_STUDENT, USER_TYPE_TEAM_MEMBER,USER_TYPE_DESIGN_ENTHUSIAST];

export const getDisplayNameAsPerLabel = (label) => {
    return displayMapper[label];
}

export const showBusinessAccess = (customer) => {
    return USES_WITH_BUSINESS_ACCESS.includes(customer?.user_type)
};

export const showIfUserProfileIsComplete = (customer) => {
    return parseInt(customer?.profile_completion) === 100;
}

export const getUserProfileAccessRoute = (customer) => {
    
    let url = "";
    switch (customer.user_type) {
        case  USER_TYPE_BUSINESS:
           
            url = businessAccessURL;
            break;
        case  USER_TYPE_TEAM_MEMBER:
            url = teamAccesssURL;
            break;

        case USER_TYPE_STUDENT:
            url = studentAccesssURL;
            break;
        case USER_TYPE_DESIGN_ENTHUSIAST:
            url = enthusiastAccesssURL;
            break;
        default:
    }
    return url;
};

