import React, {useState} from "react";
import "./accountcategory.scss";
import { Link } from "react-router-dom";
import RadioButton from "../../../components/RadioButton/RadioButton";
import { regBackground, rightarrowwhite } from "../../../images";
import FooterV2 from "../../../components/FooterV2/FooterV2";
import {
  businessFormFiveURL,
  personalAccountRegistrationUrl,
  privacypolicyURL
} from "../../../components/helpers/constant-words";
import {useNavigate} from "react-router-dom";
import { useDispatch } from "react-redux";
import { setPersonalText, setBrandText } from "../../../redux/app/appAction";
import BlinkingDots from "../../../Animations/BlinkingDots/BlinkingDots";
import FormModal from "./FormModal/FormModal";

const AccountCategory = () => {
  const [account, setAccount] = useState("");
  const navigate = useNavigate();
  const [modalShow, setModalShow] = useState(false);
  const dispatch = useDispatch()

  const [errors, setErrors] = useState({});
  const handleChangeAccount = (key_name, value) => {
    setAccount(value);
    setErrors({})
  }
  const handleValidationLogin = (account) => {
    let isValid = true;
    let errors = {};
    if (!account) {
        isValid = false;
        errors["account_type"] = "Please select an account type from above";
    }
    return {
        isValid,
        errors
    }
  }
  const handleProceed = () => {
    let check = handleValidationLogin(account)
    if(check.isValid){
    if(account === 'personal-account') {
      dispatch(setPersonalText(true))
      dispatch(setBrandText(false))
      navigate(personalAccountRegistrationUrl)
      window.scrollTo(0,0)
    } else {
      navigate(businessFormFiveURL)
      dispatch(setBrandText(true))
      dispatch(setPersonalText(false))
      window.scrollTo(0,0)
    }
    setErrors({})
    }else{
      setErrors(check?.errors);
    }
  }
  const renderError = (keyName) => {
    return errors && errors[keyName] ? (<p className="error" style={{ marginBottom:"20px" }}>{errors[keyName]}</p>) : null;
  };

  return (
    <>
     <BlinkingDots />
      <main className="ac_main">

        <section className="ac_sec1">
          <div className="my_container">
            <div className="text_container">
              <p className="head_text">Get Early Access</p>
              <h1 style={{margin: '1rem'}} className="heading"></h1>
              <h2 className="sub_heading">
                What Would You Like To SIGN UP For ?
              </h2>
              <div className={"descrip"}>
              <p className="choose_text">Choose one 
              {/* <div className={"entit"} > */}
             <span style={{cursor:"pointer"}} onClick={() => setModalShow(true)}> &#9432;</span>
            {/* </div> */}
            </p>
          </div>
            </div>

            <div className="cateogories_wrapper">
              <div className="cateogory_box">
                <ul className="radio_container">
                  <RadioButton
                      checked={account === 'personal-account'}
                      handleChangeUser={handleChangeAccount}
                    label="Personal Account"
                    labelId="personal-account"
                      value={'personal-account'}
                  />
                </ul>
                <p className="notice_message">
                I want to Search for People and Products via Archinza Design Assistant
                </p>
              </div>
              <div className="cateogory_box">
                <ul className="radio_container">
                  <RadioButton
                      checked={account === 'brand-account'}
                      value={'brand-account'}
                      handleChangeUser={handleChangeAccount}
                      label="Business Account"
                      labelId="brand-account"
                  />
                </ul>
                <p className="notice_message">
                I want to Reach new clients, and Be Suggested by the Archinza Design Assistant 
                </p>
              </div>
             
            </div>

            <div className="notice">
            <div >{renderError("account_type")}</div>
              By continuing, you agree to the terms of{" "}
              <Link target={'_blank'} className="orange_text" to={privacypolicyURL}>Archinza Policy</Link>.
              {/* <span style={{cursor: "pointer"}} onClick={() => navigate(privacypolicyURL)} >Archinza Policy</span>. */}
            </div>
           
            <div className="btn_wrapper">
            
              <button onClick={handleProceed} className="form_btn">
                Proceed
                <img
                  src={rightarrowwhite}
                  alt="icon"
                  className="icon"
                  loading="lazy"
                />
              </button>
            </div>
          </div>
        </section>
        <FooterV2 />
      </main>
      <FormModal show={modalShow} onHide={() => setModalShow(false)} />
    </>
  );
};

export default AccountCategory;
