import React, { useEffect, useRef, useState } from "react";
import "./homepage.scss";
import {
  archinzaChatVideo,
  chatImg,
  images,
  rightarrowwhite,
  rightArrowBlue,
} from "../../images";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  Pagination,
  Autoplay,
  EffectCoverflow,
  Navigation,
  EffectFade,
} from "swiper/modules";
import {
  bannerData,
  builtData,
  homeFaqData,
  noticeData,
  perksData,
} from "../../components/Data/homeData";
import { useWindowSize } from "react-use";
import { CustomAccordion } from "../../components/Accordion/Accordion";
import { Link } from "react-router-dom";
import AutoplayVideo from "../../components/AutoplayVideo/AutoplayVideo";
import ReactTyped from "react-typed";
//import { registrationFormURL } from "../../components/helpers/constant-words";
import { consoleUrl, registrationFormURL } from "../../components/helpers/constant-words";
import {getCustomer, getISPro} from "../../redux/app/appSelectors";
import { useSelector } from "react-redux";
import FooterV2 from "../../components/FooterV2/FooterV2";

const HomepageV2 = () => {
  const { width } = useWindowSize();
  const bannerSwiperRef = useRef(null);
  const typedRef = useRef([]);
  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const deadline = "July, 01, 2024 00:00:00";
  const videoSource = archinzaChatVideo;
  const posterImage = chatImg;

  const getTime = () => {
    const time = Date.parse(deadline) - Date.now();
    setDays(Math.floor(time / (1000 * 60 * 60 * 24)));
    setHours(Math.floor((time / (1000 * 60 * 60)) % 24));
    setMinutes(Math.floor((time / 1000 / 60) % 60));
    setSeconds(Math.floor((time / 1000) % 60));
  };
  const customer = useSelector((state) => getCustomer(state));
  const getLoggedInState = (customer) => {
    return !!(customer && customer._id);
}

  useEffect(() => {
    const interval = setInterval(() => getTime(deadline), 1000);

    return () => clearInterval(interval);
  }, []);

  // const bannerList = bannerData.map((data, i) => (
  //   <React.Fragment key={`banner-${i}`}>
  //     <SwiperSlide>
  //       <div className="banner_wrap">
  //         <h2 className="banner_title">
  //           <ReactTyped
  //             key={`animation-${i}`}
  //             typeSpeed={40}
  //             ref={(el) => (typedRef.current[i] = el)}
  //             strings={[data.title]}
  //             className={`typed-element-${i}`}
  //           />
  //         </h2>
  //         <p className="banner_desc">{data.desc}</p>
  //         <div className="cta_wrapper">
  //           <Link className="common_cta">
  //             <div className="text">{data.ctaText}</div>
  //             <img
  //               src={rightarrowwhite}
  //               alt="icon"
  //               className="icon"
  //               loading="lazy"
  //             />
  //           </Link>
  //         </div>
  //       </div>
  //     </SwiperSlide>
  //   </React.Fragment>
  // ));

  const perkList = perksData.map((data, i) => (
    <React.Fragment key={`perk-${i}`}>
      <SwiperSlide>
        <div className="perk_card" data-aos="fade-up" data-aos-delay={i * 100}>
          <img
            width={110}
            height={110}
            src={data.icon}
            alt="icon"
            className="perk_icon"
            // loading="lazy"
          />
          <h2 className="heading">{data.title}</h2>
          <p className="desc">{data.desc}</p>
        </div>
      </SwiperSlide>
    </React.Fragment>
  ));

  const noticeList = noticeData.map((data, i) => (
    <React.Fragment key={`notice-${i}`}>
      <SwiperSlide>
        <div className="notice_card_home">
          <p className="desc">{data.desc}</p>
        </div>
      </SwiperSlide>
    </React.Fragment>
  ));

  useEffect(() => {
    if (bannerSwiperRef.current) {
      bannerSwiperRef.current.swiper.on("slideChange", () => {
        if (typedRef.current) {
          typedRef.current[
            bannerSwiperRef.current.swiper.realIndex
          ].typed.reset();
        }
      });
    }
  }, [bannerSwiperRef]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <section className="home_sec1">
        <img
          src={images.homeBg.image}
          alt={images.homeBg.alt}
          className="home_background"
        />
        <div className="my_container">
          <div className="banner_slider" data-aos="fade">
            <Swiper
              ref={bannerSwiperRef}
              modules={[Pagination, Autoplay, Navigation, EffectFade]}
              effect="fade"
              spaceBetween={20}
              pagination={{
                clickable: true,
              }}
              autoplay={{
                delay: 3500,
                disableOnInteraction: false,
              }}
              // autoHeight={true}
              speed={1000}
              loop={true}
            >
              {/* {bannerList} */}
              <SwiperSlide>
                <div className="banner_wrap banner_wrap1">
                  <h2 className="banner_title banner_title1">
                    <span
                      dangerouslySetInnerHTML={{ __html: bannerData[0].title }}
                    />

                    <ReactTyped
                      ref={(el) => (typedRef.current[0] = el)}
                      strings={[bannerData[0].animTitle]}
                      typeSpeed={40}
                    />
                    {/* <TypeAnimation
                      sequence={[
                        "One",
                        500,
                        "One Two",
                        500,
                        "One Two Three",
                        500,
                        "One Two",
                        500,
                        "One",
                        500,
                        "",
                        500,
                      ]}
                      repeat={Infinity}
                    /> */}
                  </h2>
                  {/* <p className="banner_desc">{bannerData[0].desc}</p>
                  <div className="cta_wrapper">
                    <Link className="common_cta">
                      <div className="text">{bannerData[0].ctaText}</div>
                      <img
                        src={rightarrowwhite}
                        alt="icon"
                        className="icon"
                        loading="lazy"
                      />
                    </Link>
                  </div> */}
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="banner_wrap">
                  <h2 className="banner_title banner_title2">
                    <span
                      dangerouslySetInnerHTML={{ __html: bannerData[1].title }}
                    />

                    <ReactTyped
                      ref={(el) => (typedRef.current[1] = el)}
                      strings={[bannerData[1].animTitle]}
                      typeSpeed={40}
                    />
                  </h2>
                  {/* <p className="banner_desc">{bannerData[1].desc}</p>
                  <div className="cta_wrapper">
                    <Link className="common_cta">
                      <div className="text">{bannerData[1].ctaText}</div>
                      <img
                        src={rightarrowwhite}
                        alt="icon"
                        className="icon"
                        loading="lazy"
                      />
                    </Link>
                  </div> */}
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="banner_wrap">
                  <h2 className="banner_title banner_title3">
                    <span
                      dangerouslySetInnerHTML={{ __html: bannerData[2].title }}
                    />

                    <ReactTyped
                      ref={(el) => (typedRef.current[2] = el)}
                      strings={[bannerData[2].animTitle]}
                      typeSpeed={40}
                    />
                  </h2>
                  {/* <p className="banner_desc">{bannerData[2].desc}</p>
                  <div className="cta_wrapper">
                    <Link className="common_cta">
                      <div className="text">{bannerData[2].ctaText}</div>
                      <img
                        src={rightarrowwhite}
                        alt="icon"
                        className="icon"
                        loading="lazy"
                      />
                    </Link>
                  </div> */}
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="banner_wrap">
                  <h2 className="banner_title banner_title4">
                    <span
                      dangerouslySetInnerHTML={{ __html: bannerData[3].title }}
                    />

                    <ReactTyped
                      ref={(el) => (typedRef.current[3] = el)}
                      strings={[bannerData[3].animTitle]}
                      typeSpeed={40}
                    />
                  </h2>
                  {/* <p className="banner_desc">{bannerData[3].desc}</p>
                  <div className="cta_wrapper">
                    <Link className="common_cta">
                      <div className="text">{bannerData[3].ctaText}</div>
                      <img
                        src={rightarrowwhite}
                        alt="icon"
                        className="icon"
                        loading="lazy"
                      />
                    </Link>
                  </div> */}
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="banner_wrap">
                  <h2 className="banner_title banner_title5">
                    <span
                      dangerouslySetInnerHTML={{ __html: bannerData[4].title }}
                    />

                    <ReactTyped
                      ref={(el) => (typedRef.current[4] = el)}
                      strings={[bannerData[4].animTitle]}
                      typeSpeed={40}
                    />
                  </h2>
                  {/* <p className="banner_desc">{bannerData[4].desc}</p>
                  <div className="cta_wrapper">
                    <Link className="common_cta">
                      <div className="text">{bannerData[4].ctaText}</div>
                      <img
                        src={rightarrowwhite}
                        alt="icon"
                        className="icon"
                        loading="lazy"
                      />
                    </Link>
                  </div> */}
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="banner_wrap">
                  <h2 className="banner_title banner_title6">
                    <span
                      dangerouslySetInnerHTML={{ __html: bannerData[5].title }}
                    />

                    <ReactTyped
                      ref={(el) => (typedRef.current[5] = el)}
                      strings={[bannerData[5].animTitle]}
                      typeSpeed={40}
                    />
                  </h2>
                  {/* <p className="banner_desc">{bannerData[5].desc}</p>
                  <div className="cta_wrapper">
                    <Link className="common_cta">
                      <div className="text">{bannerData[5].ctaText}</div>
                      <img
                        src={rightarrowwhite}
                        alt="icon"
                        className="icon"
                        loading="lazy"
                      />
                    </Link>
                  </div> */}
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="banner_wrap">
                  <h2 className="banner_title banner_title7">
                    <span
                      dangerouslySetInnerHTML={{ __html: bannerData[6].title }}
                    />

                    <ReactTyped
                      ref={(el) => (typedRef.current[6] = el)}
                      strings={[bannerData[6].animTitle]}
                      typeSpeed={40}
                    />
                  </h2>
                  {/* <p className="banner_desc">{bannerData[6].desc}</p>
                  <div className="cta_wrapper">
                    <Link className="common_cta">
                      <div className="text">{bannerData[6].ctaText}</div>
                      <img
                        src={rightarrowwhite}
                        alt="icon"
                        className="icon"
                        loading="lazy"
                      />
                    </Link>
                  </div> */}
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
          <div className="cta_wrapper">
          {getLoggedInState(customer) === false ?  <Link className="common_cta" to={registrationFormURL} onClick={() => {window.scrollTo(0,0)}}>
              <div className="text">Get Early Access</div>
              <img
                src={rightarrowwhite}
                alt="icon"
                className="icon"
                loading="lazy"
              />
            </Link>
            :
             <Link className="common_cta" to={consoleUrl} onClick={() => {window.scrollTo(0,0)}}>
             <div className="text">Get Early Access</div>
             <img
               src={rightarrowwhite}
               alt="icon"
               className="icon"
               loading="lazy"
             />
           </Link>
          }
          </div>
          <div className="launch_container" data-aos="fade-up">
            <p className="title">Launching in</p>
            <div className="clock_wrapper">
              <div className="clock_content">
                <p className="clock_number">
                  {days >= 0 ? (days < 10 ? "0" + days : days) : "00"}
                </p>
                <p className="clock_text">Days</p>
              </div>
              <div className="clock_dots">:</div>
              <div className="clock_content">
                <p className="clock_number">
                  {hours >= 0 ? (hours < 10 ? "0" + hours : hours) : "00"}
                </p>
                <p className="clock_text">Hours</p>
              </div>
              <div className="clock_dots">:</div>
              <div className="clock_content">
                <p className="clock_number">
                  {minutes >= 0
                    ? minutes < 10
                      ? "0" + minutes
                      : minutes
                    : "00"}
                </p>
                <p className="clock_text">Minutes</p>
              </div>
              <div className="clock_dots">:</div>
              <div className="clock_content">
                <p className="clock_number">
                  {seconds >= 0
                    ? seconds < 10
                      ? "0" + seconds
                      : seconds
                    : "00"}
                </p>
                <p className="clock_text">Seconds</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="home_sec2">
        <div className="my_container">
          <h2 className="title" data-aos="fade-up">
            Everything you need, <br />
            simple to find
          </h2>
          <div className="perks_container">
            <Swiper
              modules={[Autoplay, EffectCoverflow]}
              spaceBetween={80}
              slidesPerView={3}
              autoplay={true}
              // effect={width < 600 && "coverflow"}
              breakpoints={{
                0: {
                  slidesPerView: 1,
                  spaceBetween: -20,
                  loop: true,
                  autoplay: {
                    disableOnInteraction: false,
                    delay: 2000,
                  },
                  // centeredSlides: true,
                  // effect: "coverflow",
                  // grabCursor: true,
                  // centeredSlides: true,
                  // slidesPerView: "auto",
                  // coverflowEffect: {
                  //   rotate: 0,
                  //   stretch: 20,
                  //   depth: 50,
                  //   modifier: 2,
                  //   slideShadows: false,
                  // },
                },
                600: {
                  slidesPerView: 2,
                  spaceBetween: 40,
                  loop: false,
                },
                992: {
                  slidesPerView: 3,
                  spaceBetween: 80,
                },
              }}
            >
              {perkList}
            </Swiper>
          </div>
          <div className="cta_wrapper" data-aos="fade-up">
          {getLoggedInState(customer) === false ?  <Link className="common_cta" to={registrationFormURL} onClick={() => {window.scrollTo(0,0)}}>
              <div className="text">Get Early Access</div>
              <img
                src={rightarrowwhite}
                alt="icon"
                className="icon"
                loading="lazy"
              />
            </Link>
            :
             <Link className="common_cta" to={consoleUrl} onClick={() => {window.scrollTo(0,0)}}>
             <div className="text">Get Early Access</div>
             <img
               src={rightarrowwhite}
               alt="icon"
               className="icon"
               loading="lazy"
             />
           </Link>
          }
          </div>
        </div>
      </section>

      <section className="home_sec3">
        <div className="my_container">
          <div className="row question_mbrow">
            <div className="col-lg-8 question_col">
              <div className={width < 992 && "row question_mbrow"}>
                <div className={width < 992 && "col-7 p-0"}>
                  <div className="text_container" data-aos="fade-up">
                    <h2 className="title">Built for all stakeholders</h2>
                    <p className="desc">
                      {/* <span className="orange_text">
                        A bridge for all stakeholders
                      </span>{" "}
                      in the built environment. */}
                      <span className="orange_text">No commissions |</span>{" "}
                      Equal Access <span className="orange_text"> |</span>{" "}
                      Reliable Reach
                    </p>
                  </div>
                </div>
                <div className={width < 992 && "col-5 p-0 question_col2"}>
                  {width < 992 && (
                    <img
                      width={553}
                      height={654}
                      src={images.mannequeenImg.image}
                      alt={images.mannequeenImg.alt}
                      className="mannequeen_model"
                      loading="lazy"
                      data-aos="fade-up"
                    />
                  )}
                </div>
              </div>
              <div className="question_container">
                <div data-aos="fade-up">
                  <CustomAccordion
                    firstActive={false}
                    borderLeft={true}
                    items={builtData}
                  />
                </div>
                <div className="cta_wrapper" data-aos="fade-up">
                {getLoggedInState(customer) === false ?   <Link className="common_cta" to={registrationFormURL} onClick={() => {window.scrollTo(0,0)}}>
                    <div className="text">Get Early Access</div>
                    <img
                      src={rightarrowwhite}
                      alt="icon"
                      className="icon"
                      loading="lazy"
                    />
                  </Link>
                  :
                  <Link className="common_cta" to={consoleUrl} onClick={() => {window.scrollTo(0,0)}}>
                  <div className="text">Get Early Access</div>
                  <img
                    src={rightarrowwhite}
                    alt="icon"
                    className="icon"
                    loading="lazy"
                  />
                </Link>
              }
                </div>
              </div>
            </div>
            {width > 992 && (
              <div className="col-lg-4 question_col question_col2">
                <img
                  width={553}
                  height={654}
                  src={images.mannequeenImg.image}
                  alt={images.mannequeenImg.alt}
                  className="mannequeen_model"
                  loading="lazy"
                  data-aos="fade-up"
                />
              </div>
            )}
          </div>
        </div>
      </section>

      <section className="home_sec4">
        <div className="my_container">
          <div className="row">
            <div className="col-lg-6">
              <div className="text_container" data-aos="fade-up">
                <h2 className="title">
                  Frequently <br />
                  asked
                </h2>
                <p className="desc">Answers to most common Questions</p>
              </div>
            </div>
            <div className="col-lg-6">
              <div data-aos="fade-up" data-aos-delay="100">
                <CustomAccordion items={homeFaqData} />
              </div>
            </div>
          </div>
          <div className="cta_wrapper" data-aos="fade-up">
          {getLoggedInState(customer) === false ?  <Link className="common_cta" to={registrationFormURL} onClick={() => {window.scrollTo(0,0)}}>
              <div className="text">Get Early Access</div>
              <img
                src={rightarrowwhite}
                alt="icon"
                className="icon"
                loading="lazy"
              />
            </Link> 
            :
            <Link className="common_cta" to={consoleUrl} onClick={() => {window.scrollTo(0,0)}}>
            <div className="text">Get Early Access</div>
            <img
              src={rightarrowwhite}
              alt="icon"
              className="icon"
              loading="lazy"
            />
          </Link> 
          }
          </div>
        </div>
      </section>

      <section className="home_sec5">
        <div className="my_container">
          <div className="row video_row">
            <div className="col-md-6 order-md-2">
              <div className="text_container" data-aos="fade-up">
                <h2 className="title">It starts here!</h2>
                <p className="desc">
                  Redesigning <br />
                  the business of design
                </p>
                <div className="cta_wrapper" data-aos="fade-up">
                {getLoggedInState(customer) === false ?   <Link className="common_cta" to={registrationFormURL}> 
                    <div className="text">Get Early Access</div>
                    <img
                      src={rightArrowBlue}
                      alt="icon"
                      className="icon"
                      loading="lazy"
                    />
                  </Link>
                  :
                  <Link className="common_cta" to={consoleUrl} onClick={() => {window.scrollTo(0,0)}}> 
                    <div className="text">Get Early Access</div>
                    <img
                      src={rightArrowBlue}
                      alt="icon"
                      className="icon"
                      loading="lazy"
                    />
                  </Link>
                }
                </div>
              </div>
            </div>
            <div className="col-md-6 order-md-1">
              <div className="archinza_chat_video" data-aos="fade-up">
                <AutoplayVideo
                  className="video_box"
                  videoSource={videoSource}
                  fallbackImg={posterImage}
                  width="100%"
                  height="50.188em"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="home_sec6">
        <div className="my_container">
          <div className="notice_container" data-aos="fade-up">
            <h2 className="heading">Did you know?</h2>
            <Swiper
              modules={[Autoplay, Pagination]}
              spaceBetween={50}
              loop={true}
              speed={1000}
              slidesPerView={1}
              pagination={width < 768 ? true : false}
              autoplay={{
                delay: 3000,
                disableOnInteraction: false,
              }}
            >
              {noticeList}
            </Swiper>
          </div>
        </div>
      </section>

      <FooterV2 bgColor="#000000" />
    </>
  );
};

export default HomepageV2;
