// routes name
export const homepageURL = "/";
export const privacypolicyURL = "/privacy-policy";
export const termsandconditionURL = "/terms-and-conditions";
export const contactUsURL = "/contact-us";
export const datatypesURL = "/data-types";
export const comingsoonURL = "/coming-soon";
export const faqsURL = "/faqs";
export const loginURL = "/login";
export const resetPassURL = "/reset-password";

// Early access form path
export const registrationFormURL = "/apply";

export const personalAccountRegistrationUrl = "/user-registration";
export const regiserOTPURL = "/apply/otp";

// Console paths
export const DRconsoleURL = "/design-enthusiast";
export const consoleUrl = "/console";
export const congratulationsPageUrl = "/congratulations";
 export const studentsConsoleURL = "/students-console";
export const teamMemberConsoleURL = "/team-member-console";
export const businessConsoleURL = "/business-console";
export const changeRoleURL = "/change-role";
export const teamMemberChangeRoleURL = "/team-member-change-role";

// Profile paths
export const editProfile = "/edit-profile";

// Early access forms
export const teamAccesssURL = "/team-member-access";
export const studentAccesssURL = "/student-access";
export const businessAccessURL = "/business-access";
export const businessFormFiveURL = "/business-form";
export const formFiveRes2Url = "/business-res";
export const enthusiastAccesssURL = "/enthusiast-access";

// Social links
export const facebookURL =
  "https://www.facebook.com/profile.php?id=100091559990889";
export const instagramURL = "https://www.instagram.com/archin.za/";
export const linkedinURL = "https://www.linkedin.com/company/archinza/";

export const BlogsListingURL = "/home";
export const BlogsInnerURL = "/blogs-inner/:blogId";

