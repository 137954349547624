import React, { useEffect, useState } from "react";
import "./blogsListing.scss";
import BreadCrumb from "../../components/Breadcrumb/Breadcrumb";
import { 
    blogsListing, 
   rightArrowBlue ,
   lanceanderson
} from "../../images";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import {toast} from "react-toastify";
import {SuccessMsg, ErrorsMsg } from "../../util/Message";
import FooterV2 from "../../components/FooterV2/FooterV2";

//import { BlogsListingData } from "../../components/Data/blogsData";
//import {}

const BlogsListing = () => {
    const [blogs, setBlogs] = useState([])

    useEffect(() => {
      window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        async function fetchBlogContent(code) {
                try {
                    let config = {
                        method: 'get',
                        url: `https://blog-api.archinza.com/blog/home`,
                        data : 'stream'
                    };
                
                    //let result = await axios.request(config);
                    const res = await axios.request(config);
                    console.log(res);
                    if (res && res?.data.status === 'Success') {
                        const {all_posts} = res?.data.data;
                            setBlogs(all_posts)
                    } else {
                      toast(ErrorsMsg("something went wrong"),{
                        className: "otp_toast",
                        position: toast.POSITION.TOP_CENTER,
                        hideProgressBar: true,
                        closeButton: false,
                      });
                      
                    }
                } catch (err) {
                
                }
            }
        fetchBlogContent()
    },[])

  const blogsListingArr = blogs?.map((item, i) => (
    <div className="col-md-6 col-lg-4 blogslisting_col" key={item.ID}>
      <div className="blogslisting_content_wrapper">
        <img src={lanceanderson} alt="img" className="blogslisting_img" /> 
        <div className="details_wrapper">
          <p className="date">{new Date(item.post_date).toLocaleDateString()}</p>
          {/* <p className="date" dangerouslySetInnerHTML={{ __html: item.post_content }}></p> */}
          <h2 className="title">{item.post_title}</h2>
          <Link
            
            to={`/blogs-inner/${item.post_name}`}
            className="link"
          >
            <span className="link_text">Read More</span>
            <img
              src={rightArrowBlue}
              alt="right arrow"
              className="right_arrow"
            />
          </Link>
        </div>
      </div>
    </div>
  ));
  return (
    <>
      <main className="blogslisting_main">
        <img
          src={blogsListing}
          alt="background"
          className="blogslisting_background"
        />

        <section className="blogslisting_sec1">
          <div className="my_container">
            <div className="Breadcrumb_container">
              <BreadCrumb
                link="/blogs"
                linkDisabled
                text="Blogs"
                title="Our Blogs"
              />
            </div>
          </div>
        </section>

        <section className="blogslisting_sec2">
          <div className="my_container">
            <div className="row blogslisting_row">
              {/* <div className="col-md-4 blogslisting_col">
                <div className="blogslisting_content_wrapper">
                  <img src={blog1} alt="" className="blogslisting_img"/>
                  <div className="details_wrapper">
                    <p className="date">01.05.2023</p>
                    <h2 className="title">
                      Disconnections in the Design Industry: Concerns &
                      Solutions
                    </h2>
                    <Link to={() => false} className="link">
                      <span className="link_text">Read More</span>
                      <img
                        src={rightArrowBlue}
                        alt="right arrow"
                        className="right_arrow"
                      />
                    </Link>
                  </div>
                </div>
              </div> */}
              {blogsListingArr}
            </div>
          </div>
        </section>
        <FooterV2 bgColor="#000000"/>  
      </main>
    </>
  );
};

export default BlogsListing;