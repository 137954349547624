import React, { useEffect, useState } from "react";
import style from "./teammember.module.scss";
import BorderLinearProgress from "../../../components/ProgressBar/ProgressBar";
import StepWizard from "react-step-wizard";
import TAStep01 from "../FormSteps/TAStep01";
//import TAStep02 from "../FormSteps/TAStep02";
import TAStep03 from "../FormSteps/TAStep03";
import TAStep04 from "../FormSteps/TAStep04";
import TAStep05 from "../FormSteps/TAStep05";
import { DIFFERENT_USER_TYPES,TEAM_STEP_FLOW } from "../../../constants/appEnums";
import { useSearchParams } from "react-router-dom";
import {useSelector} from "react-redux";
import {getCustomer} from "../../../redux/app/appSelectors";
import callApi from "../../../util/apiCaller";
import { FORM_FIVE_FLOW, CUSTOMER_ID } from "../../../constants/enums";
import BlinkingDots from "../../../Animations/BlinkingDots/BlinkingDots";
import FooterV2 from "../../../components/FooterV2/FooterV2";

const TeamAccessForm = () => {
  let brandId = window.localStorage.getItem(CUSTOMER_ID);
  const [progress, setProgress] = useState(1/5*100);
  //const [formStep, setformStep] = useState(1);
  const [formStep, setFormStep] = useState(1);
  const [userExtraData, setUserExtraData ] = useState({});
  
  const team_step_flow = window.localStorage.getItem(TEAM_STEP_FLOW) 
  const customer = useSelector(state => getCustomer(state));
 // window.localStorage.setItem(TEAM_STEP_FLOW,customer?.current_flow) 

  const handleCalculateProgress = (step) => {
    let progress = 0;

      progress = step/5*100

    // setParams(prev => ({...prev, stepNum: step.toString()}))
    setProgress(progress);
  }
  // useEffect(() => {
  //   if(brandId){
  //   fetchContent(brandId)   
  //   }  
  // },[])
  // async function fetchContent(brandId) {

  //   try {
  //     const res = await callApi(`customer/${brandId}/v2`, "get");

  //     if (res && res?.status === 'Success') {
  //       const { customer } = res?.data;
  //       window.localStorage.setItem(TEAM_STEP_FLOW ,customer?.current_flow)
       
  //     } else {
  //     }
  //   } catch (err) {

  //   }
  // }

  useEffect(() => {
    let stepNum = customer.current_flow ? customer.current_flow : 1;
    handleCalculateProgress(stepNum);
    // let stepNum = params.get('stepNum');
    // if(stepNum && stepNum !== '0') {
    //   handleCalculateProgress(stepNum);
    //   setFormStep(parseInt(stepNum));
    // }else {
    //   setParams(prev => ({...prev, stepNum: '1'}))
    // }

  }, []);

  useEffect(() => {
    setUserExtraData({
      user_type: DIFFERENT_USER_TYPES[2].label
    })
  },[])

  const handleUserExtraData = (val) => {
      setUserExtraData((userExtraData) => ({
        ...userExtraData,
        ...val
      }));

  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
       <BlinkingDots />
      <section className={style.team_sec1}>
        {/* <img
          width={1920}
          height={1080}
          src={images.teammemberBg.image}
          alt={images.teammemberBg.alt}
          className={style.team_bg_img}
        /> */}
        <div className={style.team_form_wrap}>
          <div className="my_container">
            <div className={style.progress_wrapper}>
              <BorderLinearProgress variant="determinate" value={progress} />
            </div>
            <StepWizard
               onStepChange={e => handleCalculateProgress(e.activeStep)}
              initialStep={team_step_flow}
              transitions={{
                enterRight: "formChangeAnimation",
                enterLeft: "formChangeAnimation",
                intro: "formChangeAnimation",
              }}
            >
              <TAStep01 progressStatus={setProgress} userExtraData={userExtraData} handleUserExtraData={handleUserExtraData}/>
              {/* <TAStep02 progressStatus={setProgress} userExtraData={userExtraData} handleUserExtraData={handleUserExtraData}/> */}
              {/* <TAStep03 progressStatus={setProgress} userExtraData={userExtraData} handleUserExtraData={handleUserExtraData}/> */}
              <TAStep04 progressStatus={setProgress} userExtraData={userExtraData} handleUserExtraData={handleUserExtraData}/>
              <TAStep05 progressStatus={setProgress} userExtraData={userExtraData} handleUserExtraData={handleUserExtraData}/>
            </StepWizard>
          </div>
        </div>
        
      </section>
      <FooterV2 />
    </>
  );
};

export default TeamAccessForm;
