import React, { useEffect, useState } from "react";
import "./blogsInner.scss";
import BreadCrumb from "../../components/Breadcrumb/Breadcrumb";
import {
  blogsInner,
  blogsShareIcon,
  facebook,
  heroBlogsInnerImg,
  insta,
  linkedIn,
  shareIcon,
} from "../../images";
import {
  facebookURL,
  instagramURL,
  linkedinURL,
} from "../../components/helpers/constant-words";
import axios from "axios";
//import { BlogsInnerData } from "../../components/Data/blogsData";
import { useParams } from "react-router-dom";
import {toast} from "react-toastify";
import _ from "lodash";
import {SuccessMsg, ErrorsMsg } from "../../util/Message";
import FooterV2 from "../../components/FooterV2/FooterV2";

const BlogsInner = () => {
    const [blogsDetail, setBlogsDetail] = useState({})

    const {blogId} = useParams()

    useEffect(() => {
      window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        async function fetchInnerBlogContent(code) {

            if(blogId){
            try {
               let config = {
                    method: 'get',
                    url: `https://blog-api.archinza.com/blog/${blogId}/post`,
                    data : 'stream'
                };
            
                const res = await axios.request(config);;
                console.log(res);
                if (res && res?.data?.status === 'Success' && blogId) {
                    const {data} = res?.data;
                        setBlogsDetail(data)
                       
                } else {
                  toast(ErrorsMsg("something went wrong"),{
                    className: "otp_toast",
                    position: toast.POSITION.TOP_CENTER,
                    hideProgressBar: true,
                    closeButton: false,
                  });
                }
            } catch (err) {
          
            }
        }
        }
    fetchInnerBlogContent()
    },[blogId])

  return (
    <>
      <main className="blogsinner_main">
        <img
          src={blogsInner}
          alt="background"
          className="blogsinner_background"
        />
        <section className="blogsinner_sec1">
          <div className="my_container">
            <div className="my_container">
              <div className="Breadcrumb_container">
                <BreadCrumb
                  link="/blogs"
                  linkDisabled1
                  text="Blogs"
                  // text1="Disconnections in the Design Industry: Concerns & Solutions"
                  text1={blogsDetail?.post?.post_title}
                  // title="Disconnections in the Design Industry: <span class='bread_block'>Concerns & Solutions</span>"
                  title={blogsDetail?.post?.post_title}
                />
              </div>
            </div>
          </div>
        </section>

        {/* <section className="blogsinner_sec2">
          <div className="my_container">
            <div className="blogsinner_content_wrapper">
              <img src={heroBlogsInnerImg} alt="" className="hero_img" />
              <ul className="desc_wrapper">
                <li className="desc">
                  In today’s time, the architecture and design industry does not
                  rely heavily on effective networking and marketing strategies
                  for success. However, these strategies are crucial for
                  attracting clients, building professional relationships, and
                  staying ahead of the competition. Here are some common
                  concerns professionals face in networking and marketing and
                  tips on how to fix them:
                  <br /> Inadequate Communication Channels Networking and
                  marketing efforts rely on effective communication channels to
                  reach the target audience. Unfortunately, the architecture and
                  design industry often faces challenges finding the right
                  platforms and mediums to communicate their work and expertise.
                  The digital landscape continuously evolves, making it
                  difficult for professionals to keep up with the latest trends
                  and choose the most effective online platforms for promotion.
                  Professionals must adapt to changing communication trends and
                  leverage a singular platform to connect with their target
                  audience. This includes having a solid online presence, active
                  social media profiles, and engaging content showcasing their
                  expertise and projects.
                  <br /> Lack of Personal Branding Personal branding is crucial
                  in establishing a professional identity and standing out in a
                  highly competitive industry. Many architects and designers
                  must be convinced that the work alone is not enough to create
                  more buzz for the business. Instead, the design ecosystem
                  should define its strengths, values, and philosophy and engage
                  with the community. Professionals can build a recognisable and
                  memorable reach by consistently showcasing the brand through
                  its portfolio, online presence, and communication style.
                  <br />
                  Insufficient Digital Networking Networking is vital for
                  succeeding in the architecture and design industry. Failing to
                  build and nurture a solid digital network can hinder
                  professional growth and limit access to hyperlocal clients and
                  collaborators in a field that relies heavily on referrals and
                  recommendations. Attending industry events, joining
                  professional organisations, participating in conferences and
                  workshops, and engaging with online communities are effective
                  ways to expand one's network. Architects and designers can tap
                  into vast resources to help build and maintain digital
                  relationships with other professionals, clients, suppliers,
                  and influencers.
                  <br />
                  Lack of Targeted Marketing Strategies A common mistake in the
                  design industry is adopting a generalised approach to
                  marketing. Professionals can effectively reach their ideal
                  clients online with a targeted marketing strategy. Architects
                  and designers should invest time understanding their target
                  audience, needs, preferences, and pain points. Professionals
                  can create custom profiles and campaigns that resonate with
                  their intended audience and address specific client segments.
                  <br />
                  Networking and marketing are critical for success in the
                  architecture and design industry. By acknowledging and
                  addressing the loopholes and disorientations that
                  professionals often encounter, one can develop effective
                  strategies for growth.
                </li>
              </ul>
              <div className="social_media_wrapper">
              <img src={blogsShareIcon} alt="share icon" className="social_icon"/>
              <a
                href={facebookURL}
                target="_blank"
                rel="noreferrer"
                className="social_link"
              >
                <img src={facebook} alt="facebook" className="social_icon" />
              </a>
              <a
                href={instagramURL}
                target="_blank"
                rel="noreferrer"
                className="social_link"
              >
                <img src={insta} alt="instagram" className="social_icon" />
              </a>
              <a
                href={linkedinURL}
                target="_blank"
                rel="noreferrer"
                className="social_link"
              >
                <img src={linkedIn} alt="linkedIn" className="social_icon" />
              </a>
            </div>
            </div>
          </div>
        </section> */}

        <section className="blogsinner_sec2">
          <div className="my_container">
            
    <div className="blogsinner_content_wrapper" >
      <img src={heroBlogsInnerImg} alt={"PlaceHolder for Images"} className="hero_img" />
      <ul className="desc_wrapper">
        {/* {item.desc.map((list) => ( */}
        {/* { !_.isEmpty(postContent) && postContent?.map((item, i) => ( */}
          <li className="desc" dangerouslySetInnerHTML={{ __html: blogsDetail?.post?.post_content }}></li>
        {/* ))}  */}
      </ul>

      <div className="social_media_wrapper">
        <img src={blogsShareIcon} alt="share icon" className="social_icon" />
        <a
          href={""}
          target="_blank"
          rel="noreferrer"
          className="social_link"
        >
          <img src={facebook} alt="facebook" className="social_icon" />
        </a>
        <a
          href={""}
          target="_blank"
          rel="noreferrer"
          className="social_link"
        >
          <img src={insta} alt="instagram" className="social_icon" />
        </a>
        <a
          href={""}
          target="_blank"
          rel="noreferrer"
          className="social_link"
        >
          <img src={linkedIn} alt="linkedIn" className="social_icon" />
        </a>
      </div>
    </div>
          </div>
        </section>
        <FooterV2 bgColor="#000000"/>  
      </main>
    </>
  );
};

export default BlogsInner;