import { protag } from "../../images";
import style from "./radiobuttonlighttheme.module.scss";

const RadioButtonLightTheme = ({ label, labelId,value, handleChangeUser, isPro, extraSpace = false,checked }) => {
  return (
    <>
      <li className={style.radio_wrapper}>
        {isPro === true && (
          <img
            width={41}
            height={21}
            src={protag}
            alt="pro"
            className={style.protag_img}
            loading="lazy"
          />
        )}
        <input
          className={style.radio_input}
          type="radio"
          checked={checked}
          value={value}
          id={labelId}
          onChange={(e)=> handleChangeUser("user_type", e.target.value)}
        />
        <label
          className={`${style.radio_label} ${
            extraSpace === true && style.extraSpace
          }`}
          htmlFor={labelId}
        >
          {label}
        </label>
      </li>
    </>
  );
};

export default RadioButtonLightTheme;
