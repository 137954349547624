import React, { useEffect, useState } from "react";
import style from "../BusinessAccess/business.module.scss";
import CheckboxButton from "../../../components/CheckboxButton/CheckboxButton";
import LogoutText from "../../../components/LogoutText/LogoutText";
import { rightarrowwhite, plusicon } from "../../../images";
import FullWidthTextField from "../../../components/TextField/FullWidthTextField";
import { useDispatch, useSelector } from "react-redux";
import { updateCustomerProfile } from "../../../redux/app/appAction";
import { getUserId, getCustomer } from "../../../redux/app/appSelectors";
import callApi from "../../../util/apiCaller";
import { useNavigate } from "react-router-dom";
import { consoleUrl } from "../../../components/helpers/constant-words";
import _ from "lodash";
import {toast} from "react-toastify";
import {MsgWithVarText} from "../../EditProfile/EditProfile";
import AutoCompleteField from "../../../components/AutoCompleteField/AutoCompleteField";

// const concernsArr = [
//   "Sampling",
//   "Knowledge on trends | materials",
//   "Add new skill sets to the business",
//   "Hiring",
//   "Marketing & Reach to get new clients",
//   "Finding products materials for live projects",
//   "Maintaining product | material library",
// ];

const BAStep03 = ({
  currentStep,
  totalSteps,
  previousStep,
  nextStep,
  progressStatus,
  handleUserExtraData,
  userExtraData
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  //const [code ,setCode] = useState("")
  const userId = useSelector((state) => getUserId(state)) ;
  const customer = useSelector((state) => getCustomer(state));
  const [unmetNeeds, setUnmetNeeds] = useState({
    requirements: []
  })
  const [code, setCode] = useState([])
  const [addOthers, setAddOthers] = useState("")
  const [firstName, setFirstName] = useState("")
  const [updatedRequirement, setUpdatedRequirement] = useState([])
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if(customer?.contact_name){
      if(customer?.requirements){
        setUnmetNeeds({
          requirements: customer?.requirements
        })
      }
    let str = customer?.contact_name
    let str1 = ""
    for(let i=0; i<str.length; i++){
     
      if(str[i] === " "){
        break;
      }else{
        if(i === 0){
          let s =str[0].toUpperCase()
          str1 +=s
        }else{
        str1 += str[i];
        
        }
      }
    }
setFirstName(str1)
  }
  },[customer])

  useEffect(() => {

    fetchContentCode("business-firm-unmet-needs")
   },[])
   async function fetchContentCode(code) {

    try {
      const res = await callApi(`content/code/${code}`, "get");
      console.log(res);
      if (res && res?.status === 'Success') {
        const { params, code } = res?.data.content;

        setUpdatedRequirement([...params]);
        setCode(params.map(item => item.label))

      } else {
      }
    } catch (err) {

    }

  }
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    const handleKeyPress = (e) => {
      if (e.key === 'Enter' && addOthers !== "") {
        //handleUserDetail("requirements", addOthers);
        if(unmetNeeds?.requirements?.indexOf(addOthers) === -1) {
          handleUserDetail("requirements", addOthers);
          // let arr = [...updatedRequirement]
          // arr?.push({label:addOthers,value:addOthers})
          // setUpdatedRequirement([...arr])
        } else {
          toast(<MsgWithVarText text={'Tag already present'} />, {
            className: "mail_toast",
            position: toast.POSITION.TOP_CENTER,
            hideProgressBar: true,
            closeButton: false,
          })
        }
        setAddOthers("");
      }
    };

    window.addEventListener('keypress', handleKeyPress);

    return () => {
      window.removeEventListener('keypress', handleKeyPress);
    };
  }, [addOthers]);

  const handleUserDetail = ( keyName,keyValue) => {
    let prevIndex = unmetNeeds?.requirements?.indexOf(keyValue);

    if(prevIndex !== -1) {
      let update = {
        ...unmetNeeds,
        [keyName]: unmetNeeds?.requirements?.filter(
            (_, index) => index !== prevIndex
        ),
      };
      setUnmetNeeds(update);
    } else {
      let update ={...unmetNeeds};
      update[keyName] = [...unmetNeeds?.requirements];
      if(keyValue === "All of the Above"){
        let arr = [...unmetNeeds?.requirements,...code.slice(0,code.length-1)]
        let set = new Set(arr)
        let arr1 = Array.from(set)
        update['requirements'] = [...arr1]
      }else{
        update['requirements']?.push(keyValue);
      }
      update.current_flow = currentStep;
      setUnmetNeeds(update);
      setErrors({});
    }

  }

   const handleValidation = (user) => {
    let isValid = true;
    let errors = {};
    if (user?.requirements.length === 0) {
        isValid = false;
        errors["requirements"] = "Please select atleast one unmet needs";
    }

      return {
        isValid,
        errors
    }
  }

   const handleUpdateApi = () => {
    let check = handleValidation(unmetNeeds);
    let update= {...unmetNeeds}
    update.current_flow = currentStep;
    if(check.isValid && userId && unmetNeeds){
      dispatch(updateCustomerProfile(userId, update))
     // handleUserExtraData(unmetNeeds);
      nextStep(4);
      window.scrollTo(0, 0);
      setErrors({})
      navigate(consoleUrl);
    }else {
      setErrors(check.errors);
    }

   }
   const renderError = (keyName) => {
    return errors && errors[keyName] ? <p className="error" style={{marginBottom:"20px"}}>{errors[keyName]}</p> : null;
  };

  const concernList = updatedRequirement?.map((option) => (
    unmetNeeds?.requirements?.indexOf(option.value) === -1 ?
    <React.Fragment key={option.value}>
      <CheckboxButton isChecked={false} label={option.label} labelId={`bus${option.value}`} handleUserDetail={handleUserDetail} keyName={"requirements"}/>
    </React.Fragment>: null
  ));
  const addedList = !_.isEmpty(unmetNeeds?.requirements) ? unmetNeeds?.requirements?.map((option,i) => (
    <React.Fragment key={option}>
      <CheckboxButton isChecked={true} label={option} labelId={`newbus${option+i}`} handleUserDetail={handleUserDetail} keyName={"requirements"} />
    </React.Fragment>
  )): null

  useEffect(() => {
    progressStatus((currentStep / totalSteps) * 100);
  }, [currentStep, progressStatus, totalSteps]);

    const handleAddMore = () => {
        if(addOthers) {
            if(unmetNeeds?.requirements?.indexOf(addOthers) === -1){
                handleUserDetail("requirements",addOthers)

            }else{
                toast(<MsgWithVarText text={'Tag already present'} />, {
                    className: "mail_toast",
                    position: toast.POSITION.TOP_CENTER,
                    hideProgressBar: true,
                    closeButton: false,
                })
            }
            setAddOthers("");
        }
    }

    const handleUser = (keyName,keyValue) => {
        if(keyValue && keyValue !== "") {
            if(unmetNeeds?.requirements?.indexOf(keyValue) === -1){
                if(keyValue !== "") {
                    handleUserDetail("requirements", keyValue)
                }
            } else{
                toast(<MsgWithVarText text={'Tag already present'} />, {
                    className: "mail_toast",
                    position: toast.POSITION.TOP_CENTER,
                    hideProgressBar: true,
                    closeButton: false,
                })
            }
        }
    }

    return (
        <>
            <div className={style.text_container}>
                <h1 className={style.title}>
                  {firstName}, as a business | firm owner. What Are Your Unmet needs | concerns ?
                </h1>
                <p className={style.description}>Choose as many</p>
            </div>
            <div className={`${style.steps} ${style.step01}`}>
                <div className={`${style.steps} ${style.step01}`}>
                    <div className={style.field_wrapper}>
                        {code && <AutoCompleteField
                            freeSolo={false}
                            popupIcon={""}
                            textLabel="Unmet needs/Concerns"
                            data={code}
                            //course={user?.student_course}
                            handleUserDetail={handleUser}

                        />}
                          <div className={'my-4'}>
                    {renderError("requirements")}
                </div>
                    </div>
                </div>
                {/*<ul className={style.step02_ul}>{addedList}{concernList}</ul>*/}
                <div className={`${style.add_more_wrap} ${style.add_more_v2}`}>
                    <div className={style.steps_ul_new}>
                        <FullWidthTextField label="Add Others" key={"Student Add Others"} onChange={(e) => setAddOthers(e.target.value)} value={addOthers} />
                        <img
                            src={plusicon}
                            alt="icon"
                            className={style.icon}
                            loading="lazy"
                            onClick={handleAddMore}
                        />
                        {/*<FullWidthTextField label="Add Others" value={addOthers} onChange={} />*/}
                        {/* <img
              src={plusicon}
              alt="icon"
              className={style.icon}
              loading="lazy"
              onClick={handleAddOthers}
            /> */}

                    </div>
                </div>
                <div className={'my-4'}>
                {/* <div className={`${style.reduceSpace }`}> */}
                    {

                        !_.isEmpty(unmetNeeds?.requirements) ?
                            unmetNeeds?.requirements?.map((key, i) => (
                                <React.Fragment key={`{key}+${i}io`}>
                                    <CheckboxButton isChecked={true} label={key} labelId={`newstud${key+i}`} handleUserDetail={handleUserDetail} keyName={"requirements"} />
                                </React.Fragment>
                            )) :
                            null

                    }
                </div>
              
            </div>

            <div className={style.next_logout}>
                <div className={style.cta_wrapper}>
                    <div
                        className={style.next_button}
                        onClick={handleUpdateApi}
                    >
                        <div className={style.text}>Submit</div>
                        {/*<img*/}
                        {/*  src={rightarrowwhite}*/}
                        {/*  alt="icon"*/}
                        {/*  className={style.icon}*/}
                        {/*  loading="lazy"*/}
                        {/*/>*/}
                    </div>
                    <div
                        className={style.back_button}
                        onClick={() => {
                            previousStep(2);
                            setErrors({})
                            window.scrollTo(0, 0);
                        }}
                    >
                        Back
                    </div>
                </div>
                {/* <LogoutText userExtraData={unmetNeeds}/> */}
            </div>
        </>
    );
};

export default BAStep03;
