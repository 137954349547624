import React, { useEffect, useState } from "react";
import style from "../Form/formfive.module.scss";
import styles from "./formfiveotp.module.scss";
import LogoutText from "../../../components/LogoutText/LogoutText";
import FullWidthTextField from "../../../components/TextField/FullWidthTextField";
import {checkiconWhite, otpicon, rightarrowblack, rightarrowwhite} from "../../../images";
import CountryCodeDropdown from "../../../components/CountryCodeDropdown/CountryCodeDropdown";
import {
  addBrand, addBrandV2, addNewBrandAccountAfterVerification,
  loginCustomerViaOTP, sendDataForBrandValidation, setCustomerData,
  updateCustomerArchPro,
  updateCustomerProfile,
  verifyCustomerOTP
} from "../../../redux/app/appAction";
import { useDispatch, useSelector } from "react-redux";
import { getUserId, getCustomer, getBrandId, getBrand } from "../../../redux/app/appSelectors";
import { useNavigate } from "react-router-dom";
import { formFiveRes2Url } from "../../../components/helpers/constant-words";
import _ from "lodash";
import { Country, State, City } from "country-state-city";
import AutoCompleteField from "../../../components/AutoCompleteField/AutoCompleteField";
import { validatePhoneNumber } from "../../../util/phoneValidator";
import callApi from "../../../util/apiCaller";
import {APP_TOKEN} from "../../../constants/appEnums";
import {toast} from "react-toastify";
import {ErrorsMsg, SuccessMsg} from "../../../util/Message";
import OtpInput from "react-otp-input";
import {BRAND_ID, CUSTOMER_ID} from "../../../constants/enums";
import PasswordInput from "../../../components/PasswordInput/PasswordInput";
import PasswordInputLightTheme from "../../../components/PasswordInputLightTheme/PasswordInputLightTheme"
import { setIsPro, setCreateAccount } from "../../../redux/app/appAction";
import { useCallback } from "react";

const RStep01 = ({ nextStep, currentStep, totalSteps, progressStatus, userExtraData, handleUserExtraData }) => {
  const dispatch = useDispatch();
  const userId = useSelector((state) => getUserId(state));
  // const customer = useSelector((state) => getCustomer(state));
  const brand = useSelector((state) => getBrand(state));

  const [isWhatsappSame, setIsWhatsappSame] = useState(false);
  const [isWhatsappBusiness, setIsWhatsappBusiness] = useState(false)
  //const navigate = useNavigate();

  const [user, setUser] = useState();
  const [time, setTime] = useState(0);
  //console.log(user.video_link);
  const [errors, setErrors] = useState({});
  const [code, setCode] = useState("")
  const [ownerPhone, setOwnerPhone] = useState("")
  const brandId = useSelector((state) => getBrandId(state));
  const [country, setCountry] = useState([])
  const [city, setCity] = useState([])
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [otpVal, setOtpVal] = useState({
    "local_otp": "",
  });
  const [phonee, setPhonee] = useState("")
  const [resend, setResend] = useState(false);
  const [sameUserId, setSameUserId] = useState("")
  const [samePincode, setSamePincode] = useState("")

  useEffect(() => {
    if(isOtpSent){
      { window.scrollTo({
        top: 0,
        behavior: 'smooth'
    })}
    }else{
      { window.scrollTo({
        top: 0,
        behavior: 'smooth'
    })}
    }
  }, [isOtpSent])

  useEffect(() => {
    let country = Country.getAllCountries().map((option) => {
      return option.name
    })

    setCountry(country)
  }, [])
  useEffect(() => {
    // if(customer && customer._id) {
    //     navigate(consoleUrl);
    // }
    let cityList = City.getCitiesOfCountry(code).map((option) => option.name)
    let city = _.uniq(cityList);
    setCity(city)
  }, [code])
  useEffect(() => {
    dispatch(setCreateAccount(false))
  },[])

  useEffect(() => {
    if(brand){
    setUser({
      country_code: "91",
      business_owner_country_code: "91",
      business_owner_contact_email: brand?.business_owner_contact_email
    })
  }
    setIsWhatsappSame(brand?.contact_phone === brand?.whatsapp_no);
  }, [brand])

  const debouncedHandleSearch = useCallback(
    _.debounce((qtext) => {
        fetchByName(qtext);
    }, 700),
    []
);

const handleSearch = (qtext) => {
  //setUser(qtext);
  debouncedHandleSearch(qtext); // This will be called after 700ms of user inactivity
}
const debouncedHandlePincode = useCallback(
  _.debounce((city,pincode) => {
      fetchByPincode(city,pincode);
  }, 700),
  []
);
const handlePincode = (city,pincode) => {
  debouncedHandlePincode(city,pincode)
}
const fetchByPincode = (city,pincode) => {
  
  if ( pincode) {
      
  
  callApi(`city/validate-pincode`,'post', {
    "city" : city,
    "pincode": pincode
     
  }).then(res => {
      if (res?.status === "Success" && res?.data?.isValid === 'true') {
          

      } else {
        setSamePincode(res?.data?.errors[0])
      }
  });
}
};

const fetchByName = (user) => {
  
  if ( user) {
  callApi(`customer/validate-username`,'post', {
    "username" : user,
  }).then(res => {
      if (res?.status === "Success" && res?.data?.isValid === 'true') {
      } else {
        setSameUserId(res?.data?.errors[0])
      }
    });
  }
};

  const handleChangeUser = (keyName, keyValue) => {
    let update = { ...user };
    if(keyName === "pincode"){
      handlePincode(user?.city,keyValue)
    }
    if(keyName === "brand_username"){
      handleSearch(keyValue)
    }
    if(keyName === "contact_phone"){
      if( keyValue.substring(0,1) === "0"){
        setPhonee("Please remove zero from start")
      
        update.contact_phone = keyValue;
      }else{
        setPhonee("")
        // if(keyValue && keyValue.length !== 10){
        //     setPhonee("Please enter correct phone number")
        //     //update.whatsapp_no = keyValue;
        //     update.contact_phone = keyValue;
        // }else{
        //     setPhonee("")
        //     update.contact_phone = keyValue;
        // }
      }
     }
     if(keyName === "business_owner_contact_phone"){
      if( keyValue.substring(0,1) === "0"){
        setOwnerPhone("Please remove zero from start")
      
        update.business_owner_contact_phone = keyValue;
      }else{
        setOwnerPhone("")
      }
     }
    if (keyValue) {
      setErrors((prev) => ({ ...prev, [keyName]: "" }))
    }
    if(keyName === 'country') {
      let selectedCounty = Country.getAllCountries().filter((option) => option.name === keyValue)[0];
      setCode(selectedCounty.isoCode);
    }
   

    if (isWhatsappSame && (keyName === 'whatsapp_no' || keyName === 'contact_phone')) {
      update.whatsapp_no = keyValue;
      update.contact_phone = keyValue;
    } else {
      update[keyName] = keyValue;
    }
    if (isWhatsappBusiness && (keyName === 'business_owner_whatsapp_no' || keyName === 'business_owner_contact_phone')) {
      update.business_owner_whatsapp_no = keyValue;
      update.business_owner_contact_phone = keyValue;
    } else {
      update[keyName] = keyValue;
    }
    //if(brandId){
    setUser(update);
    //}
  }

  const handleUser = (keyName, keyValue) => {
    if(keyValue ){
      handleChangeUser(keyName, keyValue) 
    }
  }

  const handleotpChange = (keyName, keyValue) => {
    let update = {...otpVal};
    setErrors({})
    update[keyName] = keyValue;
    setOtpVal(update);
  };

  const handleValidation = (user) => {
    let isValid = true;
    let errors = {};
    let validation = {}
    if(user?.contact_phone && user?.country_code){
      validation = validatePhoneNumber(user?.contact_phone, user?.country_code)
    }
    if (!user?.business_name) {
      isValid = false;
      errors["business_name"] = "Business name cannot be blank";
    }
    if (!user?.contact_name) {
      isValid = false;
      errors["contact_name"] = "Contact name cannot be blank";
    }

    if (!user?.contact_phone) {
      isValid = false;
      errors["contact_phone"] = "Contact phone cannot be blank";
    } else if (!validation.isValid) {
      isValid = false;
      errors['contact_phone'] = validation.message;
    }
    if (!user?.business_personal_contact_email) {
      isValid = false;
      errors["business_personal_contact_email"] = "Email Id cannot be blank";
    }else {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(user?.business_personal_contact_email)) {
          isValid = false;
          errors["business_personal_contact_email"] = "Invalid email";
      }
    }
    if (!user?.country) {
      isValid = false;
      errors["country"] = "Country cannot be blank";
    }
    if (!user?.city) {
      isValid = false;
      errors["city"] = "City cannot be blank";
    }
    if (!user?.pincode) {
      isValid = false;
      errors["pincode"] = "Pincode cannot be blank";
    }
    if (!user?.brand_username) {
      isValid = false;
      errors["brand_username"] = "Account username cannot be blank";
    }
    if (!user?.brand_password) {
      isValid = false;
      errors["brand_password"] = "Account password cannot be blank";
    }else if(user?.brand_password && user?.brand_password?.length <8){
      isValid = false;
      errors["brand_password"] = "Account password should not be less than 8";
    }
    if (!user?.business_owner_name) {
      isValid = false;
      errors["business_owner_name"] = "Business owner name cannot be blank";
    }
    if (!user?.business_owner_contact_email) {
      isValid = false;
      errors["business_owner_contact_email"] = "Business owner email cannot be blank";
    }else {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(user?.business_owner_contact_email)) {
          isValid = false;
          errors["business_owner_contact_email"] = "Invalid email";
      }
    }
    let businessPhoneno = {}
    if(user?.business_owner_contact_phone && user?.business_owner_country_code){
      businessPhoneno = validatePhoneNumber(user?.business_owner_contact_phone, user?.business_owner_country_code)
    }

    if (!user?.business_owner_contact_phone) {
      isValid = false;
      errors["business_owner_contact_phone"] = "Business owner contact phone cannot be blank";
    } else if (!businessPhoneno.isValid) {
      isValid = false;
      errors['business_owner_contact_phone'] = validation.message;
    }

    if (!user?.business_owner_country_code) {
      isValid = false;
      errors["business_owner_country_code"] = "Business owner country code cannot be blank";
    }
    let WhatsAppno = {}
    if(user?.whatsapp_no && user?.country_code){
      WhatsAppno = validatePhoneNumber(user?.whatsapp_no, user?.country_code)
    }
    if (!user?.whatsapp_no) {
      isValid = false;
      errors["whatsapp_no"] = "whatsapp number cannot be blank";
    } else if (!WhatsAppno.isValid) {
      isValid = false;
      errors['whatsapp_no'] = WhatsAppno.message;
    }
    let WhatsAppnob = {}
    if(user?.business_owner_whatsapp_no && user?.business_owner_country_code){
      WhatsAppnob = validatePhoneNumber(user?.business_owner_whatsapp_no, user?.business_owner_country_code)
    }
    if (!user?.business_owner_whatsapp_no) {
      isValid = false;
      errors["business_owner_whatsapp_no"] = "whatsapp number cannot be blank";
    } else if (!WhatsAppnob.isValid) {
      isValid = false;
      errors['business_owner_whatsapp_no'] = WhatsAppnob.message;
    }
    return {
      isValid,
      errors
    }
  };



  const handleUpdateContactDetail = () => {
    let check = handleValidation(user);

    if (check?.isValid) {
      sendDataForBrandValidation(user).then(res => {
        if(res.status === 'Success') {
          toast(SuccessMsg(res?.data?.message),{
            className:"mail_toast",
            position: toast.POSITION.TOP_CENTER,
            hideProgressBar: true,
            closeButton: false,
          })
         // window.localStorage.removeItem(CUSTOMER_ID)
          setIsOtpSent(true);
          window.scrollTo(0,0)
        } else {
          //setSameUserId(res?.data?.message)
          toast(ErrorsMsg(res?.data?.message),{
            className:"mail_toast",
            position: toast.POSITION.TOP_CENTER,
            hideProgressBar: true,
            closeButton: false,
          })
        }
      }).catch(e => {
        console.log(e)
      })
    } else {
      setErrors(check?.errors);
    }
  }

  const renderError = (keyName) => {
    return errors && errors[keyName] ? <p className="error" style={{marginTop:"20px"}}>{errors[keyName]}</p> : null;
  };

  const otpValidation = (user) => {
    let isValid = true;
    let errors = {};
    if (!user?.local_otp) {
      isValid = false;
      errors["local_otp"] = "otp cannot be blank";
    }else if(user?.local_otp.length !== 6){
      isValid=false;
      errors["local_otp"] = "otp should be of 6 digit";
    }

    return {
      isValid,
      errors
    }
  }

  const verifyHandler = () => {
    let creds = {};
    creds.local_otp = otpVal.local_otp;
    let check = otpValidation(creds)
    if(check.isValid){
      addBrandV2(user, otpVal.local_otp).then(res => {
        if(res?.status === 'Success' && res?.data?.brand) {
          const {brand,token} = res?.data
          dispatch(setCustomerData(brand));
          //window.localStorage.setItem(APP_TOKEN,token);
          nextStep();
          window.scrollTo(0, 0);
        } else {
          
          toast(ErrorsMsg("Incorrect otp"),{
            className:"mail_toast",
            position: toast.POSITION.TOP_CENTER,
            hideProgressBar: true,
            closeButton: false,
          })
        }
      });
      setErrors({})
    } else {
      setErrors(check?.errors);
    }
  }

  const OTPMsg = () => (
      <div className="otp_box">
        <img
            width={39}
            height={39}
            src={checkiconWhite}
            className="otp_resend_icon"
            loading="lazy"
            alt="icon"
        />
        <p className="title">OTP reshared</p>
      </div>
  );

  const resendHandler = () => {
    if (resend === false) {
      sendDataForBrandValidation(user).then(res => {
        if(res.status === 'Success') {
          setErrors({})
          window.scrollTo();
          resetTimer();
          setOtpVal({
            local_otp:""
          })
          setResend(true);
          toast(<OTPMsg/>, {
            className: "otp_toast",
            position: toast.POSITION.TOP_CENTER,
            hideProgressBar: true,
            closeButton: false,
          });
        }
      }).catch(e => {
      
      });

    } else {
      setResend(false);
    }
  };

      useEffect(() => {
        const resetButton = setTimeout(() => {
            setResend(false);
        }, 1000);
        return () => clearTimeout(resetButton);
    }, [resend]);

    useEffect(() => {
        let timerInterval = null;

        // Decrease time by 1 second every second
        if (time > 0) {
            timerInterval = setInterval(() => {
                setTime(prevTime => prevTime - 1);
            }, 1000);
        }

        // Clear interval and reset timer when time reaches 0
        if (time === 0) {
            clearInterval(timerInterval);
        }

        return () => clearInterval(timerInterval);
    }, [time])

    const resetTimer = () => {
        setTime(60);
    };

  return (
    isOtpSent ? (<>{  window.scrollTo({
      top: 0,
      behavior: 'smooth'
  })}
      <section className={styles.login_sec2}>
        <div className={styles.my_container}>
          <div className={styles.text_container}>
            <p className={styles.head_text}>OTP Verification</p>
            <h1 className={styles.title}>
              Verify Your OTP, Sent To Owner's Registered&nbsp;
              <br/>
              E-Mail ID & Mobile Number
              <span className={styles.coloured_text}>.</span>
            </h1>
            <div className={styles.icon_notice_wrapper}>
              <div className={styles.icon_wrapper}>
                <img
                    width={81}
                    height={81}
                    src={otpicon}
                    alt="icon"
                    className={styles.icon}
                    loading="lazy"
                />
              </div>
              <p className={styles.description}>Enter OTP</p>
            </div>
          </div>

          <div className={styles.otp_box_wrapper}>
            <OtpInput
                value={otpVal?.local_otp}
                onChange={(e) => handleotpChange("local_otp", e)}
                numInputs={6}
                containerStyle={styles.otp_input_wrapper}
                inputStyle={styles.otp_input}
                isInputNum={true}
                focusStyle={styles.otp_focused}
                shouldAutoFocus
            />
            <div style={{display:"flex",alignItems:"centre",justifyContent:"center"}}>{renderError("local_otp")}</div>
          </div>
          <div className={styles.next_logout}>
           
            <div className={styles.cta_wrapper}>
              <div
                  className={styles.next_button}
                  style={{
                    opacity: otpVal.local_otp === "" ? 0.5 : 1,
                    cursor: otpVal.local_otp === "" ? "no-drop" : "pointer",
                  }}
                  onClick={verifyHandler}
              >
                <div className={styles.text}>Verify</div>
                <img
                    src={rightarrowblack}
                    alt="icon"
                    className={styles.icon}
                    loading="lazy"
                />
              </div>
              {time !== 0 ?
                                        <div className={styles.back_button}>
                                            Resend otp in {time} sec
                                        </div>
                                        :
                                        <div className={styles.back_button} onClick={resendHandler}>
                                            {resend === false ? "Resend OTP" : "Resending OTP"}
                                        </div>
                                    }
            </div>
          </div>
        </div>
      </section>
  
    </>) : (
        <>
          <div className={style.text_container}>
            <h1 className={style.title}> Your Business Account Details</h1>
            <p className={style.description}></p>
          </div>
          <div className={`${style.steps} ${style.rstep1}`}>
            <div className={`row`}>
              <div className={`col-md-6 ${style.rstep01_col}`}>
                <div className={style.field_wrapper}>
                  <FullWidthTextField
                      key="Name of the Business*"
                      label="Name of the Business* "
                      value={user?.business_name}
                      keyName={"business_name"}
                      onChange={(e) => handleChangeUser("business_name", e.target.value)}
                  />
                  {renderError("business_name")}
                </div>
              </div>
              <div className={`col-md-6 ${style.rstep01_col}`}>
                <div className={style.field_wrapper}>
                  <FullWidthTextField
                      key="Name of the Contact person*"
                      label="Name of the Contact person* "
                      value={user?.contact_name}
                      keyName={"contact_name"}
                      onChange={(e) => handleChangeUser("contact_name", e.target.value)}
                  />
                  {renderError("contact_name")}
                </div>
              </div>
              <div className={`col-md-6 ${style.rstep01_col}`}>
                <div className={style.field_wrapper}>
                  <div className="row">
                    <div className="col-4 col-sm-4 col-md-4 ps-0">
                      <div className={style.country_code}>
                        <CountryCodeDropdown id={"count1"} textLabel="Code" handleChangeUser={handleChangeUser}/>
                        {/* <CountryCodeDropdown textLabel="Code" handleChangeUser={handleChangeUser} /> */}
                        {renderError("country_code")}
                      </div>
                    </div>
                    <div className="col-8 col-sm-8 col-md-8 pe-0">
                      <div className={style.country_code}>
                        <FullWidthTextField
                            key="number"
                            label="Phone*"
                            type="number"
                            value={user?.contact_phone}
                            onChange={(e) => {
                              handleChangeUser("contact_phone", e.target.value)
                            }}
                        />
                        {renderError("contact_phone")}
                        <p className="error">{phonee}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={`col-md-6 ${style.rstep01_col}`}>
                <div className={style.field_wrapper}>
                  <FullWidthTextField
                      key="WhatsApp number*"
                      label="WhatsApp number*"
                      // defaultValue={user?.whatsapp_phone}
                      value={!isWhatsappSame ? user?.whatsapp_no : user?.contact_phone}
                      onChange={(e) => {
                        handleChangeUser("whatsapp_no", e.target.value)
                      }}
                  />
                  {renderError("whatsapp_no")}
                </div>
                <div className={style.checkbox_wrapper}>
                  <label className={style.checkbox_label} for="sameas">
                    {/* <input hidden name="inw"/> */}
                    <input
                        checked={isWhatsappSame}
                        name="inw"
                        type="checkbox"
                        className={style.check_box}
                        id="sameas"
                        onChange={(e) => {
                          if (!isWhatsappSame) { handleChangeUser("whatsapp_no", user?.contact_phone) }
                          setIsWhatsappSame(prev => !prev)
                        }}

                    />
                    Same as phone number
                  </label>
                </div>
              </div>
              <div className={`col-md-6 ${style.rstep01_col}`}>
                <div className={style.field_wrapper}>
                  <FullWidthTextField
                      key="Email ID*"
                      label="Email ID* "
                      value={user?.business_personal_contact_email}
                      keyName={"business_personal_contact_email"}
                      onChange={(e) => handleChangeUser("business_personal_contact_email", e.target.value)}
                  />
                  {renderError("business_personal_contact_email")}
                </div>
              </div>
              <div className={`col-md-6 ${style.rstep01_col}`}>
                <div className={style.field_wrapper}>
                  <AutoCompleteField
                      textLabel="Country*"
                      data={country}
                      handleUserDetail={handleUser}
                      keyName={"country"}
                      disableClearable={false}
                      course={user?.country}
                  />

                  {renderError("country")}
                  {/* <FullWidthTextField
                key="Address or link of your address*"
                fullWidth={true}
                rows={1}
                rowsMax={1}
                label="Address or link of your address*"
                value={user?.address}
                onChange={(e) => handleChangeUser("address", e.target.value)}
              />
              {renderError("address")} */}
                </div>
              </div>
           { user?.country === "India" && (<> <div className={`col-md-6 ${style.rstep01_col}`}>
                <div className={style.field_wrapper}>
                  <AutoCompleteField
                      textLabel="City*"
                      data={city}
                      handleUserDetail={handleUser}
                      keyName={"city"}
                      course={user?.city}
                  />

                  {renderError("city")}
                  {/* <FullWidthTextField key="GST number" label="GST number"
              value={user?.gstin_number}
              onChange={(e) => handleChangeUser("gstin_number", e.target.value)}
              />
             {renderError("gstin_number")} */}
                </div>
              </div>
              <div className={`col-md-6 ${style.rstep01_col}`}>
                <div className={style.field_wrapper}>
                  <FullWidthTextField
                      key="pincode"
                      label="Pincode"
                      value={user?.pincode}
                      onChange={(e) => handleChangeUser("pincode", e.target.value)}
                  />
                  {renderError("pincode")}{" "}
                  <p className="error">{samePincode}</p>
                </div>
              </div> </>)
              }
              <div className={`col-md-6 ${style.rstep01_col}`}>
                <div className={style.field_wrapper}>
                  <FullWidthTextField
                      key="accountusername"
                      label="Account Username*"
                      value={user?.brand_username}
                      onChange={(e) => handleChangeUser("brand_username", e.target.value)}
                  />
                  {renderError("brand_username")}{" "}
                  <p className="error">{sameUserId}</p>
                </div>
              </div>
              <div className={`col-md-6 ${style.rstep01_col}`}>
                <div className={style.field_wrapper}>
                <PasswordInputLightTheme
                    value={user?.brand_password}
                    keyName={"brand_password"}
                    label={"Account Password*"}
                    id={"main-password-account"}
                    handleChangeUser={handleChangeUser} 
                    />
                  {renderError("brand_password")}{" "}
                </div>
              </div>
            </div>
          </div>
          <div className={style.text_container}>
            <hr style={{ margin: "60px 0" }} />
            <h1 className={style.title}>Owners Contact Details</h1>
            <p className={style.description}></p>
          </div>
          <div className={`${style.steps} ${style.rstep1}`}>
            <div className={`row`}>
              <div className={`col-md-6 ${style.rstep01_col}`}>
                <div className={style.field_wrapper}>
                  <FullWidthTextField
                      key="Name of the owner"
                      label="Name of the Owner*"
                      value={user?.business_owner_name}
                      keyName={"business_owner_name"}
                      onChange={(e) => handleChangeUser("business_owner_name", e.target.value)}
                  />
                  {renderError("business_owner_name")}
                </div>
              </div>
              <div className={`col-md-6 ${style.rstep01_col}`}>
                <div className={style.field_wrapper}>
                  <FullWidthTextField
                      key="emailid"
                      label="Email ID"
                      value={user?.business_owner_contact_email}
                      keyName={"business_owner_contact_email"}
                      onChange={(e) => handleChangeUser("business_owner_contact_email", e.target.value)}
                  />
                  {renderError("business_owner_contact_email")}
                </div>
              </div>
              <div className={`col-md-6 ${style.rstep01_col}`}>
                <div className={style.field_wrapper}>
                  <div className="row">
                    <div className="col-4 col-sm-4 col-md-4 ps-0">
                      <div className={style.country_code}>
                        <CountryCodeDropdown
                            textLabel="Code"
                            id={"count2"}
                            keyName={"business_owner_country_code"}
                            handleChangeUser={handleChangeUser}
                        />
                        {renderError("business_owner_country_code")}
                      </div>
                    </div>
                    <div className="col-8 col-sm-8 col-md-8 pe-0">
                      <div className={style.country_code}>
                        <FullWidthTextField
                            key="number"
                            label="Phone*"
                            type="number"
                            value={user?.business_owner_contact_phone}
                            onChange={(e) => {
                              handleChangeUser("business_owner_contact_phone", e.target.value)
                            }}
                        />
                        {renderError("business_owner_contact_phone")}
                        <p className="error">{ownerPhone}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={`col-md-6 ${style.rstep01_col}`}>
                <div className={style.field_wrapper}>
                  <FullWidthTextField
                      key="business_owner_whatsapp_no*"
                      label="WhatsApp number*"
                      // defaultValue={user?.whatsapp_phone}
                      value={!isWhatsappBusiness ? user?.business_owner_whatsapp_no : user?.business_owner_contact_phone}
                      onChange={(e) => {
                        handleChangeUser("business_owner_whatsapp_no", e.target.value)
                      }}
                  />
                  {renderError("business_owner_whatsapp_no")}
                </div>
                <div className={style.checkbox_wrapper}>
                  <label className={style.checkbox_label} for="sames5">
                    {/* <input hidden name="inw"/> */}
                    <input
                        checked={isWhatsappBusiness}
                        name="inwj"
                        type="checkbox"
                        className={style.check_box}
                        id="sames5"
                        onChange={(e) => {
                          if (!isWhatsappBusiness) { handleChangeUser("business_owner_whatsapp_no", user?.business_owner_contact_phone) }
                          setIsWhatsappBusiness(prev => !prev)
                        }}

                    />
                    Same as phone number
                  </label>
                </div>
              </div>
            </div>
          </div>

          <div className={style.next_logout}>
            <div className={style.cta_wrapper}>
              <div
                  className={style.next_button}
                  onClick={handleUpdateContactDetail}
              >
                <div className={style.text}>Send OTP</div>
                <img
                    src={rightarrowblack}
                    alt="icon"
                    className={style.icon}
                    loading="lazy"
                />
              </div>
            </div>
            {/*<LogoutText userExtraData={userExtraData} userData={user} />*/}
          </div>
        </>
    )
  );
};

export default RStep01;
