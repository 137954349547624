import React, { useEffect, useState } from "react";
import style from "../Form/formfive.module.scss";
import LogoutText from "../../../components/LogoutText/LogoutText";
import { rightarrowblack, rightarrowwhite } from "../../../images";
import RadioButton from "../../../components/RadioButton/RadioButton";
import callApi from "../../../util/apiCaller";
import { updateCustomerArchPro } from "../../../redux/app/appAction";
import { useDispatch, useSelector } from "react-redux";
import { getBrandId, getBrand } from "../../../redux/app/appSelectors";
import {ErrorsMsg, SuccessMsg} from "../../../util/Message";
import {toast} from "react-toastify";
import { setBrand, setBrandId,updateCustomerArchProV2,setCustomerData} from "../../../redux/app/appAction";
import RadioButtonLightTheme from "../../../components/RadioButtonLightTheme/RadioButtonLightTheme";

// const formAProjectSizeArr = [
//   "Anything",
//   "500-2000 Sft",
//   "2000-5000 Sft",
//   "5000-10000 Sft",
//   "Above 10000 Sft",
// ];

const FAStep05 = ({
  previousStep,
  nextStep,
  currentStep,
  totalSteps,
  progressStatus,
  handleUserExtraData, userExtraData
}) => {
  const dispatch = useDispatch();
  const brandId = useSelector((state) => getBrandId(state)) ;
  const brandData = useSelector((state) => getBrand(state))
  const [errors, setErrors] = useState({});
  //const [showError, setShowError] = useState(true)
  const [code, setCode] = useState([])
  const [projectSize, setProjectSize ] = useState()

  useEffect(() => {
    setProjectSize({"project_size": brandData?.project_size})
  },[brandData])

  const handleChangeUser = ( keyName,keyValue) => {

    if(keyValue){
      setErrors({})
    }
    let update ={...projectSize};
    update["project_size"] = keyValue;
    update.brand_current_flow = currentStep;
    setProjectSize(update);

  }
  const handleValidation = (user) => {
    let isValid = true;
    let errors = {};
    if (!user?.project_size) {
        isValid = false;
        errors["project_size"] = "Please select project size";
    }

      return {
        isValid,
        errors
    }
  }
  const handleUpdateApi = (brandId,projectSize) => {
    let check = handleValidation(projectSize);
   
    if(check.isValid && brandId && projectSize){
      updateCustomerArchProV2(brandId, projectSize).then(res => {
        if(res?.status === 'Success' && res?.data?.brand) {
          dispatch(setCustomerData(res?.data?.brand));
          nextStep();
          window.scrollTo(0, 0);
        } else {
          toast(ErrorsMsg(res?.data?.message),{
            className:"mail_toast",
            position: toast.POSITION.TOP_CENTER,
            hideProgressBar: true,
            closeButton: false,
          })
        }
      });

      setErrors({})
    }else {
      setErrors(check?.errors);
      }
  }
  const renderError = (keyName) => {
    return errors && errors[keyName] ? <p className="error" >{errors[keyName]}</p> : null;
  };


  useEffect(() => {

    fetchContentCode("minimum-project-size")
   },[])

  async function fetchContentCode(code) {

    try {
      const res = await callApi(`content/code/${code}`, "get");
      if (res && res?.status === 'Success') {
        const { params } = res?.data?.content;
        //let param = params?.map((option) => option.label)
        setCode(params);
      } else {
      }
    } catch (err) {

    }
  }

  const formAProjectSizeList = code?.map((option) => (
    <React.Fragment key={`${option.value}cee`}>
      <RadioButtonLightTheme label={option.label} labelId={`sizec3${option.value}`} value={option.value} checked={option?.value === projectSize?.project_size} handleChangeUser={handleChangeUser} />
    </React.Fragment>
  ));

  return (
    <>
      <div className={style.text_container}>
      <p className={`${style.head_text1}`}>About Your Project</p>
        <h1 className={style.title}>Minimum Project Size</h1>
        <p className={style.description}></p>
      </div>
      <div className={`${style.steps} ${style.step01}`}>
        <ul className={style.steps_ul}>{formAProjectSizeList}</ul>
        <div style={{display:"flex",justifyContent:"center",alignItems:"center"}}>{renderError("project_size")}</div> 
      </div>

      <div className={style.next_logout}>
       
        <div className={style.cta_wrapper}>
          <div
            className={style.next_button}
            onClick={() => handleUpdateApi(brandId,projectSize )}
          >
            <div className={style.text}>Next</div>
            <img
              src={rightarrowblack}
              alt="icon"
              className={style.icon}
              loading="lazy"
            />
          </div>
          <div
            className={style.back_button}
            onClick={() => {
              previousStep(4);
              window.scrollTo(0, 0);
            }}
          >
            Back
          </div>
        </div>
        <LogoutText  userData={projectSize} />
      </div>
    </>
  );
};

export default FAStep05;
