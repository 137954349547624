import React from "react";
import "./floatingicon.scss";
import { floatingIcon, floatingIcon1 } from "../../images";
import { Link } from "react-router-dom";

const FloatingIcon = () => {
  const getWhatsappRedirection = () => {
    const phoneNumber = 919871185558;
    const text = `Hi`
    return `https://wa.me/${phoneNumber}?text=${encodeURI(text)}`
  }
  return (
    <>
      <div className="floating_sec1">
        <Link target={'_blank'} to={getWhatsappRedirection()}>
          <div className="img_wrapper">
            <img src={floatingIcon} alt="whatsapp" className="floating_icon1" />
            <img
              src={floatingIcon1}
              alt="whatsapp"
              className="floating_icon2"
            />
          </div>
        </Link>
      </div>
    </>
  );
};

export default FloatingIcon;

