import React from "react";
import "./businessconsole.scss";

import {
  consoleArrow,
  consoleBackground_1,
  consoleDot,
  rightarrowwhite,
  rocket,
} from "../../images";
import { Link } from "react-router-dom";
import consoleDataJSON from "../../constants/consoleData.json"
import ProfileCard from "../../components/ProfileCard/ProfileCard";
import ConsoleNoticeCard from "../../components/ConsoleNoticeCard/ConsoleNoticeCard";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import { useWindowSize } from "react-use";
import ReachCard from "../../components/ReachCard/ReachCard";
import { useSelector } from "react-redux";
import {getCustomer} from "../../redux/app/appSelectors";
import { businessAccessURL, businessFormFiveURL } from "../../components/helpers/constant-words";

export const businessData = [
  {
    id: 1,
    greet: "Hey there,",
    name: "Talha",
    btn: "Business | Firm Owner",
    btnColor: "#ED008C",
    phone: "+91 7506377907",
    tel: "+917506377907",
    email: "talhaasre.dev@gmail.com",
    address: "Mumbai, Maharashtra, India",
    heading: "Did you know?",
    desc: "The Design & Build Industry accounts for 60% employment in india, with 52% being women.",
    bckImg: consoleBackground_1,
  },
];

const BusinessConsole = () => {
  const { width } = useWindowSize();
  const customer = useSelector(state => getCustomer(state));
  return (
    <>
      <main className="main_container">
        <section className="businessconsole_sec1">
         
            <>
              <div className="my_container">
                <img
                  src={consoleBackground_1}
                  alt="background"
                  className="console_background"
                />
                <img src={consoleDot} alt="dot" className="console_dot1" />
                <img src={consoleDot} alt="dot" className="console_dot2" />

                <div className="row console_row" >
                  <div className="col-lg-8 console_col">
                    <ProfileCard
                      name={customer?.contact_name}
                      ctaTextColor={consoleDataJSON.USER_CONSOLE_BTN_COLOR}
                      title={customer?.user_type}
                      contactNo={customer?.contact_phone}
                      emailId={customer?.contact_email}
                      address={customer?.address}
                    />
                  </div>
                  <div className="col-lg-4 console_col">
                    <Swiper
                      modules={[Pagination]}
                      spaceBetween={20}
                      pagination={true}
                    >
                      <SwiperSlide>
                        <ConsoleNoticeCard
                           title={consoleDataJSON.USER_CONSOLE_HEADING}
                           notice={consoleDataJSON.USER_CONSOLE_DESCRIPTION}
                        />
                      </SwiperSlide>
                      <SwiperSlide>
                        <ConsoleNoticeCard
                          title={consoleDataJSON.USER_CONSOLE_HEADING}
                          notice={consoleDataJSON.USER_CONSOLE_DESCRIPTION}
                        />
                      </SwiperSlide>
                      <SwiperSlide>
                        <ConsoleNoticeCard
                          title={consoleDataJSON.USER_CONSOLE_HEADING}
                          notice={consoleDataJSON.USER_CONSOLE_DESCRIPTION}
                        />
                      </SwiperSlide>
                      <SwiperSlide>
                        <ConsoleNoticeCard
                          title={consoleDataJSON.USER_CONSOLE_HEADING}
                          notice={consoleDataJSON.USER_CONSOLE_DESCRIPTION}
                        />
                      </SwiperSlide>
                    </Swiper>
                  </div>
                </div>
              </div>
            </>
         
        </section>

        <section className="reach_sec">
          <div className="my_container">
            <div className="row">
              <div className="col-lg-8 p-0">
                <ReachCard
                  url={businessFormFiveURL}
                  ctaText="Onboard Your Business | Service"
                  noticeText="You will be required to upload product catalogues, company
              profile, logo"
                />
              </div>
              <div className="col-lg-4"></div>
            </div>
          </div>
        </section>

        <section className="businessconsole_sec2">
          <div className="my_container">
            <div className="row intro_row">
              <div className="col-lg-12 intro_col">
                <h2 className="heading">
                  Welcome to the Archinza Design Network!
                </h2>
                <div className="intro_wrapper">
                  <p className="desc">We will notify you when we launch!</p>
                  <img
                    src={rocket}
                    alt="rocket launch"
                    className="rocket_img"
                  />
                </div>
              </div>
              <div className="col-lg-7 intro_col">
                <div className="sub_content_wrapper">
                  <h2 className="title">Congratulations!</h2>
                  <h2 className="subheading">
                    You are eligible for free early access to{" "}
                    <span className="white_text">Archinza PRO!</span>
                  </h2>
                </div>
              </div>
              <div className="col-lg-5 intro_col">
                <div className="button_container">
                  <Link to={businessAccessURL} className="cta_wrapper">
                    <p className="cta_text">
                      Complete Claiming Free Access To ARCHINZA PRO Now
                      {width < 600 && (
                        <img
                          src={rightarrowwhite}
                          alt="arrow"
                          className="arrow_img"
                        />
                      )}
                    </p>
                    {width > 600 && (
                      <img
                        src={rightarrowwhite}
                        alt="arrow"
                        className="arrow_img"
                      />
                    )}
                  </Link>
                  <div className="notice">
                    <Link className="orange_text" to="/"> Click here</Link> to Save &
                    Exit
                  </div>
                </div>
                 <div className="next_button_container">
                  {/* <Link to={() => false} className="cta_wrapper">
                    <img src={consoleArrow} alt="arrow" className="arrow_img" />
                    <p className="cta_text">start</p>
                  </Link>
                  <button className="remind_btn">Remind me later</button> */}
                  {/* <ConsolePopup /> */}
                </div> 
              </div>
            </div>
            {width < 600 && (
              <div className="mobile_notice">
                {businessData.map((item, i) => (
                  <Swiper
                    modules={[Pagination]}
                    spaceBetween={20}
                    pagination={true}
                  >
                    <SwiperSlide>
                      <ConsoleNoticeCard
                        title={item.heading}
                        notice={item.desc}
                      />
                    </SwiperSlide>
                    <SwiperSlide>
                      <ConsoleNoticeCard
                        title={item.heading}
                        notice={item.desc}
                      />
                    </SwiperSlide>
                    <SwiperSlide>
                      <ConsoleNoticeCard
                        title={item.heading}
                        notice={item.desc}
                      />
                    </SwiperSlide>
                    <SwiperSlide>
                      <ConsoleNoticeCard
                        title={item.heading}
                        notice={item.desc}
                      />
                    </SwiperSlide>
                  </Swiper>
                ))}
              </div>
            )}
          </div>
        </section>
      </main>
    </>
  );
};

export default BusinessConsole;
