import Modal from "react-bootstrap/Modal";
import "./form-modal.scss";

const defineArr = [
  "Personal Account : Ideal for Individuals who want to discover people and products for their projects",
  "Business Account : Ideal for Individuals/ Business / Firm Owners who want to reach out to a wider audience, and be found matchmade by AI"
];

function FormFiveModal(props) {
  const defineList = defineArr.map((data, i) => (
    <li className="list_item" key={`define-${Math.random(i)}`}>
      {data}
    </li>
  ));
  return (
    <Modal
      {...props}
      className="define_modal"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <div className="define_modal_wrapper">
          <div className="icon" style={{color:"#fff"}} >&#9432;</div>
          <ul className="define_list" style={{color:"#fff"}}>{defineList}</ul>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default FormFiveModal;
