import React, { useEffect , useState} from "react";
import style from "../Form/formfive.module.scss";
import LogoutText from "../../../components/LogoutText/LogoutText";
import FullWidthTextField from "../../../components/TextField/FullWidthTextField";
import { rightarrowblack, rightarrowwhite } from "../../../images";
import { updateCustomerArchPro ,updateCustomerArchProV2,setCustomerData} from "../../../redux/app/appAction";
import {ErrorsMsg, SuccessMsg} from "../../../util/Message";
import {toast} from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { getBrandId, getBrand } from "../../../redux/app/appSelectors";


const FIStep01 = ({
  nextStep,
  currentStep,
  totalSteps,
  progressStatus,
  previousStep,
  handleUserExtraData,
  userExtraData,
  goToStep
}) => {
  const dispatch = useDispatch();
  const brandId = useSelector((state) => getBrandId(state)) ;
  const brandData = useSelector((state) => getBrand(state));
  const [user, setUser] = useState({
    admin_media_email: "",

 });
 const [errors, setErrors] = useState({});

  useEffect(() => {
    if(brandData?.admin_media_email){
    setUser({ admin_media_email: brandData?.admin_media_email})
    }
  },[brandData])

 const handleChangeUser = (keyName, keyValue) => {
   let update = {...user};
   if(keyValue){
    setErrors({})
   }
   update[keyName] = keyValue;
   update.brand_current_flow = currentStep;
   setUser(update);
 }
 const handleValidation = (user) => {
   let isValid = true;
   let errors = {};

   if (!user?.admin_media_email) {
       isValid = false;
       errors["admin_media_email"] = " Email ID cannot be blank";
   }else {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(user?.admin_media_email)) {
        isValid = false;
        errors["admin_media_email"] = "Invalid email";
    }
  }

   return {
       isValid,
       errors
   }
 };
 const handleUpdateContactDetail = () => {
  let check = handleValidation(user);
    
  if(check.isValid && brandId && user){
    updateCustomerArchProV2(brandId, user).then(res => {
      if(res?.status === 'Success' && res?.data?.brand) {
        dispatch(setCustomerData(res?.data?.brand));
        nextStep();
        window.scrollTo(0, 0);
      } else {
        toast(ErrorsMsg(res?.data?.message),{
          className:"mail_toast",
          position: toast.POSITION.TOP_CENTER,
          hideProgressBar: true,
          closeButton: false,
        })
      }
    });

    setErrors({})
  }else {
    setErrors(check?.errors);
    }

 }
 const renderError = (keyName) => {
   return errors && errors[keyName] ? <p className="error" style={{margin: "10px 0", }}>{errors[keyName]}</p> : null;
 };



  return (
    <>
      <div className={style.text_container}>
      <p className={`${style.head_text1}`}>BUSINESS CONNECT DATA</p>
       
        <h1 className={style.title}>
          {/* Be Found By Clients,{" "}
          <span className={style.coloured_text}>led by AI!</span> */}
        Please Provide your Email IDs
        </h1>
        <p className={style.description}></p>
      </div>
      <div className={`${style.steps} ${style.fastep01}`}>
        <div className={style.led_ai_row}>
          <div className={style.field_wrapper}>
            <FullWidthTextField label="Email ID*" value={user?.admin_media_email} key={"email_i"}
                onChange={(e) => handleChangeUser("admin_media_email", e.target.value)}/>

          </div>
          {/* <p className={style.description} style={{color:"#111"}}> 
 	
   eg.  MEDIA {" "}-{" "} media@abc.net</p>
   <p className={style.description} style={{color:"#111"}}> 
 	
   eg.  ADMIN {" "}-{" "} admin@abc.net</p> */}
        
          {renderError("admin_media_email") }
        </div>
      </div>

      <div className={style.next_logout}>
        <div className={style.cta_wrapper}>
          <div
            className={style.next_button}
            onClick={handleUpdateContactDetail}
          >
            <div className={style.text}>Next</div>
            <img
              src={rightarrowblack}
              alt="icon"
              className={style.icon}
              loading="lazy"
            />
          </div>
          <div
            className={style.back_button}
            onClick={() => {
              previousStep()
              setErrors({})
              window.scrollTo(0, 0);
            }}
          >
            Back
          </div>
        </div>
        <LogoutText userData={user}/>
      </div>
    </>
  );
};

export default FIStep01;