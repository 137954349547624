import { checkicon, checkiconWhite, otpicon, rightarrowwhite } from "../images/index";

export const SuccessMsg = (msg) => (
    <div className="otp_box">
      <img
        width={39}
        height={39}
        src={checkiconWhite}
        className="otp_resend_icon"
        loading="lazy"
        alt="icon"
      />
      <p className="title" >{msg}</p>
    </div>
  );


  export const ErrorsMsg = (msg) => (
    <div className="otp_box">
      {/* <img
        width={39}
        height={39}
        src={checkiconWhite}
        className="otp_resend_icon"
        loading="lazy"
        alt="icon"
      /> */}
      <p className="title text-center" >{msg}</p>
    </div>
  );


