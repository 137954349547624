import React, { useEffect , useState} from "react";
import style from "../BusinessAccess/business.module.scss";
import LogoutText from "../../../components/LogoutText/LogoutText";
import RadioButton from "../../../components/RadioButton/RadioButton";
import { rightarrowwhite } from "../../../images";
import { useDispatch, useSelector } from "react-redux";
import { updateCustomerProfile } from "../../../redux/app/appAction";
import { getUserId, getCustomer } from "../../../redux/app/appSelectors";
import { fetchContentCode } from "../../../redux/app/appAction";
import { getCode } from "../../../redux/app/appSelectors";
import callApi from "../../../util/apiCaller";

//const expArr = ["0 - 5 years", "5 - 10 years", "10 - 20 years", "20+ years"];

const BAStep02 = ({ nextStep, previousStep, currentStep, totalSteps, progressStatus, handleUserExtraData, userExtraData }) => {
  const dispatch = useDispatch();
  const userId = useSelector((state) => getUserId(state)) ;
  const customer = useSelector((state) => getCustomer(state))
  const [code ,setCode] = useState({})
  const [errors, setErrors] = useState({});
  //const content = useSelector((state) => getCode(state));
  const [firmExperience, setFirmExperience] = useState({
    years_of_experience: ""
  })

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    if(customer?.years_of_experience){
    setFirmExperience({
      years_of_experience: customer?.years_of_experience
    })
  }
  },[customer])
  const handleChangeUser = (keyName, keyValue) => {
      let update = {...firmExperience};
      if(keyValue){
        setErrors({})
      }
      update["years_of_experience"] = (keyValue)
      update.current_flow = currentStep;
      setFirmExperience(update);

  }
  const handleValidation = (user) => {
    let isValid = true;
    let errors = {};
    if (!user?.years_of_experience) {
        isValid = false;
        errors["years_of_experience"] = "Please select firm experience";
    }

      return {
        isValid,
        errors
    }
  }

  const handleUpdateApi = () => {
    let check = handleValidation(firmExperience);
    let update= {...firmExperience}
    update.current_flow = currentStep;
    if(check.isValid && userId ){
      dispatch(updateCustomerProfile(userId, update))
      //handleUserExtraData(firmExperience);
      nextStep(3);
      setErrors({})
      window.scrollTo(0, 0);
    }else {
      setErrors(check.errors);
    }

   }
   const renderError = (keyName) => {
    return errors && errors[keyName] ? <p className="error" >{errors[keyName]}</p> : null;
  };
   useEffect(() => {

    fetchContentCode("business-firm-experience-years")
   },[])
   async function fetchContentCode(code) {

    try {
      const res = await callApi(`content/code/${code}`, "get");
      console.log(res);
      if (res && res?.status === 'Success') {
        const { content } = res?.data;
        setCode(content);
      } else {
      }
    } catch (err) {

    }

  }

  const expList = code?.params?.map((option) => (
    <React.Fragment key={option?.label}>
      <RadioButton label={option?.label} labelId={option?.label} value={option?.value} checked={option?.label === firmExperience?.years_of_experience} handleChangeUser={handleChangeUser} />
    </React.Fragment>
  ));

  useEffect(() => {
    progressStatus((currentStep / totalSteps) * 100);
  }, [currentStep, progressStatus, totalSteps]);

  return (
    <>
      <div className={style.text_container}>
        <h1 className={style.title}>Please select how long your firm has been established.</h1>
        <p className={style.description}></p>
      </div>
      <div className={`${style.steps} ${style.step03}`}>
        <ul className={style.steps_ul}>{expList}</ul>
        <div style={{display: "flex",justifyContent:"center",alignItems:"center"}}>{renderError("years_of_experience")}</div>
      </div>

      <div className={style.next_logout}>
      
        <div className={style.cta_wrapper}>
          <div
            className={style.next_button}
            onClick={handleUpdateApi}
          >
            <div className={style.text}>Next</div>
            <img
              src={rightarrowwhite}
              alt="icon"
              className={style.icon}
              loading="lazy"
            />
          </div>
          {/* <div
            className={style.back_button}
            onClick={() => {
              previousStep(8);
              setErrors({})
              window.scrollTo(0, 0);
            }}
          >
            Back
          </div> */}
        </div>
        <LogoutText userExtraData={firmExperience} />
      </div>
    </>
  );
};

export default BAStep02;
