

export const getCustomer = state => state.app.user;
export const getIsAuthenticated = state => state.app.isAuthenticated;
export const getUserId = state => state.app.activeUserId;
export const getCode = state => state.app.code;
export const getError = state => state.app.error;
export const getBrand = state => state.app.brand;
export const getBrandId = state => state.app.brandId;
export const getBrandCount = state => state.app.brandCount;
export const getCityList = state => state.app.cityList;
export const getBrandAccounts = state => state.app.brandAccounts;
export const getISPro = state => state.app.isPro;
export const getCreateAccount = state => state.app.isCreateAccount;
export const getBrandText = state => state.app.brandText;
export const getPersonalText = state => state.app.personalText;