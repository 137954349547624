import style from "./checkboxbutton.module.scss";
import { useSelector } from "react-redux";
import { getCreateAccount } from "../../redux/app/appSelectors";

const CheckboxButton = ({ keyName,label, labelId, isChecked , handleUserDetail,i}) => {
  const lightTheme =   useSelector((state) => getCreateAccount(state));
  return (
    <>
      <div className={`${lightTheme === false ? style.checkbox_wrapper_light : style.checkbox_wrapper}`}>
        <input
          className={style.checkbox_input}
          type="checkbox"
          value={label}
          name="checkbox"
          id={labelId}
          checked={isChecked}
          onChange={(e) => handleUserDetail(keyName, e.target.value,i)}
        />
        <label className={style.checkbox_label} htmlFor={labelId}>
          {label}
          <div className={style.close}></div>
        </label>
      </div>
    </>
  );
};

export default CheckboxButton;

